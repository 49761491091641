<template>
    <div class="px-4 py-2 absolute w-full">
        <div class="flex flex-wrap items-center justify-between pb-2">
            <Breadcrumb :text="breadcrumb"/>
            <router-link to="/occupation-list">
                <button class="back-btn" type="button">
                    <back-icon class="mt-1 mr-2"/>
                    Back to list
                </button>
            </router-link>
        </div>

        <div class="visasis-card">
            <div class="grid grid-cols-1 md:grid-cols-2 gap-x-4 gap-y-2">
                <div class="w-full">
                    <label>Select Assessing Auth</label>
                    <v-select
                        v-model="form.assessing_authority_uuid"
                        :options="assessing_auth_list"
                        :reduce="accessing_authority => accessing_authority.uuid"
                        class="pt-1"
                        label="name"
                        multiple
                        placeholder="Choose..."
                    />
                </div>
                <div class="w-full">
                    <label class="flex items-center">Occupation Name <img alt="required"
                                                                          class="w-2 h-2 ml-1" src="@/assets/images/star.svg">
                    </label>
                    <input v-model='form.name'
                           class="py-2 rounded border border-gray-300 bg-gray-50 text-sm placeholder-gray-400 focus:border-invblue resize-none w-full px-4 mt-1"
                           placeholder="Name" type='text'>
                    <span v-if='form.errors().has("name")' class="text-red-800 text-xs">
                        {{ form.errors().get('name') }}
                    </span>
                </div>
                <div class="w-full">
                    <label class="flex items-center">Slug <img alt="required" class="w-2 h-2 ml-1"
                                                               src="@/assets/images/star.svg"> </label>
                    <input v-model='form.slug'
                           class="py-2 rounded border border-gray-300 bg-gray-50 text-sm placeholder-gray-400 focus:border-invblue resize-none w-full px-4 mt-1"
                           placeholder="Slug" type='text'>
                    <span v-if='form.errors().has("slug")' class="text-red-800 text-xs">
                        {{ form.errors().get('slug') }}
                    </span>
                </div>
                <div class="w-full">
                    <label class="flex items-center">ANZSCO Code <img alt="required"
                                                                      class="w-2 h-2 ml-1" src="@/assets/images/star.svg">
                    </label>
                    <input v-model='form.anzsco_code'
                           class="py-2 rounded border border-gray-300 bg-gray-50 text-sm placeholder-gray-400 focus:border-invblue resize-none w-full px-4 mt-1"
                           placeholder="Anzsco Code" type='text'>
                    <span v-if='form.errors().has("anzsco_code")' class="text-red-800 text-xs">
                        {{ form.errors().get('anzsco_code') }}
                    </span>
                </div>
                <div class="w-full">
                    <label class="flex items-center">ABS Version <img alt="required"
                                                                      class="w-2 h-2 ml-1" src="@/assets/images/star.svg">
                    </label>
                    <input v-model='form.abs_version'
                           class="py-2 rounded border border-gray-300 bg-gray-50 text-sm placeholder-gray-400 focus:border-invblue resize-none w-full px-4 mt-1"
                           placeholder="Abs Version" type='text'>
                    <span v-if='form.errors().has("abs_version")' class="text-red-800 text-xs">
                        {{ form.errors().get('abs_version') }}
                    </span>
                </div>
                <!--                <div class="w-full">-->
                <!--                    <label class="flex items-center">ABS Link <img class="w-2 h-2 ml-1" src="@/assets/images/star.svg" alt="required"> </label>-->
                <!--                    <input v-model='form.abs_link' class="py-2 rounded border border-gray-300 bg-gray-50 text-sm placeholder-gray-400 focus:border-invblue resize-none w-full px-4 mt-1" type='url' placeholder="Abs Link">-->
                <!--                    <span class="text-red-800 text-xs" v-if='form.errors().has("abs_link")'>-->
                <!--                        {{ form.errors().get('abs_link') }}-->
                <!--                    </span>-->
                <!--                </div>-->
                <div class="w-full">
                    <label class="flex items-center">Skill Level <img alt="required"
                                                                      class="w-2 h-2 ml-1" src="@/assets/images/star.svg">
                    </label>
                    <input v-model='form.skill_level'
                           class="py-2 rounded border border-gray-300 bg-gray-50 text-sm placeholder-gray-400 focus:border-invblue resize-none w-full px-4 mt-1"
                           placeholder="Skill Level" type='text'>
                    <span v-if='form.errors().has("skill_level")' class="text-red-800 text-xs">
                        {{ form.errors().get('skill_level') }}
                    </span>
                </div>
                <div class="w-full">
                    <label>Status</label>
                    <select v-model='form.status'
                            class="py-2 rounded border border-gray-300 bg-gray-50 text-sm placeholder-gray-400 focus:border-invblue resize-none w-full px-4 mt-1">
                        <option :selected="form.status==1" value="1">Active</option>
                        <option :selected="form.status==0" value="0">Inactive</option>
                    </select>
                </div>
                <div class="w-full">
                    <label>Is Popular?</label>
                    <select v-model='form.is_popular'
                            class="py-2 rounded border border-gray-300 bg-gray-50 text-sm placeholder-gray-400 focus:border-invblue resize-none w-full px-4 mt-1">
                        <option :selected="form.is_popular==1" value="1">Yes</option>
                        <option :selected="form.is_popular==0" value="0">No</option>
                    </select>
                </div>
                <div class="w-full">
                    <label>Description</label>
                    <article class="prose" style="max-width: 100%">
                        <ckeditor v-model="form.description" :config="editorConfig" :editor="editor"/>
                    </article>
                </div>
            </div>

            <button v-if="isSubmitting==false" class="mt-4 justify-center rounded-md bg-blue-600 px-6 py-1.5 text-white hover:bg-blue-500" type="button"
                    @click="createOccupation">
                Submit
            </button>
            <span v-else class="mt-4 block">
                <LoadingButton text="Submitting"/>
            </span>

        </div>
    </div>
</template>

<script>
import VisaApi from "@/libs/axios"
import form from 'vuejs-form'
import store from '@/store'
import Breadcrumb from '@/component/Breadcrumb'
import LoadingButton from "@/component/LoadingButton"
import {sanitizeName} from '@/libs/helpers.js'
import vSelect from "vue-select"
import "vue-select/dist/vue-select.css"
import BackIcon from "../../../data/icons/BackIcon.vue";
import CKEditor from "@ckeditor/ckeditor5-vue"
import {ClassicEditor} from 'ckeditor5';
import ckeditorConfig from "../../../libs/ckeditorConfig";

export default {
    components: {BackIcon, LoadingButton, Breadcrumb, "v-select": vSelect, ckeditor: CKEditor.component},
    data() {
        return {
            breadcrumb: '',
            isSubmitting: false,
            assessing_auth_list: [],
            selected_assessing: '',
            anzsco_group_list: [],

            editor: ClassicEditor,
            editorConfig: ckeditorConfig,

            form: form.default({
                id: '',
                uuid: '',
                assessing_authority_uuid: [],
                name: '',
                slug: '',
                anzsco_code: '',
                abs_version: '',
                abs_link: '',
                skill_level: '',
                description: '',
                is_popular: '0',
                status: '1',
            }).rules({
                name: 'required',
                anzsco_code: 'required',
                slug: 'required',
                abs_version: 'required',
                skill_level: 'required',
            }),
        };
    },

    methods: {
        createOccupation() {
            if (this.form.validate().errors().any()) {
                return;
            } else {
                store.commit('setting/LOADING_ON')
                this.isSubmitting = true;
                VisaApi.post("/occupation/create", this.form.all()).then((response) => {
                    store.commit('setting/LOADING_OFF')
                    if (response.code == '201') {
                        this.$toast.success(response.message, {
                            position: "top-right",
                            dismissible: true,
                        });
                        this.$router.push({name: 'occupation-list'})
                    }
                    this.isSubmitting = false;
                }).catch((error) => {
                    this.$toast.error(error.response.data.message, {
                        position: "top-right",
                        dismissible: true,
                    });
                    this.isSubmitting = false;
                    store.commit('setting/LOADING_OFF')
                });
            }
        },

        getAssessingAuthList() {
            VisaApi.get("assessing-authority").then((response) => {
                this.assessing_auth_list = response.data;
            });
        },

        getAnzscoGroupList() {
            VisaApi.get("anzsco-group").then((response) => {
                this.anzsco_group_list = response.data;
            });
        }

    },

    watch: {
        'form.name': {
            handler() {
                this.form.slug = sanitizeName(this.form.name);
            }
        }
    },

    mounted() {
        this.breadcrumb = this.$router.currentRoute.value.meta.breadcrumb;
        this.getAssessingAuthList();
        this.getAnzscoGroupList();
    }
}
</script>
