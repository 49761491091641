<template>
    <div class="grid grid-cols-1 justify-center sm:flex sm:items-center sm:justify-between gap-2 py-3 px-0">
        <div class="w-full flex justify-center sm:justify-start">
            <p class="text-gray-400">Showing {{meta.from}} to {{meta.to}} of {{meta.total}} entries</p>
        </div>
        <div class="lg:my-0 w-full flex justify-center">
            <div class="grid content-center">
                <div class="flex items-center text-gray-400">
                    <p>Page: </p>
                    <input :value="inputPage" class="w-10 h-6 px-1 ml-1 border border-gray-300 bg-gray-50 text-sm placeholder-gray-400 focus:outline-none" type="text"
                           @input="$emit('update:inputPage', $event)">
                    <button class="px-1 w-7 h-6 text-sm text-white bg-green-600 hover:bg-green-700"
                            @click="meta.current_page=inputPage">Go
                    </button>
                </div>
            </div>
        </div>
        <div class="w-full flex items-center justify-center sm:justify-end">
            <button v-if="meta.prev_page!=null" class="px-1 h-7 grid content-center mx-1 rounded bg-gray-600 hover:bg-gray-500"
                    @click="setPrevPage">
                <img alt="prev" class="w-4" src="@/assets/images/dbl_arrow_left.svg">
            </button>
            <button v-else
                    class="px-1 h-7 grid content-center mx-1 rounded cursor-not-allowed bg-gray-600 hover:bg-gray-500">
                <img alt="prev" class="w-4" src="@/assets/images/dbl_arrow_left.svg">
            </button>
            <div class="flex items-center">
                <p v-show="prev_of_prev" class="px-2.5 h-7 grid content-center rounded cursor-pointer mx-1 bg-gray-300 hover:bg-green-600 hover:text-white"
                   @click="setCurrentPage(meta.current_page-2)">
                    {{meta.current_page-2}}
                </p>
                <p v-show="meta.prev_page!=null" class="px-2.5 h-7 grid content-center rounded cursor-pointer mx-1 bg-gray-300 hover:bg-green-600 hover:text-white"
                   @click="setCurrentPage(meta.prev_page)">
                    {{meta.prev_page}}
                </p>
                <p class="px-2.5 h-7 grid content-center rounded mx-1 border cursor-not-allowed text-white border-green-600 bg-green-600">
                    {{meta.current_page}}
                </p>
                <p v-show="meta.next_page!=null" class="px-2.5 h-7 grid content-center rounded cursor-pointer mx-1 bg-gray-300 hover:bg-green-600 hover:text-white"
                   @click="setCurrentPage(meta.next_page)">
                    {{meta.next_page}}
                </p>
                <p v-show="next_of_next" class="px-2.5 h-7 grid content-center rounded cursor-pointer mx-1 bg-gray-300 hover:bg-green-600 hover:text-white"
                   @click="setCurrentPage(meta.current_page+2)">
                    {{meta.current_page+2}}
                </p>
            </div>
            <button v-if="meta.next_page!=null" class="px-1 h-7 grid content-center mx-1 rounded bg-gray-600 hover:bg-gray-500"
                    @click="setNextPage">
                <img alt="next" class="w-4" src="@/assets/images/dbl_arrow_right.svg">
            </button>
            <button v-else
                    class="px-1 h-7 grid content-center mx-1 rounded cursor-not-allowed bg-gray-600 hover:bg-gray-500">
                <img alt="next" class="w-4" src="@/assets/images/dbl_arrow_right.svg">
            </button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TablePagination',
    props: ['meta', 'prev_of_prev', 'next_of_next', 'inputPage'],
    methods: {
        setCurrentPage(page) {
            this.meta.current_page = page;
        },
        setNextPage() {
            this.meta.current_page = this.meta.current_page + 1;
        },
        setPrevPage() {
            this.meta.current_page = this.meta.current_page - 1;
        },
    },
}
</script>
