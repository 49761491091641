<template>
    <div class="px-4 py-2 absolute w-full">

        <Breadcrumb :text="breadcrumb"/>

        <div class="mb-4 border-b">
            <ul id="myTab" class="flex flex-wrap -mb-px text-sm font-medium text-center"
                data-tabs-toggle="#myTabContent" role="tablist">
                <li class="mr-2" role="presentation" @click="active_tab = 'general'">
                    <button id="general-tab"
                            :class="active_tab == 'general' ? 'text-blue-600 border-blue-600 hover:text-blue-500 hover:border-blue-400' : 'border-transparent hover:border-gray-300 dark:hover:text-gray-300'"
                            aria-controls="profile" aria-selected="false" class="inline-block p-4 border-b-2" data-tabs-target="#general"
                            role="tab" type="button">General Settings
                    </button>
                </li>
                <li class="mr-2" role="presentation" @click="active_tab = 'file'">
                    <button id="file-tab"
                            :class="active_tab == 'file' ? 'text-blue-600 border-blue-600 hover:text-blue-500 hover:border-blue-400' : 'border-transparent  hover:border-gray-300 dark:hover:text-gray-300'"
                            aria-controls="file" aria-selected="false" class="inline-block p-4 border-b-2" data-tabs-target="#file" role="tab"
                            type="button">File Settings
                    </button>
                </li>
                <li class="mr-2" role="presentation" @click="active_tab = 'social'">
                    <button id="social-tab"
                            :class="active_tab == 'social' ? 'text-blue-600 border-blue-600 hover:text-blue-500 hover:border-blue-400' : 'border-transparent  hover:border-gray-300 dark:hover:text-gray-300'"
                            aria-controls="social" aria-selected="false" class="inline-block p-4 border-b-2" data-tabs-target="#social" role="tab"
                            type="button">Social Settings
                    </button>
                </li>
            </ul>
        </div>
        <div id="myTabContent" class="visasis-card">
            <div id="general" :class="{ 'hidden' : active_tab != 'general' }" aria-labelledby="general-tab" class="rounded-lg"
                 role="tabpanel">
                <general-settings @updated="getSettings"/>
            </div>
            <div id="file" :class="{ 'hidden' : active_tab != 'file' }" aria-labelledby="file-tab" class="rounded-lg"
                 role="tabpanel">
                <file-settings @updated="getSettings"/>
            </div>
            <div id="social" :class="{ 'hidden' : active_tab != 'social' }" aria-labelledby="social-tab" class="rounded-lg"
                 role="tabpanel">
                <social-settings @updated="getSettings"/>
            </div>
        </div>

    </div>
</template>

<script>
import VisaApi from "@/libs/axios"
import form from 'vuejs-form'
import GeneralSettings from "../../component/settings/GeneralSettings.vue";
import FileSettings from "../../component/settings/FileSettings.vue";
import SocialSettings from "../../component/settings/SocialSettings.vue";
import Modal from '@/component/Modal'
import DeleteModal from '@/component/DeleteModal'
import Table from '@/component/Table'
import Breadcrumb from '@/component/Breadcrumb'
import store from '@/store'

export default {
    components: {
        Modal, DeleteModal, Table, Breadcrumb,
        GeneralSettings, FileSettings, SocialSettings
    },

    data() {
        return {
            mdl_title: 'Setting',
            modal_mode: 'create',
            deletable_id: '',
            tableData: [],
            search_input: '',
            breadcrumb: '',
            url: '',
            sort_status: false,
            isDeleteSubmitting: false,
            isModalSubmitting: false,
            active_tab: 'general',

            form: form.default({
                id: '',
                slug: '',
                type: '1',
                value: '',
            }).rules({
                slug: 'required',
            }),

        };
    },

    methods: {

        openModal(value, mode) {
            this.form.errors().forget();
            const modalCls = document.getElementById('modal').classList;
            const modalAnimCls = document.getElementById('modal_animation').classList;
            if (value == true && mode == 'create') {
                modalCls.remove('hidden');
                modalAnimCls.add('open-modal-animation');

                this.form.id = '';
                this.form.slug = '';
                this.form.type = '1';
                this.form.value = '';

                this.modal_mode = 'create';
                this.mdl_title = 'Setting';
            } else if (value == true && mode == 'update') {
                modalCls.remove('hidden');
                modalAnimCls.add('open-modal-animation');
            } else {
                modalCls.add('hidden');
                modalAnimCls.remove('open-modal-animation');
                this.isModalSubmitting = false;
            }
        },

        takeFileFromInput(e) {
            const newFile = e.target.files[0]
            this.form.value = newFile;
        },

        createSetting() {
            if (this.form.validate().errors().any()) {
                return;
            } else {
                this.openModal(false, 'create');
                VisaApi.post("/settings/store", this.form.all(), {headers: {"Content-Type": "multipart/form-data"}}).then((response) => {
                    this.getList();
                    this.$refs.inputfile.value = null;
                    this.$toast.success(response.message, {
                        position: "top-right",
                        dismissible: true,
                    });
                }).catch((error) => {
                    this.$toast.error(error.response.data.message, {
                        position: "top-right",
                        dismissible: true,
                    });
                });
            }
            ;
        },

        async getSettings() {
            await store.dispatch('setting/defaultSetting')
        },

        openDeleteModal(id) {
            const modalCls = document.getElementById('delete_modal').classList;
            const modalAnimCls = document.getElementById('delete_modal_animation').classList;
            this.deletable_id = id;
            modalCls.remove('hidden');
            modalAnimCls.add('delete-modal-animation');
        },

        closeDeleteModal() {
            const modalCls = document.getElementById('delete_modal').classList;
            const modalAnimCls = document.getElementById('delete_modal_animation').classList;
            modalCls.add('hidden');
            modalAnimCls.remove('delete-modal-animation');
            this.isDeleteSubmitting = false;
        },

        deleteData() {
            this.isDeleteSubmitting = true;
            VisaApi.delete("/settings/delete/" + this.deletable_id).then((response) => {
                if (response.code == '200') {
                    this.getList();
                    this.$toast.success(response.message, {
                        position: "top-right",
                        dismissible: true,
                    });
                    this.closeDeleteModal();
                }
            }).catch((error) => {
                this.$toast.error(error.response.data.message, {
                    position: "top-right",
                    dismissible: true,
                });
            });
        },
    },

    async mounted() {
        this.url = 'settings?&search=' + this.search_input;
        await this.getSettings()
        this.breadcrumb = this.$router.currentRoute.value.meta.breadcrumb;
    }
}
</script>
