import {createRouter, createWebHistory} from 'vue-router'
import AdminLayout from '../views/layout/AdminLayout.vue'
import Dashboard from '../views/dashboard/Dashboard.vue'
import Login from '../views/login/Login.vue'
import CreateGroup from '../views/anzsco/group/CreateGroup.vue'
import UpdateGroup from '../views/anzsco/group/UpdateGroup.vue'
import GroupList from '../views/anzsco/group/GroupList.vue'
import ViewGroup from '../views/anzsco/group/ViewGroup.vue'
import CreateAnzscoDetails from '../views/anzsco/details/CreateAnzscoDetails.vue'
import UpdateAnzscoDetails from '../views/anzsco/details/UpdateAnzscoDetails.vue'
import AnzscoDetailsList from '../views/anzsco/details/AnzscoDetailslist.vue'
import ViewAnzscoDetails from '../views/anzsco/details/ViewAnzscoDetails.vue'
import AssessingAuthorityList from '../views/assessingAuthority/AssessingAuthorityList.vue'
import ViewAssessingAuthority from '../views/assessingAuthority/ViewAssessingAuthority.vue'
import OccupationAssessingAuthority from '../views/assessingAuthority/OccupationAssessingAuthority.vue'
import CreateOccupation from '../views/occupations/occupation/CreateOccupation.vue'
import UpdateOccupation from '../views/occupations/occupation/UpdateOccupation.vue'
import OccupationList from '../views/occupations/occupation/OccupationList.vue'
import ViewOccupation from '../views/occupations/occupation/ViewOccupation.vue'
import Alternative from '../views/occupations/occupationAlternative/Alternative.vue'
import ViewAlternative from '../views/occupations/occupationAlternative/ViewAlternative.vue'
import SpecialisationList from '../views/occupations/occupationSpecialization/SpecialisationList.vue'
import ViewSpecialization from '../views/occupations/occupationSpecialization/ViewSpecialization.vue'
import CreateVisaCategory from '../views/visas/visaCategory/CreateVisaCategory.vue'
import UpdateVisaCategory from '../views/visas/visaCategory/UpdateVisaCategory.vue'
import VisaCategoryList from '../views/visas/visaCategory/VisaCategoryList.vue'
import ViewVisaCategory from '../views/visas/visaCategory/ViewVisaCategory.vue'
import CreateVisaType from '../views/visas/visaType/CreateVisaType.vue'
import UpdateVisaType from '../views/visas/visaType/UpdateVisaType.vue'
import VisaTypeList from '../views/visas/visaType/VisaTypeList.vue'
import ViewVisaType from '../views/visas/visaType/ViewVisaType.vue'
import VisaFees from '../views/visas/visaFees/VisaFees.vue'
import ViewVisaFees from '../views/visas/visaFees/ViewVisaFees.vue'
import VisaFeesItem from '../views/visas/visaFeesItem/VisaFeesItem.vue'
import ViewVisaFeesItem from '../views/visas/visaFeesItem/ViewVisaFeesItem.vue'
import CreateVisa from '../views/visas/visa/CreateVisa.vue'
import UpdateVisa from '../views/visas/visa/UpdateVisa.vue'
import VisaList from '../views/visas/visa/VisaList.vue'
import ViewVisa from '../views/visas/visa/ViewVisa.vue'
import VisaPathway from '../views/visas/VisaPathway/List.vue'
import CreateVisaStream from '../views/visas/visaStream/CreateVisaStream.vue'
import UpdateVisaStream from '../views/visas/visaStream/UpdateVisaStream.vue'
import VisaStreamList from '../views/visas/visaStream/VisaStreamList.vue'
import ViewVisaStream from '../views/visas/visaStream/ViewVisaStream.vue'
import LinVisaStreamList from '../views/visas/visaStream/LinVisaStreamList.vue'
import CreateVisaSchedule from '../views/visas/visaSchedule/CreateVisaSchedule.vue'
import UpdateVisaSchedule from '../views/visas/visaSchedule/UpdateVisaSchedule.vue'
import VisaScheduleList from '../views/visas/visaSchedule/VisaScheduleList.vue'
import ViewVisaSchedule from '../views/visas/visaSchedule/ViewVisaSchedule.vue'
import VisaCondition from '../views/visas/visaCondition/VisaCondition.vue'
import ViewVisaCondition from '../views/visas/visaCondition/ViewVisaCondition.vue'
import VisaVisaConditionList from '../views/visas/visaCondition/VisaVisaConditionList.vue'
import CreateLin from '../views/lin/CreateLin.vue'
import UpdateLin from '../views/lin/UpdateLin.vue'
import LinList from '../views/lin/LinList.vue'
import ViewLin from '../views/lin/ViewLin.vue'
import LinOccupation from '../views/lin/LinOccupation.vue'
import ViewLinOccupation from '../views/lin/ViewLinOccupation.vue'
import Caveat from '../views/caveat/Caveat.vue'
import ViewCaveat from '../views/caveat/ViewCaveat.vue'
import State from '../views/state/State.vue'
import ViewState from '../views/state/ViewState.vue'
import Permission from '../views/permission/Permission.vue'
import Profile from '../views/profile/Profile.vue'
import AttachAnzscoWithOccupation from '../views/occupations/occupation/AttachAnzscoWithOccupation.vue'
import AttachOccupationWithAssessingAuthority
    from '../views/occupations/occupation/AttachOccupationWithAssessingAuthority.vue'
import AttachLinOccupationWithCaveat from '../views/lin/AttachLinOccupationWithCaveat.vue'
import CreatePageContent from '../views/frontendManage/page/CreatePageContent.vue'
import UpdatePageContent from '../views/frontendManage/page/UpdatePageContent.vue'
import ViewPageContent from '../views/frontendManage/page/ViewPageContent.vue'
import PageContentList from '../views/frontendManage/page/PageContentList.vue'
import CreateMenu from '../views/frontendManage/menu/CreateMenu.vue'
import UpdateMenu from '../views/frontendManage/menu/UpdateMenu.vue'
import ViewMenu from '../views/frontendManage/menu/ViewMenu.vue'
import MenuList from '../views/frontendManage/menu/MenuList.vue'
import CreateMenuItem from '../views/frontendManage/menu/CreateMenuItem.vue'
import UpdateMenuItem from '../views/frontendManage/menu/UpdateMenuItem.vue'
import ViewMenuItem from '../views/frontendManage/menu/ViewMenuItem.vue'
import MenuItemList from '../views/frontendManage/menu/MenuItemList.vue'
import CreatePage from '../views/frontendManage/page/CreatePage.vue'
import UpdatePage from '../views/frontendManage/page/UpdatePage.vue'
import ViewPage from '../views/frontendManage/page/ViewPage.vue'
import PageList from '../views/frontendManage/page/PageList.vue'
import AttachPageContent from '../views/frontendManage/page/AttachPageContent.vue'
import CreateVisaRequirement from '../views/visas/visaRequirement/CreateVisaRequirement.vue'
import UpdateVisaRequirement from '../views/visas/visaRequirement/UpdateVisaRequirement.vue'
import ViewVisaRequirement from '../views/visas/visaRequirement/ViewVisaRequirement.vue'
import VisaRequirementList from '../views/visas/visaRequirement/VisaRequirementList.vue'
import CreateOccupationClassification
    from '../views/occupations/occupationClassification/CreateOccupationClassification.vue'
import UpdateOccupationClassification
    from '../views/occupations/occupationClassification/UpdateOccupationClassification.vue'
import ViewOccupationClassification
    from '../views/occupations/occupationClassification/ViewOccupationClassification.vue'
import OccupationClassificationList
    from '../views/occupations/occupationClassification/OccupationClassificationList.vue'
import Setting from '../views/setting/Setting.vue'
import NotFound from '../views/errors/404.vue'

const routes = [
    {path: "/login", name: "login", component: Login},

    {
        path: '/', name: '/', component: AdminLayout, meta: {requiresAuth: true}, children: [
            {path: 'dashboard', name: 'dashboard', component: Dashboard, meta: {breadcrumb: "Admin/Dashboard"}},

            //anzsco group
            {
                path: 'group-create',
                name: 'group-create',
                component: CreateGroup,
                meta: {breadcrumb: "Anzsco/Group/Create"}
            },
            {
                path: 'group-update/:id',
                name: 'group-update',
                component: UpdateGroup,
                meta: {breadcrumb: "Anzsco/Group/Update"}
            },
            {path: 'group-list', name: 'group-list', component: GroupList, meta: {breadcrumb: "Anzsco/Group/List"}},
            {path: 'group-view/:id', name: 'group-view', component: ViewGroup, meta: {breadcrumb: "Anzsco/Group/View"}},

            //anzsco details
            {
                path: 'anzsco-details-create',
                name: 'anzsco-details-create',
                component: CreateAnzscoDetails,
                meta: {breadcrumb: "Anzsco/Details/Create"}
            },
            {
                path: 'anzsco-details-update/:id',
                name: 'anzsco-details-update',
                component: UpdateAnzscoDetails,
                meta: {breadcrumb: "Anzsco/Details/Update"}
            },
            {
                path: 'anzsco-details-list',
                name: 'anzsco-details-list',
                component: AnzscoDetailsList,
                meta: {breadcrumb: "Anzsco/Details/List"}
            },
            {
                path: 'anzsco-details-view/:id',
                name: 'anzsco-details-view',
                component: ViewAnzscoDetails,
                meta: {breadcrumb: "Anzsco/Details/View"}
            },

            //assessing authority
            {
                path: 'assessing-authority-view/:id',
                name: 'assessing-authority-view',
                component: ViewAssessingAuthority,
                meta: {breadcrumb: "Admin/Assessing-authority/View"}
            },
            {
                path: 'assessing-authority',
                name: 'assessing-authority',
                component: AssessingAuthorityList,
                meta: {breadcrumb: "Admin/Assessing-authority/List"}
            },
            {
                path: 'occupation-authority',
                name: 'occupation-authority',
                component: OccupationAssessingAuthority,
                meta: {breadcrumb: "Admin/Occupation/Authority"}
            },

            //occupation
            {
                path: 'occupation-create',
                name: 'occupation-create',
                component: CreateOccupation,
                meta: {breadcrumb: "Admin/Occupation/Create"}
            },
            {
                path: 'occupation-update/:id',
                name: 'occupation-update',
                component: UpdateOccupation,
                meta: {breadcrumb: "Admin/Occupation/Update"}
            },
            {
                path: 'occupation-list',
                name: 'occupation-list',
                component: OccupationList,
                meta: {breadcrumb: "Admin/Occupation/List"}
            },
            {
                path: 'occupation-view/:id',
                name: 'occupation-view',
                component: ViewOccupation,
                meta: {breadcrumb: "Admin/Occupation/View"}
            },

            //occupation classification
            {
                path: 'occupation-alternative',
                name: 'occupation-alternative',
                component: Alternative,
                meta: {breadcrumb: "Admin/Occupation-Alternative"}
            },
            {
                path: 'occupation-alternative-view/:id',
                name: 'occupation-alternative-view',
                component: ViewAlternative,
                meta: {breadcrumb: "Admin/Occupation-Alternative/View"}
            },
            {
                path: 'occupation-specialisation-list',
                name: 'occupation-specialisation-list',
                component: SpecialisationList,
                meta: {breadcrumb: "Admin/Occupation-Specialisation/List"}
            },
            {
                path: 'occupation-specialisation-view/:id',
                name: 'occupation-specialisation-view',
                component: ViewSpecialization,
                meta: {breadcrumb: "Admin/Occupation-Specialisation/View"}
            },

            //visa category
            {
                path: 'visa-category-create',
                name: 'visa-category-create',
                component: CreateVisaCategory,
                meta: {breadcrumb: "Admin/Visa/Category/Create"}
            },
            {
                path: 'visa-category-update/:id',
                name: 'visa-category-update',
                component: UpdateVisaCategory,
                meta: {breadcrumb: "Admin/Visa/Category/Update"}
            },
            {
                path: 'visa-category-list',
                name: 'visa-category-list',
                component: VisaCategoryList,
                meta: {breadcrumb: "Admin/Visa/Category/List"}
            },
            {
                path: 'visa-category-view/:id',
                name: 'visa-category-view',
                component: ViewVisaCategory,
                meta: {breadcrumb: "Admin/Visa/Category/View"}
            },

            //visa type
            {
                path: 'visa-type-create',
                name: 'visa-type-create',
                component: CreateVisaType,
                meta: {breadcrumb: "Admin/Visa/Type/Create"}
            },
            {
                path: 'visa-type-update/:id',
                name: 'visa-type-update',
                component: UpdateVisaType,
                meta: {breadcrumb: "Admin/Visa/Type/Update"}
            },
            {
                path: 'visa-type-list',
                name: 'visa-type-list',
                component: VisaTypeList,
                meta: {breadcrumb: "Admin/Visa/Type/List"}
            },
            {
                path: 'visa-type-view/:id',
                name: 'visa-type-view',
                component: ViewVisaType,
                meta: {breadcrumb: "Admin/Visa/Type/View"}
            },

            //visa fees
            {path: 'visa-fees', name: 'visa-fees', component: VisaFees, meta: {breadcrumb: "Admin/Visa/Fees"}},
            {
                path: 'visa-fees-view/:id',
                name: 'visa-fees-view',
                component: ViewVisaFees,
                meta: {breadcrumb: "Admin/Visa/Fees/View"}
            },
            {path: 'fees-item', name: 'fees-item', component: VisaFeesItem, meta: {breadcrumb: "Admin/Visa/Fees-item"}},
            {
                path: 'fees-item-view/:id',
                name: 'fees-item-view',
                component: ViewVisaFeesItem,
                meta: {breadcrumb: "Admin/Visa/Fees-item/View"}
            },

            //visa
            {path: 'visa-create', name: 'visa-create', component: CreateVisa, meta: {breadcrumb: "Admin/Visa/Create"}},
            {
                path: 'visa-update/:id',
                name: 'visa-update',
                component: UpdateVisa,
                meta: {breadcrumb: "Admin/Visa/Update"}
            },
            {path: 'visa-list', name: 'visa-list', component: VisaList, meta: {breadcrumb: "Admin/Visa/List"}},
            {path: 'visa-view/:id', name: 'visa-view', component: ViewVisa, meta: {breadcrumb: "Admin/Visa/View"}},

            //visa pathway
            {
                path: 'visa-pathway',
                name: 'visa-pathway',
                component: VisaPathway,
                meta: {breadcrumb: "Admin/Visa/Visa Pathway"}
            },

            //visa stream
            {
                path: 'visa-stream-create',
                name: 'visa-stream-create',
                component: CreateVisaStream,
                meta: {breadcrumb: "Admin/Visa/Stream/Create"}
            },
            {
                path: 'visa-stream-update/:id',
                name: 'visa-stream-update',
                component: UpdateVisaStream,
                meta: {breadcrumb: "Admin/Visa/Stream/Update"}
            },
            {
                path: 'visa-stream-list',
                name: 'visa-stream-list',
                component: VisaStreamList,
                meta: {breadcrumb: "Admin/Visa/Stream/List"}
            },
            {
                path: 'visa-stream-view/:id',
                name: 'visa-stream-view',
                component: ViewVisaStream,
                meta: {breadcrumb: "Admin/Visa/Stream/View"}
            },

            //visa lin stream
            {
                path: 'lin-visa-stream-list',
                name: 'visa-lin-stream-list',
                component: LinVisaStreamList,
                meta: {breadcrumb: "Admin / Visa / Lin Visa Stream / List"}
            },

            //visa schedule
            {
                path: 'visa-schedule-create',
                name: 'visa-schedule-create',
                component: CreateVisaSchedule,
                meta: {breadcrumb: "Admin/Visa/Schedule/Create"}
            },
            {
                path: 'visa-schedule-update/:id',
                name: 'visa-schedule-update',
                component: UpdateVisaSchedule,
                meta: {breadcrumb: "Admin/Visa/Schedule/Update"}
            },
            {
                path: 'visa-schedule-list',
                name: 'visa-schedule-list',
                component: VisaScheduleList,
                meta: {breadcrumb: "Admin/Visa/Schedule/List"}
            },
            {
                path: 'visa-schedule-view/:id',
                name: 'visa-schedule-view',
                component: ViewVisaSchedule,
                meta: {breadcrumb: "Admin/Visa/Schedule/View"}
            },

            //visa condition
            {
                path: 'visa-condition',
                name: 'visa-condition',
                component: VisaCondition,
                meta: {breadcrumb: "Admin/Visa/Condition"}
            },
            {
                path: 'visa-condition-view/:id',
                name: 'visa-condition-view',
                component: ViewVisaCondition,
                meta: {breadcrumb: "Admin/Visa/Condition/View"}
            },
            {
                path: 'visa-visa-condition-list',
                name: 'visa-visa-condition-list',
                component: VisaVisaConditionList,
                meta: {breadcrumb: "Admin/Visa/Visa-Condition"}
            },

            //lin
            {path: 'lin-create', name: 'lin-create', component: CreateLin, meta: {breadcrumb: "Admin/Lin/Create"}},
            {path: 'lin-update/:id', name: 'lin-update', component: UpdateLin, meta: {breadcrumb: "Admin/Lin/Update"}},
            {path: 'lin-view/:id', name: 'lin-view', component: ViewLin, meta: {breadcrumb: "Admin/Lin/View"}},
            {path: 'lin-list', name: 'lin-list', component: LinList, meta: {breadcrumb: "Admin/Lin/List"}},

            //lin occupation
            {
                path: 'lin-occupation',
                name: 'lin-occupation',
                component: LinOccupation,
                meta: {breadcrumb: "Admin/Lin-Occupation"}
            },
            {
                path: 'lin-occupation-view/:id',
                name: 'lin-occupation-view',
                component: ViewLinOccupation,
                meta: {breadcrumb: "Admin/Lin-Occupation/View"}
            },

            //caveat
            {path: 'caveat', name: 'caveat', component: Caveat, meta: {breadcrumb: "Admin/Caveat"}},
            {
                path: 'caveat-view/:id',
                name: 'caveat-view',
                component: ViewCaveat,
                meta: {breadcrumb: "Admin/Caveat/View"}
            },

            //state
            {path: 'state', name: 'state', component: State, meta: {breadcrumb: "Admin/State"}},
            {path: 'state-view/:id', name: 'state-view', component: ViewState, meta: {breadcrumb: "Admin/State/View"}},

            //permission
            {path: 'permission', name: 'permission', component: Permission, meta: {breadcrumb: "Admin/Permission"}},

            //profile
            {path: 'profile', name: 'profile', component: Profile, meta: {breadcrumb: "Admin/Profile"}},
            {
                path: 'attach-anzsco-occupation/:id',
                name: 'attach-anzsco-occupation',
                component: AttachAnzscoWithOccupation,
                meta: {breadcrumb: "Admin/Attach-anzsco-with-occupation"}
            },
            {
                path: 'attach-occupation-with-assessing-authority/:id',
                name: 'attach-occupation-with-assessing-authority',
                component: AttachOccupationWithAssessingAuthority,
                meta: {breadcrumb: "Admin/Attach-occupation-with-assessing-authority"}
            },
            {
                path: 'attach-lin-occupation-with-caveat/:id',
                name: 'attach-lin-occupation-with-caveat',
                component: AttachLinOccupationWithCaveat,
                meta: {breadcrumb: "Admin/Attach-lin-occupation-with-caveat"}
            },
            {
                path: 'create-page-content/:page_id',
                name: 'create-page-content',
                component: CreatePageContent,
                meta: {breadcrumb: "Admin/Page-content/Create"}
            },
            {
                path: 'update-page-content/:id',
                name: 'update-page-content',
                component: UpdatePageContent,
                meta: {breadcrumb: "Admin/Page-content/Update"}
            },
            {
                path: 'view-page-content/:id',
                name: 'view-page-content',
                component: ViewPageContent,
                meta: {breadcrumb: "Admin/Page-content/View"}
            },
            {
                path: 'page-content-list',
                name: 'page-content-list',
                component: PageContentList,
                meta: {breadcrumb: "Admin/Page-content/List"}
            },

            //menu
            {path: 'create-menu', name: 'create-menu', component: CreateMenu, meta: {breadcrumb: "Admin/Menu/Create"}},
            {
                path: 'update-menu/:id',
                name: 'update-menu',
                component: UpdateMenu,
                meta: {breadcrumb: "Admin/Menu/Update"}
            },
            {path: 'view-menu/:id', name: 'view-menu', component: ViewMenu, meta: {breadcrumb: "Admin/Menu/View"}},
            {
                path: 'menu-list',
                name: 'menu-list',
                component: () => import("../views/frontendManage/menu/MenuList.vue"),
                meta: {breadcrumb: "Admin/Menu/List"}
            },

            //menu items
            {
                path: 'create-menu-items/:menu_id',
                name: 'create-menu-items',
                component: CreateMenuItem,
                meta: {breadcrumb: "Admin/Menu-items/Create"}
            },
            {
                path: 'update-menu-items/:menu_id/:id',
                name: 'update-menu-items',
                component: UpdateMenuItem,
                meta: {breadcrumb: "Admin/Menu-items/Update"}
            },
            {
                path: 'view-menu-items/:menu_id/:id',
                name: 'view-menu-items',
                component: ViewMenuItem,
                meta: {breadcrumb: "Admin/Menu-items/View"}
            },
            {
                path: 'menu-items-list',
                name: 'menu-items-list',
                component: MenuItemList,
                meta: {breadcrumb: "Admin/Menu-items/List"}
            },

            //page
            {path: 'create-page', name: 'create-page', component: CreatePage, meta: {breadcrumb: "Admin/Page/Create"}},
            {
                path: 'update-page/:id',
                name: 'update-page',
                component: UpdatePage,
                meta: {breadcrumb: "Admin/Page/Update"}
            },
            {path: 'view-page/:id', name: 'view-page', component: ViewPage, meta: {breadcrumb: "Admin/Page/View"}},
            {path: 'page-list', name: 'page-list', component: PageList, meta: {breadcrumb: "Admin/Page/List"}},
            {
                path: 'attach-page-content/:id',
                name: 'attach-page-content',
                component: AttachPageContent,
                meta: {breadcrumb: "Admin/Attach-page-content"}
            },

            //requirements
            {
                path: 'create-visa-requirement',
                name: 'create-visa-requirement',
                component: CreateVisaRequirement,
                meta: {breadcrumb: "Admin/Visa-requirement/Create"}
            },
            {
                path: 'update-visa-requirement/:id',
                name: 'update-visa-requirement',
                component: UpdateVisaRequirement,
                meta: {breadcrumb: "Admin/Visa-requirement/Update"}
            },
            {
                path: 'view-visa-requirement/:id',
                name: 'view-visa-requirement',
                component: ViewVisaRequirement,
                meta: {breadcrumb: "Admin/Visa-requirement/View"}
            },
            {
                path: 'visa-requirement-list',
                name: 'visa-requirement-list',
                component: VisaRequirementList,
                meta: {breadcrumb: "Admin/Visa-requirement/List"}
            },

            {
                path: 'create-occupation-classification',
                name: 'create-occupation-classification',
                component: CreateOccupationClassification,
                meta: {breadcrumb: "Admin/Occupation-classification/Create"}
            },
            {
                path: 'update-occupation-classification/:id',
                name: 'update-occupation-classification',
                component: UpdateOccupationClassification,
                meta: {breadcrumb: "Admin/Occupation-classification/Update"}
            },
            {
                path: 'view-occupation-classification/:id',
                name: 'view-occupation-classification',
                component: ViewOccupationClassification,
                meta: {breadcrumb: "Admin/Occupation-classification/View"}
            },
            {
                path: 'occupation-classification-list',
                name: 'occupation-classification-list',
                component: OccupationClassificationList,
                meta: {breadcrumb: "Admin/Occupation-classification/List"}
            },
            {path: 'setting', name: 'setting', component: Setting, meta: {breadcrumb: "Admin/Setting"}},
        ]
    },

    {path: '/:pathMatch(.*)', component: NotFound}
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach((to, from, next) => {
    if (to.meta.requiresAuth == true && localStorage.getItem('token') == null) {
        next({name: 'login'})
    } else if (to.meta.requiresAuth == true && to.path == '/' && localStorage.getItem('token') != null) {
        next({name: 'dashboard'})
    } else next()
})

export default router
