<template>
    <div class="px-4 py-2 absolute w-full">

        <Breadcrumb :text="breadcrumb"/>

        <div class="visasis-card">
            <div class="flex flex-wrap items-center -mx-4">
                <TableEntryShow @input="[meta.current_page = 1, meta.per_page = $event]"/>
                <TableTopActions :is_modal="true" @create="openModal(true, 'create')"
                                 @input="[meta.current_page = 1, search_input = $event]"/>
            </div>

            <vue-good-table
                :columns="columns"
                :rows="rows"
            >
                <template #table-row="props">
                    <div v-if="props.column.field == 'description'">
                        <span v-if="props.row.description!=null && props.row.description.length >= 30"
                              class="whitespace-nowrap"> {{ props.row.description.slice(0, 30) }} ... </span>
                        <span v-else class="whitespace-nowrap"> {{ props.row.description }} </span>
                    </div>
                    <div v-if="props.column.field == 'status'">
                        <p v-if="props.row.status==1" class="w-min px-2 rounded m-auto text-white bg-green-700">
                            Active</p>
                        <p v-else class="w-min px-2 rounded m-auto text-white bg-orange-700">Inactive</p>
                    </div>
                    <div v-if="props.column.field == 'action'">
                        <div class="px-3 py-2 flex items-center justify-center">
                            <router-link :to="{ name: 'visa-fees-view', params: {id:props.row.uuid} }">
                                <view-icon/>
                            </router-link>
                            <update-icon @click="getSingleVisaFees(props.row.uuid)"/>
                            <delete-icon @click="openDeleteModal(props.row.uuid)"/>
                        </div>
                    </div>
                </template>
            </vue-good-table>

            <TablePagination
                :inputPage="currentPage"
                :meta='meta'
                :next_of_next='next_of_next'
                :prev_of_prev='prev_of_prev'
                @update:inputPage="updatePageValue"
            />
        </div>

        <!-- modal -->
        <Modal :isSubmitting="isModalSubmitting" :modal_title='mdl_title' @close="openModal(false, 'create')"
               @submit="handleSubmitMethod">
            <template v-slot:body>
                <div class="grid grid-cols-1 md:grid-cols-2 gap-x-4 gap-y-2 mt-2 px-2">
                    <div class="w-full">
                        <label class="flex items-center">Visa Sub-Class <img alt="required"
                                                                             class="w-2 h-2 ml-1"
                                                                             src="@/assets/images/star.svg"> </label>
                        <input v-model='form.visa_subclass'
                               class="py-2 rounded border border-gray-300 bg-gray-50 text-sm placeholder-gray-400 focus:border-invblue resize-none w-full px-4 mt-1"
                               placeholder="Visa Sub-Class" type='text'>
                        <span v-if='form.errors().has("visa_subclass")' class="text-red-800 text-xs">
                            {{ form.errors().get('visa_subclass') }}
                        </span>
                    </div>
                    <div class="w-full">
                        <label class="flex items-center">Installment Number <img alt="required"
                                                                                 class="w-2 h-2 ml-1"
                                                                                 src="@/assets/images/star.svg"> </label>
                        <input v-model='form.installment_no'
                               class="py-2 rounded border border-gray-300 bg-gray-50 text-sm placeholder-gray-400 focus:border-invblue resize-none w-full px-4 mt-1"
                               placeholder="Installment Number" type='text'>
                        <span v-if='form.errors().has("installment_no")' class="text-red-800 text-xs">
                            {{ form.errors().get('installment_no') }}
                        </span>
                    </div>
                    <div class="w-full">
                        <label>Status</label>
                        <select v-model='form.status'
                                class="py-2 rounded border border-gray-300 bg-gray-50 text-sm placeholder-gray-400 focus:border-invblue resize-none w-full px-4 mt-1">
                            <option :selected="form.status==1" value="1">Active</option>
                            <option :selected="form.status==0" value="0">Inactive</option>
                        </select>
                    </div>
                    <div class="w-full">
                        <label>Description</label>
                        <textarea v-model='form.description' class="p-2 rounded border border-gray-300 bg-gray-50 text-sm placeholder-gray-400 focus:border-invblue resize-none w-full mt-1" cols="50" placeholder="Note"
                                  rows="4"></textarea>
                    </div>
                </div>
            </template>
        </Modal>

        <!-- delete modal -->
        <DeleteModal
            :isDeleteSubmitting="isDeleteSubmitting"
            :is_open="is_open_delete_modal"
            modal_title='Delete Visa Fees'
            @close="closeDeleteModal"
            @submit="deleteVisaFees"
        />

    </div>
</template>

<script>
import VisaApi from "@/libs/axios"
import form from 'vuejs-form'
import store from '@/store'
import Modal from '@/component/Modal'
import DeleteModal from '@/component/DeleteModal'
import Breadcrumb from '@/component/Breadcrumb'
import TablePagination from '@/component/TablePagination'
import {VueGoodTable} from 'vue-good-table-next'
import 'vue-good-table-next/dist/vue-good-table-next.css'
import {meta_data, next_of_next_status, prev_of_prev_status} from '@/libs/helpers.js'
import ViewIcon from "../../../data/icons/ViewIcon.vue";
import UpdateIcon from "../../../data/icons/UpdateIcon.vue";
import DeleteIcon from "../../../data/icons/DeleteIcon.vue";
import AddIcon from "../../../data/icons/AddIcon.vue";
import TableEntryShow from "../../../component/table/TableEntryShow.vue";
import TableTopActions from "../../../component/table/TableTopActions.vue";

export default {
    components: {
        TableTopActions,
        TableEntryShow,
        AddIcon,
        DeleteIcon,
        UpdateIcon,
        ViewIcon,
        Modal,
        DeleteModal,
        VueGoodTable,
        Breadcrumb,
        TablePagination
    },
    data() {
        return {
            mdl_title: 'Create Visa Fees',
            modal_mode: 'create',
            uuid_deletable: '',

            meta: meta_data,
            prev_of_prev: false,
            next_of_next: false,
            per_page_array: [10, 20, 30, 40, 50],
            currentPage: '',

            search_input: '',
            breadcrumb: '',
            url: '',
            sort_status: false,
            isDeleteSubmitting: false,
            isModalSubmitting: false,
            is_open_delete_modal: false,
            columns: [
                {label: 'Visa Subclass', field: 'visa_subclass'},
                {label: 'Installment Number', field: 'installment_no'},
                {label: 'Description', field: 'description'},
                {label: 'Status', field: 'status'},
                {label: 'Action', field: 'action', sortable: false, width: '100px'}
            ],
            rows: [],

            form: form.default({
                id: '',
                uuid: '',
                visa_subclass: '',
                installment_no: '',
                description: '',
                status: '1',
            }).rules({
                visa_subclass: 'required',
                installment_no: 'required',
            }).messages({
                'installment_no.required': 'This field is required.',
            }),
        };
    },

    methods: {
        openModal(value, mode) {
            this.form.errors().forget();
            const modalCls = document.getElementById('modal').classList;
            const modalAnimCls = document.getElementById('modal_animation').classList;
            if (value == true && mode == 'create') {
                modalCls.remove('hidden');
                modalAnimCls.add('open-modal-animation');

                this.form.id = '';
                this.form.uuid = '';
                this.form.visa_subclass = '';
                this.form.installment_no = '';
                this.form.description = '';
                this.form.status = '1';

                this.modal_mode = 'create';
                this.mdl_title = 'Create Visa Fees';
            } else if (value == true && mode == 'update') {
                modalCls.remove('hidden');
                modalAnimCls.add('open-modal-animation');
            } else {
                modalCls.add('hidden');
                modalAnimCls.remove('open-modal-animation');
                this.isModalSubmitting = false;
            }
        },

        createVisaFees() {
            if (this.form.validate().errors().any()) {
                return;
            } else {
                this.openModal(false, 'create');
                VisaApi.post("/visa-fees/create", this.form.all()).then((response) => {
                    if (response.code == '201') {
                        this.getVisaFeesList();
                        this.$toast.success(response.message, {
                            position: "top-right",
                            dismissible: true,
                        });
                    }
                }).catch((error) => {
                    this.$toast.error(error.response.data.message, {
                        position: "top-right",
                        dismissible: true,
                    });
                });
            }
        },

        async getVisaFeesList() {
            store.commit('setting/LOADING_ON')
            await VisaApi.get(this.url).then((response) => {
                store.commit('setting/LOADING_OFF')
                if (response.code == '200') {
                    this.rows = response.data.data;
                    Object.assign(this.meta, response.data.meta);
                }
            });
        },

        async getSingleVisaFees(uuid) {
            await VisaApi.get("/visa-fees/" + uuid).then((response) => {
                if (response.code == '200') {
                    Object.assign(this.form, response.data);
                    this.mdl_title = 'Update Visa Fees';
                    this.modal_mode = 'update';
                    this.openModal(true, 'update');
                }
            });
        },

        updateVisaFees() {
            if (this.form.validate().errors().any()) {
                return;
            } else {
                this.isModalSubmitting = true;
                VisaApi.patch("/visa-fees/update/" + this.form.uuid, this.form.all()).then((response) => {
                    if (response.code == '200') {
                        this.openModal(false, 'update');
                        let element = this.rows.filter(item => item.id == this.form.id);
                        Object.assign(element[0], response.data);
                        this.$toast.success(response.message, {
                            position: "top-right",
                            dismissible: true,
                        });
                    }
                }).catch((error) => {
                    this.$toast.error(error.response.data.message, {
                        position: "top-right",
                        dismissible: true,
                    });
                    this.openModal(false, 'update');
                });
            }
        },

        openDeleteModal(uuid) {
            this.is_open_delete_modal = true
            this.uuid_deletable = uuid;
        },

        closeDeleteModal() {
            this.is_open_delete_modal = false
            this.isDeleteSubmitting = false;
        },

        deleteVisaFees() {
            this.isDeleteSubmitting = true;
            VisaApi.delete("/visa-fees/" + this.uuid_deletable).then((response) => {
                if (response.code == '200') {
                    this.getVisaFeesList();
                    this.$toast.success(response.message, {
                        position: "top-right",
                        dismissible: true,
                    });
                    this.closeDeleteModal();
                }
            }).catch((error) => {
                this.$toast.error(error.response.data.message, {
                    position: "top-right",
                    dismissible: true,
                });
            });
        },

        handleSubmitMethod() {
            if (this.modal_mode == 'create') {
                this.createVisaFees();
            } else {
                this.updateVisaFees();
            }
        },

        updatePageValue(newValue) {
            this.currentPage = newValue;
        },
    },

    computed: {
        apiUrl() {
            return this.url = 'visa-fees?page=' + this.meta.current_page + '&per_page=' + this.meta.per_page + '&order_by=visa_subclass&order_by_type=desc&search=' + this.search_input;
        },
        nextOfNext() {
            this.next_of_next = next_of_next_status(this.meta);
        },
        prevOfPrev() {
            this.prev_of_prev = prev_of_prev_status(this.meta);
        },
    },

    watch: {
        apiUrl() {
            this.getVisaFeesList();
        },
        nextOfNext() {
        },
        prevOfPrev() {
        },
    },

    mounted() {
        this.url = 'visa-fees?page=' + this.meta.current_page + '&per_page=' + this.meta.per_page + '&order_by=visa_subclass&order_by_type=desc&search=' + this.search_input;
        this.getVisaFeesList();
        this.breadcrumb = this.$router.currentRoute.value.meta.breadcrumb;
    }
}
</script>
