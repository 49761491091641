<template>
    <div class="px-4 py-2 absolute w-full">

        <div class="flex flex-wrap items-center justify-between pb-2 border-b border-gray-300">
            <Breadcrumb :text="breadcrumb"/>
            <router-link :to="'/update-page/'+this.$route.params.id">
                <button class="rounded-md bg-red-600 inline-flex px-6 py-2 text-white hover:bg-red-500" type="button">
                    <back-icon class="mt-1 mr-2"/>
                    Back to list
                </button>
            </router-link>
        </div>

        <div class="visasis-card">
            <div class="grid grid-cols-1 md:grid-cols-2 gap-x-4 gap-y-2">
                <div class="w-full">
                    <label>Type</label>
                    <select v-model='form.type' class="py-2 rounded border border-gray-300 bg-gray-50 text-sm placeholder-gray-400 focus:border-invblue resize-none w-full px-4 mt-1"
                            @change="resetContent">
                        <option :selected="form.type=='text-editor'" value="text-editor">Text Editor</option>
                        <option :selected="form.type=='html-editor'" value="html-editor">Html Editor</option>
                    </select>
                </div>
                <div class="w-full">
                    <label class="flex items-center">Title <img alt="required" class="w-2 h-2 ml-1"
                                                                src="@/assets/images/star.svg"> </label>
                    <input v-model='form.title' class="py-2 rounded border border-gray-300 bg-gray-50 text-sm placeholder-gray-400 focus:border-invblue resize-none w-full px-4 mt-1" placeholder="Title"
                           type='text'>
                    <span v-if='form.errors().has("title")' class="text-red-800 text-xs">
                        {{ form.errors().get('title') }}
                    </span>
                </div>
            </div>

            <div v-show="form.type=='text-editor'" class="w-full mt-4">
                <label class="flex items-center">Content <img alt="required" class="w-2 h-2 ml-1"
                                                              src="@/assets/images/star.svg"> </label>
                <textarea :value="form.content" class="py-2 rounded border border-gray-300 bg-gray-50 text-sm placeholder-gray-400 focus:border-invblue resize-none w-full px-4 mt-1" cols="50" placeholder="Write your content here..."
                          rows="10"
                          @input="getContentInput($event)"></textarea>
                <span v-if='form.errors().has("content")' class="text-red-800 text-xs">
                    {{ form.errors().get('content') }}
                </span>
            </div>

            <div v-show="form.type=='html-editor'" class="w-full mt-4">
                <label class="flex items-center">Content <img alt="required" class="w-2 h-2 ml-1"
                                                              src="@/assets/images/star.svg"> </label>
                <article class="prose" style="max-width: 100%">
                    <ckeditor v-model="editorData" :editor="editor" @input="onChange" @ready="onReady"></ckeditor>
                </article>
                <span v-if='form.errors().has("content")' class="text-red-800 text-xs">
                    {{ form.errors().get('content') }}
                </span>
            </div>

            <button v-if="isSubmitting==false" class="mt-4 justify-center rounded-md bg-blue-600 px-6 py-1.5 text-white hover:bg-blue-500" type="button"
                    @click="update">
                Submit
            </button>
            <span v-else class="mt-4 block">
                <LoadingButton text="Submitting"/>
            </span>

        </div>
    </div>
</template>

<script>
import VisaApi from "@/libs/axios"
import form from 'vuejs-form'
import Breadcrumb from '@/component/Breadcrumb'
import CKEditor from "@ckeditor/ckeditor5-vue"
import LoadingButton from "@/component/LoadingButton"
import BackIcon from "../../../data/icons/BackIcon.vue";
import {ClassicEditor} from 'ckeditor5';

export default {
    components: {BackIcon, LoadingButton, Breadcrumb, ckeditor: CKEditor.component},
    data() {
        return {
            breadcrumb: '',
            isSubmitting: false,

            form: form.default({
                id: '',
                page_id: '',
                type: '',
                title: '',
                content: '',
            }).rules({
                type: 'required',
                title: 'required',
                content: 'required',
            }),

            editor: ClassicEditor,
            editorData: "",
        };
    },

    methods: {
        update() {
            if (this.form.validate().errors().any()) {
                return;
            } else {
                this.isSubmitting = true;
                VisaApi.patch("/frontend/page-contents/" + this.form.id, this.form.all()).then((response) => {
                    if (response.code == '201') {
                        this.$toast.success(response.message, {
                            position: "top-right",
                            dismissible: true,
                        });
                        this.$router.push({name: 'page-list'})
                    }
                    this.isSubmitting = false;
                }).catch((error) => {
                    this.$toast.error(error.response.data.message, {
                        position: "top-right",
                        dismissible: true,
                    });
                    this.isSubmitting = false;
                });
            }
        },

        onReady(editor) {
            // console.log( "CKEditor5 Vue Component is ready to use!", editor );
        },

        // getting html editor input
        onChange(data) {
            this.form.content = data;
        },

        resetContent() {
            this.form.content = "";
            this.editorData = "";
        },

        getSingleData(id) {
            VisaApi.get("/frontend/page-contents/" + id).then((response) => {
                if (response.code == '200') {
                    Object.assign(this.form, response.data);
                    if (response.data.type == 'html-editor') {
                        this.editorData = response.data.content;
                    }
                }
            });
        },

        // getting text area input
        getContentInput(data) {
            this.form.content = data;
        }

    },

    mounted() {
        this.breadcrumb = this.$router.currentRoute.value.meta.breadcrumb;
        this.getSingleData(this.$route.params.id);
        this.form.page_id = this.$route.params.id
    }
}
</script>
