<template>
    <div class="px-4 py-2 absolute w-full">

        <Breadcrumb :text="breadcrumb"/>

        <div class="visasis-card">

            <div class="flex flex-wrap items-center -mx-4">
                <TableEntryShow @input="[meta.current_page = 1, meta.per_page = $event]"/>
                <TableTopActions to="/lin-create" @input="[meta.current_page = 1, search_input = $event]"/>
            </div>

            <vue-good-table
                :columns="columns"
                :rows="rows"
            >
                <template #table-row="props">
                    <div v-if="props.column.field == 'description'">
                        <span v-if="props.row.description!=null && props.row.description.length >= 30"
                              class="whitespace-nowrap"> {{ props.row.description.slice(0, 30) }} ... </span>
                        <span v-else class="whitespace-nowrap"> {{ props.row.description }} </span>
                    </div>
                    <div v-if="props.column.field == 'status'">
                        <p v-if="props.row.status==1" class="w-min px-2 rounded m-auto text-white bg-green-700">
                            Active</p>
                        <p v-else class="w-min px-2 rounded m-auto text-white bg-orange-700">Inactive</p>
                    </div>
                    <div v-if="props.column.field == 'action'">
                        <div class="px-3 py-2 flex items-center justify-center">
                            <router-link :to="{ name: 'lin-view', params: {id:props.row.uuid} }">
                                <view-icon/>
                            </router-link>
                            <router-link :to="{ name: 'lin-update', params: {id:props.row.uuid} }">
                                <update-icon/>
                            </router-link>
                            <delete-icon @click="openDeleteModal(props.row.uuid)"/>
                        </div>
                    </div>
                </template>
            </vue-good-table>

            <TablePagination
                :inputPage="currentPage"
                :meta='meta'
                :next_of_next='next_of_next'
                :prev_of_prev='prev_of_prev'
                @update:inputPage="updatePageValue"
            />
        </div>


        <!-- delete modal -->
        <DeleteModal
            :isDeleteSubmitting="isDeleteSubmitting"
            :is_open="is_open_delete_modal"
            modal_title='Delete Lin'
            @close="closeDeleteModal"
            @submit="deleteLin"
        />

    </div>
</template>

<script>
import VisaApi from "@/libs/axios"
import store from '@/store'
import DeleteModal from '@/component/DeleteModal'
import Breadcrumb from '@/component/Breadcrumb'
import TablePagination from '@/component/TablePagination'
import {VueGoodTable} from 'vue-good-table-next'
import 'vue-good-table-next/dist/vue-good-table-next.css'
import {DeleteIcon, UpdateIcon, ViewIcon} from "../../data/icons/index.js";
import {meta_data, next_of_next_status, prev_of_prev_status} from '@/libs/helpers.js'
import AddIcon from "../../data/icons/AddIcon.vue";
import TableEntryShow from "../../component/table/TableEntryShow.vue";
import TableTopActions from "../../component/table/TableTopActions.vue";

export default {
    components: {
        TableTopActions,
        TableEntryShow,
        AddIcon,
        DeleteModal,
        Breadcrumb,
        TablePagination,
        VueGoodTable,
        DeleteIcon,
        ViewIcon,
        UpdateIcon
    },

    data() {
        return {
            is_open_delete_modal: false,
            uuid_deletable: '',

            meta: meta_data,
            prev_of_prev: false,
            next_of_next: false,
            per_page_array: [10, 20, 30, 40, 50],
            currentPage: '',

            search_input: '',
            breadcrumb: '',
            url: '',
            sort_status: false,
            isDeleteSubmitting: false,

            columns: [
                {label: 'Name', field: 'name'},
                {label: 'Number', field: 'number'},
                {label: 'Version', field: 'start_date'},
                {label: 'End Date', field: 'end_date'},
                {label: 'Description', field: 'description'},
                {label: 'Suspended By', field: 'superseded_by'},
                {label: 'Status', field: 'status'},
                {label: 'Action', field: 'action', sortable: false, width: '100px'}
            ],
            rows: [],
        };
    },

    methods: {
        async getLinList() {
            store.commit('setting/LOADING_ON')
            await VisaApi.get(this.url).then((response) => {
                store.commit('setting/LOADING_OFF')
                if (response.code == '200') {
                    this.rows = response.data.data;
                    Object.assign(this.meta, response.data.meta);
                }
            });
        },

        openDeleteModal(uuid) {
            this.is_open_delete_modal = true;
            this.uuid_deletable = uuid;
        },

        closeDeleteModal() {
            this.isDeleteSubmitting = false;
            this.is_open_delete_modal = false;
        },

        deleteLin() {
            this.isDeleteSubmitting = true;
            VisaApi.delete("/lin/" + this.uuid_deletable).then((response) => {
                if (response.code == '200') {
                    this.getLinList();
                    this.$toast.success(response.message, {
                        position: "top-right",
                        dismissible: true,
                    });
                }
                this.closeDeleteModal();
            }).catch((error) => {
                this.$toast.error(error.response.data.message, {
                    position: "top-right",
                    dismissible: true,
                });
                this.closeDeleteModal();
            });
        },

        toggleSort() {
            if (this.sort_status == false) {
                this.rows.sort((a, b) => a.name.localeCompare(b.name));
                this.sort_status = true;
            } else {
                this.rows.sort((a, b) => b.name.localeCompare(a.name));
                this.sort_status = false;
            }
        },

        updatePageValue(newValue) {
            this.currentPage = newValue;
        },
    },

    computed: {
        apiUrl() {
            return this.url = 'lin?page=' + this.meta.current_page + '&per_page=' + this.meta.per_page + '&order_by=name&order_by_type=desc&search=' + this.search_input;
        },
        nextOfNext() {
            this.next_of_next = next_of_next_status(this.meta);
        },
        prevOfPrev() {
            this.prev_of_prev = prev_of_prev_status(this.meta);
        },
    },

    watch: {
        apiUrl() {
            this.getLinList();
        },
        nextOfNext() {
        },
        prevOfPrev() {
        },
    },

    mounted() {
        this.url = 'lin?page=' + this.meta.current_page + '&per_page=' + this.meta.per_page + '&order_by=name&order_by_type=desc&search=' + this.search_input;
        this.getLinList();
        this.breadcrumb = this.$router.currentRoute.value.meta.breadcrumb;
    }
}
</script>
