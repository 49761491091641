<template>
    <div class="px-4 py-2 absolute w-full">
        <div class="flex flex-wrap items-center justify-between">
            <Breadcrumb :text="breadcrumb"/>
            <router-link to="/visa-list">
                <button class="back-btn" type="button">
                    <back-icon class="mt-1 mr-2"/>
                    Back to list
                </button>
            </router-link>
        </div>

        <div class="visasis-card">
            <div class="grid grid-cols-1 md:grid-cols-2 gap-x-4 gap-y-2">
                <div class="w-full">
                    <label class="flex items-center">Select Category <img alt="required"
                                                                          class="w-2 h-2 ml-1" src="@/assets/images/star.svg">
                    </label>
                    <v-select
                        v-model="form.visa_category_uuid"
                        :options="category_list"
                        :reduce="category => category.uuid"
                        class="pt-1"
                        label="name"
                        placeholder="Choose Category..."
                    />
                    <span v-if='form.errors().has("visa_category_uuid")' class="text-red-800 text-xs">
                        {{ form.errors().get('visa_category_uuid') }}
                    </span>
                </div>
                <div class="w-full">
                    <label class="flex items-center">Select Types <img alt="required"
                                                                       class="w-2 h-2 ml-1" src="@/assets/images/star.svg">
                    </label>
                    <v-select
                        v-model="form.visa_type_uuids"
                        :options="type_list"
                        :reduce="type => type.uuid"
                        class="pt-1"
                        label="name"
                        multiple
                        placeholder="Choose Type..."
                    />
                    <span v-if='form.errors().has("visa_type_uuids")' class="text-red-800 text-xs">
                        {{ form.errors().get('visa_type_uuids') }}
                    </span>
                </div>
                <div class="w-full">
                    <label class="flex items-center">Name <img alt="required" class="w-2 h-2 ml-1"
                                                               src="@/assets/images/star.svg"> </label>
                    <input v-model='form.name'
                           class="py-2 rounded border border-gray-300 bg-gray-50 text-sm placeholder-gray-400 focus:border-invblue resize-none w-full px-4 mt-1"
                           placeholder="Name" type='text'>
                    <span v-if='form.errors().has("name")' class="text-red-800 text-xs">
                        {{ form.errors().get('name') }}
                    </span>
                </div>
                <div class="w-full">
                    <label class="flex items-center">Slug <img alt="required" class="w-2 h-2 ml-1"
                                                               src="@/assets/images/star.svg"> </label>
                    <input v-model='form.slug'
                           class="py-2 rounded border border-gray-300 bg-gray-50 text-sm placeholder-gray-400 focus:border-invblue resize-none w-full px-4 mt-1"
                           placeholder="Slug" type='text'>
                    <span v-if='form.errors().has("slug")' class="text-red-800 text-xs">
                        {{ form.errors().get('slug') }}
                    </span>
                </div>
                <div class="w-full">
                    <label>Class</label>
                    <input v-model='form.visa_class'
                           class="py-2 rounded border border-gray-300 bg-gray-50 text-sm placeholder-gray-400 focus:border-invblue resize-none w-full px-4 mt-1"
                           placeholder="Class" type='text'>
                </div>
                <div class="w-full">
                    <label class="flex items-center">Sub-Class <img alt="required" class="w-2 h-2 ml-1"
                                                                    src="@/assets/images/star.svg"> </label>
                    <input v-model='form.visa_subclass'
                           class="py-2 rounded border border-gray-300 bg-gray-50 text-sm placeholder-gray-400 focus:border-invblue resize-none w-full px-4 mt-1"
                           placeholder="Sub-Class" type='text'>
                    <span v-if='form.errors().has("visa_subclass")' class="text-red-800 text-xs">
                        {{ form.errors().get('visa_subclass') }}
                    </span>
                </div>
                <div class="w-full">
                    <label>Is Popular</label>
                    <select v-model='form.is_popular'
                            class="py-2 rounded border border-gray-300 bg-gray-50 text-sm placeholder-gray-400 focus:border-invblue resize-none w-full px-4 mt-1">
                        <option :selected="form.is_popular==1" value="1">Yes</option>
                        <option :selected="form.is_popular==0" value="0">No</option>
                    </select>
                </div>
                <div class="w-full">
                    <label>Status</label>
                    <select v-model='form.status'
                            class="py-2 rounded border border-gray-300 bg-gray-50 text-sm placeholder-gray-400 focus:border-invblue resize-none w-full px-4 mt-1">
                        <option :selected="form.status==1" value="1">Active</option>
                        <option :selected="form.status==0" value="0">Inactive</option>
                    </select>
                </div>
            </div>
            <div class="w-full">
                <label>Short Description</label>
                <article class="prose" style="max-width: 100%">
                    <ckeditor v-model="form.short_description" :config="editorConfig" :editor="editor"/>
                </article>
            </div>

            <div class="w-full">
                <label>Summary</label>
                <article class="prose" style="max-width: 100%">
                    <ckeditor v-model="form.summary" :config="editorConfig" :editor="editor"/>
                </article>
            </div>
            <div class="w-full">
                <label>Visa Fees</label>
                <article class="prose" style="max-width: 100%">
                    <ckeditor v-model="form.visa_fees" :config="editorConfig" :editor="editor"/>
                </article>
            </div>

            <button v-if="isSubmitting==false" class="mt-4 justify-center rounded-md bg-blue-600 px-6 py-1.5 text-white hover:bg-blue-500" type="button"
                    @click="createVisa">
                Submit
            </button>
            <span v-else class="mt-4 block">
                <LoadingButton text="Submitting"/>
            </span>

        </div>
    </div>
</template>

<script>
import VisaApi from "@/libs/axios"
import form from 'vuejs-form'
import Breadcrumb from '@/component/Breadcrumb'
import vSelect from "vue-select"
import "vue-select/dist/vue-select.css"
import LoadingButton from "@/component/LoadingButton"
import {sanitizeName,} from '@/libs/helpers.js'
import BackIcon from "../../../data/icons/BackIcon.vue";
import CKEditor from "@ckeditor/ckeditor5-vue"
import ckeditorConfig from "../../../libs/ckeditorConfig";
import {ClassicEditor} from "ckeditor5";

export default {
    components: {BackIcon, LoadingButton, Breadcrumb, "v-select": vSelect, ckeditor: CKEditor.component},
    data() {
        return {
            breadcrumb: '',
            category_list: [],
            type_list: [],
            isSubmitting: false,
            editor: ClassicEditor,
            editorConfig: ckeditorConfig,

            form: form.default({
                visa_category_uuid: '',
                visa_type_uuids: [],
                name: '',
                slug: '',
                visa_subclass: '',
                visa_class: '',
                short_description: '',
                summary: '',
                visa_fees: '',
                is_popular: '0',
                status: '1',
            }).rules({
                visa_category_uuid: 'required',
                visa_type_uuids: 'required',
                name: 'required',
                slug: 'required',
                visa_subclass: 'required',
            }).messages({
                'visa_category_uuid.required': 'This field is required',
                'visa_type_uuids.required': 'This field is required',
            }),
        };
    },

    methods: {
        createVisa() {
            if (this.form.validate().errors().any()) {
                return;
            } else {
                this.isSubmitting = true;
                VisaApi.post("/visa/create", this.form.all()).then((response) => {
                    this.$toast.success(response.message, {
                        position: "top-right",
                        dismissible: true,
                    });
                    this.$router.push({name: 'visa-list'})
                    this.isSubmitting = false;
                }).catch((error) => {
                    this.$toast.error(error.response.data.message, {
                        position: "top-right",
                        dismissible: true,
                    });
                    this.isSubmitting = false;
                });
            }
            ;
        },

        async getVisaTypeList() {
            await VisaApi.get('visa-type').then((response) => {
                if (response.code == '200') {
                    this.type_list = response.data;
                }
            });
        },
    },

    watch: {
        'form.name': {
            handler(val) {
                this.form.slug = sanitizeName(val)
            }
        }
    },

    mounted() {
        this.breadcrumb = this.$router.currentRoute.value.meta.breadcrumb;
        this.getVisaTypeList();
        this.$store.dispatch('api/getCategory').then((response) => {
            this.category_list = response;
        })
    }
}
</script>
