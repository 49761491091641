<template>
    <form @submit.prevent="updateSettings">
        <div class="grid grid-cols-1 lg:grid-cols-2 gap-x-4 gap-y-2">
            <TextInput v-if="hasSlug('title')" :label="'Company name'" :model="general.title" field="title"
                       @input-value="getValue"></TextInput>
            <TextInput v-if="hasSlug('email')" :label="'Company email'" :model="general.email" field="email"
                       @input-value="getValue"></TextInput>
            <TextInput v-if="hasSlug('phone')" :label="'Company phone'" :model="general.phone" field="phone"
                       @input-value="getValue"></TextInput>
            <TextInput v-if="hasSlug('copyright_text')" :label="'Copyright text'" :model="general.copyright_text"
                       field="copyright_text" @input-value="getValue"></TextInput>
            <TextInput v-if="hasSlug('address')" :label="'Address'" :model="general.address" field="address"
                       @input-value="getValue"></TextInput>
        </div>

        <button class="justify-center mt-4 py-1.5 rounded-md bg-blue-600 px-6 text-white hover:bg-blue-500" type="button"
                @click="updateSettings">
            Submit
        </button>
    </form>
</template>

<script>
import {reactive, onMounted, computed} from "vue";
import {TextInput} from "../ui/forms/index.js";
import store from "../../store";

export default {
    components: {
        TextInput,
    },
    setup(props, {emit}) {
        const general = reactive({});
        const setting = computed(() => store.getters["setting/settings"]);
        onMounted(async () => {
            Object.assign(general, {
                title: setting.value.title,
                email: setting.value.email,
                phone: setting.value.phone,
                copyright_text: setting.value.copyright_text,
                address: setting.value.address
            })
        })

        const hasSlug = (slug) => {
            return general.hasOwnProperty(slug)
        }

        const getValue = (payload) => {
            switch (payload.field) {
                case 'title':
                    general.title = payload.value;
                    break;
                case 'email':
                    general.email = payload.value;
                    break;
                case 'phone':
                    general.phone = payload.value;
                    break;
                case 'copyright_text':
                    general.copyright_text = payload.value;
                    break;
                case 'address':
                    general.address = payload.value;
                    break;
            }
        }

        const updateSettings = async () => {
            await store.dispatch('setting/updateSettings', general)
            emit('updated')
            setTimeout(() => {
                window.location.reload()
            }, 50)
        }

        return {
            hasSlug,
            general,
            getValue,
            updateSettings
        }
    }
}


</script>

<style scoped>

</style>
