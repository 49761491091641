<template>
    <div class="w-full lg:w-1/2 px-4 py-2">
        <div class="flex items-center">
            <span> Show </span>
            <select v-model="per_page" class="px-3 py-1 mx-1.5 rounded bg-white border border-gray-300">
                <option v-for="data in page_options" :key="data">{{data}}</option>
            </select>
            <span> entries </span>
            <slot></slot>
        </div>
    </div>
</template>

<script setup>
import {ref, watch} from "vue";

const per_page = ref(10)
const page_options = ref([10, 20, 30, 40, 50, 'All'])

const emit = defineEmits(['input'])

watch(per_page, () => {
    emit('input', per_page)
})

</script>

<style scoped>

</style>
