<template>
    <div class="p-4">

        <div class="flex justify-between border-b pb-4 border-gray-200">
            <div class="flex items-center">
                <p>Profile</p>
                <img alt="img" class="h-4 mx-2" src="@/assets/images/arow-right.svg">
                <p>{{ user.name }}</p>
            </div>
            <button class="bg-gray-400 text-white rounded px-2 py-1" @click="openModal(true)">
                Edit Profile
            </button>
        </div>

        <div class="flex flex-wrap -mx-4">
            <div class="w-full lg:w-1/4 p-4">
                <div class="p-4 shadow bg-gray-100 text-gray-700">
                    <div class="w-36 h-36 lg:w-32 lg:h-32 xl:w-44 xl:h-44 mx-auto">
                        <img alt="admin" class="w-full h-full rounded-full p-2 bg-gray-200"
                             src="@/assets/images/pc.jpg">
                    </div>
                    <p class="text-center text-2xl mt-4">{{ user.name }}</p>
                    <p class="text-center text-sm">All access is reserved</p>
                </div>
            </div>
            <div class="w-full lg:w-3/4 p-4">
                <div class="w-full h-full shadow bg-gray-100 text-gray-300 p-4">
                    <div class="flex flex-wrap -mx-4 text-gray-700">
                        <div class="w-full md:w-1/2 p-4">
                            <div>
                                <p class="font-bold">Name</p>
                                <p class="text-xl">{{ user.name }}</p>
                            </div>
                            <div class="mt-4">
                                <p class="font-bold">Email</p>
                                <p class="text-xl">{{ user.email }}</p>
                            </div>
                        </div>
                        <div class="w-full md:w-1/2 p-4">
                            <div>
                                <p class="font-bold">Role</p>
                                <p class="text-xl uppercase">{{ user.role }}</p>
                            </div>
                            <div class="mt-4">
                                <p class="font-bold">Email Verified</p>
                                <p class="text-xl">{{ user.email_verified }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="mt-4">
                        <p class="text-xl font-bold text-gray-700">Permissions</p>
                        <div v-for="(permission, index) in user.permissions" :key="index" class="inline-flex">
                            <p class="px-4 py-2 my-2 mr-2 rounded text-white bg-gray-700">
                                {{ permission.name }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <!-- modal -->
        <Modal :modal_title='mdl_title' @close="openModal(false)" @submit="updateUser">
            <template v-slot:body>
                <div class="w-full mt-2 px-2">
                    <label>Name</label>
                    <input v-model='form.name' class="py-2 rounded border border-gray-300 bg-gray-50 text-sm placeholder-gray-400 focus:border-invblue resize-none w-full px-4 mt-1" placeholder="Name"
                           type='text'>
                    <span v-if='form.errors().has("name")' class="text-red-800 text-xs">
                        {{ form.errors().get('name') }}
                    </span>
                </div>
            </template>
        </Modal>

    </div>
</template>

<script>
import VisaApi from "@/libs/axios"
import form from 'vuejs-form'
import store from '@/store'
import Modal from '@/component/Modal'

export default {
    components: {Modal},
    data() {
        return {
            mdl_title: 'Update Profile',
            user: {},
            form: form.default({
                id: '',
                uuid: '',
                name: '',
            }).rules({
                name: 'required',
            }),
        };
    },

    methods: {
        openModal(value) {
            this.form.errors().forget();
            const modalCls = document.getElementById('modal').classList;
            const modalAnimCls = document.getElementById('modal_animation').classList;
            if (value == true) {
                modalCls.remove('hidden');
                modalAnimCls.add('open-modal-animation');
                this.form.uuid = this.user.uuid;
                this.form.name = this.user.name;
            } else {
                modalCls.add('hidden');
                modalAnimCls.remove('open-modal-animation');
            }
        },

        async getUserList() {
            store.commit('setting/LOADING_ON')
            await VisaApi.get("/profile").then((response) => {
                store.commit('setting/LOADING_OFF')
                if (response.code == '200') {
                    this.user = response.data;
                } else {
                    store.commit('setting/LOADING_OFF')
                    this.$toast.error("Something went wrong!", {
                        position: "top-right",
                        dismissible: true,
                    });
                }
            });
        },

        updateUser() {
            store.commit('setting/LOADING_ON')
            if (this.form.validate().errors().any()) {
                store.commit('setting/LOADING_OFF')
                return;
            } else {
                VisaApi.post("/profile/update", this.form.all()).then((response) => {
                    store.commit('setting/LOADING_OFF')
                    if (response.code == '200') {
                        this.openModal(false);
                        this.user = response.data;
                        this.$toast.success(response.message, {
                            position: "top-right",
                            dismissible: true,
                        });
                    } else {
                        store.commit('setting/LOADING_OFF')
                        this.$toast.error("Something went wrong!", {
                            position: "top-right",
                            dismissible: true,
                        });
                    }
                });
            }
        },

    },

    computed: {},

    watch: {},

    async mounted() {
        this.getUserList();
    }
}
</script>