import VisaApi from "../../libs/axios";

export default {
    namespaced: true,
    state: {
        settings: localStorage.getItem('settings') !== null ? JSON.parse(localStorage.getItem('settings')) : null,
        loading: false
    },
    getters: {
        settings: state => state.settings,
        loading: state => state.loading,
    },
    mutations: {
        SET_DEFAULT_SETTING(state, payload) {
            localStorage.setItem('settings', JSON.stringify(payload))
        },
        UPDATE_DEFAULT_SETTING(state, payload) {
            state.settings = {};
            state.settings = payload;
        },
        LOADING_ON(state, payload) {
            state.loading = true
        },
        LOADING_OFF(state, payload) {
            state.loading = false
        }
    },
    actions: {
        async defaultSetting({commit}) {
            commit('LOADING_ON')
            return await VisaApi.get('settings')
                .then((res) => {
                    commit("SET_DEFAULT_SETTING", res.data)
                    commit("LOADING_OFF")
                    return res.data;
                })
                .catch((err) => {
                    commit('LOADING_OFF')
                    console.log(err)
                    return err;
                })
        },
        async updateSettings({commit}, payload) {
            await VisaApi.post('settings/update?_method=PATCH', {'settings': payload}, {headers: {"Content-Type": "multipart/form-data"}})
                .then((res) => {
                    commit("LOADING_OFF")
                })
                .catch((err) => {
                    commit('LOADING_OFF')
                    console.log(err)
                })
        },
        updateDefaultSetting({commit}, payload) {
            commit('UPDATE_DEFAULT_SETTING', payload)
        },
    },
}
