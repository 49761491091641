<template>
    <div class="relative">
        <img alt="user" class="outside-click w-8 cursor-pointer rounded-full"
             src="@/assets/images/user1.jpg" @click="$emit('open')">
        <div v-show="props.is_open"
             class="outside-click mt-2 absolute top-10 right-0 w-36 bg-slate-700 text-white shadow z-10 rounded">
            <div class="outside-click text-center">
                <p class="outside-click py-1 border-b border-gray-600"> Super Admin </p>
                <router-link to="/profile">
                    <p class="outside-click py-1 mt-2 cursor-pointer hover:bg-gray-600"> Profile </p>
                </router-link>
                <p class="cursor-pointer py-1 mb-2 hover:bg-gray-600" @click="logoutUser">Logout</p>
            </div>
        </div>
    </div>
</template>

<script setup>
import store from "../../store";
import VisaApi from "../../libs/axios";
import {toaster} from '@/libs/helpers.js'
import {useRouter} from "vue-router";

const router = useRouter();

const props = defineProps({
    is_open: Boolean
})
const logoutUser = () => {
    store.commit('setting/LOADING_ON')
    VisaApi.post('/logout').then((response) => {
        store.commit('setting/LOADING_OFF')
        if (response.success == true) {
            store.dispatch('user/logout');
            toaster.success(response.message)
            router.push({name: 'login'});
        } else {
            store.commit('setting/LOADING_OFF')
            toaster.error(response.message)
        }
    });
}
</script>

<style scoped>

</style>
