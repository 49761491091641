<template>
    <div class="px-4 py-2 absolute w-full">
        <div class="flex flex-wrap items-center justify-between">
            <Breadcrumb :text="breadcrumb"/>
            <router-link to="/visa-requirement-list">
                <button class="back-btn" type="button">
                    <back-icon class="mt-1 mr-2"/>
                    Back to list
                </button>
            </router-link>
        </div>

        <div class="visasis-card">
            <p class="w-max px-4 py-1 mb-2 font-bold rounded uppercase bg-gray-300 border-gray-400">
                Visa Requirement
            </p>
            <div class="grid grid-cols-1 lg:grid-cols-2 gap-2">
                <div class="w-full flex items-center">
                    <label class="font-bold">Visa Name:</label>
                    <p v-if="data.visa!=null" class="pl-2">{{ data.visa.name }}</p>
                </div>
                <div class="w-full flex items-center">
                    <label class="font-bold">Visa Stream Name:</label>
                    <p v-if="data.visa_stream!=null" class="pl-2">{{ data.visa_stream.name }}</p>
                </div>
                <div class="w-full flex items-center">
                    <label class="font-bold">Visa Subclass:</label>
                    <p class="pl-2">{{ data.visa_subclass }}</p>
                </div>
                <div class="w-full flex items-center">
                    <label class="font-bold">Age:</label>
                    <p class="pl-2">{{ data.age }}</p>
                </div>
                <div class="w-full flex items-center">
                    <label class="font-bold">English:</label>
                    <p class="pl-2">{{ data.english }}</p>
                </div>
                <div class="w-full flex items-center">
                    <label class="font-bold">Visa Sponsorship:</label>
                    <p class="pl-2">{{ data.sponsorship }}</p>
                </div>
                <div class="w-full flex items-center">
                    <label class="font-bold">Status:</label>
                    <p v-if="data.status==1" class="pl-2">Active</p>
                    <p v-else class="pl-2">Inactive</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import VisaApi from "@/libs/axios"
import Breadcrumb from '@/component/Breadcrumb'
import BackIcon from "../../../data/icons/BackIcon.vue";

export default {
    components: {BackIcon, Breadcrumb,},
    data() {
        return {
            breadcrumb: '',
            data: {},
        };
    },

    methods: {
        getSingleData(uuid) {
            VisaApi.get("/visa-requirement/" + uuid).then((response) => {
                if (response.code == '200') {
                    this.data = response.data;
                }
            });
        },
    },

    mounted() {
        this.breadcrumb = this.$router.currentRoute.value.meta.breadcrumb;
        this.getSingleData(this.$route.params.id);
    }
}
</script>
