<template>
    <div class="py-4">
        <span class="text-gray-600 text-xl">{{ text }}</span>
    </div>
</template>

<script>
export default {
    props: ['text'],
};
</script>
