<template>
    <div class="px-4 py-2 absolute w-full">

        <Breadcrumb :text="breadcrumb"/>

        <div class="visasis-card">
            <div class="flex flex-wrap items-center -mx-4">
                <TableEntryShow @input="[meta.current_page = 1, meta.per_page = $event]"/>
                <div class="w-full lg:w-1/2 px-4 py-2">
                    <div class="flex justify-start lg:justify-end flex-wrap gap-1">
                        <input v-model="search_input"
                               class="pl-4 w-[64%] h-10 rounded border focus:outline-none border-gray-300" placeholder="Search"
                               type="text"/>
                        <button class="px-6 w-[34%] flex justify-center items-center h-10 gap-2 py-2 text-center tracking-wider rounded bg-green-600 hover:bg-green-500 text-white"
                                @click="openModal(true, 'create')">
                            <hyper-link-icon/>
                            <span class="hidden sm:block">Attach</span>
                        </button>
                    </div>
                </div>
            </div>

            <vue-good-table
                :columns="columns"
                :rows="rows"
            >
                <template #table-row="props">
                    <div v-if="props.column.field == 'visa'">
                        {{ props.row.visa_subclass }} - {{ props.row.name }}
                    </div>
                    <div v-if="props.column.field == 'must_main'">
                        <span v-html="visaConditionView(props.row.conditions, 2)"/>
                    </div>
                    <div v-if="props.column.field == 'must_gt_18'">
                        <span v-html="visaConditionView(props.row.conditions, 4)"/>
                    </div>
                    <div v-if="props.column.field == 'must_lt_18'">
                        <span v-html="visaConditionView(props.row.conditions, 6)"/>
                    </div>
                    <div v-if="props.column.field == 'action'">
                        <div class="px-3 py-2 flex items-center justify-center">
                            <view-icon @click="viewSingleVisaCondition(props.row.id)"/>
                            <update-icon @click="getSingleVisaCondition(props.row.id)"/>
                            <delete-icon @click="openDeleteModal(props.row)"/>
                        </div>
                    </div>
                </template>
            </vue-good-table>

            <TablePagination
                :inputPage="currentPage"
                :meta='meta'
                :next_of_next='next_of_next'
                :prev_of_prev='prev_of_prev'
                @update:inputPage="updatePageValue"
            />
        </div>

        <!-- attach modal -->
        <Modal :isSubmitting="isModalSubmitting" :modal_title='mdl_title' @close="openModal(false, 'create')"
               @submit="attachVisaWithVisaCondition">
            <template v-slot:body>
                <div class="grid grid-cols-1 md:grid-cols-2 gap-x-4 gap-y-2 mt-2 px-2">
                    <div class="w-full">
                        <label class="flex items-center">Select Visa<img alt="required"
                                                                         class="w-2 h-2 ml-1" src="@/assets/images/star.svg">
                        </label>
                        <v-select
                            v-model="form.visa_id"
                            :get-option-label="(option) => option.visa_subclass + ' - ' + option.name"
                            :options="visa_list"
                            :reduce="visa => visa.id"
                            class="pt-1"
                            placeholder="Choose visa..."
                        />
                        <span v-if='form.errors().has("visa_id")' class="text-red-800 text-xs">
                            {{ form.errors().get('visa_id') }}
                        </span>
                    </div>
                    <div class="w-full">
                        <label class="flex items-center">Visa Stream</label>
                        <v-select
                            v-model="form.visa_stream_id"
                            :options="visa_stream_list"
                            :reduce="visa_stream => visa_stream.id"
                            class="pt-1"
                            label="name"
                            placeholder="Choose visa stream..."
                        />
                    </div>
                </div>
                <div class="px-2 mt-2">
                    <button class="px-3 py-2 bg-green-600 hover:bg-green-700 rounded text-white"
                            @click="addNewCondition">
                        <add-icon/>
                    </button>
                </div>

                <div v-for="(type, ti) in visa_condition_type_list" :key="'type-'+ti">
                    <div class="flex justify-between gap-x-4 gap-y-2 mt-2 px-2">
                        <div class="w-full">
                            <v-select
                                v-model="type.visa_condition_type"
                                :options="visa_condition_types"
                                :reduce="(visa_condition_type) => visa_condition_type.id"
                                class="pt-1"
                                label="title"
                                placeholder="Choose visa condition type..."
                            />
                        </div>
                        <div class="w-full">
                            <v-select
                                v-model="type.visa_conditions"
                                :get-option-label="(option) => option.con_number"
                                :options="visa_conditions"
                                :reduce="visa_condition => visa_condition.id"
                                class="pt-1"
                                multiple
                                placeholder="Choose visa condition..."
                            />
                        </div>
                        <button class="px-3 bg-red-600 hover:bg-red-700 rounded text-white"
                                @click="removeCondition(ti, form.visa_id)">
                            <minus-circle-icon/>
                        </button>
                    </div>
                </div>
            </template>
        </Modal>

        <!-- view modal -->
        <ViewModal :is_open="is_open_view_modal" :modal_title="'Visa condition details'"
                   @close="is_open_view_modal = false">
            <template #default>
                <h2 class="font-bold text-xl mb-3">{{ visa_condition.visa_subclass }} - {{ visa_condition.name }}</h2>
                <div class="min-w-full">
                    <table class="w-full bg-white border border-gray-300">
                        <thead>
                        <tr class="border">
                            <th class="p-2">Condition Type</th>
                            <th class="p-2">Condition Details</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(type, ti) in visa_condition.condition_criteria" :key="'type-'+ti" class="border">
                            <td class="p-2 bg-gray-50 whitespace-normal">
                                {{ getVisaConditionType(visa_condition.condition_types, type.visa_condition_type_id) }}
                            </td>
                            <td class="p-2 whitespace-normal">
                                <div v-for="(condition, con_in) in type.visa_conditions.split(',')"
                                     :key="'condition-'+con_in"
                                     class="p-2 min-w-full">
                                    <span>{{ getConditionDetails(visa_condition.conditions, condition) }}</span>
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </template>
        </ViewModal>

        <!-- delete modal -->
        <DeleteModal
            :isDeleteSubmitting="isDeleteSubmitting"
            :is_open="is_open_delete_modal"
            modal_title='Detach Visa Condition'
            @close="closeDeleteModal"
            @submit="detachVisaFromVisaCondition"
        />

    </div>
</template>

<script>
import VisaApi from "@/libs/axios"
import form from 'vuejs-form'
import store from '@/store'
import Modal from '@/component/Modal'
import ViewModal from '@/component/ui/modals/ViewModal'
import DeleteModal from '@/component/DeleteModal'
import Breadcrumb from '@/component/Breadcrumb'
import vSelect from "vue-select"
import "vue-select/dist/vue-select.css"
import TablePagination from '@/component/TablePagination'
import {VueGoodTable} from 'vue-good-table-next'
import 'vue-good-table-next/dist/vue-good-table-next.css'
import {meta_data, next_of_next_status, prev_of_prev_status, toaster} from '@/libs/helpers.js'
import AddIcon from "../../../data/icons/AddIcon.vue";
import ViewIcon from "../../../data/icons/ViewIcon.vue";
import UpdateIcon from "../../../data/icons/UpdateIcon.vue";
import DeleteIcon from "../../../data/icons/DeleteIcon.vue";
import TableEntryShow from "../../../component/table/TableEntryShow.vue";
import TableTopActions from "../../../component/table/TableTopActions.vue";
import HyperLinkIcon from "../../../data/icons/HyperLinkIcon.vue";
import MinusCircleIcon from "../../../data/icons/MinusCircleIcon.vue";

export default {
    components: {
        MinusCircleIcon,
        HyperLinkIcon,
        TableTopActions, TableEntryShow,
        DeleteIcon,
        UpdateIcon, ViewModal,
        ViewIcon, AddIcon, Modal, DeleteModal, VueGoodTable, Breadcrumb, "v-select": vSelect, TablePagination
    },
    data() {
        return {
            mdl_title: 'Create Visa Condition',
            modal_mode: 'create',
            uuid_deletable: '',

            meta: meta_data,
            prev_of_prev: false,
            next_of_next: false,
            per_page_array: [10, 20, 30, 40, 50],
            currentPage: '',

            columns: [
                {label: 'Visa', field: 'visa'},
                {label: 'Must for main', field: 'must_main'},
                {label: 'Must for >18', field: 'must_gt_18'},
                {label: 'Must for <18', field: 'must_lt_18'},
                {label: 'Action', field: 'action'},
            ],
            rows: [],
            default_condition: {
                visa_condition_type: '',
                visa_conditions: [],
            },
            visa_condition_type_list: [
                {
                    visa_condition_type: '',
                    visa_conditions: [],
                }
            ],
            visa_list: [],
            visa_stream_list: [],
            visa_conditions: [],
            visa_condition_types: [],

            search_input: '',
            breadcrumb: '',
            url: '',
            sort_status: false,
            is_open_delete_modal: false,
            isDeleteSubmitting: false,
            isModalSubmitting: false,
            is_open_view_modal: false,
            visa_condition: {},

            form: form.default({
                visa_id: '',
                visa_stream_id: '',
                visa_conditions: [],
                status: '1',
            }).rules({
                visa_id: 'required',
            }).messages({
                'visa_id.required': 'Visa is required.'
            }),
            detachable_data: {
                visa_condition_id: '',
                visa_id: '',
            }
        };
    },

    methods: {
        openModal(value, mode) {
            this.form.errors().forget();
            const modalCls = document.getElementById('modal').classList;
            const modalAnimCls = document.getElementById('modal_animation').classList;
            if (value == true && mode == 'create') {
                modalCls.remove('hidden');
                modalAnimCls.add('open-modal-animation');

                this.form.visa_id = '';
                this.form.visa_stream_id = '';
                this.form.status = '1';
                this.visa_condition_type_list = [
                    {
                        visa_condition_type: '',
                        visa_conditions: [],
                    }
                ]

                this.modal_mode = 'create';
                this.mdl_title = 'Create Visa Condition';
            } else if (value == true && mode == 'update') {
                this.modal_mode = 'update';
                this.mdl_title = 'Update Visa Condition';
                modalCls.remove('hidden');
                modalAnimCls.add('open-modal-animation');
            } else {
                modalCls.add('hidden');
                modalAnimCls.remove('open-modal-animation');
                this.isModalSubmitting = false;
            }
        },
        addNewCondition() {
            this.visa_condition_type_list.push({
                visa_condition_type: '',
                visa_conditions: [],
            })
        },

        removeCondition(index, visa_id) {
            this.visa_condition_type_list.splice(index, 1)
            if (this.modal_mode == 'update') {
                this.detachable_data.visa_id = visa_id
                this.detachVisaFromVisaCondition();
            }
        },

        attachVisaWithVisaCondition() {
            let is_valid = true;
            this.visa_condition_type_list.forEach((type) => {
                if (type.visa_condition_type == '' || type.visa_conditions.length <= 0) {
                    is_valid = false
                }
            })

            if (!is_valid) {
                toaster.error("Condition and condition type is missing");
                return;
            }
            this.form.visa_condition_list = this.visa_condition_type_list
            if (this.form.validate().errors().any()) {
                return;
            } else {
                VisaApi.post("/visa-condition/attach/visa", this.form.all()).then((response) => {
                    this.getVisaOfVisaConditionList();
                    toaster.success(response.message);
                    this.openModal(false, 'create');
                }).catch((error) => {
                    toaster.error(error.response.data.message);
                });
            }
        },

        //visa of visa condition
        async getVisaOfVisaConditionList() {
            store.commit('setting/LOADING_ON')
            await VisaApi.get(this.apiUrl).then((response) => {
                store.commit('setting/LOADING_OFF')
                if (response.code == '200') {
                    this.rows = response.data.data;
                    Object.assign(this.meta, response.data.meta);
                }
            });
        },

        //visa list
        async getVisaList() {
            await VisaApi.get('visa').then((response) => {
                if (response.code == '200') {
                    this.visa_list = response.data
                }
            });
        },

        //visa condition list
        async getVisaConditionList() {
            await VisaApi.get('visa-condition').then((response) => {
                if (response.code == '200') {
                    this.visa_conditions = response.data
                }
            });
        },

        //get visa condition type list
        async getVisaConditionTypeList() {
            await VisaApi.get('visa-condition/type/list').then((response) => {
                if (response.code == '200') {
                    this.visa_condition_types = response
                }
            });
        },

        //visa stream list
        async getVisaStreamList() {
            await VisaApi.get('visa-stream').then((response) => {
                if (response.code == '200') {
                    this.visa_stream_list = response.data.data;
                }
            });
        },

        visaConditionView(conditions, type) {
            let _types = conditions.filter((con) => con.pivot.visa_condition_type_id == type)
            let type_html = '';
            for (let i = 0; i < _types.length; i++) {
                type_html += '<span class="w-min px-2 text-sm rounded bg-gray-100 mx-1" title="' + _types[i].description + '">' + _types[i].con_number + '</span>';
            }
            return type_html;
        },

        getVisaConditionType(condition_types, type_id) {
            let _con_types = condition_types.filter((ct) => ct.id == type_id)
            if (_con_types.length > 0) return _con_types[0].title;
            else return '';
        },

        getConditionDetails(conditions, condition_id) {
            let _cons = conditions.filter((con) => con.id == condition_id)
            if (_cons.length > 0) {
                return _cons[0].con_number + ' - ' + _cons[0].description;
            } else return '';
        },

        visaConditionTypesView(con_types) {
            return '<span class="w-min px-2 text-sm rounded bg-gray-100 mx-1">' + con_types[0].title + '</span>';
        },

        viewSingleVisaCondition(visa_id) {
            this.is_open_view_modal = true
            VisaApi.get(`/visa-condition/single/visa/${visa_id}`).then((response) => {
                if (response.code == '200') {
                    this.visa_condition = response.data
                }
            }).catch((error) => {
                toaster.error(error.response.data.message);
            });
        },

        async getSingleVisaCondition(visa_id) {
            await VisaApi.get(`/visa-condition/single/visa/${visa_id}`).then(async (response) => {
                if (response.code == '200') {
                    this.form.visa_id = response.data.id
                    this.form.visa_stream_id = response.data.stream

                    await this.$nextTick(async () => {
                        this.visa_condition_type_list = await response.data.condition_criteria.map((criteria) => {
                            return {
                                visa_condition_type: criteria.visa_condition_type_id,
                                visa_conditions: criteria.visa_conditions.split(',').map(Number)
                            };
                        })

                        this.openModal(true, 'update')
                    })
                }
            }).catch((error) => {
                toaster.error(error.response.data.message);
            });
        },

        openDeleteModal(visa) {
            this.is_open_delete_modal = true
            this.detachable_data.visa_id = visa.id
        },

        closeDeleteModal() {
            this.is_open_delete_modal = false
            this.isDeleteSubmitting = false;
        },

        //detach visa from visa condition
        detachVisaFromVisaCondition() {
            this.isDeleteSubmitting = true;
            VisaApi.post("/visa-condition/detach/visa/", this.detachable_data).then((response) => {
                if (response.code == '200') {
                    this.getVisaOfVisaConditionList();
                    toaster.success(response.message);
                    this.closeDeleteModal();
                }
            }).catch((error) => {
                toaster.error(error.response.data.message);
                this.closeDeleteModal();
            });
        },

        updatePageValue(newValue) {
            this.currentPage = newValue;
        },
    },

    computed: {
        apiUrl() {
            return 'visa-condition/visa/list?page=' + this.meta.current_page + '&per_page=' + this.meta.per_page + '&order_by=id&order_by_type=desc&search=' + this.search_input;
        },
        nextOfNext() {
            this.next_of_next = next_of_next_status(this.meta);
        },
        prevOfPrev() {
            this.prev_of_prev = prev_of_prev_status(this.meta);
        },
    },

    watch: {
        apiUrl() {
            this.getVisaOfVisaConditionList();
        },
        nextOfNext() {
        },
        prevOfPrev() {
        },
    },

    async mounted() {
        await this.getVisaOfVisaConditionList();
        await this.getVisaList();
        await this.getVisaConditionList();
        await this.getVisaConditionTypeList();
        await this.getVisaStreamList();
        this.breadcrumb = this.$router.currentRoute.value.meta.breadcrumb;
    }
}

</script>

<style scoped>

</style>
