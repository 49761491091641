<template>
    <div class="px-4 py-2 absolute w-full">

        <Breadcrumb :text="breadcrumb"/>

        <div class="visasis-card">

            <div class="flex flex-wrap items-center -mx-4">
                <TableEntryShow @input="[meta.current_page = 1, meta.per_page = $event]"/>
                <TableTopActions :is_modal="true" :need_import="true"
                                 @create="openModal(true, 'create')" @import="is_open_import_modal = true"
                                 @input="[meta.current_page = 1, search_input = $event]"/>
            </div>

            <vue-good-table
                :columns="columns"
                :rows="rows"
            >
                <template #table-row="props">
                    <div v-if="props.column.field == 'lin'">
                        <span v-if="props.row.lin!=null"> {{ props.row.lin.number }} </span>
                    </div>
                    <div v-if="props.column.field == 'occupation'">
                        <span v-if="props.row.occupation!=null"> {{ props.row.occupation.name }} </span>
                    </div>
                    <div v-if="props.column.field == 'caveat_no'">
                        <span v-if="props.row.caveat_no!=null"> {{ props.row.caveat_no.join(', ') }} </span>
                    </div>
                    <div v-if="props.column.field == 'status'">
                        <p v-if="props.row.status==1" class="w-min px-2 rounded m-auto text-white bg-green-700">
                            Active</p>
                        <p v-else class="w-min px-2 rounded m-auto text-white bg-red-700">Inactive</p>
                    </div>
                    <div v-if="props.column.field == 'action'">
                        <div class="px-3 py-2 flex items-center justify-center">
                            <router-link :to="{ name: 'lin-occupation-view', params: {id:props.row.uuid} }">
                                <view-icon/>
                            </router-link>
                            <update-icon @click="getSingleLinOccupation(props.row.uuid)"/>
                            <delete-icon @click="openDeleteModal(props.row.uuid)"/>
                        </div>
                    </div>
                </template>
            </vue-good-table>

            <TablePagination
                :inputPage="currentPage"
                :meta='meta'
                :next_of_next='next_of_next'
                :prev_of_prev='prev_of_prev'
                @update:inputPage="updatePageValue"
            />
        </div>


        <!-- modal -->
        <Modal :isSubmitting="isModalSubmitting" :modal_title='mdl_title' @close="openModal(false, 'create')"
               @submit="handleSubmitMethod">
            <template v-slot:body>
                <div class="grid grid-cols-1 md:grid-cols-2 gap-x-4 gap-y-2 mt-2 px-5 py-5">
                    <div class="w-full">
                        <label class="flex items-center">Select Lin <img alt="required"
                                                                         class="w-2 h-2 ml-1" src="@/assets/images/star.svg">
                        </label>
                        <v-select
                            v-model="form.lin_uuid"
                            :get-option-label="(lin) => lin.number"
                            :options="lin_list"
                            :reduce="(lin) => lin.uuid"
                            class="pt-1"
                            label="number"
                            placeholder="Choose lin..."
                        />
                        <span v-if='form.errors().has("lin_uuid")' class="text-red-800 text-xs">
                            {{ form.errors().get('lin_uuid') }}
                        </span>
                    </div>
                    <div class="w-full">
                        <label class="flex items-center">Select Skilled List <img alt="required"
                                                                                  class="w-2 h-2 ml-1"
                                                                                  src="@/assets/images/star.svg"> </label>
                        <v-select
                            v-model="form.lin_sol_code"
                            :options="lin_sol_list"
                            :reduce="lin => lin.code"
                            class="pt-1"
                            label="code"
                            placeholder="Choose lin code..."
                        />
                        <span v-if='form.errors().has("lin_sol_code")' class="text-red-800 text-xs">
                            {{ form.errors().get('lin_sol_code') }}
                        </span>
                    </div>
                    <div class="w-full">
                        <label class="flex items-center">Anzsco Code <img alt="required"
                                                                          class="w-2 h-2 ml-1" src="@/assets/images/star.svg">
                        </label>
                        <input v-model='form.anzsco_code' class="py-2 rounded border border-gray-300 bg-gray-50 text-sm placeholder-gray-400 focus:border-invblue resize-none w-full px-4 mt-1" placeholder="Anzsco Code"
                               type='text'>
                        <span v-if='form.errors().has("anzsco_code")' class="text-red-800 text-xs">
                            {{ form.errors().get('anzsco_code') }}
                        </span>
                    </div>
                    <div class="w-full">
                        <label class="flex items-center">Version</label>
                        <input v-model='form.version' class="py-2 rounded border border-gray-300 bg-gray-50 text-sm placeholder-gray-400 focus:border-invblue resize-none w-full px-4 mt-1" placeholder="Version"
                               type='date'>
                    </div>
                    <div class="w-full mb-5">
                        <label>Caveat</label>
                        <v-select
                            v-model="form.caveat_no"
                            :options="caveats"
                            :reduce="caveat => caveat.caveat_no"
                            class="pt-1"
                            label="caveat_no"
                            multiple
                            placeholder="Choose"
                        />
                    </div>
                    <div class="w-full mb-5">
                        <label>Status</label>
                        <v-select
                            v-model="form.status"
                            :options="statusList"
                            :reduce="status => status.id"
                            class="pt-1"
                            label="title"
                            placeholder="Choose"
                        />
                    </div>
                </div>
            </template>
        </Modal>

        <!-- delete modal -->
        <DeleteModal
            :isDeleteSubmitting="isDeleteSubmitting"
            :is_open="is_open_delete_modal"
            modal_title='Delete Lin Occupation'
            @close="closeDeleteModal"
            @submit="deleteLinOccupation"
        />

        <ImportModal
            :demo_link="demo_lin_occupation_link"
            :demo_link_label="'Demo lin occupation link'"
            :is_open="is_open_import_modal"
            :is_submitting="is_import_submitting"
            title="Import lin occupation"
            @close="closeImportModal"
            @submit="importLinOccupation"
        />

    </div>
</template>

<script>
import VisaApi from "@/libs/axios"
import form from 'vuejs-form'
import store from '@/store'
import Modal from '@/component/Modal'
import DeleteModal from '@/component/DeleteModal'
import Breadcrumb from '@/component/Breadcrumb'
import ImportModal from "../../component/ui/modals/ImportModal.vue";
import vSelect from "vue-select"
import "vue-select/dist/vue-select.css"
import TablePagination from '@/component/TablePagination'
import {VueGoodTable} from 'vue-good-table-next'
import 'vue-good-table-next/dist/vue-good-table-next.css'
import {DeleteIcon, UpdateIcon, ViewIcon} from "../../data/icons/index.js";
import {limitString} from "../../libs/helpers";
import {lin_sol_list} from "../../data/type";
import {meta_data, next_of_next_status, prev_of_prev_status} from '@/libs/helpers.js'
import AddIcon from "../../data/icons/AddIcon.vue";
import DownloadIcon from "../../data/icons/DownloadIcon.vue";
import TableEntryShow from "../../component/table/TableEntryShow.vue";
import TableTopActions from "../../component/table/TableTopActions.vue";

export default {
    components: {
        TableTopActions,
        TableEntryShow,
        DownloadIcon,
        ImportModal,
        ViewIcon,
        UpdateIcon,
        DeleteIcon,
        AddIcon,
        Modal,
        DeleteModal,
        Breadcrumb,
        "v-select": vSelect,
        TablePagination,
        VueGoodTable,
    },
    data() {
        return {
            mdl_title: 'Create Lin Occupation',
            modal_mode: 'create',
            uuid_deletable: '',
            demo_lin_occupation_link: process.env.VUE_APP_ASSET_FILE_URL + "/demo_lin_occupation.xlsx",
            is_open_delete_modal: false,
            is_open_import_modal: false,
            is_import_submitting: false,
            lin_list: [],
            caveats: [],
            selected_lin: '',
            limitString,
            lin_sol_list: lin_sol_list,

            meta: meta_data,
            prev_of_prev: false,
            next_of_next: false,
            per_page_array: [10, 20, 30, 40, 50],
            currentPage: '',

            search_input: '',
            breadcrumb: '',
            sort_status: false,
            isDeleteSubmitting: false,
            isModalSubmitting: false,
            statusList: [
                {id: 1, title: 'Active'},
                {id: 2, title: 'In Active'},
            ],

            form: form.default({
                id: '',
                uuid: '',
                lin_uuid: '',
                anzsco_code: '',
                lin_sol_name: '',
                lin_sol_code: '',
                caveat_no: [],
                version: '',
                status: 1,
            }).rules({
                lin_uuid: 'required',
                anzsco_code: 'required',
                lin_sol_name: 'required',
                lin_sol_code: 'required',
            }),

            columns: [
                {label: 'Lin', field: 'lin', sortable: false},
                {label: 'Occupation', field: 'occupation', sortable: false},
                {label: 'Anzsco Code', field: 'anzsco_code'},
                {label: 'Lin Sol Code', field: 'lin_sol_code'},
                {label: 'Caveat', field: 'caveat_no'},
                {label: 'Version', field: 'version'},
                {label: 'Status', field: 'status', sortable: false},
                {label: 'Action', field: 'action', sortable: false, width: '100px'}
            ],
            rows: [],
        };
    },

    methods: {
        openModal(value, mode) {
            this.form.errors().forget();
            const modalCls = document.getElementById('modal').classList;
            const modalAnimCls = document.getElementById('modal_animation').classList;
            if (value == true && mode == 'create') {
                modalCls.remove('hidden');
                modalAnimCls.add('open-modal-animation');

                this.form.id = '';
                this.form.uuid = '';
                this.form.lin_uuid = '';
                this.form.anzsco_code = '';
                this.form.lin_sol_name = '';
                this.form.lin_sol_code = '';
                this.form.version = '';
                this.form.caveat_no = [];
                this.form.status = 1;

                this.modal_mode = 'create';
                this.mdl_title = 'Create Lin Occupation';
            } else if (value == true && mode == 'update') {
                modalCls.remove('hidden');
                modalAnimCls.add('open-modal-animation');
            } else {
                modalCls.add('hidden');
                modalAnimCls.remove('open-modal-animation');
                this.isModalSubmitting = false;
            }
        },

        createLinOccupation() {
            if (this.form.validate().errors().any()) {
                return;
            } else {
                this.isModalSubmitting = true;
                VisaApi.post("/lin-occupation/create", this.form.all()).then((response) => {
                    this.getLinList();
                    this.$toast.success(response.message, {
                        position: "top-right",
                        dismissible: true,
                    });
                    this.openModal(false, 'create');
                    this.getLinOccupationList()
                }).catch((error) => {
                    this.$toast.error(error.response.data.message, {
                        position: "top-right",
                        dismissible: true,
                    });
                    this.openModal(false, 'create');
                });
            }
            ;
        },

        async getLinOccupationList() {
            store.commit('setting/LOADING_ON')
            await VisaApi.get(this.apiUrl).then((response) => {
                store.commit('setting/LOADING_OFF')
                if (response.code == '200') {
                    this.rows = response.data.data;
                    Object.assign(this.meta, response.data.meta);
                }
            });
        },

        async getSingleLinOccupation(uuid) {
            await VisaApi.get("/lin-occupation/" + uuid).then((response) => {
                if (response.code == '200') {
                    Object.assign(this.form, response.data);
                    this.form.lin_uuid = response.data?.lin?.uuid
                    this.mdl_title = 'Update Lin Occupation';
                    this.modal_mode = 'update';
                    this.openModal(true, 'update');
                }
            });
        },

        updateLinOccupation() {
            if (this.form.validate().errors().any()) {
                return;
            } else {
                this.isModalSubmitting = true;
                VisaApi.patch("/lin-occupation/update/" + this.form.uuid, this.form.all()).then((response) => {
                    if (response.code == '200') {
                        this.openModal(false, 'update');
                        let element = this.rows.filter(item => item.id == this.form.id)
                        Object.assign(element[0], response.data)
                        this.$toast.success(response.message, {
                            position: "top-right",
                            dismissible: true,
                        });
                    }
                }).catch((error) => {
                    this.$toast.error(error.response.data.message, {
                        position: "top-right",
                        dismissible: true,
                    });
                    this.openModal(false, 'update');
                });
            }
        },

        openDeleteModal(uuid) {
            this.is_open_delete_modal = true;
            this.uuid_deletable = uuid;
        },

        closeDeleteModal() {
            this.isDeleteSubmitting = false;
            this.is_open_delete_modal = false;
        },

        closeImportModal() {
            this.is_import_submitting = false
            this.is_open_import_modal = false
        },

        // import lin occupation in exel file
        importLinOccupation(file) {
            if (!file) {
                this.$toast.error("File not selected", {
                    position: "top-right",
                    dismissible: true,
                });
                return;
            }
            this.is_import_submitting = true

            //submit
            VisaApi.post("/lin-occupation/import", {file: file}, {headers: {"Content-Type": "multipart/form-data"}})
                .then((response) => {
                    if (response.code == '200') {
                        this.$toast.success(response.message, {
                            position: "top-right",
                            dismissible: true,
                        });
                        this.is_open_import_modal = false
                        this.is_import_submitting = false;
                        this.getLinOccupationList();
                    }
                    this.is_import_submitting = false;
                }).catch((error) => {
                console.log(error)
                this.$toast.error('Something went wrong', {
                    position: "top-right",
                    dismissible: true,
                });
                this.is_open_import_modal = false
                this.is_import_submitting = false;
            });
        },

        //delete lin occupation
        deleteLinOccupation() {
            this.isDeleteSubmitting = true;
            VisaApi.delete("/lin-occupation/" + this.uuid_deletable).then((response) => {
                if (response.code == '200') {
                    this.getLinOccupationList();
                    this.$toast.success(response.message, {
                        position: "top-right",
                        dismissible: true,
                    });
                }
                this.closeDeleteModal();
            }).catch((error) => {
                this.$toast.error(error.response.data.message, {
                    position: "top-right",
                    dismissible: true,
                });
                this.closeDeleteModal();
            });
        },

        handleSubmitMethod() {
            if (this.modal_mode == 'create') {
                this.createLinOccupation();
            } else {
                this.updateLinOccupation();
            }
        },

        async getLinList() {
            await VisaApi.get('/lin').then((response) => {
                if (response.code == '200') {
                    this.lin_list = response.data;
                }
            });
        },
        async getCaveatList() {
            await store.dispatch('caveat/getAllCaveat').then((res) => this.caveats = res)
        },

        updatePageValue(newValue) {
            this.currentPage = newValue;
        },

    },

    computed: {
        apiUrl() {
            return 'lin-occupation?page=' + this.meta.current_page + '&per_page=' + this.meta.per_page + '&order_by=id&order_by_type=desc&search=' + this.search_input;
        },
        nextOfNext() {
            this.next_of_next = next_of_next_status(this.meta);
        },
        prevOfPrev() {
            this.prev_of_prev = prev_of_prev_status(this.meta);
        },
    },

    watch: {
        apiUrl() {
            this.getLinOccupationList();
        },
        'form.lin_sol_code': function (val) {
            let lin = lin_sol_list.find((lin) => lin.code == val)
            this.form.lin_sol_name = lin?.name ?? ''
        },
        nextOfNext() {
        },
        prevOfPrev() {
        },
    },

    async mounted() {
        await this.getLinOccupationList();
        await this.getLinList();
        await this.getCaveatList();
        this.breadcrumb = this.$router.currentRoute.value.meta.breadcrumb;
    }
}
</script>
