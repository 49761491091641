<template>
    <router-link
        v-slot="{ isActive, href, navigate }"
        :to="props.to"
    >
        <a
            :class="isActive || props.prefixes.includes(route.name) ? 'bg-gray-700' : 'hover:bg-gray-700'"
            :href="href"
            class="flex items-center py-3 px-4 cursor-pointer rounded"
            @click="[navigate, $emit('redirect')]"
        >
            <img :src="sidebarIcon()" alt="image" class="w-5 h-5">
            <p class="pl-2">{{props.name}}</p>
        </a>
    </router-link>
</template>

<script setup>
import {useRoute} from "vue-router";

const props = defineProps({
    to: String,
    name: String,
    icon: String,
    prefixes: String
})
const route = useRoute()

const sidebarIcon = () => {
    return require(`@/assets/images/${props.icon}.svg`)
}

</script>

<style scoped>

</style>
