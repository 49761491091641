<template>
    <div class="px-4 py-2 absolute w-full">
        <div class="flex flex-wrap items-center justify-between">
            <Breadcrumb :text="breadcrumb"/>
            <router-link to="/visa-type-list">
                <button class="back-btn" type="button">
                    <back-icon class="mt-1 mr-2"/>
                    Back to list
                </button>
            </router-link>
        </div>

        <div class="visasis-card">
            <p class="w-max px-4 py-1 mb-2 font-bold rounded uppercase bg-gray-300 border-gray-400">
                Visa Type: {{ data.name }}
            </p>
            <div class="grid grid-cols-1 gap-2 p-4 w-full rounded bg-white">
                <div class="w-full flex items-center">
                    <label class="font-bold">Slug:</label>
                    <p class="pl-2">{{ data.slug }}</p>
                </div>
                <div class="w-full flex items-center">
                    <label class="font-bold">Group:</label>
                    <p v-if="data.group!=null" class="pl-2">{{ getTypeGroup(data.group) }}</p>
                </div>
                <div class="w-full flex items-center">
                    <label class="font-bold">Status:</label>
                    <div class="ml-2" v-html="getStatus(data.status)"></div>
                </div>
                <div class="w-full flex items-center">
                    <label class="font-bold">Description:</label>
                    <p class="pl-2">{{ data.description }}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import VisaApi from "@/libs/axios"
import Breadcrumb from '@/component/Breadcrumb'
import BackIcon from "../../../data/icons/BackIcon.vue";
import {getStatus} from "../../../data/common";
import {getTypeGroup} from "../../../data/type";

export default {
    components: {BackIcon, Breadcrumb,},
    data() {
        return {
            breadcrumb: '',
            data: {},
        };
    },

    methods: {
        getTypeGroup,
        getStatus,
        getSingleData(uuid) {
            VisaApi.get("/visa-type/" + uuid).then((response) => {
                if (response.code == '200') {
                    this.data = response.data;
                }
            });
        },
    },

    mounted() {
        this.breadcrumb = this.$router.currentRoute.value.meta.breadcrumb;
        let id = this.$route.params.id;
        this.getSingleData(id);
    }
}
</script>
