import {ref} from "vue";

export default function useModal(formData, createItem, updateItem) {
    const modal_title = ref()
    const modal_mode = ref('create')
    const isModalSubmitting = ref(false)

    const openModal = (value, mode) => {
        const modalCls = document.getElementById('modal').classList;
        const modalAnimCls = document.getElementById('modal_animation').classList;
        if (value == true && mode == 'create') {
            Object.keys(formData).forEach(key => delete formData[key]);
            modalCls.remove('hidden');
            modalAnimCls.add('open-modal-animation');
            modal_mode.value = 'create';
        } else if (value == true && mode == 'update') {
            modalCls.remove('hidden');
            modalAnimCls.add('open-modal-animation');
            modal_mode.value = 'update';
        } else {
            modalCls.add('hidden');
            modalAnimCls.remove('open-modal-animation');
            isModalSubmitting.value = false;
        }
    }

    const handleSubmitMethod = () => {
        const modalCls = document.getElementById('modal').classList;
        if (modal_mode.value == 'create') {
            createItem()
            modalCls.add('hidden');
        } else {
            updateItem()
            modalCls.add('hidden');
        }
    }

    return {
        modal_title,
        modal_mode,
        isModalSubmitting,
        openModal,
        handleSubmitMethod
    }
}
