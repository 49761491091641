<template>
    <div class="px-4 py-2 absolute w-full">

        <div class="flex flex-wrap items-center justify-between pb-2 border-b border-gray-300">
            <Breadcrumb :text="breadcrumb"/>
            <router-link to="/occupation-list">
                <button class="rounded-md bg-orange-600 px-6 py-1.5 text-white hover:bg-orange-500" type="button">
                    Back to list
                </button>
            </router-link>
        </div>

        <div class="p-2 mt-2 h-full rounded bg-slate-100">
            <p class="font-bold mt-2">Attach anzsco group with a occupation.</p>

            <div class="grid grid-cols-1 md:grid-cols-2 gap-x-4 gap-y-2 mt-4">
                <div class="w-full">
                    <label>Occupation Name:</label>
                    <p>{{ occupation_name }}</p>
                </div>
                <div class="w-full">
                    <label>Select Anzsco</label>
                    <v-select
                        v-model="anzsco_group_selected"
                        :options="anzsco_list"
                        class="pt-1"
                        label="name"
                        multiple
                        placeholder="Choose"
                    />
                </div>
            </div>

            <div class="text-center mt-8">
                <button v-if="isSubmitting==false" class="mt-4 rounded-md bg-blue-600 px-6 py-1.5 text-white hover:bg-blue-500" type="button"
                        @click="attachAnzsco">
                    Attach Anzsco
                </button>
                <span v-else>
                    <LoadingButton text="Attaching"/>
                </span>
            </div>

            <div class="overflow-x-auto mt-8 mb-4">
                <p class="font-bold text-sm">This occupation already attached with the following anzsco: </p>
                <table class="w-full border-collapse border border-gray-400">
                    <thead>
                    <tr class="text-left">
                        <th class="border border-gray-300 p-2">Name</th>
                        <th class="border border-gray-300 p-2">Code</th>
                        <th class="border border-gray-300 p-2">Type</th>
                        <th class="border border-gray-300 p-2 whitespace-nowrap">Skill level</th>
                        <th class="border border-gray-300 p-2 text-center">Detach</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="data in alreadyAttachedList" :key="data.id">
                        <td class="border border-gray-300 p-2">{{ data.name }}</td>
                        <td class="border border-gray-300 p-2">{{ data.code }}</td>
                        <td class="border border-gray-300 p-2">
                            <p v-show="data.type==1">Major</p>
                            <p v-show="data.type==2">Sub Major</p>
                            <p v-show="data.type==3">Minor</p>
                            <p v-show="data.type==4">Unit</p>
                        </td>
                        <td class="border border-gray-300 p-2">{{ data.skill_level }}</td>
                        <td class="border border-gray-300 p-2">
                            <div>
                                <img alt="img" class="w-5 cursor-pointer mx-auto"
                                     src="@/assets/images/cross_red.svg" @click="openDeleteModal(data.uuid)">
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>

        </div>

        <!-- delete modal -->
        <DeleteModal modal_title='Detach anzsco' @close="closeDeleteModal" @submit="detachAnzsco"></DeleteModal>

    </div>
</template>

<script>
import VisaApi from "@/libs/axios"
import form from 'vuejs-form'
import store from '@/store'
import Breadcrumb from '@/component/Breadcrumb'
import vSelect from "vue-select"
import "vue-select/dist/vue-select.css"
import DeleteModal from '@/component/DeleteModal'
import LoadingButton from "@/component/LoadingButton"

export default {
    components: {Breadcrumb, "v-select": vSelect, DeleteModal, LoadingButton},
    data() {
        return {
            breadcrumb: '',
            occupation_name: '',
            alreadyAttachedList: [],
            anzsco_group_selected: [],
            anzsco_list: [],
            isSubmitting: false,

            form: form.default({
                occupation_uuid: '',
                anzsco_group_uuids: [],
            }).rules({
                occupation_uuid: 'required',
            }),

        };
    },

    methods: {
        async getAnzscoList() {
            store.commit('setting/LOADING_ON')
            await VisaApi.get("/anzsco-group").then((response) => {
                store.commit('setting/LOADING_OFF')
                if (response.code == '200') {
                    this.anzsco_list = response.data;
                }
            });
        },

        attachAnzsco() {
            if (this.form.validate().errors().any()) {
                return;
            } else {
                this.isSubmitting = true;
                VisaApi.post("/anzsco-group/attach", this.form.all()).then((response) => {
                    if (response.code == '200') {
                        this.$toast.success(response.message, {
                            position: "top-right",
                            dismissible: true,
                        });
                        this.form.anzsco_group_uuids = [];
                        this.anzsco_group_selected = [];
                        this.getSingleData(this.form.occupation_uuid);
                    }
                    this.isSubmitting = false;
                }).catch((error) => {
                    this.$toast.error(error.response.data.message, {
                        position: "top-right",
                        dismissible: true,
                    });
                    this.isSubmitting = false;
                });
            }
        },

        openDeleteModal(uuid) {
            const modalCls = document.getElementById('delete_modal').classList;
            const modalAnimCls = document.getElementById('delete_modal_animation').classList;
            this.form.anzsco_group_uuids.push(uuid);
            modalCls.remove('hidden');
            modalAnimCls.add('delete-modal-animation');
        },

        closeDeleteModal() {
            const modalCls = document.getElementById('delete_modal').classList;
            const modalAnimCls = document.getElementById('delete_modal_animation').classList;
            modalCls.add('hidden');
            modalAnimCls.remove('delete-modal-animation');
            this.form.anzsco_group_uuids = [];
        },

        detachAnzsco() {
            if (this.form.validate().errors().any()) {
                return;
            } else {
                VisaApi.post("/anzsco-group/detach", this.form.all()).then((response) => {
                    this.$toast.success(response.message, {
                        position: "top-right",
                        dismissible: true,
                    });
                    this.closeDeleteModal();
                    this.getSingleData(this.form.occupation_uuid);
                }).catch((error) => {
                    this.$toast.error(error.response.data.message, {
                        position: "top-right",
                        dismissible: true,
                    });
                    this.closeDeleteModal();
                });
            }
        },

        getSingleData(uuid) {
            VisaApi.get("/occupation/" + uuid).then((response) => {
                if (response.code == '200') {
                    this.alreadyAttachedList = response.data.anzsco_groups;
                    this.occupation_name = response.data.name;
                }
            });
        },
    },

    watch: {
        anzsco_group_selected(val) {
            this.form.anzsco_group_uuids = [];
            for (let i = 0; i < val.length; i++) {
                this.form.anzsco_group_uuids.push(val[i].uuid);
            }
        }
    },

    mounted() {
        this.form.occupation_uuid = this.$route.params.id;
        this.getSingleData(this.$route.params.id);
        this.getAnzscoList();
        this.breadcrumb = this.$router.currentRoute.value.meta.breadcrumb;
    }
}
</script>
