<template>
    <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
        <table class="w-full text-left text-gray-500">
            <thead class="text-gray-600 bg-gray-100 text-md border-0">
            <tr class="border-0">
                <slot name='table-header'></slot>
            </tr>
            </thead>
            <tbody>
            <slot name="table-body"></slot>
            </tbody>
            <tfoot>
            <slot name="table-footer"></slot>
            </tfoot>
        </table>
    </div>
</template>

<script>
export default {
    name: 'Table',
};
</script>