<template>
    <div class="px-4 py-2 absolute w-full">
        <div class="flex flex-wrap items-center justify-between">
            <Breadcrumb :text="breadcrumb"/>
            <router-link to="/visa-requirement-list">
                <button class="back-btn" type="button">
                    <back-icon class="mr-2 mt-1"/>
                    Back to list
                </button>
            </router-link>
        </div>

        <div class="visasis-card">
            <div class="grid grid-cols-1 md:grid-cols-2 gap-x-4 gap-y-2">
                <div class="w-full">
                    <label class="flex items-center">Select Visa <img alt="required"
                                                                      class="w-2 h-2 ml-1" src="@/assets/images/star.svg">
                    </label>
                    <v-select
                        v-model="form.visa_id"
                        :get-option-label="(option) => option.visa_subclass + ' - ' + option.name"
                        :options="visa_list"
                        :reduce="visa => visa.id"
                        class="pt-1"
                        placeholder="Choose visa..."
                    />
                    <span v-if='form.errors().has("visa_id")' class="text-red-800 text-xs">
                        {{ form.errors().get('visa_id') }}
                    </span>
                </div>
                <div v-if="form.visa_id" class="w-full">
                    <label class="flex items-center">Select Visa Stream <img alt="required"
                                                                             class="w-2 h-2 ml-1"
                                                                             src="@/assets/images/star.svg"> </label>
                    <v-select
                        v-model="form.visa_stream_uuid"
                        :options="visa_stream_list"
                        :reduce="stream => stream.uuid"
                        class="pt-1"
                        label="name"
                        placeholder="Choose visa stream..."
                    />
                </div>
                <div class="w-full">
                    <label>Status</label>
                    <select v-model='form.status'
                            class="py-2 rounded border border-gray-300 bg-gray-50 text-sm placeholder-gray-400 focus:border-invblue resize-none w-full px-4 mt-1">
                        <option :selected="form.status=='1'" value="1">Active</option>
                        <option :selected="form.status=='0'" value="0">Inactive</option>
                    </select>
                </div>
            </div>

            <div class="grid grid-cols-1 md:grid-cols-2 gap-x-4 gap-y-2 mt-2">
                <div class="w-full">
                    <!-- age limit -->
                    <label class="flex items-center mt-2">
                        <toggle-switch :is_active="form.age_limit" class="mr-3"
                                       @toggle="changeRequirements('age_limit')"/>
                        Age limit <img alt="required" class="w-2 h-2 ml-1" src="@/assets/images/star.svg">
                    </label>

                    <!-- English requirement -->
                    <label class="flex items-center mt-2">
                        <toggle-switch :is_active="form.english_requirement"
                                       class="mr-3" @toggle="changeRequirements('english_requirement')"/>
                        English Requirement <img alt="required" class="w-2 h-2 ml-1" src="@/assets/images/star.svg">
                    </label>

                    <!-- EOI -->
                    <label class="flex items-center mt-2">
                        <toggle-switch :is_active="form.eoi" class="mr-3" @toggle="changeRequirements('eoi')"/>
                        EOI <img alt="required" class="w-2 h-2 ml-1" src="@/assets/images/star.svg">
                    </label>

                    <!-- Market test -->
                    <label class="flex items-center mt-2">
                        <toggle-switch :is_active="form.market_test" class="mr-3"
                                       @toggle="changeRequirements('market_test')"/>
                        Market test <img alt="required" class="w-2 h-2 ml-1" src="@/assets/images/star.svg">
                    </label>

                    <!-- Point required -->
                    <label class="flex items-center mt-2">
                        <toggle-switch :is_active="form.point_required" class="mr-3"
                                       @toggle="changeRequirements('point_required')"/>
                        Point required <img alt="required" class="w-2 h-2 ml-1" src="@/assets/images/star.svg">
                    </label>

                    <!-- Experience  -->
                    <label class="flex items-center mt-2">
                        <toggle-switch :is_active="form.experience" class="mr-3"
                                       @toggle="changeRequirements('experience')"/>
                        Experience <img alt="required" class="w-2 h-2 ml-1" src="@/assets/images/star.svg">
                    </label>
                </div>

                <div class="w-full">
                    <div v-if="form.age_limit" class="w-full">
                        <label class="flex items-center">Age <img alt="required" class="w-2 h-2 ml-1"
                                                                  src="@/assets/images/star.svg"> </label>
                        <input v-model='form.age' class="py-2 rounded border border-gray-300 bg-gray-50 text-sm placeholder-gray-400 focus:border-invblue resize-none w-full px-4 mt-1" placeholder="Age"
                               type='text'>
                    </div>
                    <div v-if="form.english_requirement" class="w-full">
                        <label class="flex items-center">IELTS<img alt="required" class="w-2 h-2 ml-1"
                                                                   src="@/assets/images/star.svg"> </label>
                        <input v-model='form.ielts' class="py-2 rounded border border-gray-300 bg-gray-50 text-sm placeholder-gray-400 focus:border-invblue resize-none w-full px-4 mt-1" placeholder="IELTS"
                               type='text'>
                    </div>
                    <div v-if="form.point_required" class="w-full">
                        <label class="flex items-center">Minimum point <img alt="required"
                                                                            class="w-2 h-2 ml-1"
                                                                            src="@/assets/images/star.svg"> </label>
                        <input v-model='form.min_point' class="py-2 rounded border border-gray-300 bg-gray-50 text-sm placeholder-gray-400 focus:border-invblue resize-none w-full px-4 mt-1" placeholder="Minimum point"
                               type='text'>
                    </div>
                    <div v-if="form.experience" class="w-full">
                        <label class="flex items-center">Minimum experience <img alt="required"
                                                                                 class="w-2 h-2 ml-1"
                                                                                 src="@/assets/images/star.svg"> </label>
                        <input v-model='form.min_experience' class="py-2 rounded border border-gray-300 bg-gray-50 text-sm placeholder-gray-400 focus:border-invblue resize-none w-full px-4 mt-1" placeholder="Minimum experience"
                               type='text'>
                    </div>
                </div>
            </div>

            <button v-if="isSubmitting==false" class="mt-4 justify-center rounded-md bg-blue-600 px-6 py-1.5 text-white hover:bg-blue-500" type="button"
                    @click="create">
                Submit
            </button>
            <span v-else class="mt-4 block">
                <LoadingButton text="Submitting"/>
            </span>

        </div>
    </div>
</template>

<script>
import VisaApi from "@/libs/axios"
import form from 'vuejs-form'
import Breadcrumb from '@/component/Breadcrumb'
import vSelect from "vue-select"
import "vue-select/dist/vue-select.css"
import LoadingButton from "@/component/LoadingButton"
import {BackIcon} from "../../../data/icons";
import ToggleSwitch from "../../../component/ui/ToggleSwitch.vue";

export default {
    components: {LoadingButton, Breadcrumb, "v-select": vSelect, BackIcon, ToggleSwitch},
    data() {
        return {
            breadcrumb: '',
            visa_list: [],
            selected_visa: '',
            visa_stream_list: [],
            all_visa_stream_list: [],
            selected_visa_stream: '',
            isSubmitting: false,

            form: form.default({
                visa_id: '',
                visa_stream_uuid: '',
                age_limit: 0,
                age: '',
                english_requirement: 0,
                ielts: '',
                eoi: 0,
                market_test: 0,
                point_required: 0,
                min_point: '',
                experience: 0,
                min_experience: '',
                status: '1',
            }).rules({
                visa_id: 'required',
                age_limit: 'required',
                english_requirement: 'required',
                eoi: 'required',
                market_test: 'required',
                point_required: 'required',
                experience: 'required',
            }).messages({
                "visa_id.required": "Visa is required",
                "age_limit.required": "Age limit field is required",
                "english_requirement.required": "English requirements field is required",
                "eoi.required": "EOI field is required",
                "market_test.required": "Market test field is required",
                "point_required.required": "Points required field is required",
                "experience.required": "Experience field is required",
            }),
        };
    },

    watch: {
        'form.visa_id': function (val) {
            this.visa_stream_list = this.all_visa_stream_list.filter((s) => {
                return s.visa_id == val
            })
        },
    },

    methods: {
        create() {
            if (this.form.validate().errors().any()) {
                return;
            } else {
                this.isSubmitting = true;
                VisaApi.post("/visa-requirement/create", this.form.all()).then((response) => {
                    this.$toast.success(response.message, {
                        position: "top-right",
                        dismissible: true,
                    });
                    this.$router.push({name: 'visa-requirement-list'})
                    this.isSubmitting = false;
                }).catch((error) => {
                    this.$toast.error(error.response.data.message, {
                        position: "top-right",
                        dismissible: true,
                    });
                    this.isSubmitting = false;
                });
            }
            ;
        },

        async getVisaList() {
            await VisaApi.get('/visa').then((response) => {
                if (response.code == '200') {
                    this.visa_list = response.data;
                }
            });
        },

        async getVisaStreamList() {
            await VisaApi.get('/visa-stream').then((response) => {
                if (response.code == '200') {
                    this.visa_stream_list = response.data;
                    this.all_visa_stream_list = response.data;
                }
            });
        },

        changeRequirements(field) {
            switch (field) {
                case 'age_limit' :
                    this.form.age_limit = this.form.age_limit === 0 ? 1 : 0;
                    break;
                case 'english_requirement' :
                    this.form.english_requirement = this.form.english_requirement === 0 ? 1 : 0;
                    break;
                case 'eoi' :
                    this.form.eoi = this.form.eoi === 0 ? 1 : 0;
                    break;
                case 'market_test' :
                    this.form.market_test = this.form.market_test === 0 ? 1 : 0;
                    break;
                case 'point_required' :
                    this.form.point_required = this.form.point_required === 0 ? 1 : 0;
                    break;
                case 'experience' :
                    this.form.experience = this.form.experience === 0 ? 1 : 0;
                    break;
            }
        }
    },

    mounted() {
        this.breadcrumb = this.$router.currentRoute.value.meta.breadcrumb;
        this.getVisaList();
        this.getVisaStreamList();
    }
}
</script>
