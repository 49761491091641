<template>
    <form @submit.prevent="updateSettings">
        <div class="grid grid-cols-1 lg:grid-cols-2 gap-x-4 gap-y-2">
            <TextInput v-if="hasSlug('facebook')" :label="'Facebook'" :model="social.facebook" field="facebook"
                       @input-value="getValue"/>
            <TextInput v-if="hasSlug('linkedin')" :label="'Linkedin'" :model="social.linkedin" field="linkedin"
                       @input-value="getValue"/>
            <TextInput v-if="hasSlug('whatsapp')" :label="'Whatsapp'" :model="social.whatsapp" field="whatsapp"
                       @input-value="getValue"/>
        </div>

        <button class="justify-center mt-4 py-1.5 rounded-md bg-blue-600 px-6 text-white hover:bg-blue-500" type="button"
                @click="updateSettings">
            Submit
        </button>

    </form>
</template>

<script>

import {TextInput} from "../ui/forms";
import {computed, onMounted, reactive} from "vue";
import store from "../../store";

export default {
    components: {
        TextInput
    },

    setup(props, {emit}) {
        const social = reactive({});
        const settings = computed(() => store.getters["setting/settings"]);
        const getSettings = async () => {
            Object.assign(social, {
                facebook: settings.value.facebook,
                linkedin: settings.value.linkedin,
                whatsapp: settings.value.whatsapp,
            })
        }
        onMounted(async () => {
            await getSettings()
        })

        const hasSlug = (slug) => {
            return social.hasOwnProperty(slug)
        }

        const getValue = (payload) => {
            switch (payload.field) {
                case 'facebook':
                    social.facebook = payload.value;
                    break;
                case 'linkedin':
                    social.linkedin = payload.value;
                    break;
                case 'whatsapp':
                    social.whatsapp = payload.value;
                    break;
            }
        }

        const updateSettings = async () => {
            await store.dispatch('setting/updateSettings', social)
            emit('updated')
            setTimeout(() => {
                window.location.reload()
            }, 50)
        }

        return {
            hasSlug,
            social,
            getValue,
            updateSettings
        }
    }
}


</script>

<style scoped>

</style>
