<template>
    <div class="px-4 py-2 absolute w-full">

        <Breadcrumb :text="breadcrumb"/>

        <div class="visasis-card">
            <div class="flex flex-wrap items-center -mx-4">
                <TableEntryShow @input="[meta.current_page = 1, meta.per_page = $event]"/>
                <div class="w-full lg:w-1/2 px-4 py-2">
                    <div class="flex justify-start lg:justify-end flex-wrap gap-1">
                        <input v-model="search_input"
                               class="pl-4 h-10 rounded border focus:outline-none border-gray-300" placeholder="Search"
                               type="text"/>
                        <button class="px-6 h-10 flex justify-center items-center gap-2 py-2 text-center tracking-wider rounded bg-violet-700 hover:bg-violet-500 text-white"
                                @click="is_open_oc_auth_import_modal = true">
                            <download-icon/>
                            <span class="hidden sm:block">Import Occ. Authority</span>
                        </button>
                    </div>
                </div>
            </div>

            <vue-good-table :columns="columns" :rows="rows">
                <template #table-row="props">
                    <div v-if="props.column.field == 'detach'">
                        <div class="px-3 py-2 flex items-center justify-center">
                            <delete-icon @click="[detachable_data = props.row, is_open_detach_modal = true]"/>
                        </div>
                    </div>
                </template>
            </vue-good-table>

            <TablePagination
                :inputPage="meta.current_page"
                :meta='meta'
                :next_of_next='next_of_next_status(meta)'
                :prev_of_prev='prev_of_prev_status(meta)'
                @update:inputPage="meta.current_page = $event"
            />
        </div>

        <!-- delete modal -->
        <DeleteModal
            :isDeleteSubmitting="isDetachSubmitting"
            :is_open="is_open_detach_modal"
            :modal_title='mdl_title'
            @close="[isDetachSubmitting = false, is_open_detach_modal = false]"
            @submit="detachOccupationAssessing"
        />


        <!-- Import -->
        <ImportModal
            :demo_link="demo_occupation_auth_link"
            :is_open="is_open_oc_auth_import_modal"
            :is_submitting="isAuthSubmitting"
            demo_link_label="Download Demo Occupation Assessing Authority File"
            title="Import Occupation Assessing Authority"
            @close="closeOcAuthImportModal"
            @submit="importOcAuthExcelFile"
        />
    </div>
</template>

<script setup>
import VisaApi from "@/libs/axios"
import store from '@/store'
import DeleteModal from '@/component/DeleteModal'
import Breadcrumb from '@/component/Breadcrumb'
import TablePagination from '@/component/TablePagination'
import {VueGoodTable} from 'vue-good-table-next'
import 'vue-good-table-next/dist/vue-good-table-next.css'
import {
    meta_data,
    next_of_next_status,
    prev_of_prev_status,
    toaster
} from '@/libs/helpers.js'
import DeleteIcon from "../../data/icons/DeleteIcon.vue";
import TableEntryShow from "../../component/table/TableEntryShow.vue";
import {computed, onMounted, ref, watch} from "vue";
import {useRouter} from "vue-router";
import DownloadIcon from "../../data/icons/DownloadIcon.vue";
import AddIcon from "../../data/icons/AddIcon.vue";
import ImportModal from "../../component/ui/modals/ImportModal.vue";

//variables
const breadcrumb = ref()
const router = useRouter()
const meta = ref(meta_data)
const mdl_title = ref("Detach")
const is_open_detach_modal = ref(false)
const isDetachSubmitting = ref(false)
const is_open_oc_auth_import_modal = ref(false)
const isAuthSubmitting = ref(false)
const demo_occupation_auth_link = process.env.VUE_APP_ASSET_FILE_URL + "/demo_occupation_authority.xlsx"
const detachable_data = ref(null)
const search_input = ref('')
const columns = ref([
    {label: 'Occupation', field: 'occupation'},
    {label: 'Anzsco Code', field: 'anzsco_code'},
    {label: 'Assessing Authority', field: 'authority'},
    {label: 'Short Code', field: 'short_code'},
    {label: 'Detach', field: 'detach'},
])
const rows = ref([])

//computed
const url = computed(() => 'occupation/with/authority?page=' + meta.value.current_page + '&per_page=' + meta.value.per_page + '&order_by=id&order_by_type=desc&search=' + search_input.value)

//methods

//detach occupation with assessing authority
const detachOccupationAssessing = async () => {
    store.commit('setting/LOADING_ON')
    await VisaApi.get(`occupation/authority/detach/${detachable_data.value.occupation_uuid}/${detachable_data.value.authority_id}`).then(() => {
        store.commit('setting/LOADING_OFF')
        is_open_detach_modal.value = false
        getList()
    }).catch((error) => {
        store.commit('setting/LOADING_OFF')
        toaster.error(error.response.data.message)
    });
}

//get assessing authority occupation list
const getList = async () => {
    store.commit('setting/LOADING_ON')
    await VisaApi.get(url.value).then((response) => {
        store.commit('setting/LOADING_OFF')
        if (response.code == '200') {
            Object.assign(meta.value, response.data.meta);
            rows.value = response.data.data;
        }
    }).catch((error) => {
        store.commit('setting/LOADING_OFF')
        toaster.error(error.response.data.message)
    });
}

const closeOcAuthImportModal = () => {
    isAuthSubmitting.value = false
    is_open_oc_auth_import_modal.value = false
}

const importOcAuthExcelFile = (file) => {
    if (file == '') {
        toaster.error('No file selected!', {
            position: "top-right",
            dismissible: true,
        });
        return;
    } else {
        VisaApi.post("/occupation/authority/import", {file: file}, {headers: {"Content-Type": "multipart/form-data"}})
            .then((response) => {
                if (response.code == '200') {
                    toaster.success(response.message, {
                        position: "top-right",
                        dismissible: true,
                    });
                    closeOcAuthImportModal()
                    getList();
                }
                closeOcAuthImportModal()
            }).catch((error) => {
            toaster.error(error.response.data.message, {
                position: "top-right",
                dismissible: true,
            });
            closeOcAuthImportModal()
        });
    }
}

//watch
watch(() => url.value, () => {
    getList()
})

//mounted
onMounted(async () => {
    breadcrumb.value = await router.currentRoute.value.meta.breadcrumb
    await getList();
})

</script>

<style scoped>

</style>
