<template>
    <div class="flex items-center relative mr-2 md:mr-4">
        <img alt="notification" class="outside-click w-8 cursor-pointer" src="@/assets/images/notification.svg"
             @click="$emit('open')">
        <p class="absolute -top-2 right-0.5 text-white">4</p>
        <div v-show="props.is_open"
             class="outside-click mt-2 shadow absolute top-10 right-0 py-4 min-w-max z-10 rounded bg-slate-700 text-white">
            <p class="outside-click hover:bg-gray-600 m-0 py-1 px-2">4 notification available</p>
        </div>
    </div>
</template>

<script setup>
const props = defineProps({
    is_open: Boolean
})

</script>

<style scoped>

</style>
