<template>
    <div class="px-4 py-2 absolute w-full">
        <div class="flex flex-wrap items-center justify-between pb-2">
            <Breadcrumb :text="breadcrumb"/>
            <router-link to="/occupation-list">
                <button class="back-btn" type="button">
                    <back-icon class="mt-1 mr-2"/>
                    Back to list
                </button>
            </router-link>
        </div>

        <div class="visasis-card">
            <div class="grid grid-cols-1 md:grid-cols-2 gap-x-4 gap-y-2">
                <div class="w-full">
                    <label>Select Assessing Auth</label>
                    <v-select
                        v-model="form.assessing_authority_uuid"
                        :options="assessing_auth_list"
                        :reduce="accessing_authority => accessing_authority.uuid"
                        class="pt-1"
                        label="name"
                        multiple
                        placeholder="Choose..."
                    />
                </div>
                <div class="w-full">
                    <label class="flex items-center">Occupation Name <img alt="required"
                                                                          class="w-2 h-2 ml-1" src="@/assets/images/star.svg">
                    </label>
                    <input v-model='form.name'
                           class="py-2 rounded border border-gray-300 bg-gray-50 text-sm placeholder-gray-400 focus:border-invblue resize-none w-full px-4 mt-1"
                           placeholder="Name" type='text'>
                    <span v-if='form.errors().has("name")' class="text-red-800 text-xs">
                        {{ form.errors().get('name') }}
                    </span>
                </div>
                <div class="w-full">
                    <label class="flex items-center">Slug <img alt="required" class="w-2 h-2 ml-1"
                                                               src="@/assets/images/star.svg"> </label>
                    <input v-model='form.slug'
                           class="py-2 rounded border border-gray-300 bg-gray-50 text-sm placeholder-gray-400 focus:border-invblue resize-none w-full px-4 mt-1"
                           placeholder="Slug" type='text'>
                    <span v-if='form.errors().has("slug")' class="text-red-800 text-xs">
                        {{ form.errors().get('slug') }}
                    </span>
                </div>
                <div class="w-full">
                    <label class="flex items-center">Anzsco Code <img alt="required"
                                                                      class="w-2 h-2 ml-1" src="@/assets/images/star.svg">
                    </label>
                    <input v-model='form.anzsco_code'
                           class="py-2 rounded border border-gray-300 bg-gray-50 text-sm placeholder-gray-400 focus:border-invblue resize-none w-full px-4 mt-1"
                           placeholder="Anzsco Code" type='text'>
                    <span v-if='form.errors().has("anzsco_code")' class="text-red-800 text-xs">
                        {{ form.errors().get('anzsco_code') }}
                    </span>
                </div>
                <div class="w-full">
                    <label class="flex items-center">Abs Version <img alt="required"
                                                                      class="w-2 h-2 ml-1" src="@/assets/images/star.svg">
                    </label>
                    <input v-model='form.abs_version'
                           class="py-2 rounded border border-gray-300 bg-gray-50 text-sm placeholder-gray-400 focus:border-invblue resize-none w-full px-4 mt-1"
                           placeholder="Abs Version" type='text'>
                    <span v-if='form.errors().has("abs_version")' class="text-red-800 text-xs">
                        {{ form.errors().get('abs_version') }}
                    </span>
                </div>
                <div class="w-full">
                    <label class="flex items-center">Skill Level <img alt="required"
                                                                      class="w-2 h-2 ml-1" src="@/assets/images/star.svg">
                    </label>
                    <input v-model='form.skill_level'
                           class="py-2 rounded border border-gray-300 bg-gray-50 text-sm placeholder-gray-400 focus:border-invblue resize-none w-full px-4 mt-1"
                           placeholder="Skill Level" type='text'>
                    <span v-if='form.errors().has("skill_level")' class="text-red-800 text-xs">
                        {{ form.errors().get('skill_level') }}
                    </span>
                </div>
                <div class="w-full">
                    <label>Status</label>
                    <select v-model='form.status'
                            class="py-2 rounded border border-gray-300 bg-gray-50 text-sm placeholder-gray-400 focus:border-invblue resize-none w-full px-4 mt-1">
                        <option :selected="form.status==1" value="1">Active</option>
                        <option :selected="form.status==0" value="0">Inactive</option>
                    </select>
                </div>
                <div class="w-full">
                    <label>Is Popular?</label>
                    <select v-model='form.is_popular'
                            class="py-2 rounded border border-gray-300 bg-gray-50 text-sm placeholder-gray-400 focus:border-invblue resize-none w-full px-4 mt-1">
                        <option :selected="form.is_popular==1" value="1">Yes</option>
                        <option :selected="form.is_popular==0" value="0">No</option>
                    </select>
                </div>
                <FileInput v-if="form.photo" :max="1" :model="form.photo" field="photo" label="Photo"
                           @file-uploaded="getPhotoValue"/>
                <div class="w-full">
                    <label>Description</label>
                    <article class="prose" style="max-width: 100%">
                        <ckeditor v-model="form.description" :config="editorConfig" :editor="editor"/>
                    </article>
                </div>
            </div>

            <button v-if="isSubmitting==false" class="mt-4 justify-center rounded-md px-6 py-1.5 text-white bg-blue-600 hover:bg-blue-700" type="button"
                    @click="updateOccupation">
                Submit
            </button>
            <span v-else class="mt-4 block">
                <LoadingButton text="Submitting"/>
            </span>
        </div>

        <!-- Assessing Authority -->
        <div class="p-5 mt-2 bg-white rounded shadow-md">
            <div class="flex justify-between items-center">
                <p class="font-bold">Assessing Authority</p>
                <button class="m-2 px-4 py-1 rounded text-white bg-green-600 hover:bg-green-700"
                        @click="openModal(true, 'assessing-auth', 'attach-assessing', '')">Attach New
                </button>
            </div>
            <table class="w-full border-collapse border border-gray-200">
                <thead>
                <tr class="text-left">
                    <th class="border border-gray-200 bg-gray-50 p-2">Name</th>
                    <th class="border border-gray-200 bg-gray-50 p-2">Link</th>
                    <th class="border border-gray-200 bg-gray-50 p-2 whitespace-nowrap">Short code</th>
                    <th class="border border-gray-200 bg-gray-50 p-2">Status</th>
                    <th class="border border-gray-200 bg-gray-50 p-2 text-center">Detach</th>
                </tr>
                </thead>
                <tbody v-if="assessingAuthAttached.length>0">
                <tr v-for="data in assessingAuthAttached" :key="data.id">
                    <td class="border border-gray-200 p-2">{{ data.name }}</td>
                    <td class="border border-gray-200 p-2">{{ data.link }}</td>
                    <td class="border border-gray-200 p-2">{{ data.short_code }}</td>
                    <td class="border border-gray-200 p-2">
                        <p v-if="data.status==1" class="text-green-500">Active</p>
                        <p v-else-if="data.status==0" class="text-red-500">Inactive</p>
                    </td>
                    <td class="border border-gray-200 p-2">
                        <div>
                            <img alt="img"
                                 class="w-5 cursor-pointer mx-auto" src="@/assets/images/cross_red.svg" @click="openDeleteModal(data.uuid, 'detach-assessing')">
                        </div>
                    </td>
                </tr>
                </tbody>
                <tbody v-else>
                <tr>
                    <td class="border border-gray-200 p-2 text-red-500 text-center" colspan="5">
                        Data is empty
                    </td>
                </tr>
                </tbody>
            </table>
        </div>

        <!-- Occupation alternative -->
        <div class="p-5 mt-6 rounded shadow-md bg-white">
            <div class="flex justify-between items-center">
                <p class="font-bold">Occupation alternative</p>
                <button class="m-2 px-4 py-1 rounded text-white bg-green-600 hover:bg-green-700"
                        @click="openModal(true, 'alternative', 'create', '')">Add New
                </button>
            </div>
            <table class="mt-1 w-full border-collapse">
                <thead>
                <tr class="text-left">
                    <th class="border border-gray-200 bg-gray-50 p-2">Title</th>
                    <th class="border border-gray-200 bg-gray-50 p-2 text-center">Status</th>
                    <th class="border border-gray-200 bg-gray-50 p-2 text-center">Action</th>
                </tr>
                </thead>
                <tbody>
                <tr v-if="alternativeList.length==0">
                    <td class="border border-gray-200 p-2 text-center" colspan="3">
                        <span class="text-red-500">No data found!</span>
                    </td>
                </tr>
                <tr v-for="data in alternativeList" v-else :key="data.id">
                    <td class="border border-gray-200 p-2">{{ data.title }}</td>
                    <td class="border border-gray-200 p-2 text-center">
                        <p v-if="data.status==1" class="text-green-500">Active</p>
                        <p v-else class="text-orange-500">Inactive</p>
                    </td>
                    <td class="border border-gray-200 p-2">
                        <div class="md:flex md:justify-center">
                            <svg class="w-5 h-5 mx-1 cursor-pointer"
                                 fill="none" stroke="Blue" stroke-width="1.5" viewBox="0 0 24 24"
                                 xmlns="http://www.w3.org/2000/svg" @click="openModal(true, 'alternative', 'update', data)">
                                <path d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" stroke-linecap="round"
                                      stroke-linejoin="round"/>
                            </svg>
                            <img alt="img" class="w-5 mx-1 cursor-pointer"
                                 src="@/assets/images/cross_red.svg" @click="openDeleteModal(data.uuid, '')">
                        </div>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>

        <!-- Occupation specialization -->
        <div class="p-5 mt-6 bg-white rounded shadow-md">
            <div class="flex justify-between items-center">
                <p class="font-bold">Occupation specialization</p>
                <button class="m-2 px-4 py-1 rounded text-white bg-green-600 hover:bg-green-700"
                        @click="openModal(true, 'specialization', 'create', '')">Add New
                </button>
            </div>
            <table class="mt-1 w-full border-collapse">
                <thead>
                <tr class="text-left">
                    <th class="border border-gray-200 bg-gray-50 p-2">Title</th>
                    <th class="border border-gray-200 bg-gray-50 p-2 text-center">Status</th>
                    <th class="border border-gray-200 bg-gray-50 p-2 text-center">Action</th>
                </tr>
                </thead>
                <tbody>
                <tr v-if="specializationList.length==0">
                    <td class="border border-gray-200 p-2 text-center" colspan="3">
                        <span class="text-red-500">No data found!</span>
                    </td>
                </tr>
                <tr v-for="data in specializationList" v-else :key="data.id">
                    <td class="border border-gray-200 p-2">{{ data.title }}</td>
                    <td class="border border-gray-200 p-2 text-center">
                        <p v-if="data.status==1" class="text-green-500">Active</p>
                        <p v-else class="text-orange-500">Inactive</p>
                    </td>
                    <td class="border border-gray-200 p-2">
                        <div class="md:flex md:justify-center">
                            <!-- <img class="w-5 h-5 mx-1 cursor-pointer" src="@/assets/images/eye.svg" alt="view"> -->
                            <svg class="w-5 h-5 mx-1 cursor-pointer"
                                 fill="none" stroke="Blue" stroke-width="1.5" viewBox="0 0 24 24"
                                 xmlns="http://www.w3.org/2000/svg" @click="openModal(true, 'specialization', 'update', data)">
                                <path d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" stroke-linecap="round"
                                      stroke-linejoin="round"/>
                            </svg>
                            <img alt="img" class="w-5 mx-1 cursor-pointer"
                                 src="@/assets/images/cross_red.svg" @click="openDeleteModal(data.uuid, '')">
                        </div>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>

        <!-- Occupation in NEC category -->
        <div class="p-5 mt-6 bg-white rounded shadow-md">
            <div class="flex justify-between items-center">
                <p class="font-bold">Occupation in NEC categoty</p>
                <button class="m-2 px-4 py-1 rounded text-white bg-green-600 hover:bg-green-700"
                        @click="openModal(true, 'nec-category', 'create', '')">Add New
                </button>
            </div>
            <table class="mt-1 w-full border-collapse">
                <thead>
                <tr class="text-left">
                    <th class="border border-gray-200 bg-gray-50 p-2">Title</th>
                    <th class="border border-gray-200 bg-gray-50 p-2 text-center">Status</th>
                    <th class="border border-gray-200 bg-gray-50 p-2 text-center">Action</th>
                </tr>
                </thead>
                <tbody>
                <tr v-if="necCategoryList.length==0">
                    <td class="border border-gray-200 p-2 text-center" colspan="3">
                        <span class="text-red-500">No data found!</span>
                    </td>
                </tr>
                <tr v-for="data in necCategoryList" v-else :key="data.id">
                    <td class="border border-gray-200 p-2">{{ data.title }}</td>
                    <td class="border border-gray-200 p-2 text-center">
                        <p v-if="data.status==1" class="text-green-500">Active</p>
                        <p v-else class="text-orange-500">Inactive</p>
                    </td>
                    <td class="border border-gray-200 p-2">
                        <div class="md:flex md:justify-center">
                            <!-- <img class="w-5 h-5 mx-1 cursor-pointer" src="@/assets/images/eye.svg" alt="view"> -->
                            <svg class="w-5 h-5 mx-1 cursor-pointer"
                                 fill="none" stroke="Blue" stroke-width="1.5" viewBox="0 0 24 24"
                                 xmlns="http://www.w3.org/2000/svg" @click="openModal(true, 'nec-category', 'update', data)">
                                <path d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" stroke-linecap="round"
                                      stroke-linejoin="round"/>
                            </svg>
                            <img alt="img" class="w-5 mx-1 cursor-pointer"
                                 src="@/assets/images/cross_red.svg" @click="openDeleteModal(data.uuid, '')">
                        </div>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>


        <!-- modal -->
        <Modal :isSubmitting="isModalSubmitting" :modal_title='mdl_title' @close="openModal(false, '', '', '')"
               @submit="handleSubmitMethod">
            <template v-slot:body>

                <div v-show="modal_mode=='create' || modal_mode=='update'">
                    <div class="grid grid-cols-1 md:grid-cols-2 gap-x-4 gap-y-2 p-4">
                        <div class="w-full">
                            <label class="flex items-center"> Title <img alt="required"
                                                                         class="w-2 h-2 ml-1" src="@/assets/images/star.svg">
                            </label>
                            <input v-model='classificationForm.title'
                                   class="py-2 rounded border border-gray-300 bg-gray-50 text-sm placeholder-gray-400 focus:border-invblue resize-none w-full px-4 mt-1 focus:outline-none"
                                   placeholder="Title" type='text'>
                            <span v-if='classificationForm.errors().has("title")' class="text-red-800 text-xs">
                                {{ classificationForm.errors().get('title') }}
                            </span>
                        </div>
                        <div class="w-full">
                            <label>Status</label>
                            <select v-model='classificationForm.status'
                                    class="py-2.5 rounded border border-gray-300 bg-gray-50 text-sm placeholder-gray-400 focus:border-invblue resize-none w-full px-4 mt-1">
                                <option :selected="classificationForm.status==1" value="1">Active</option>
                                <option :selected="classificationForm.status==0" value="0">Inactive</option>
                            </select>
                        </div>
                    </div>
                    <div class="w-full px-4">
                        <label>Description</label>
                        <textarea v-model='classificationForm.description' class="py-2 rounded border border-gray-300 bg-gray-50 text-sm placeholder-gray-400 focus:border-invblue resize-none w-full px-4 mt-1 focus:outline-none" cols="50" placeholder="Note"
                                  rows="4"></textarea>
                    </div>
                </div>

                <!-- attach-assessing -->
                <div v-show="modal_mode=='attach-assessing'">
                    <div class="w-full p-4 mb-80">
                        <label class="flex items-center">Select Assessing Authority <img alt="required"
                                                                                         class="w-2 h-2 ml-1"
                                                                                         src="@/assets/images/star.svg"> </label>
                        <div v-show="assessing_auth_list.length>0">
                            <v-select
                                v-model="assessingAuthForm.assessing_authority_uuid"
                                :options="assessing_auth_list"
                                :reduce="accessing_authority => accessing_authority.uuid"
                                class="pt-1"
                                label="name"
                                placeholder="Choose"
                            />
                            <span v-if='assessingAuthForm.errors().has("assessing_authority_uuid")'
                                  class="text-red-800 text-xs">
                                {{ assessingAuthForm.errors().get('assessing_authority_uuid') }}
                            </span>
                        </div>
                    </div>
                </div>

                <!-- attach-anzsco -->
                <div v-show="modal_mode=='attach-anzsco'">
                    <div class="w-full p-4 mb-80">
                        <label class="flex items-center">Select ANZSCO Group <img alt="required"
                                                                                  class="w-2 h-2 ml-1"
                                                                                  src="@/assets/images/star.svg"> </label>
                        <div v-show="assessing_auth_list.length>0">
                            <v-select
                                v-model="anzscoForm.anzsco_group_uuids"
                                :options="anzsco_group_list"
                                :reduce="anzsco_group => anzsco_group.uuid"
                                class="pt-1"
                                label="name"
                                multiple
                                placeholder="Choose"
                            />
                            <span v-if='anzscoForm.errors().has("anzsco_group_uuids")' class="text-red-800 text-xs">
                                {{ anzscoForm.errors().get('anzsco_group_uuids') }}
                            </span>
                        </div>
                    </div>
                </div>

            </template>
        </Modal>

        <!-- classification delete modal -->
        <DeleteModal
            :isDeleteSubmitting="isDeleteSubmitting"
            :is_open="is_open_delete_modal"
            modal_title='Delete Classification'
            @close="closeDeleteModal"
            @submit="deleteModalSumbit"
        />

    </div>
</template>

<script>
import VisaApi from "@/libs/axios"
import form from 'vuejs-form'
import store from '@/store'
import Breadcrumb from '@/component/Breadcrumb'
import LoadingButton from "@/component/LoadingButton"
import {sanitizeName} from '@/libs/helpers.js'
import DeleteModal from '@/component/DeleteModal'
import Modal from '@/component/Modal'
import vSelect from "vue-select"
import "vue-select/dist/vue-select.css"
import BackIcon from "../../../data/icons/BackIcon.vue";
import {FileInput} from "../../../component/ui/forms";
import CKEditor from "@ckeditor/ckeditor5-vue"
import {ClassicEditor} from 'ckeditor5';
import ckeditorConfig from "../../../libs/ckeditorConfig";

export default {
    components: {
        FileInput,
        BackIcon,
        LoadingButton,
        Breadcrumb,
        DeleteModal,
        Modal,
        "v-select": vSelect,
        ckeditor: CKEditor.component
    },
    data() {
        return {
            editor: ClassicEditor,
            editorConfig: ckeditorConfig,

            breadcrumb: '',
            mdl_title: '',
            deleteModalTitle: '',
            modal_mode: 'create',
            occupation_uuid: '',
            deletable_uuid: '',
            isDeleteSubmitting: false,
            isSubmitting: false,
            isModalSubmitting: false,
            is_open_delete_modal: false,

            alternativeList: [],
            specializationList: [],
            necCategoryList: [],

            assessing_auth_list: [],
            selected_assessing: '',
            anzsco_group_list: [],
            selected_anzsco_group: '',
            anzscoAttachedList: [],

            selected_data: {},
            assessingAuthAttached: [],

            form: form.default({
                id: '',
                uuid: '',
                assessing_authority_uuid: [],
                anzsco_group_uuid: '',
                name: '',
                slug: '',
                anzsco_code: '',
                abs_version: '',
                abs_link: '',
                skill_level: '',
                description: '',
                is_popular: '0',
                status: '1',
                photo: '',
            }).rules({
                name: 'required',
                slug: 'required',
                anzsco_code: 'required',
                abs_version: 'required',
                skill_level: 'required',
            }),

            classificationForm: form.default({
                uuid: '',
                anzsco_code: '',
                type: '',
                title: '',
                description: '',
                status: '0',
            }).rules({
                title: 'required',
            }),

            assessingAuthForm: form.default({
                occupation_uuid: '',
                assessing_authority_uuid: '',
            }).rules({
                assessing_authority_uuid: 'required',
            }).messages({
                'assessing_authority_uuid.required': 'This field is required',
            }),

            anzscoForm: form.default({
                occupation_uuid: '',
                anzsco_group_uuids: [],
            }).rules({
                anzsco_group_uuids: 'required',
            }),

            importForm: form.default({file: '',}),
        };
    },

    methods: {
        updateOccupation() {
            if (this.form.validate().errors().any()) {
                return;
            } else {
                this.isSubmitting = true;
                VisaApi.post("/occupation/update/" + this.form.uuid, this.form.all(), {headers: {"Content-Type": "multipart/form-data"}}).then((response) => {
                    this.$toast.success(response.message, {
                        position: "top-right",
                        dismissible: true,
                    });
                    this.$router.push({name: 'occupation-list'})
                    this.isSubmitting = false;
                }).catch((error) => {
                    this.$toast.error(error.response.data.message, {
                        position: "top-right",
                        dismissible: true,
                    });
                    this.isSubmitting = false;
                });
            }
        },

        getSingleData() {
            store.commit('setting/LOADING_ON')
            VisaApi.get("/occupation/" + this.occupation_uuid).then((response) => {
                store.commit('setting/LOADING_OFF')
                this.form.id = response.data.id;
                this.form.uuid = response.data.uuid;
                this.form.name = response.data.name;
                this.form.slug = response.data.slug;
                this.form.anzsco_code = response.data.anzsco_code;
                this.form.abs_version = response.data.abs_version;
                this.form.abs_link = response.data.abs_link;
                this.form.skill_level = response.data.skill_level;
                this.form.description = response.data.description;
                this.form.status = response.data.status;
                this.form.is_popular = response.data.is_popular;
                this.form.photo = response.data.photo;

                this.assessingAuthAttached = response.data.assessing_authorities;
                this.anzscoAttachedList = response.data.anzsco_groups;


                this.alternativeList = [];
                this.specializationList = [];
                this.necCategoryList = [];

                this.classificationForm.anzsco_code = response.data.anzsco_code;

                if (response.data.assessing_authorities?.length) {
                    this.form.assessing_authority_uuid = response.data.assessing_authorities.map((aa) => {
                        return aa.uuid;
                    })
                }

                if (response.data.classifications?.length) {
                    for (let i = 0; i < response.data.classifications.length; i++) {
                        if (response.data.classifications[i].type == '2') {
                            this.alternativeList.push(response.data.classifications[i]);
                        } else if (response.data.classifications[i].type == '3') {
                            this.specializationList.push(response.data.classifications[i]);
                        } else if (response.data.classifications[i].type == '4') {
                            this.necCategoryList.push(response.data.classifications[i]);
                        }
                    }
                }
            });
        },

        getPhotoValue(photo) {
            this.form.photo = photo.value
        },

        openModal(value, type, mode, data) {
            this.classificationForm.errors().forget();
            const modalCls = document.getElementById('modal').classList;
            const modalAnimCls = document.getElementById('modal_animation').classList;
            if (value == true && type == 'alternative') {
                modalCls.remove('hidden');
                modalAnimCls.add('open-modal-animation');
                if (mode == 'create') {
                    this.modal_mode = 'create';
                    this.mdl_title = 'Create Alternative';
                    this.classificationForm.uuid = '';
                    this.classificationForm.type = '2';
                    this.classificationForm.title = '';
                    this.classificationForm.description = '';
                    this.classificationForm.status = '0';
                } else {
                    this.modal_mode = 'update';
                    this.mdl_title = 'Update Alternative';
                    Object.assign(this.classificationForm, data);
                }
            } else if (value == true && type == 'specialization') {
                modalCls.remove('hidden');
                modalAnimCls.add('open-modal-animation');
                if (mode == 'create') {
                    this.modal_mode = 'create';
                    this.mdl_title = 'Create Specialization';
                    this.classificationForm.uuid = '';
                    this.classificationForm.type = '3';
                    this.classificationForm.title = '';
                    this.classificationForm.description = '';
                    this.classificationForm.status = '0';
                } else {
                    this.modal_mode = 'update';
                    this.mdl_title = 'Update Specialization';
                    Object.assign(this.classificationForm, data);
                }
            } else if (value == true && type == 'nec-category') {
                modalCls.remove('hidden');
                modalAnimCls.add('open-modal-animation');
                if (mode == 'create') {
                    this.modal_mode = 'create';
                    this.mdl_title = 'Create NEC Category';
                    this.classificationForm.uuid = '';
                    this.classificationForm.type = '4';
                    this.classificationForm.title = '';
                    this.classificationForm.description = '';
                    this.classificationForm.status = '0';
                } else {
                    this.modal_mode = 'update';
                    this.mdl_title = 'Update NEC Category';
                    Object.assign(this.classificationForm, data);
                }
            } else if (value == true && type == 'assessing-auth') {
                modalCls.remove('hidden');
                modalAnimCls.add('open-modal-animation');
                if (mode == 'attach-assessing') {
                    this.modal_mode = 'attach-assessing';
                    this.mdl_title = 'Attach assessing authority';
                    this.assessingAuthForm.assessing_authority_uuid = '';
                }
            } else if (value == true && type == 'anzsco-group') {
                modalCls.remove('hidden');
                modalAnimCls.add('open-modal-animation');
                if (mode == 'attach-anzsco') {
                    this.modal_mode = 'attach-anzsco';
                    this.mdl_title = 'Attach ANZSCO Group';
                    this.anzscoForm.anzsco_group_uuids = [];
                }
            } else {
                modalCls.add('hidden');
                modalAnimCls.remove('open-modal-animation');
                this.isModalSubmitting = false;
            }
        },

        handleSubmitMethod() {
            if (this.modal_mode == 'attach-assessing') {
                this.attachAssessing();
            } else if (this.modal_mode == 'attach-anzsco') {
                this.attachAnzsco();
            } else if (this.modal_mode == 'create') {
                this.createData();
            } else {
                this.updateData();
            }
        },

        createData() {
            if (this.classificationForm.validate().errors().any()) {
                return;
            } else {
                this.isModalSubmitting = true;
                VisaApi.post("/occupation-classification/create", this.classificationForm.all()).then((response) => {
                    this.$toast.success(response.message, {
                        position: "top-right",
                        dismissible: true,
                    });
                    this.getSingleData();
                    this.openModal(false, '', '', '');
                }).catch((error) => {
                    this.$toast.error(error.response.data.message, {
                        position: "top-right",
                        dismissible: true,
                    });
                    this.openModal(false, '', '', '');
                });
            }
        },

        updateData() {
            if (this.classificationForm.validate().errors().any()) {
                return;
            } else {
                this.isModalSubmitting = true;
                VisaApi.patch("/occupation-classification/update/" + this.classificationForm.uuid, this.classificationForm.all()).then((response) => {
                    this.$toast.success(response.message, {
                        position: "top-right",
                        dismissible: true,
                    });
                    this.getSingleData();
                    this.openModal(false, '', '', '');
                }).catch((error) => {
                    this.$toast.error(error.response.data.message, {
                        position: "top-right",
                        dismissible: true,
                    });
                    this.openModal(false, '', '', '');
                });
            }
        },

        openDeleteModal(uuid, type) {
            if (type == 'detach-assessing') {
                this.deleteModalTitle = 'Detach assessing authority';
                this.assessingAuthForm.assessing_authority_uuid = uuid;
            } else if (type == 'detach-anzsco') {
                this.deleteModalTitle = 'Detach ANZSCO Group';
                this.anzscoForm.anzsco_group_uuids.push(uuid);
            } else {
                this.deleteModalTitle = 'Delete classification';
                this.deletable_uuid = uuid;
            }
            this.is_open_delete_modal = true
        },

        closeDeleteModal() {
            this.deletable_uuid = '';
            this.assessingAuthForm.assessing_authority_uuid = '';
            this.anzscoForm.anzsco_group_uuids = [];
            this.isDeleteSubmitting = false;
            this.is_open_delete_modal = false
        },

        deleteModalSumbit() {
            if (this.deleteModalTitle == 'Detach assessing authority') {
                this.detachAssessing();
            } else if (this.deleteModalTitle == 'Detach ANZSCO Group') {
                this.detachAnzsco();
            } else {
                this.isDeleteSubmitting = true;
                VisaApi.delete("/occupation-classification/" + this.deletable_uuid).then((response) => {
                    this.$toast.success(response.message, {
                        position: "top-right",
                        dismissible: true,
                    });
                    this.closeDeleteModal();
                    this.getSingleData();
                }).catch((error) => {
                    this.$toast.error(error.response.data.message, {
                        position: "top-right",
                        dismissible: true,
                    });
                    this.closeDeleteModal();
                });
            }
        },

        attachAssessing() {
            if (this.assessingAuthForm.validate().errors().any()) {
                return;
            } else {
                this.isModalSubmitting = true;
                VisaApi.post("/occupation/attach", this.assessingAuthForm.all()).then((response) => {
                    this.$toast.success(response.message, {
                        position: "top-right",
                        dismissible: true,
                    });
                    this.assessingAuthForm.assessing_authority_uuid = '';
                    this.getSingleData(this.occupation_uuid);
                    this.openModal(false, '', '', '');
                }).catch((error) => {
                    this.$toast.error(error.response.data.message, {
                        position: "top-right",
                        dismissible: true,
                    });
                    this.openModal(false, '', '', '');
                });
            }
        },

        detachAssessing() {
            if (this.assessingAuthForm.validate().errors().any()) {
                this.$toast.error('Invalid assessing auth', {
                    position: "top-right",
                    dismissible: true,
                });
            } else {
                this.isDeleteSubmitting = true;
                VisaApi.post("/occupation/detach", this.assessingAuthForm.all()).then((response) => {
                    this.$toast.success(response.message, {
                        position: "top-right",
                        dismissible: true,
                    });
                    this.closeDeleteModal();
                    this.getSingleData(this.occupation_uuid);
                }).catch((error) => {
                    this.$toast.error(error.response.data.message, {
                        position: "top-right",
                        dismissible: true,
                    });
                    this.closeDeleteModal();
                });
            }
        },

        getAssessingAuthList() {
            VisaApi.get("assessing-authority").then((response) => {
                this.assessing_auth_list = response.data;
            });
        },

        getAnzscoGroupList() {
            VisaApi.get("anzsco-group").then((response) => {
                this.anzsco_group_list = response.data;
            });
        },

        attachAnzsco() {
            if (this.anzscoForm.validate().errors().any()) {
                return;
            } else {
                this.isModalSubmitting = true;
                VisaApi.post("/anzsco-group/attach", this.anzscoForm.all()).then((response) => {
                    this.$toast.success(response.message, {
                        position: "top-right",
                        dismissible: true,
                    });
                    this.anzscoForm.anzsco_group_uuids = [];
                    this.selected_anzsco_group = '';
                    this.getSingleData(this.occupation_uuid);
                    this.openModal(false, '', '', '');
                }).catch((error) => {
                    this.$toast.error(error.response.data.message, {
                        position: "top-right",
                        dismissible: true,
                    });
                    this.openModal(false, '', '', '');
                });
            }
        },

        detachAnzsco() {
            if (this.anzscoForm.validate().errors().any()) {
                this.$toast.error('Invalid anzsco group', {
                    position: "top-right",
                    dismissible: true,
                });
            } else {
                VisaApi.post("/anzsco-group/detach", this.anzscoForm.all()).then((response) => {
                    this.$toast.success(response.message, {
                        position: "top-right",
                        dismissible: true,
                    });
                    this.closeDeleteModal();
                    this.getSingleData(this.occupation_uuid);
                }).catch((error) => {
                    this.$toast.error(error.response.data.message, {
                        position: "top-right",
                        dismissible: true,
                    });
                    this.closeDeleteModal();
                });
            }
        },

        openImport() {
            const modalCls = document.getElementById('import_modal').classList;
            const modalAnimCls = document.getElementById('import_modal_animation').classList;
            modalCls.remove('hidden');
            modalAnimCls.add('delete-modal-animation');
        },

        closeImport() {
            const modalCls = document.getElementById('import_modal').classList;
            const modalAnimCls = document.getElementById('import_modal_animation').classList;
            modalCls.add('hidden');
            modalAnimCls.remove('delete-modal-animation');
            this.importForm.file = '';
        },


        handleDragOver(event) {
            event.preventDefault();
        },

        handleDrop(event) {
            event.preventDefault();
            const file = event.dataTransfer.files[0];
            this.importForm.file = file;
            // console.log('drag & drop file=', this.form.file);
        },

        handleFileSelected(e) {
            this.importForm.file = e.target.files[0];
            // console.log('browse file=',this.form.file);
        },

        formatFileSize(size) {
            if (size === 0) return "0 Bytes";
            const k = 1024;
            const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
            const i = Math.floor(Math.log(size) / Math.log(k));
            return parseFloat((size / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
        },

        openFileInput() {
            this.$refs.fileInput.click();
        },

        removeImportFile() {
            this.importForm.file = '';
        },

        importExcelFile() {
            if (this.importForm.file == '') {
                this.$toast.error('Please select a file', {
                    position: "top-right",
                    dismissible: true,
                });
                return;
            } else {
                this.isSubmitting = true;
                VisaApi.post("/occupation-classification/import", this.importForm.all(), {headers: {"Content-Type": "multipart/form-data"}}).then((response) => {
                    this.$toast.success(response.message, {
                        position: "top-right",
                        dismissible: true,
                    });
                    this.closeImport();
                    this.importForm.file = '';
                    this.getSingleData(this.occupation_uuid);
                    this.isSubmitting = false;
                }).catch((error) => {
                    this.$toast.error(error.response.data.message, {
                        position: "top-right",
                        dismissible: true,
                    });
                    this.isSubmitting = false;
                });
            }
        },
    },

    watch: {
        'form.name': {
            handler() {
                this.form.slug = sanitizeName(this.form.name);
            }
        }
    },

    mounted() {
        this.breadcrumb = this.$router.currentRoute.value.meta.breadcrumb;
        this.occupation_uuid = this.$route.params.id;
        this.assessingAuthForm.occupation_uuid = this.$route.params.id;
        this.anzscoForm.occupation_uuid = this.$route.params.id;
        this.getSingleData();
        this.getAssessingAuthList();
        this.getAnzscoGroupList();
    }
}
</script>
