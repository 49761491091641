export default {
    enabled: true,
    mode: 'records',
    perPage: 10,
    position: 'bottom',
    perPageDropdownEnabled: false,
    setCurrentPage: 1,
    jumpFirstOrLast: true,
    firstLabel: 'First Page',
    lastLabel: 'Last Page',
    nextLabel: 'next',
    prevLabel: 'prev',
    ofLabel: 'of',
    allLabel: 'All',
    infoFn: (params) => `Showing ${params.firstRecordOnPage} to ${params.lastRecordOnPage} of page ${params.currentPage}`,
}
