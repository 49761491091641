<template>
    <div class="px-4 py-2 absolute w-full">

        <div class="flex flex-wrap items-center justify-between pb-2 border-b border-gray-300">
            <Breadcrumb :text="breadcrumb"/>
            <router-link to="/lin-occupation">
                <button class="rounded-md bg-orange-600 px-6 py-1.5 text-white hover:bg-orange-500" type="button">
                    Back to list
                </button>
            </router-link>
        </div>

        <div class="visasis-card">
            <p class="font-bold mt-2">Attach lin occupation with caveat.</p>

            <div class="grid grid-cols-1 md:grid-cols-2 gap-x-4 gap-y-2 mt-4">
                <div class="w-full flex items-center">
                    <label>Lin Sol Name:</label>
                    <p class="pl-2">{{ lin_sol_name }}</p>
                </div>
                <div class="w-full flex items-center mt-4">
                    <label>Lin Sol Code:</label>
                    <p class="pl-2">{{ lin_sol_code }}</p>
                </div>
                <div class="w-full">
                    <label class="flex items-center">Select Caveat <img alt="required"
                                                                        class="w-2 h-2 ml-1" src="@/assets/images/star.svg">
                    </label>
                    <v-select
                        v-model="caveat_selected"
                        :options="caveats"
                        class="pt-1"
                        label="list"
                        multiple
                        placeholder="Choose"
                    />
                    <span v-if='attachForm.errors().has("caveat_uuids")' class="text-red-800 text-xs">
                        {{ attachForm.errors().get('caveat_uuids') }}
                    </span>
                </div>
            </div>

            <div class="text-center mt-8">
                <button v-if="isSubmitting==false" class="mt-4 rounded-md bg-blue-600 px-6 py-1.5 text-white hover:bg-blue-500" type="button"
                        @click="attachCaveat">
                    Attach Caveat
                </button>
                <span v-else>
                    <LoadingButton text="Attaching"/>
                </span>
            </div>

            <div class="overflow-x-auto mt-8 mb-4">
                <p class="font-bold text-sm">This lin occupation already attached with the following caveats: </p>
                <table class="w-full border-collapse border border-gray-400">
                    <thead>
                    <tr class="text-left">
                        <th class="border border-gray-200 bg-gray-50 p-2">List</th>
                        <th class="border border-gray-200 bg-gray-50 p-2">Note</th>
                        <th class="border border-gray-200 bg-gray-50 p-2">Status</th>
                        <th class="border border-gray-200 bg-gray-50 p-2">Detach</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-if="alreadyAttachedList.length==0">
                        <td class="border border-gray-200 p-2 text-center" colspan="4">
                            <span class="text-red-500">No data found!</span>
                        </td>
                    </tr>
                    <tr v-for="data in alreadyAttachedList" v-else :key="data.id">
                        <td class="border border-gray-200 p-2">{{ data.list }}</td>
                        <td class="border border-gray-200 p-2">{{ data.note }}</td>
                        <td class="border border-gray-200 p-2">
                            <p v-if="data.status==1" class="text-green-500">Active</p>
                            <p v-else-if="data.status==0" class="text-red-500">Inactive</p>
                        </td>
                        <td class="border border-gray-200 p-2">
                            <div>
                                <img alt="img" class="w-5 cursor-pointer mx-auto"
                                     src="@/assets/images/cross_red.svg" @click="openDeleteModal(data.uuid)">
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>

        </div>

        <!-- delete modal -->
        <DeleteModal
            :isDeleteSubmitting="isDeleteSubmitting"
            :is_open="is_open_delete_modal"
            modal_title='Detach anzsco'
            @close="closeDeleteModal"
            @submit="detachCaveat"
        />

    </div>
</template>

<script>
import VisaApi from "@/libs/axios"
import form from 'vuejs-form'
import store from '@/store'
import Breadcrumb from '@/component/Breadcrumb'
import vSelect from "vue-select"
import "vue-select/dist/vue-select.css"
import DeleteModal from '@/component/DeleteModal'
import LoadingButton from "@/component/LoadingButton"

export default {
    components: {Breadcrumb, "v-select": vSelect, DeleteModal, LoadingButton},
    data() {
        return {
            breadcrumb: '',
            alreadyAttachedList: [],
            caveats: [],
            caveat_selected: [],
            lin_sol_name: '',
            lin_sol_code: '',
            isSubmitting: false,
            isDeleteSubmitting: false,
            is_open_delete_modal: false,

            attachForm: form.default({
                lin_occupation_uuid: '',
                caveat_uuids: [],
            }).rules({
                caveat_uuids: 'required',
            }),

            detachForm: form.default({
                lin_occupation_uuid: '',
                caveat_uuid: '',
            }).rules({
                caveat_uuid: 'required',
            }),
        };
    },

    methods: {
        async getCaveatList() {
            store.commit('setting/LOADING_ON')
            await VisaApi.get("/caveat").then((response) => {
                store.commit('setting/LOADING_OFF')
                if (response.code == '200') {
                    this.caveats = response.data;
                }
            });
        },

        attachCaveat() {
            if (this.attachForm.validate().errors().any()) {
                return;
            } else {
                this.isSubmitting = true;
                VisaApi.post("/lin-occupation/attach", this.attachForm.all()).then((response) => {
                    if (response.code == '200') {
                        this.$toast.success(response.message, {
                            position: "top-right",
                            dismissible: true,
                        });
                    }
                    this.isSubmitting = false;
                    this.attachForm.caveat_uuids = [];
                    this.caveat_selected = [];
                    this.getSingleData(this.attachForm.lin_occupation_uuid);
                }).catch((error) => {
                    this.$toast.error(error.response.data.message, {
                        position: "top-right",
                        dismissible: true,
                    });
                    this.isSubmitting = false;
                });
            }
        },

        openDeleteModal(uuid) {
            this.is_open_delete_modal = true;
            this.detachForm.caveat_uuid = uuid;
        },

        closeDeleteModal() {
            this.isDeleteSubmitting = false;
            this.is_open_delete_modal = false;
        },

        detachCaveat() {
            if (this.detachForm.validate().errors().any()) {
                return;
            } else {
                this.isDeleteSubmitting = true;
                VisaApi.post("/lin-occupation/detach", this.detachForm.all()).then((response) => {
                    this.$toast.success(response.message, {
                        position: "top-right",
                        dismissible: true,
                    });
                    this.closeDeleteModal();
                    this.getSingleData(this.detachForm.lin_occupation_uuid);
                    this.isDeleteSubmitting = false;
                }).catch((error) => {
                    this.$toast.error(error.response.data.message, {
                        position: "top-right",
                        dismissible: true,
                    });
                    this.closeDeleteModal();
                });
            }
        },

        getSingleData(uuid) {
            VisaApi.get("/lin-occupation/" + uuid).then((response) => {
                if (response.code == '200') {
                    this.alreadyAttachedList = response.data.caveats;
                    this.lin_sol_name = response.data.lin_sol_name;
                    this.lin_sol_code = response.data.lin_sol_code;
                }
            });
        },
    },

    watch: {
        caveat_selected(data) {
            this.attachForm.caveat_uuids = [];
            for (let i = 0; i < data.length; i++) {
                this.attachForm.caveat_uuids.push(data[i].uuid);
            }
        },
    },

    mounted() {
        this.attachForm.lin_occupation_uuid = this.$route.params.id;
        this.detachForm.lin_occupation_uuid = this.$route.params.id;
        this.getSingleData(this.$route.params.id);
        this.getCaveatList();
        this.breadcrumb = this.$router.currentRoute.value.meta.breadcrumb;
    }
}
</script>
