import {createStore} from 'vuex'

//modules
import user from './modules/user.js'
import setting from './modules/setting.js'
import api from './modules/api.js'
import visa from './modules/visa.js'
import caveat from './modules/caveat.js'

export default createStore({
    namespaced: true,
    modules: {
        user, setting, api, visa, caveat
    }
})
