<template>
    <div>
        <section class="h-screen">
            <div class="px-6 h-full text-gray-800">
                <div class="flex xl:justify-center lg:justify-between justify-center items-center flex-wrap h-full g-6">
                    <div class="grow-0 shrink-1 md:shrink-0 basis-auto xl:w-6/12 lg:w-6/12 md:w-9/12 mb-12 md:mb-0">
                        <img
                            :src="loginPageImage"
                            alt="Sample image"
                            class="w-full"
                        />
                    </div>
                    <div class="xl:ml-20 xl:w-5/12 lg:w-5/12 md:w-8/12 mb-12 md:mb-0 bg-gray-50 p-10 rounded-md">
                        <div class="text-center mb-10">
                            <h1 class="text-4xl text-gray-700 mb-3">Welcome to visasis!</h1>
                            <p class="text-gray-400">Please sign-in to your account and start the adventure</p>
                        </div>

                        <!-- user name -->
                        <div class="mb-6">
                            <input
                                v-model="form.username"
                                class="block w-full px-4 py-2 font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                                placeholder="User Name"
                                type="text"
                            />
                            <span v-if='form.errors().has("username")' class="text-red-800 text-xs">
                                {{ form.errors().get('username') }}
                            </span>
                        </div>
                        <!-- user password -->
                        <div class="mb-6">
                            <input
                                v-model="form.password"
                                class="block w-full px-4 py-2 font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                                placeholder="Password"
                                type="password"
                            />
                            <span v-if='form.errors().has("password")' class="text-red-800 text-xs">
                                {{ form.errors().get('password') }}
                            </span>
                        </div>

                        <div class="text-center lg:text-left">
                            <button
                                class="inline-block px-7 py-3 bg-blue-600 text-white font-medium text-sm leading-snug uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
                                type="button"
                                @click="login"
                            >
                                Login
                            </button>
                        </div>

                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import VisaApi from "../../libs/axios"
import form from 'vuejs-form'
import store from '../../store'
import {settings} from "../../libs/helpers";

let loginData = {
    username: '',
    password: '',
};

export default {
    name: 'Login',

    data() {
        return {
            settings: settings(),
            form: form.default(loginData).rules({
                username: 'required',
                password: 'required',
            }),
        };
    },

    methods: {
        async login() {
            store.commit('setting/LOADING_ON')
            if (this.form.validate().errors().any()) {
                store.commit('setting/LOADING_OFF')
                return;
            } else {
                await VisaApi.post("/login", this.form.all()).then((response) => {
                    store.commit('setting/LOADING_OFF')
                    if (response.code == '200') {
                        this.$store.dispatch('user/login', response.data);
                        this.$toast.success("Login successful", {
                            position: "top-right",
                            dismissible: true,
                        });
                        this.$router.push({name: 'dashboard'})
                    } else {
                        store.commit('setting/LOADING_OFF')
                        this.$toast.error("Something went wrong!", {
                            position: "top-right",
                            dismissible: true,
                        });
                    }
                });
            }
        },
    },

    computed: {
        loginPageImage() {
            if (this.settings != null) {
                return this.settings.login_page_image
            }
        }
    },

    async mounted() {
        await store.dispatch('setting/defaultSetting')
    }
}
</script>
