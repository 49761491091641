<template>
    <Transition name="fade">
        <div v-if="props.is_open" id="modal" class="relative z-50">
            <div class="fixed inset-0 bg-gray-500 bg-opacity-75"></div>
            <div class="fixed inset-0 overflow-y-auto">
                <div class="flex min-h-full justify-center text-center sm:items-center sm:p-0">
                    <div class="relative rounded-lg bg-white text-left shadow-xl mx-1 w-full md:w-10/12 xl:w-8/12">
                        <div class="bg-white text-gray-600 pb-2">
                            <h3 class="py-2.5 px-4 text-lg leading-6 border-b bg-slate-700 text-white">{{
                                props.modal_title }}</h3>
                            <div class="p-5">
                                <slot> This is the default body!</slot>
                            </div>
                        </div>
                        <div class="bg-gray-200 py-2 px-4 flex justify-end items-center">
                            <button class="rounded-md border border-transparent bg-red-600 px-4 py-1 mr-3 text-white hover:bg-red-700" type="button"
                                    @click="closeModal">
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Transition>
</template>

<script setup>
const emit = defineEmits(["close"])
const props = defineProps({
    modal_title: {
        required: true
    },
    is_open: {
        required: true,
        default: false
    }
})
const closeModal = () => {
    emit("close")
}
</script>

<style scoped>

</style>
