<template>
    <div class="px-4 py-2 absolute w-full">

        <div class="flex flex-wrap items-center justify-between pb-2">
            <Breadcrumb :text="breadcrumb"/>
            <router-link to="/page-list">
                <button class="rounded-md bg-red-600 inline-flex px-6 py-2 text-white hover:bg-red-500" type="button">
                    <back-icon class="mr-2 mt-1"/>
                    Back to list
                </button>
            </router-link>
        </div>

        <div class="visasis-card">
            <div class="grid grid-cols-1 md:grid-cols-2 gap-x-4 gap-y-2">
                <div class="w-full">
                    <label class="flex items-center">Title
                        <img alt="required" class="w-2 h-2 ml-1" src="@/assets/images/star.svg"> </label>
                    <input v-model='form.title' class="py-2 rounded border border-gray-300 bg-gray-50 text-sm placeholder-gray-400 focus:border-invblue resize-none w-full px-4 mt-1" placeholder="Title"
                           type='text'>
                    <span v-if='form.errors().has("title")' class="text-red-800 text-xs">
                        {{ form.errors().get('title') }}
                    </span>
                </div>
                <div class="w-full">
                    <label>Select Menu Item</label>
                    <select v-model="form.menu_item_id"
                            class="py-2 rounded border border-gray-300 bg-gray-50 text-sm placeholder-gray-400 focus:border-invblue resize-none w-full px-4 mt-1">
                        <option value="">Please select</option>
                        <optgroup v-for="menu in menu_items" :label="menuTitle(menu)">
                            <option v-for="item in menu.items" :value="item.id">{{ item.title }}</option>
                        </optgroup>
                    </select>
                </div>
                <div class="w-full">
                    <label>Meta Title</label>
                    <input v-model='form.meta_title' class="py-2 rounded border border-gray-300 bg-gray-50 text-sm placeholder-gray-400 focus:border-invblue resize-none w-full px-4 mt-1" placeholder="Meta Title"
                           type='text'>
                </div>
                <div class="w-full">
                    <label>Meta Keyword</label>
                    <input v-model='form.meta_keyword' class="py-2 rounded border border-gray-300 bg-gray-50 text-sm placeholder-gray-400 focus:border-invblue resize-none w-full px-4 mt-1" placeholder="Meta Title"
                           type='text'>
                </div>
                <div class="w-full">
                    <label>Status</label>
                    <select v-model='form.is_active'
                            class="py-2 rounded border border-gray-300 bg-gray-50 text-sm placeholder-gray-400 focus:border-invblue resize-none w-full px-4 mt-1">
                        <option :selected="form.is_active=='1'" value="1">Active</option>
                        <option :selected="form.is_active=='0'" value="0">Inactive</option>
                    </select>
                </div>
                <div class="w-full">
                    <label class="flex items-center">Breadcrumb background
                        <img alt="required" class="w-2 h-2 ml-1" src="@/assets/images/star.svg">
                    </label>
                    <select v-model='form.breadcrumb_background'
                            class="py-2 rounded border border-gray-300 bg-gray-50 text-sm placeholder-gray-400 focus:border-invblue resize-none w-full px-4 mt-1">
                        <option v-for="(breadcrumb, bi) in breadcrumbBackgrounds" :key="'bi-'+bi"
                                :value="breadcrumb.id">
                            {{ breadcrumb.name }}
                        </option>
                    </select>
                    <span v-if='form.errors().has("breadcrumb_background")' class="text-red-800 text-xs">
                        {{ form.errors().get('breadcrumb_background') }}
                    </span>
                </div>
                <div class="w-full">
                    <label class="flex items-center">Breadcrumb Text</label>
                    <input v-model='form.breadcrumb_text' class="py-2 rounded border border-gray-300 bg-gray-50 text-sm placeholder-gray-400 focus:border-invblue resize-none w-full h-9 px-4 mt-1" placeholder="Breadcrumb text"
                           type='text'>
                </div>
                <div v-if="form.breadcrumb_background == 1" class="w-full">
                    <label class="flex items-center">Breadcrumb Image
                        <img alt="required" class="w-2 h-2 ml-1" src="@/assets/images/star.svg"> </label>
                    <input ref="breadcrumb_file" class="rounded border border-gray-300 bg-gray-50 text-sm placeholder-gray-400 focus:border-invblue resize-none px-4 w-full py-1 mt-1" type='file'
                           @input="uploadImage">
                    <img v-if="form.breadcrumb_image !== null" :src="form.breadcrumb_image" alt="Image" height="50"
                         width="50"/>
                </div>
                <div v-if="form.breadcrumb_background == 2" class="w-full">
                    <label class="flex items-center">Breadcrumb Color
                        <img alt="required" class="w-2 h-2 ml-1" src="@/assets/images/star.svg"> </label>
                    <input v-model='form.breadcrumb_color' class="rounded border border-gray-300 bg-gray-50 text-sm placeholder-gray-400 focus:border-invblue resize-none px-1 w-1/8 h-9 mt-1" placeholder="Breadcrumb color"
                           type='color'>
                </div>
            </div>
            <div class="w-full">
                <label>Meta Description</label>
                <textarea v-model='form.meta_description' class="py-2 rounded border border-gray-300 bg-gray-50 text-sm placeholder-gray-400 focus:border-invblue resize-none w-full px-4 mt-1" cols="50" placeholder="Meta Description"
                          rows="4"></textarea>
            </div>
            <div class="w-full">
                <label>Content</label>
                <article class="prose" style="max-width: 100%">
                    <ckeditor v-model="form.content" :config="editorConfig" :editor="editor"></ckeditor>
                </article>
            </div>

            <button v-if="isSubmitting==false" class="mt-4 justify-center rounded-md bg-blue-600 px-6 py-1.5 text-white hover:bg-blue-500" type="button"
                    @click="update">
                Submit
            </button>
            <span v-else class="mt-4 block">
                <LoadingButton text="Submitting"/>
            </span>
        </div>
    </div>
</template>

<script>
import VisaApi from "@/libs/axios"
import form from 'vuejs-form'
import Breadcrumb from '@/component/Breadcrumb'
import LoadingButton from "@/component/LoadingButton"
import DeleteModal from '@/component/DeleteModal'
import Modal from '@/component/Modal'
import vSelect from "vue-select"
import "vue-select/dist/vue-select.css"
import {breadcrumbBackgrounds} from "../../../data/menu";
import FileInput from "../../../component/ui/forms/FileInput.vue";
import BackIcon from "../../../data/icons/BackIcon.vue";
import PageContentList from "./PageContentList.vue";
import CKEditor from "@ckeditor/ckeditor5-vue"
import {ClassicEditor} from 'ckeditor5';
import ckeditorConfig from "../../../libs/ckeditorConfig";

export default {
    name: "CreateMenu",
    components: {
        PageContentList,
        BackIcon, LoadingButton, Breadcrumb,
        DeleteModal, Modal, "v-select": vSelect,
        FileInput, ckeditor: CKEditor.component
    },

    data() {
        return {
            breadcrumb: '',
            isSubmitting: false,
            modal_title: '',
            page_content_list: [],
            page_content_selected: '',
            isDeleteSubmitting: false,
            isModalSubmitting: false,
            breadcrumbBackgrounds,
            deletable_content_id: '',
            menu_items: [],
            page_id: '',
            editor: ClassicEditor,
            editorConfig: ckeditorConfig,


            form: form.default({
                id: '',
                title: '',
                menu_item_id: '',
                is_active: '0',
                content: '',
                meta_title: '',
                meta_keyword: '',
                breadcrumb_background: '',
                breadcrumb_image: null,
                breadcrumb_color: '#000000',
                breadcrumb_text: '',
                meta_description: '',
            }).rules({
                title: 'required',
                breadcrumb_background: 'required',
            }),

            attachForm: form.default({
                page_id: '',
                page_content_id: '',
                sort_order: '',
            }).rules({
                page_content_id: 'required',
                sort_order: 'required',
            }),
        };
    },

    methods: {
        openModal(value) {
            this.attachForm.errors().forget();
            const modalCls = document.getElementById('modal').classList;
            const modalAnimCls = document.getElementById('modal_animation').classList;
            if (value == true) {
                modalCls.remove('hidden');
                modalAnimCls.add('open-modal-animation');

                this.attachForm.page_content_id = '';
                this.attachForm.sort_order = '';
                this.page_content_selected = '';

                this.modal_title = 'Attach page content';
            } else {
                modalCls.add('hidden');
                modalAnimCls.remove('open-modal-animation');
                this.isModalSubmitting = false;
            }
        },

        update() {
            if (this.form.validate().errors().any()) {
                return;
            } else {
                this.isSubmitting = true;
                const formData = new FormData();

                for (const key in this.form.all()) {
                    if (this.form.all().hasOwnProperty(key)) {
                        formData.append(key, this.form.all()[key]);
                    }
                }

                VisaApi.post("/frontend/update/pages/" + this.form.id, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }).then((response) => {
                    if (response.code == '201') {
                        this.$toast.success(response.message, {
                            position: "top-right",
                            dismissible: true,
                        });
                        this.$router.push({name: 'page-list'})
                    }
                    this.isSubmitting = false;
                }).catch((error) => {
                    this.$toast.error(error.response.data.message, {
                        position: "top-right",
                        dismissible: true,
                    });
                    this.isSubmitting = false;
                });
            }
        },

        attachContent() {
            if (this.attachForm.validate().errors().any()) {
                return;
            } else {
                this.isModalSubmitting = true;
                VisaApi.post("/frontend/page/attach-page-content", this.attachForm.all()).then((response) => {
                    this.$toast.success(response.message, {
                        position: "top-right",
                        dismissible: true,
                    });
                    this.page_content_selected = '';
                    this.attachForm.page_content_id = '';
                    this.attachForm.sort_order = '';
                    this.getSingleData(this.attachForm.page_id);
                    this.openModal(false);
                }).catch((error) => {
                    this.$toast.error(error.response.data.message, {
                        position: "top-right",
                        dismissible: true,
                    });
                    this.openModal(false);
                });
            }
        },

        detachPageContent() {
            this.isDeleteSubmitting = true;
            VisaApi.post("/frontend/page/detach-page-content", {
                page_id: this.attachForm.page_id,
                page_content_id: this.deletable_content_id
            }).then((response) => {
                this.$toast.success(response.message, {
                    position: "top-right",
                    dismissible: true,
                });
                this.closeDeleteModal();
                this.getSingleData(this.attachForm.page_id);
            }).catch((error) => {
                this.$toast.error(error.response.data.message, {
                    position: "top-right",
                    dismissible: true,
                });
                this.closeDeleteModal();
            });
        },

        menuTitle(menu) {
            if (menu.is_active) return menu.title + ' (Active)';
            return menu.title;
        },

        openDeleteModal(id) {
            const modalCls = document.getElementById('delete_modal').classList;
            const modalAnimCls = document.getElementById('delete_modal_animation').classList;
            this.deletable_content_id = id;
            modalCls.remove('hidden');
            modalAnimCls.add('delete-modal-animation');
        },

        closeDeleteModal() {
            const modalCls = document.getElementById('delete_modal').classList;
            const modalAnimCls = document.getElementById('delete_modal_animation').classList;
            modalCls.add('hidden');
            modalAnimCls.remove('delete-modal-animation');
            this.deletable_content_id = '';
            this.isDeleteSubmitting = false;
        },

        async getPageContentList() {
            await VisaApi.get("/frontend/page/page-contents").then((response) => {
                // this.page_content_list = response.data;
            });
        },

        getSingleData(id) {
            VisaApi.get("/frontend/pages/" + id).then((response) => {
                Object.assign(this.form, response.data);
                this.form.content = response.data.content ?? ''
                this.form.menu_item_id = response.data.menu_item_id ?? ''
                this.attachForm.page_id = response.data.id;
            });
        },

        uploadImage(event) {
            this.form.breadcrumb_image = event.target.files[0];
        },
        getMenuItems() {
            VisaApi.get("/frontend/group-menu-item").then((response) => {
                this.menu_items = response.data;
            });
        },
    },

    watch: {
        page_content_selected(value) {
            if (value != null) {
                this.attachForm.page_content_id = value.id;
            }
        }
    },

    mounted() {
        this.breadcrumb = this.$router.currentRoute.value.meta.breadcrumb;
        this.page_id = this.$route.params.id
        this.getMenuItems()
        this.getSingleData(this.page_id);
        this.getPageContentList();
    }
}
</script>
