<template>
    <div class="px-4 py-2 absolute w-full">
        <div class="flex flex-wrap items-center justify-between">
            <Breadcrumb :text="breadcrumb"/>
            <router-link to="/visa-stream-list">
                <button class="back-btn" type="button">
                    <back-icon class="mt-1 mr-2"/>
                    Back to list
                </button>
            </router-link>
        </div>

        <div class="visasis-card">
            <div class="grid grid-cols-1 md:grid-cols-2 gap-x-4 gap-y-2">
                <div class="w-full">
                    <label class="flex items-center">Select Visa <img alt="required"
                                                                      class="w-2 h-2 ml-1"
                                                                      src="@/assets/images/star.svg"> </label>
                    <v-select
                        v-model="form.visa_uuid"
                        :options="visa_list"
                        :reduce="visa => visa.uuid"
                        class="pt-1"
                        label="name"
                        placeholder="Choose visa..."
                    >
                        <template v-slot:option="option">
                            {{ option.visa_subclass }} - {{ option.name }}
                        </template>
                    </v-select>
                    <span v-if='form.errors().has("visa_uuid")' class="text-red-800 text-xs">
                        {{ form.errors().get('visa_uuid') }}
                    </span>
                </div>

                <div class="w-full">
                    <label class="flex items-center">Stream Name <img alt="required"
                                                                      class="w-2 h-2 ml-1"
                                                                      src="@/assets/images/star.svg"> </label>
                    <input v-model='form.name' class="py-2 rounded border border-gray-300 bg-gray-50 text-sm placeholder-gray-400 focus:border-invblue resize-none w-full px-4 mt-1" placeholder="Name"
                           type='text'>
                    <span v-if='form.errors().has("name")' class="text-red-800 text-xs">
                        {{ form.errors().get('name') }}
                    </span>
                </div>
                <div class="w-full">
                    <label class="flex items-center">Slug <img alt="required" class="w-2 h-2 ml-1"
                                                               src="@/assets/images/star.svg">
                    </label>
                    <input v-model='form.slug' class="py-2 rounded border border-gray-300 bg-gray-50 text-sm placeholder-gray-400 focus:border-invblue resize-none w-full px-4 mt-1" placeholder="Slug"
                           type='text'>
                    <span v-if='form.errors().has("slug")' class="text-red-800 text-xs">
                        {{ form.errors().get('slug') }}
                    </span>
                </div>
                <div class="w-full">
                    <label class="flex items-center">Sort Order</label>
                    <input v-model='form.sort_order' class="py-2 rounded border border-gray-300 bg-gray-50 text-sm placeholder-gray-400 focus:border-invblue resize-none w-full px-4 mt-1" min="0" placeholder="Sort Order"
                           type='number'>
                    <span v-if='form.errors().has("slug")' class="text-red-800 text-xs">
                        {{ form.errors().get('sort_order') }}
          </span>
                    <span class="text-gray-900 text-xs">
          Default is 0. Put larger value if want to show later.
          </span>
                </div>
                <div class="w-full">
                    <label>Status</label>
                    <select v-model='form.status'
                            class="py-2 rounded border border-gray-300 bg-gray-50 text-sm placeholder-gray-400 focus:border-invblue resize-none w-full px-4 mt-1">
                        <option :selected="form.status==1" value="1">Active</option>
                        <option :selected="form.status==0" value="0">Inactive</option>
                    </select>
                </div>
                <div class="w-full">
                    <label>Short Description</label>
                    <textarea v-model='form.description' class="py-2 rounded border border-gray-300 bg-gray-50 text-sm placeholder-gray-400 focus:border-invblue resize-none w-full px-4 mt-1" cols="50" placeholder="Short Description"
                              rows="4"></textarea>
                </div>
                <div class="w-full">
                    <label>Summary</label>
                    <article class="prose" style="max-width: 100%">
                        <ckeditor v-model="form.summary" :config="editorConfig" :editor="editor"/>
                    </article>
                </div>
            </div>

            <button v-if="isSubmitting==false" class="mt-4 justify-center rounded-md bg-blue-600 px-6 py-1.5 text-white hover:bg-blue-500" type="button"
                    @click="updateVisaStream">
                Submit
            </button>
            <span v-else class="mt-4 block">
                <LoadingButton text="Submitting"/>
            </span>
        </div>
    </div>
</template>

<script>
import VisaApi from "@/libs/axios"
import form from 'vuejs-form'
import Breadcrumb from '@/component/Breadcrumb'
import vSelect from "vue-select"
import "vue-select/dist/vue-select.css"
import LoadingButton from "@/component/LoadingButton"
import {sanitizeName,} from '@/libs/helpers.js'
import BackIcon from "../../../data/icons/BackIcon.vue";
import CKEditor from "@ckeditor/ckeditor5-vue"
import {ClassicEditor} from 'ckeditor5';
import ckeditorConfig from "../../../libs/ckeditorConfig";

export default {
    components: {BackIcon, LoadingButton, Breadcrumb, "v-select": vSelect, ckeditor: CKEditor.component},
    data() {
        return {
            breadcrumb: '',
            visa_list: [],
            visa_name: '',
            isSubmitting: false,

            editor: ClassicEditor,
            editorConfig: ckeditorConfig,

            form: form.default({
                id: '',
                uuid: '',
                visa_uuid: '',
                name: '',
                slug: '',
                visa_subclass: '',
                description: '',
                summary: '',
                status: '1',
                sort_order: 0,
            }).rules({
                visa_uuid: 'required',
                name: 'required',
                slug: 'required',
                visa_subclass: 'required',
            }),
        };
    },

    methods: {
        updateVisaStream() {
            if (this.form.validate().errors().any()) {
                return;
            } else {
                this.isSubmitting = true;
                VisaApi.patch("/visa-stream/update/" + this.form.uuid, this.form.all()).then((response) => {
                    if (response.code == '200') {
                        this.$toast.success(response.message, {
                            position: "top-right",
                            dismissible: true,
                        });
                        this.$router.push({name: 'visa-stream-list'})
                    }
                    this.isSubmitting = false;
                }).catch((error) => {
                    this.$toast.error(error.response.data.message, {
                        position: "top-right",
                        dismissible: true,
                    });
                    this.isSubmitting = false;
                });
            }
        },

        async getVisaList() {
            await VisaApi.get("/visa").then((response) => {
                if (response.code == '200') {
                    this.visa_list = response.data;
                }
            });
        },

        async getSingleData(uuid) {
            await VisaApi.get("/visa-stream/" + uuid).then((response) => {
                if (response.code == '200') {
                    let stream = response.data
                    this.form.id = stream.id
                    this.form.uuid = stream.uuid
                    this.form.visa_uuid = stream.visa.uuid
                    this.form.name = stream.name
                    this.form.slug = stream.slug
                    this.form.visa_subclass = stream.visa_subclass
                    this.form.description = stream.description
                    this.form.summary = stream.summary
                    this.form.sort_order = stream.sort_order
                }
            });
        },
    },

    watch: {
        'form.name': function (val) {
            this.form.slug = this.form.visa_subclass + '-' + sanitizeName(val);
        },
        'form.visa_uuid': function (val) {
            let vv = this.visa_list.find((v) => v.uuid == val)
            this.form.visa_subclass = vv.visa_subclass
            this.form.slug = this.form.visa_subclass + (this.form.name != '' ? '-' + sanitizeName(this.form.name) : '');
        }
    },

    mounted() {
        this.breadcrumb = this.$router.currentRoute.value.meta.breadcrumb;
        this.getVisaList();
        let id = this.$route.params.id;
        this.getSingleData(id);
    }
}
</script>
