export const status = [
    {id: 1, name: 'Active'},
    {id: 2, name: 'Inactive'}
]

export const feature = [
    {id: 1, name: 'Yes'},
    {id: 2, name: 'No'}
]

export const getStatus = (_status) => {
    let $status = status.find((st) => st.id == _status)
    if (typeof $status != 'undefined' && $status.id == 1) {
        return '<span class="px-3 py-1 text-sm rounded bg-green-50 text-black border border-green-500">Active</span>';
    } else if (typeof $status != 'undefined' && $status.id == 2) {
        return '<span class="px-3 py-1 text-sm rounded bg-red-50 text-black border border-red-500">In Active</span>';
    } else return 'n/a';
}

export const getFeature = (_feature) => {
    let $feature = feature.find((st) => st.id == _feature)
    if (typeof $feature != 'undefined' && $feature.id == 1) {
        return '<span class="px-3 py-1 text-sm rounded bg-green-50 text-black border border-green-500">Yes</span>';
    }
    return '<span class="px-3 py-1 text-sm rounded bg-red-50 text-black border border-red-500">No</span>';
}
