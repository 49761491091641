<template>
    <!-- Import -->
    <Transition name="fade">
        <div v-if="is_open" id="import_modal" class="relative z-10">
            <div class="fixed inset-0 bg-gray-500 bg-opacity-75"></div>
            <div class="fixed inset-0 z-10 overflow-y-auto">
                <div class="flex min-h-full items-center justify-center text-center sm:items-center sm:p-0">
                    <div id="import_modal_animation"
                         class="relative overflow-hidden rounded-lg bg-white text-left shadow-xl sm:my-8 sm:w-full sm:max-w-lg">
                        <div class="bg-white">
                            <h3 class="px-3 py-3 border-b bg-slate-700 text-white">{{ title }}</h3>
                            <div class="relative p-14 m-4 border-2 border-dashed border-gray-400 rounded-lg cursor-pointer" @dragover="handleDragOver"
                                 @drop="handleDrop">
                                <input ref="fileInput" class="hidden" type="file" @change="handleFileSelected">
                                <div v-if="!file">
                                    <span class="text-gray-600">Drag & Drop your file here or </span>
                                    <button class="mt-2 bg-blue-600 hover:bg-blue-700 text-white text-xs py-1 px-3 rounded"
                                            @click="openFileInput">
                                        Browse
                                    </button>
                                </div>
                                <div v-else>
                                    <p class="font-semibold">{{ file.name }}</p>
                                    <p class="text-sm text-gray-600">{{ formatFileSize(file.size) }}</p>
                                </div>
                                <img v-show="file" alt="cross"
                                     class="w-6 h-6 cursor-pointer absolute top-1 right-1"
                                     src="@/assets/images/cross_red.svg" @click="removeFile">
                            </div>

                            <a v-if="demo_link" :href="demo_link" class="px-3 text-blue-500 flex" target="_blank">
                                {{ demo_link_label }}
                                <download-icon class="mt-1 ml-2"/>
                            </a>
                        </div>
                        <div class="bg-gray-200 px-3 py-1 flex justify-end items-center">
                            <div>
                            <span v-if="is_submitting">
                                <LoadingButton text="Importing"/>
                            </span>
                                <button v-else class="mr-3 justify-center rounded-md bg-blue-600 px-6 py-1.5 text-white hover:bg-blue-700" type="button"
                                        @click="importFile">
                                    Import
                                </button>
                            </div>
                            <button class="inline-flex justify-center rounded-md border border-transparent bg-red-600 px-6 py-1.5 text-white hover:bg-red-700" type="button"
                                    @click="closeModal">
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Transition>
</template>

<script setup>
import {DownloadIcon} from "../../../data/icons";
import LoadingButton from "@/component/LoadingButton"
import {ref, watch} from "vue";
import Toaster from "@meforma/vue-toaster";

const props = defineProps({
    title: {
        required: true
    },
    is_open: {
        required: true
    },
    is_submitting: {
        required: false
    },
    demo_link: {
        required: false
    },
    demo_link_label: {
        required: false,
        default: ""
    }
})

const fileInput = ref(null)
const file = ref()

const emit = defineEmits(["close", "submit"])

const closeModal = () => {
    emit("close")
}

const handleDragOver = (event) => {
    event.preventDefault();
}

const handleDrop = (event) => {
    event.preventDefault();
    file.value = event.dataTransfer.files[0];
}

const handleFileSelected = (e) => {
    file.value = e.target.files[0];
}

const openFileInput = () => {
    fileInput.value.click();
}

const removeFile = () => {
    file.value = '';
}

const formatFileSize = (size) => {
    if (size === 0) return "0 Bytes";
    const k = 1024;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    const i = Math.floor(Math.log(size) / Math.log(k));
    return parseFloat((size / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
}

const importFile = () => {
    emit("submit", file.value)
}

watch(() => props.is_open, (value) => {
    if (!value) removeFile()
})
</script>

<style scoped>

</style>
