<template>
    <div class="px-4 py-2 absolute w-full">
        <div class="flex flex-wrap items-center justify-between pb-2 border-b border-gray-300">
            <Breadcrumb :text="breadcrumb"/>
            <router-link to="/page-list">
                <button class="back-btn" type="button">
                    <back-icon class="mt-1 mr-2"/>
                    Back to list
                </button>
            </router-link>
        </div>

        <div class="visasis-card">
            <p class="w-max px-4 py-1 mb-2 font-bold rounded uppercase bg-gray-300 border-gray-400">
                Page Details
            </p>
            <div class="grid grid-cols-1 gap-2 p-4 w-full rounded bg-white">
                <div class="w-full flex items-center">
                    <label class="font-bold">Title:</label>
                    <p class="pl-2">{{ pageDetails.title }}</p>
                </div>
                <div class="w-full flex items-center">
                    <label class="font-bold">Meta Title:</label>
                    <p class="pl-2">{{ pageDetails.meta_title }}</p>
                </div>
                <div class="w-full flex items-center">
                    <label class="font-bold">Meta Keyword:</label>
                    <p class="pl-2">{{ pageDetails.meta_keyword }}</p>
                </div>
                <div class="w-full flex items-center">
                    <label class="font-bold">Status:</label>
                    <p v-if="pageDetails.is_active==1" class="pl-2">Active</p>
                    <p v-else class="pl-2">Inactive</p>
                </div>
                <div class="w-full">
                    <label class="font-bold">Meta Description:</label>
                    <p>{{ pageDetails.meta_description }}</p>
                </div>
            </div>
        </div>

        <!-- page content -->
        <page-content-list :page_id="page_id"/>

        <!-- modal -->
        <Modal :modal_title='modal_title' @close="openModal(false)" @submit="attachContent">
            <template v-slot:body>
                <div class="mt-2 px-2 mb-36">
                    <div class="w-full">
                        <label class="flex items-center">Select Page Content <img alt="required"
                                                                                  class="w-2 h-2 ml-1"
                                                                                  src="@/assets/images/star.svg"> </label>
                        <v-select
                            v-model="page_content_selected"
                            :options="page_content_list"
                            class="pt-1"
                            label="title"
                            placeholder="Choose"
                        />
                        <span v-if='form.errors().has("page_content_id")' class="text-red-800 text-xs">
                            {{ form.errors().get('page_content_id') }}
                        </span>
                    </div>
                    <div class="w-full mt-4">
                        <label class="flex items-center">Sort Order <img alt="required"
                                                                         class="w-2 h-2 ml-1" src="@/assets/images/star.svg">
                        </label>
                        <input v-model='form.sort_order' class="py-2 rounded border border-gray-300 bg-gray-50 text-sm placeholder-gray-400 focus:border-invblue resize-none w-full px-4 mt-1" placeholder="Sort order"
                               type='text'>
                        <span v-if='form.errors().has("sort_order")' class="text-red-800 text-xs">
                            {{ form.errors().get('sort_order') }}
                        </span>
                    </div>
                </div>
            </template>
        </Modal>

        <!-- delete modal -->
        <DeleteModal
            :isDeleteSubmitting="isDeleteSubmitting"
            :is_open="is_open_delete_modal"
            modal_title='Detach page content'
            @close="closeDeleteModal"
            @submit="detachPageContent"
        />

    </div>
</template>

<script>
import VisaApi from "@/libs/axios"
import Breadcrumb from '@/component/Breadcrumb'
import Modal from '@/component/Modal'
import DeleteModal from '@/component/DeleteModal'
import PageContentList from "./PageContentList.vue";
import form from 'vuejs-form'
import vSelect from "vue-select"
import "vue-select/dist/vue-select.css"
import BackIcon from "../../../data/icons/BackIcon.vue";

export default {
    components: {BackIcon, Breadcrumb, Modal, DeleteModal, PageContentList, "v-select": vSelect,},
    data() {
        return {
            breadcrumb: '',
            pageDetails: {},
            page_contents: [],
            modal_title: '',
            page_content_list: [],
            page_content_selected: '',
            isDeleteSubmitting: false,
            is_open_delete_modal: false,
            page_id: '',
            deletable_content_id: '',

            form: form.default({
                page_id: '',
                page_content_id: '',
                sort_order: '',
            }).rules({
                page_content_id: 'required',
                sort_order: 'required',
            }),
        };
    },

    methods: {
        openModal(value) {
            this.form.errors().forget();
            const modalCls = document.getElementById('modal').classList;
            const modalAnimCls = document.getElementById('modal_animation').classList;
            if (value == true) {
                modalCls.remove('hidden');
                modalAnimCls.add('open-modal-animation');

                this.form.page_content_id = '';
                this.form.sort_order = '';
                this.page_content_selected = '';

                this.modal_title = 'Attach page content';
            } else {
                modalCls.add('hidden');
                modalAnimCls.remove('open-modal-animation');
            }
        },

        getSingleData(id) {
            VisaApi.get("/frontend/pages/" + id).then((response) => {
                this.pageDetails = response.data;
                this.page_contents = response.data.page_contents;
                this.form.page_id = response.data.id;
            });
        },

        attachContent() {
            if (this.form.validate().errors().any()) {
                return;
            } else {
                VisaApi.post("/frontend/page/attach-page-content", this.form.all()).then((response) => {
                    this.$toast.success(response.message, {
                        position: "top-right",
                        dismissible: true,
                    });
                    this.page_content_selected = '';
                    this.form.page_content_id = '';
                    this.form.sort_order = '';
                    this.getSingleData(this.form.page_id);
                    this.openModal(false);
                }).catch((error) => {
                    this.$toast.error(error.response.data.message, {
                        position: "top-right",
                        dismissible: true,
                    });
                    this.openModal(false);
                });
            }
        },

        detachPageContent() {
            this.isDeleteSubmitting = true;
            VisaApi.post("/frontend/page/detach-page-content", {
                page_id: this.form.page_id,
                page_content_id: this.deletable_content_id
            }).then((response) => {
                this.$toast.success(response.message, {
                    position: "top-right",
                    dismissible: true,
                });
                this.closeDeleteModal();
                this.getSingleData(this.form.page_id);
            }).catch((error) => {
                this.$toast.error(error.response.data.message, {
                    position: "top-right",
                    dismissible: true,
                });
                this.closeDeleteModal();
            });
        },

        openDeleteModal(id) {
            const modalCls = document.getElementById('delete_modal').classList;
            const modalAnimCls = document.getElementById('delete_modal_animation').classList;
            this.deletable_content_id = id;
            modalCls.remove('hidden');
            modalAnimCls.add('delete-modal-animation');
        },

        closeDeleteModal() {
            const modalCls = document.getElementById('delete_modal').classList;
            const modalAnimCls = document.getElementById('delete_modal_animation').classList;
            modalCls.add('hidden');
            modalAnimCls.remove('delete-modal-animation');
            this.deletable_content_id = '';
            this.isDeleteSubmitting = false;
        },

        async getPageContentList() {
            await VisaApi.get("/frontend/page-contents").then((response) => {
                this.page_content_list = response.data;
            });
        },
    },

    watch: {
        page_content_selected(value) {
            if (value != null) {
                this.form.page_content_id = value.id;
            }
        }
    },

    mounted() {
        this.breadcrumb = this.$router.currentRoute.value.meta.breadcrumb;
        this.page_id = this.$route.params.id
        this.getSingleData(this.$route.params.id);
        this.getPageContentList();
    }
}
</script>
