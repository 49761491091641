<template>
    <div class="px-4 py-2 absolute w-full">

        <Breadcrumb :text="breadcrumb"/>

        <div class="visasis-card">

            <div class="flex flex-wrap items-center -mx-4">
                <TableEntryShow @input="[meta.current_page = 1, meta.per_page = $event]"/>
                <TableTopActions :is_modal="true" @create="openModal(true, 'create')"
                                 @input="[meta.current_page = 1, search_input = $event]"/>
            </div>

            <vue-good-table
                :columns="columns"
                :rows="rows"
            >
                <template #table-row="props">
                    <div v-if="props.column.field == 'stream_name'">
                        {{ props.row.visa_subclass }} - {{ props.row.stream_name }}
                    </div>
                    <div v-if="props.column.field == 'lin_sol_code'">
                        {{ props.row.lin_sol_code.join() }}
                    </div>
                    <div v-if="props.column.field == 'action'">
                        <div class="px-3 py-2 flex items-center justify-center">
                            <update-icon @click="updateLinStream(props.row)"/>
                            <delete-icon @click="openDeleteModal(props.row)"/>
                        </div>
                    </div>
                </template>
            </vue-good-table>

            <TablePagination
                :inputPage="currentPage"
                :meta='meta'
                :next_of_next='next_of_next'
                :prev_of_prev='prev_of_prev'
                @update:inputPage="updatePageValue"
            />
        </div>

        <!-- modal -->
        <Modal :isSubmitting="isModalSubmitting" :modal_title='mdl_title' @close="openModal(false, 'create')"
               @submit="handleSubmitMethod">
            <template v-slot:body>
                <div class="grid grid-cols-1 md:grid-cols-2 gap-x-4 gap-y-2 mt-2 px-2">
                    <div class="w-full">
                        <label class="flex items-center">Select stream <img alt="required"
                                                                            class="w-2 h-2 ml-1"
                                                                            src="@/assets/images/star.svg"> </label>
                        <v-select
                            v-model="form.visa_stream_uuid"
                            :get-option-label="(option) => option.visa_subclass + ' - ' + option.name"
                            :options="stream_list"
                            :reduce="stream => stream.uuid"
                            class="pt-1"
                            placeholder="Choose stream..."
                        />
                        <span v-if='form.errors().has("visa_stream_uuid")' class="text-red-800 text-xs">
                            {{ form.errors().get('visa_stream_uuid') }}
                        </span>
                    </div>

                    <div class="w-full">
                        <label class="flex items-center">Select Lin <img alt="required"
                                                                         class="w-2 h-2 ml-1" src="@/assets/images/star.svg">
                        </label>
                        <v-select
                            v-model="form.lin_uuid"
                            :get-option-label="(option) => option.number"
                            :options="lin_list"
                            :reduce="lin => lin.uuid"
                            class="pt-1"
                            placeholder="Choose lin..."
                        />
                        <span v-if='form.errors().has("lin_uuid")' class="text-red-800 text-xs">
                            {{ form.errors().get('lin_uuid') }}
                        </span>
                    </div>

                    <div class="w-full">
                        <label class="flex items-center">Lin sol code <img alt="required"
                                                                           class="w-2 h-2 ml-1"
                                                                           src="@/assets/images/star.svg"> </label>
                        <v-select
                            v-model="form.lin_sol_code"
                            :options="lin_sol_list"
                            :reduce="sol => sol.code"
                            class="pt-1"
                            label="code"
                            multiple
                            placeholder="Choose lin sol code..."
                        />
                        <span v-if='form.errors().has("lin_sol_code")' class="text-red-800 text-xs">
                            {{ form.errors().get('lin_sol_code') }}
                        </span>
                    </div>
                </div>
            </template>
        </Modal>

        <!-- delete modal -->
        <DeleteModal
            :isDeleteSubmitting="isDeleteSubmitting"
            :is_open="is_open_delete_modal"
            modal_title='Delete Visa Stream'
            @close="closeDeleteModal"
            @submit="deleteVisaStream"
        />

    </div>
</template>

<script>
import VisaApi from "@/libs/axios"
import Modal from '@/component/Modal'
import DeleteModal from '@/component/DeleteModal'
import vSelect from "vue-select"
import "vue-select/dist/vue-select.css"
import store from '@/store'
import Breadcrumb from '@/component/Breadcrumb'
import TablePagination from '@/component/TablePagination'
import {VueGoodTable} from 'vue-good-table-next'
import 'vue-good-table-next/dist/vue-good-table-next.css'
import {meta_data, next_of_next_status, prev_of_prev_status} from '@/libs/helpers.js'
import AddIcon from "../../../data/icons/AddIcon.vue";
import ViewIcon from "../../../data/icons/ViewIcon.vue";
import UpdateIcon from "../../../data/icons/UpdateIcon.vue";
import DeleteIcon from "../../../data/icons/DeleteIcon.vue";
import TableEntryShow from "../../../component/table/TableEntryShow.vue";
import TableTopActions from "../../../component/table/TableTopActions.vue";
import form from "vuejs-form";
import {lin_sol_list} from "../../../data/type";

export default {
    components: {
        "v-select": vSelect,
        TableTopActions,
        TableEntryShow,
        DeleteIcon,
        UpdateIcon,
        ViewIcon,
        AddIcon,
        Modal,
        DeleteModal,
        VueGoodTable,
        Breadcrumb,
        TablePagination
    },
    data() {
        return {
            mdl_title: 'Create Visa Fees',
            modal_mode: 'create',
            isModalSubmitting: false,
            deletable_data: null,
            is_open_modal: false,
            is_open_delete_modal: false,
            lin_sol_list: lin_sol_list,
            tableData: [],
            meta: meta_data,
            prev_of_prev: false,
            next_of_next: false,
            per_page_array: [10, 20, 30, 40, 50],
            currentPage: '',

            columns: [
                {label: 'Visa stream', field: 'stream_name'},
                {label: 'Lin Sol Code', field: 'lin_sol_code'},
                {label: 'Lin Number', field: 'number'},
                {label: 'Action', field: 'action', sortable: false, width: '100px'}
            ],
            rows: [],
            stream_list: [],
            lin_list: [],

            form: form.default({
                visa_stream_uuid: '',
                lin_uuid: '',
                lin_sol_code: [],
            }).rules({
                visa_stream_uuid: 'required',
                lin_uuid: 'required',
                lin_sol_code: 'required',
            }),
            search_input: '',
            breadcrumb: '',
            sort_status: false,
            isDeleteSubmitting: false,
        };
    },

    methods: {
        openModal(value, mode) {
            this.form.errors().forget();
            const modalCls = document.getElementById('modal').classList;
            const modalAnimCls = document.getElementById('modal_animation').classList;
            if (value == true && mode == 'create') {
                modalCls.remove('hidden');
                modalAnimCls.add('open-modal-animation');

                this.form.visa_stream_uuid = '';
                this.form.lin_uuid = '';
                this.form.lin_sol_code = [];

                this.modal_mode = 'create';
                this.mdl_title = 'Create Lin Visa Stream';
            } else if (value == true && mode == 'update') {
                modalCls.remove('hidden');
                modalAnimCls.add('open-modal-animation');
            } else {
                modalCls.add('hidden');
                modalAnimCls.remove('open-modal-animation');
                this.isModalSubmitting = false;
            }
        },

        updateLinStream(data) {
            this.form.visa_stream_uuid = data.stream_uuid;
            this.form.lin_uuid = data.lin_uuid;
            this.form.lin_sol_code = data.lin_sol_code;
            this.modal_mode = 'update';
            this.mdl_title = 'Update Lin Visa Stream';
            this.openModal(true, 'update')
        },

        async getVisaStreamList() {
            store.commit('setting/LOADING_ON')
            await VisaApi.get(this.apiUrl).then((response) => {
                store.commit('setting/LOADING_OFF')
                if (response.code == '200') {
                    this.rows = response.data.data;
                    Object.assign(this.meta, response.data.meta);
                }
            });
        },

        //stream list
        async getStreamList() {
            await VisaApi.get("/visa-stream").then((response) => {
                if (response.code == '200') {
                    this.stream_list = response.data;
                }
            });
        },

        //lin list
        async getLinList() {
            await VisaApi.get("/lin").then((response) => {
                if (response.code == '200') {
                    this.lin_list = response.data;
                }
            })
        },

        handleSubmitMethod() {
            const modalCls = document.getElementById('modal').classList;
            VisaApi.post("/visa-stream/attach/lin", this.form.all()).then((response) => {
                if (response.code == '200') {
                    this.getVisaStreamList();
                    this.$toast.success(response.message, {
                        position: "top-right",
                        dismissible: true,
                    });
                }
                modalCls.add('hidden');
            }).catch((error) => {
                this.$toast.error(error.response.data.message, {
                    position: "top-right",
                    dismissible: true,
                });
            });
        },

        openDeleteModal(data) {
            this.deletable_data = data;
            this.is_open_delete_modal = true;
        },

        closeDeleteModal() {
            this.isDeleteSubmitting = false;
            this.is_open_delete_modal = false;
        },

        deleteVisaStream() {
            this.isDeleteSubmitting = true;
            VisaApi.post("/visa-stream/detach/lin", {
                'visa_stream_uuid': this.deletable_data.stream_uuid,
                'lin_uuid': this.deletable_data.lin_uuid,
                'lin_sol_code': this.deletable_data.lin_sol_code,
            }).then((response) => {
                if (response.code == '200') {
                    this.getVisaStreamList();
                    this.$toast.success(response.message, {
                        position: "top-right",
                        dismissible: true,
                    });
                    this.closeDeleteModal();
                }
            }).catch((error) => {
                this.$toast.error(error.response.data.message, {
                    position: "top-right",
                    dismissible: true,
                });
                this.closeDeleteModal();
            });
        },

        updatePageValue(newValue) {
            this.currentPage = newValue;
        },
    },

    computed: {
        apiUrl() {
            return 'visa-stream/lin/list?page=' + this.meta.current_page + '&per_page=' + this.meta.per_page + '&order_by=id&order_by_type=desc&search=' + this.search_input;
        },
        nextOfNext() {
            this.next_of_next = next_of_next_status(this.meta);
        },
        prevOfPrev() {
            this.prev_of_prev = prev_of_prev_status(this.meta);
        },
    },

    watch: {
        apiUrl() {
            this.getVisaStreamList();
        },
        nextOfNext() {
        },
        prevOfPrev() {
        },
    },

    mounted() {
        this.getVisaStreamList();
        this.breadcrumb = this.$router.currentRoute.value.meta.breadcrumb;
        this.getStreamList();
        this.getLinList();
    }
}
</script>

<style>

</style>
