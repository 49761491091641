<template>
    <div class="px-4 py-2 absolute w-full">

        <Breadcrumb :text="breadcrumb"/>

        <div class="visasis-card">
            <div class="flex flex-wrap items-center -mx-4">
                <TableEntryShow @input="[meta.current_page = 1, meta.per_page = $event]"/>
                <TableTopActions :is_modal="true" @create="openModal(true, 'create')"
                                 @input="[meta.current_page = 1, search_input = $event]"/>
            </div>

            <vue-good-table
                :columns="columns"
                :rows="rows"
            >
                <template #table-row="props">
                    <div v-if="props.column.field == 'lin'">
                        <span v-if="props.row.lin!=null"> {{ props.row.lin.name }} </span>
                    </div>
                    <div v-if="props.column.field == 'note'" class="whitespace-nowrap">
                        <span v-if="props.row.note!=null && props.row.note.length >= 30"
                              class="whitespace-nowrap"> {{ props.row.note.slice(0, 30) }} ... </span>
                        <span v-else class="whitespace-nowrap"> {{ props.row.note }} </span>
                    </div>
                    <div v-if="props.column.field == 'status'">
                        <p v-if="props.row.status==1" class="w-min px-2 rounded m-auto text-white bg-green-700">
                            Active</p>
                        <p v-else class="w-min px-2 rounded m-auto text-white bg-orange-700">Inactive</p>
                    </div>
                    <div v-if="props.column.field == 'action'">
                        <div class="px-3 py-2 flex items-center justify-center">
                            <router-link :to="{ name: 'caveat-view', params: {id:props.row.uuid} }">
                                <view-icon/>
                            </router-link>
                            <update-icon @click="getSingleCaveat(props.row.uuid)"/>
                            <delete-icon @click="openDeleteModal(props.row.uuid)"/>
                        </div>
                    </div>
                </template>
            </vue-good-table>

            <TablePagination
                :inputPage="currentPage"
                :meta='meta'
                :next_of_next='next_of_next'
                :prev_of_prev='prev_of_prev'
                @update:inputPage="updatePageValue"
            />
        </div>

        <!-- modal -->
        <Modal :isSubmitting="isModalSubmitting" :modal_title='mdl_title' @close="openModal(false, 'create')"
               @submit="handleSubmitMethod">
            <template v-slot:body>
                <div class="grid grid-cols-1 md:grid-cols-2 gap-x-4 gap-y-2 mt-2 px-2">
                    <div class="w-full">
                        <label class="flex items-center">Select Lin <img alt="required"
                                                                         class="w-2 h-2 ml-1" src="@/assets/images/star.svg">
                        </label>
                        <v-select
                            v-model="selected_lin"
                            :options="lin_list"
                            class="pt-1"
                            label="number"
                            placeholder="Choose lin..."
                        />
                        <span v-if='form.errors().has("lin_uuid")' class="text-red-800 text-xs">
                            {{ form.errors().get('lin_uuid') }}
                        </span>
                    </div>
                    <div class="w-full">
                        <label>Status</label>
                        <v-select
                            v-model="selectedStatus"
                            :options="statusList"
                            class="pt-1"
                            label="title"
                            placeholder="Choose"
                        />
                    </div>
                    <div class="w-full">
                        <label class="flex items-center">Caveat No <img alt="required"
                                                                        class="w-2 h-2 ml-1" src="@/assets/images/star.svg">
                        </label>
                        <input v-model='form.caveat_no' class="py-2 rounded border border-gray-300 bg-gray-50 text-sm placeholder-gray-400 focus:border-invblue resize-none w-full px-4 mt-1" placeholder="Caveat No"
                               type='text'>
                        <span v-if='form.errors().has("caveat_no")' class="text-red-800 text-xs">
                            {{ form.errors().get('caveat_no') }}
                        </span>
                    </div>
                    <div class="w-full">
                        <label class="flex items-center">Caveat Note <img alt="required"
                                                                          class="w-2 h-2 ml-1" src="@/assets/images/star.svg">
                        </label>
                        <textarea v-model='form.caveat_note' class="p-2 rounded border border-gray-300 bg-gray-50 text-sm placeholder-gray-400 focus:border-invblue resize-none w-full mt-1" cols="50" placeholder="Note"
                                  rows="4"></textarea>
                        <span v-if='form.errors().has("caveat_note")' class="text-red-800 text-xs">
                            {{ form.errors().get('caveat_note') }}
                        </span>
                    </div>
                </div>
            </template>
        </Modal>

        <!-- delete modal -->
        <DeleteModal
            :isDeleteSubmitting="isDeleteSubmitting"
            :is_open="is_open_delete_modal"
            modal_title='Delete Caveat'
            @close="closeDeleteModal"
            @submit="deleteCaveat"
        />

    </div>
</template>

<script>
import VisaApi from "@/libs/axios"
import form from 'vuejs-form'
import store from '@/store'
import Modal from '@/component/Modal'
import DeleteModal from '@/component/DeleteModal'
import Breadcrumb from '@/component/Breadcrumb'
import vSelect from "vue-select"
import "vue-select/dist/vue-select.css"
import {VueGoodTable} from 'vue-good-table-next'
import 'vue-good-table-next/dist/vue-good-table-next.css'
import TablePagination from '@/component/TablePagination'
import {meta_data, next_of_next_status, prev_of_prev_status} from '@/libs/helpers.js'
import AddIcon from "../../data/icons/AddIcon.vue";
import ViewIcon from "../../data/icons/ViewIcon.vue";
import UpdateIcon from "../../data/icons/UpdateIcon.vue";
import DeleteIcon from "../../data/icons/DeleteIcon.vue";
import TableEntryShow from "../../component/table/TableEntryShow.vue";
import TableTopActions from "../../component/table/TableTopActions.vue";

export default {
    components: {
        TableTopActions, TableEntryShow,
        DeleteIcon,
        UpdateIcon,
        ViewIcon, AddIcon, Modal, DeleteModal, VueGoodTable, Breadcrumb, "v-select": vSelect, TablePagination
    },
    data() {
        return {
            mdl_title: 'Create Caveat',
            modal_mode: 'create',
            uuid_deletable: '',
            lin_list: [],
            selected_lin: '',

            meta: meta_data,
            prev_of_prev: false,
            next_of_next: false,
            currentPage: '',

            columns: [
                {label: 'Lin Name', field: 'lin'},
                {label: 'Caveat No', field: 'caveat_no'},
                {label: 'Caveat Note', field: 'caveat_note', width: '60%'},
                {label: 'Status', field: 'status', width: '100px'},
                {label: 'Action', field: 'action', sortable: false, width: '100px'}
            ],
            rows: [],

            search_input: '',
            breadcrumb: '',
            url: '',
            sort_status: false,
            is_open_delete_modal: false,
            isDeleteSubmitting: false,
            isModalSubmitting: false,
            selectedStatus: [{id: 1, title: 'Current'}],
            statusList: [
                {id: 1, title: 'Current'},
                {id: 2, title: 'Suspended'},
                {id: 3, title: 'Cancelled'},
            ],

            form: form.default({
                id: '',
                uuid: '',
                lin_uuid: '',
                caveat_no: '',
                caveat_note: '',
                status: '1',
            }).rules({
                lin_uuid: 'required',
                caveat_no: 'required',
                caveat_note: 'required',
            }),
        };
    },

    methods: {
        openModal(value, mode) {
            this.form.errors().forget();
            const modalCls = document.getElementById('modal').classList;
            const modalAnimCls = document.getElementById('modal_animation').classList;
            if (value == true && mode == 'create') {
                modalCls.remove('hidden');
                modalAnimCls.add('open-modal-animation');

                this.form.id = '';
                this.form.uuid = '';
                this.form.lin_uuid = '';
                this.form.caveat_no = '';
                this.form.caveat_note = '';
                this.form.status = '1';

                this.modal_mode = 'create';
                this.mdl_title = 'Create Caveat';
            } else if (value == true && mode == 'update') {
                modalCls.remove('hidden');
                modalAnimCls.add('open-modal-animation');
            } else {
                modalCls.add('hidden');
                modalAnimCls.remove('open-modal-animation');
                this.isModalSubmitting = false;
            }
        },

        createCaveat() {
            if (this.form.validate().errors().any()) {
                return;
            } else {
                this.isModalSubmitting = true;
                VisaApi.post("/caveat/create", this.form.all()).then((response) => {
                    if (response.code == '201') {
                        this.getCaveatList();
                        this.$toast.success(response.message, {
                            position: "top-right",
                            dismissible: true,
                        });
                        this.openModal(false, 'create');
                    }
                }).catch((error) => {
                    this.$toast.error(error.response.data.message, {
                        position: "top-right",
                        dismissible: true,
                    });
                    this.openModal(false, 'create');
                });
            }
            ;
        },

        async getCaveatList() {
            store.commit('setting/LOADING_ON')
            await VisaApi.get(this.apiUrl).then((response) => {
                store.commit('setting/LOADING_OFF')
                if (response.code == '200') {
                    this.rows = response.data.data;
                    Object.assign(this.meta, response.data.meta);
                }
            });
        },

        async getSingleCaveat(uuid) {
            await VisaApi.get("/caveat/" + uuid).then((response) => {
                if (response.code == '200') {
                    Object.assign(this.form, response.data);

                    let tmp = this.lin_list.filter(item => item.id == response.data.lin_id);
                    this.selected_lin = tmp[0];

                    let tmpStatus = this.statusList.filter(item => item.id == response.data.status);
                    this.selectedStatus = tmpStatus[0];

                    this.mdl_title = 'Update Caveat';
                    this.modal_mode = 'update';
                    this.openModal(true, 'update');
                }
            });
        },

        updateCaveat() {
            if (this.form.validate().errors().any()) {
                return;
            } else {
                this.isModalSubmitting = true;
                VisaApi.patch("/caveat/update/" + this.form.uuid, this.form.all()).then((response) => {
                    if (response.code == '200') {
                        this.openModal(false, 'update');
                        let element = this.rows.filter(item => item.id == this.form.id)
                        Object.assign(element[0], response.data)
                        this.$toast.success(response.message, {
                            position: "top-right",
                            dismissible: true,
                        });
                    }
                }).catch((error) => {
                    this.$toast.error(error.response.data.message, {
                        position: "top-right",
                        dismissible: true,
                    });
                    this.openModal(false, 'update');
                });
            }
        },

        openDeleteModal(uuid) {
            this.is_open_delete_modal = true
            this.uuid_deletable = uuid;
        },

        closeDeleteModal() {
            this.is_open_delete_modal = false
            this.isDeleteSubmitting = false;
        },

        deleteCaveat() {
            this.isDeleteSubmitting = true;
            VisaApi.delete("/caveat/" + this.uuid_deletable).then((response) => {
                if (response.code == '200') {
                    this.getCaveatList();
                    this.$toast.success(response.message, {
                        position: "top-right",
                        dismissible: true,
                    });
                    this.closeDeleteModal();
                }
            }).catch((error) => {
                this.$toast.error(error.response.data.message, {
                    position: "top-right",
                    dismissible: true,
                });
                this.closeDeleteModal();
            });
        },

        handleSubmitMethod() {
            if (this.modal_mode == 'create') {
                this.createCaveat();
            } else {
                this.updateCaveat();
            }
        },

        async getLinList() {
            await VisaApi.get('/lin').then((response) => {
                if (response.code == '200') {
                    this.lin_list = response.data;
                }
            });
        },

        updatePageValue(newValue) {
            this.currentPage = newValue;
        },
    },

    computed: {
        apiUrl() {
            return 'caveat?page=' + this.meta.current_page + '&per_page=' + this.meta.per_page + '&order_by=status&order_by_type=desc&search=' + this.search_input;
        },
        nextOfNext() {
            this.next_of_next = next_of_next_status(this.meta);
        },
        prevOfPrev() {
            this.prev_of_prev = prev_of_prev_status(this.meta);
        },
    },

    watch: {
        apiUrl() {
            this.getCaveatList();
        },

        selected_lin(value) {
            if (value != null) {
                this.form.lin_uuid = value.uuid;
            }
        },

        selectedStatus(value) {
            if (value != null) {
                this.form.status = value.id;
            }
        },

        nextOfNext() {
        },
        prevOfPrev() {
        },
    },

    async mounted() {
        this.getCaveatList();
        this.getLinList();
        this.breadcrumb = this.$router.currentRoute.value.meta.breadcrumb;
    }
}
</script>
