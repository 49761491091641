<template>
    <div class="px-4 py-2 absolute w-full">

        <div class="flex flex-wrap items-center justify-between pb-2">
            <Breadcrumb :text="breadcrumb"/>
            <router-link to="/lin-list">
                <button class="back-btn" type="button">
                    <back-icon class="mt-1 mr-2"/>
                    Back to list
                </button>
            </router-link>
        </div>

        <div class="visasis-card">
            <div class="grid grid-cols-1 md:grid-cols-2 gap-x-4 gap-y-2">
                <div class="w-full">
                    <label class="flex items-center">Name <img alt="required" class="w-2 h-2 ml-1"
                                                               src="@/assets/images/star.svg"> </label>
                    <input v-model='form.name' class="py-2 rounded border border-gray-300 bg-gray-50 text-sm placeholder-gray-400 focus:border-invblue resize-none w-full px-4 mt-1" placeholder="Name"
                           type='text'>
                    <span v-if='form.errors().has("name")' class="text-red-800 text-xs">
                        {{ form.errors().get('name') }}
                    </span>
                </div>
                <div class="w-full">
                    <label class="flex items-center">Slug <img alt="required" class="w-2 h-2 ml-1"
                                                               src="@/assets/images/star.svg"> </label>
                    <input v-model='form.slug' class="py-2 rounded border border-gray-300 bg-gray-50 text-sm placeholder-gray-400 focus:border-invblue resize-none w-full px-4 mt-1" placeholder="Slug"
                           type='text'>
                    <span v-if='form.errors().has("slug")' class="text-red-800 text-xs">
                        {{ form.errors().get('slug') }}
                    </span>
                </div>
                <div class="w-full">
                    <label class="flex items-center">Number <img alt="required" class="w-2 h-2 ml-1"
                                                                 src="@/assets/images/star.svg"> </label>
                    <input v-model='form.number' class="py-2 rounded border border-gray-300 bg-gray-50 text-sm placeholder-gray-400 focus:border-invblue resize-none w-full px-4 mt-1" placeholder="Number"
                           type='text'>
                    <span v-if='form.errors().has("number")' class="text-red-800 text-xs">
                        {{ form.errors().get('number') }}
                    </span>
                </div>
                <div class="w-full">
                    <label>Superseded By</label>
                    <input v-model='form.superseded_by' class="py-2 rounded border border-gray-300 bg-gray-50 text-sm placeholder-gray-400 focus:border-invblue resize-none w-full px-4 mt-1"
                           type='text'>
                </div>
                <div class="w-full">
                    <label class="flex items-center">Version Date <img alt="required"
                                                                       class="w-2 h-2 ml-1" src="@/assets/images/star.svg">
                    </label>
                    <input v-model='form.start_date' class="py-2 rounded border border-gray-300 bg-gray-50 text-sm placeholder-gray-400 focus:border-invblue resize-none w-full px-4 mt-1"
                           type='date'>
                    <span v-if='form.errors().has("start_date")' class="text-red-800 text-xs">
                        {{ form.errors().get('start_date') }}
                    </span>
                </div>
                <div class="w-full">
                    <label>End Date</label>
                    <input v-model='form.end_date' class="py-2 rounded border border-gray-300 bg-gray-50 text-sm placeholder-gray-400 focus:border-invblue resize-none w-full px-4 mt-1"
                           type='date'>
                </div>
                <div class="w-full">
                    <label>Description</label>
                    <textarea v-model='form.description' class="py-2 rounded border border-gray-300 bg-gray-50 text-sm placeholder-gray-400 focus:border-invblue resize-none w-full px-4 mt-1" cols="50" placeholder="Description"
                              rows="4"></textarea>
                </div>
                <div class="w-full">
                    <label>Status</label>
                    <v-select
                        v-model="form.status"
                        :options="statusList"
                        :reduce="status => status.id"
                        class="pt-1"
                        label="title"
                        placeholder="Choose"
                    />
                </div>
            </div>

            <button v-if="isSubmitting==false" class="mt-4 justify-center rounded-md bg-blue-600 px-6 py-1.5 text-white hover:bg-blue-500" type="button"
                    @click="createLin">
                Submit
            </button>
            <span v-else class="mt-4 block">
                <LoadingButton text="Submitting"/>
            </span>

        </div>
    </div>
</template>

<script>
import VisaApi from "@/libs/axios"
import form from 'vuejs-form'
import Breadcrumb from '@/component/Breadcrumb'
import vSelect from "vue-select"
import "vue-select/dist/vue-select.css"
import LoadingButton from "@/component/LoadingButton"
import {sanitizeName,} from '@/libs/helpers.js'
import BackIcon from "../../data/icons/BackIcon.vue";

export default {
    components: {BackIcon, LoadingButton, Breadcrumb, "v-select": vSelect,},
    data() {
        return {
            breadcrumb: '',
            isSubmitting: false,
            statusList: [
                {id: 1, title: 'Active'},
                {id: 2, title: 'In Active'}
            ],

            form: form.default({
                id: '',
                uuid: '',
                name: '',
                slug: '',
                number: '',
                start_date: '',
                end_date: '',
                description: '',
                superseded_by: '',
                status: 1,
            }).rules({
                name: 'required',
                slug: 'required',
                number: 'required',
                start_date: 'required',
            }),
        };
    },

    methods: {
        createLin() {
            if (this.form.validate().errors().any()) {
                return;
            } else {
                this.isSubmitting = true;
                VisaApi.post("/lin/create", this.form.all()).then((response) => {
                    this.$toast.success(response.message, {
                        position: "top-right",
                        dismissible: true,
                    });
                    this.$router.push({name: 'lin-list'})
                    this.isSubmitting = false;
                }).catch((error) => {
                    this.$toast.error(error.response.data.message, {
                        position: "top-right",
                        dismissible: true,
                    });
                    this.isSubmitting = false;
                });
            }
            ;
        },
    },

    computed: {
        slug() {
            return this.form.slug = sanitizeName(this.form.name);
        },
    },

    watch: {
        slug() {
        },
    },

    mounted() {
        this.breadcrumb = this.$router.currentRoute.value.meta.breadcrumb;
    }
}
</script>
