<template>
    <div class="p-4">
        <div class="flex flex-wrap items-center justify-between pb-4 border-b border-gray-300">
            <Breadcrumb :text="breadcrumb"/>
            <router-link to="/occupation-classification-list">
                <button class="rounded-md bg-orange-600 px-6 py-1.5 text-white hover:bg-orange-500" type="button">
                    Back to list
                </button>
            </router-link>
        </div>

        <div class="p-2 mt-1 h-full rounded bg-slate-100">
            <div class="grid grid-cols-1 md:grid-cols-2 gap-x-4 gap-y-2">
                <div class="w-full">
                    <label>ANZSCO Code</label>
                    <input v-model='form.anzsco_code'
                           class="py-2 rounded border border-gray-300 bg-gray-50 text-sm placeholder-gray-400 focus:border-invblue resize-none w-full px-4 mt-1"
                           placeholder="Anzsco code" type='text'>
                </div>
                <div class="w-full">
                    <label>Type</label>
                    <select v-model='form.type'
                            class="py-2 rounded border border-gray-300 bg-gray-50 text-sm placeholder-gray-400 focus:border-invblue resize-none w-full px-4 mt-1">
                        <option :selected="form.type=='alt'" value="alt">Alternative</option>
                        <option :selected="form.type=='spe'" value="spe">Specialization</option>
                    </select>
                </div>
                <div class="w-full">
                    <label class="flex items-center"> Title <img alt="required" class="w-2 h-2 ml-1"
                                                                 src="@/assets/images/star.svg"> </label>
                    <input v-model='form.title'
                           class="py-2 rounded border border-gray-300 bg-gray-50 text-sm placeholder-gray-400 focus:border-invblue resize-none w-full px-4 mt-1"
                           placeholder="Title" type='text'>
                    <span v-if='form.errors().has("title")' class="text-red-800 text-xs">
                        {{ form.errors().get('title') }}
                    </span>
                </div>
                <div class="w-full">
                    <label>Status</label>
                    <select v-model='form.status'
                            class="py-2 rounded border border-gray-300 bg-gray-50 text-sm placeholder-gray-400 focus:border-invblue resize-none w-full px-4 mt-1">
                        <option :selected="form.status==1" value="1">Active</option>
                        <option :selected="form.status==0" value="0">Inactive</option>
                    </select>
                </div>
                <div class="w-full">
                    <label>Description</label>
                    <textarea v-model='form.description' class="py-2 rounded border border-gray-300 bg-gray-50 text-sm placeholder-gray-400 focus:border-invblue resize-none w-full px-4 mt-1" cols="50" placeholder="Note"
                              rows="4"></textarea>
                </div>
            </div>

            <button v-if="isSubmitting==false" class="mt-4 justify-center rounded-md bg-blue-600 px-6 py-1.5 text-white hover:bg-blue-500" type="button"
                    @click="updateClassification">
                Submit
            </button>
            <span v-else class="mt-4 block">
                <LoadingButton text="Submitting"/>
            </span>

        </div>
    </div>
</template>

<script>
import VisaApi from "@/libs/axios"
import form from 'vuejs-form'
import Breadcrumb from '@/component/Breadcrumb'
import LoadingButton from "@/component/LoadingButton"

export default {
    components: {LoadingButton, Breadcrumb,},
    data() {
        return {
            breadcrumb: '',
            isSubmitting: false,

            form: form.default({
                id: '',
                uuid: '',
                anzsco_code: '',
                type: '',
                title: '',
                status: '1',
                description: '',
            }).rules({
                title: 'required',
            }),
        };
    },

    methods: {
        updateClassification() {
            if (this.form.validate().errors().any()) {
                return;
            } else {
                this.isSubmitting = true;
                VisaApi.patch("/occupation-classification/update/" + this.form.uuid, this.form.all()).then((response) => {
                    this.$toast.success(response.message, {
                        position: "top-right",
                        dismissible: true,
                    });
                    this.isSubmitting = false;
                    this.$router.push({name: 'occupation-classification-list'})
                }).catch((error) => {
                    this.$toast.error(error.response.data.message, {
                        position: "top-right",
                        dismissible: true,
                    });
                    this.isSubmitting = false;
                });
            }
        },

        getSingleData(uuid) {
            VisaApi.get("/occupation-classification/" + uuid).then((response) => {
                Object.assign(this.form, response.data);
            });
        },
    },

    mounted() {
        this.breadcrumb = this.$router.currentRoute.value.meta.breadcrumb;
        this.getSingleData(this.$route.params.id);
    }
}
</script>
