<template>
    <div>
        <header class="fixed top-0  w-full z-40 shadow-md bg-slate-900">
            <div class="flex items-center justify-between px-4 py-3">
                <div class="flex items-center">
                    <div class="w-24 md:w-64">
                        <img :src="logo" alt="logo" class="h-10">
                    </div>
                    <img alt="image" class="w-8 ml-1 cursor-pointer block lg:hidden rotate-180"
                         src="@/assets/images/collapsemenu.svg" @click="collapseSidebar">
                </div>
                <div class="flex items-center">
                    <!-- Dark mode -->
                    <DarkMode/>

                    <!-- notification -->
                    <NotificationDropdown :is_open="header_dropdown=='notification'"
                                          @open="openHeaderDropdown('notification')"/>

                    <!-- user -->
                    <UserDropdown :is_open="header_dropdown=='user'" @open="openHeaderDropdown('user')"/>

                </div>
            </div>
        </header>

        <div class="flex">
            <!-- sidebar start -->
            <SidebarMenu class="top-16"/>
            <!-- sidebar end -->

            <!-- router view and footer -->
            <div class="pb-10 lg:ml-72 w-full sidebar-height relative top-16">
                <router-view v-slot="{ Component }">
                    <Transition mode="out-in" name="fade">
                        <component :is="Component"></component>
                    </Transition>
                </router-view>

                <!--                 <div class="w-full fixed bottom-0 bg-gray-100 border-t border-gray-200">-->
                <!--                    <div class="py-1.5 text-center text-slate-600">-->
                <!--                         <p :class="is_open_sbar? 'md:-ml-40':''">{{copyrightText}}</p>-->
                <!--                    </div>-->
                <!--                </div>-->
            </div>

        </div>
    </div>

</template>
<script>
import {settings} from "../../libs/helpers";
import SidebarMenu from "../../component/sidebar/SidebarMenu.vue";
import SidebarMenuItemLink from "../../component/sidebar/SidebarMenuItemLink.vue";
import UserDropdown from "../../component/header/UserDropdown.vue";
import NotificationDropdown from "../../component/header/NotificationDropdown.vue";
import DarkMode from "../../component/header/DarkMode.vue";

export default {
    name: 'AdminLayout',

    components: {DarkMode, NotificationDropdown, SidebarMenu, SidebarMenuItemLink, UserDropdown},

    data: () => {
        return {
            settings: settings(),
            header_dropdown: '',
            year: '',
            is_open_sbar: true,
            open: false,
        };
    },
    computed: {
        logo() {
            if (this.settings != null) return this.settings.logo
        },
        copyrightText() {
            if (this.settings != null) return this.settings.copyright_text
        }
    },
    methods: {
        handleClickOutside(event) {
            if (event.target.classList[0] != 'outside-click') {
                this.header_dropdown = '';
            }
        },
        openHeaderDropdown(value) {
            if (this.header_dropdown == '') {
                this.header_dropdown = value;
            } else if (this.header_dropdown == value) {
                this.header_dropdown = '';
            } else {
                this.header_dropdown = value;
            }
        },
        collapseSidebar() {
            const sidebar = document.getElementById('sidebar');
            if (sidebar.style.width === '18rem') {
                sidebar.style.width = '0rem';
            } else {
                sidebar.style.width = '18rem';
            }
        }
    },

    mounted() {
        document.addEventListener('click', this.handleClickOutside);
        this.year = new Date().getFullYear();
    }
}
</script>
<style>
.fade-enter-active {
    animation: fadeIn 0.5s;
}

.fade-leave-to {
    animation: fadeOut .5s;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}
</style>
