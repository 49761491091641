import VisaApi from "@/libs/axios"

export default {
    namespaced: true,
    state() {
        return {}
    },
    mutations: {},
    actions: {
        getCategory() {
            return new Promise((resolve, reject) => {
                VisaApi.get('visa-category').then(function ({data}) {
                    resolve(data);
                })
                    .catch(function (error) {
                        resolve(error);
                    });
            });
        }
    },
}
