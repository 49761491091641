import VisaApi from "../../libs/axios";

export default {
    namespaced: true,
    state() {
        return {
            authUser: {},
            roleName: '',
            appURL: '',
            notificationNumber: 0,
            notificationProductNumber: 0,
        }
    },
    getters: {
        authUser: state => state.authUser
    },
    mutations: {
        STORE_USER_DATA(state, data) {
            localStorage.setItem('token', data.access_token)
            localStorage.setItem('refresh', data.refresh_token)
            state.authUser = {
                access_token: data.access_token,
                refresh_token: data.refresh_token
            };
        },
        DELETE_USER_DATA(state) {
            state.authUser = {};
            localStorage.removeItem("token");
            localStorage.removeItem("refresh");
        },
        SET_APP_URL(state, data) {
            state.appURL = data;
        },
        SET_NOTIFICATION(state, data) {
            if (data == 0) {
                state.notificationNumber = 0;
                state.notificationProductNumber = 0;
            } else {
                state.notificationNumber = 1;
                state.notificationProductNumber = data;
            }
        },
    },
    actions: {
        login({commit}, data) {
            commit('STORE_USER_DATA', data)
        },
        logout({commit}) {
            commit('DELETE_USER_DATA')
        },
        refresh_token({commit}, refresh_token) {
            return new Promise(async (resolve, reject) => {
                VisaApi.post("/refresh/token", {
                    'refresh_token': refresh_token
                }).then((res) => {
                    commit('STORE_USER_DATA', res.data)
                    resolve(res.data)
                }).catch((err) => {
                    commit('DELETE_USER_DATA')
                    reject(err)
                })
            })
        },
        setAppURl({commit}, data) {
            commit('SET_APP_URL', data);
        },
        setNotification({commit}, data) {
            commit('SET_NOTIFICATION', data);
        },
    },
}
