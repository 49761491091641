<template>
    <div id="modal" class="relative z-10 hidden">
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75"></div>
        <div class="fixed inset-0 z-10 overflow-y-auto">
            <div class="flex min-h-full items-end justify-center text-center sm:items-center sm:p-0">
                <div id="modal_animation"
                     class="relative rounded-lg bg-white text-left shadow-xl my-8 mx-1 w-full md:w-8/12 xl:w-6/12">
                    <div class="bg-white text-gray-600 pb-2">
                        <h3 class="py-2.5 px-4 text-lg leading-6 border-b bg-slate-700 text-white">{{ modal_title
                            }}</h3>
                        <slot name="body"> This is the default body!</slot>
                    </div>
                    <div class="bg-gray-200 py-2 px-4 flex justify-end items-center">
                        <button class="rounded-md border border-transparent bg-red-600 px-4 py-1 mr-3 text-white hover:bg-red-700" type="button"
                                @click="close">
                            Cancel
                        </button>
                        <span>
                            <button v-if="isSubmitting==false" class="rounded-md border border-transparent bg-green-600 px-4 py-1 text-white hover:bg-green-700" type="button"
                                    @click="submit">
                                Submit
                            </button>
                            <span v-else>
                                <LoadingButton text="Submitting"/>
                            </span>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import LoadingButton from "@/component/LoadingButton"

export default {
    name: 'Modal',
    components: {LoadingButton},
    props: ['modal_title', 'isSubmitting'],
    methods: {
        close() {
            this.$emit('close');
        },
        submit() {
            this.$emit('submit');
        }
    },
};
</script>

<style scoped>
.max-width {
    max-width: 800px;
}
</style>
