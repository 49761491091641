<template>
    <div class="py-4">

        <div class="visasis-card">
            <div class="flex flex-wrap items-center -mx-4">
                <TableEntryShow @input="[meta.current_page = 1, meta.per_page = $event]"/>
                <TableTopActions :to="'/create-page-content'+'/'+page_id"
                                 @input="[meta.current_page = 1, search_input = $event]"/>
            </div>

            <vue-good-table
                :columns="columns"
                :rows="rows"
            >
                <template #table-row="props">
                    <div v-if="props.column.field == 'content'" class="whitespace-nowrap">
                        <span v-if="props.row.content!=null && props.row.content.length >= 30"
                              class="whitespace-nowrap"> {{ props.row.content.slice(0, 30)}} ... </span>
                        <span v-else class="whitespace-nowrap"> {{props.row.content }} </span>
                    </div>
                    <div v-if="props.column.field == 'action'">
                        <div class="px-3 py-2 flex items-center justify-center">
                            <router-link :to="{ name:'view-page-content', params:{id:props.row.id} }">
                                <view-icon/>
                            </router-link>
                            <router-link :to="{ name:'update-page-content', params:{id:props.row.id} }">
                                <svg class="w-4 h-4 mx-2 cursor-pointer" fill="none" stroke="Blue"
                                     stroke-width="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" stroke-linecap="round"
                                          stroke-linejoin="round"/>
                                </svg>
                            </router-link>
                            <svg class="w-4 h-4 cursor-pointer" fill="none" stroke="Red"
                                 stroke-width="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" @click="openDeleteModal(props.row.id)">
                                <path d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" stroke-linecap="round"
                                      stroke-linejoin="round"/>
                            </svg>
                        </div>
                    </div>
                </template>
            </vue-good-table>

            <TablePagination
                :inputPage="currentPage"
                :meta='meta'
                :next_of_next='next_of_next'
                :prev_of_prev='prev_of_prev'
                @update:inputPage="updatePageValue"
            />
        </div>

        <!-- delete modal -->
        <DeleteModal
            :isDeleteSubmitting="isDeleteSubmitting"
            :is_open="is_open_delete_modal"
            modal_title='Delete page content'
            @close="closeDeleteModal"
            @submit="deleteData"
        />

    </div>
</template>

<script>
import VisaApi from "@/libs/axios"
import store from '@/store'
import DeleteModal from '@/component/DeleteModal'
import Breadcrumb from '@/component/Breadcrumb'
import {VueGoodTable} from 'vue-good-table-next'
import 'vue-good-table-next/dist/vue-good-table-next.css'
import TablePagination from '@/component/TablePagination'
import {
    meta_data,
    next_of_next_status,
    prev_of_prev_status,
} from '@/libs/helpers.js'
import AddIcon from "../../../data/icons/AddIcon.vue";
import TableEntryShow from "../../../component/table/TableEntryShow.vue";
import TableTopActions from "../../../component/table/TableTopActions.vue";
import ViewIcon from "../../../data/icons/ViewIcon.vue";

export default {
    components: {
        ViewIcon,
        TableTopActions,
        TableEntryShow,
        AddIcon,
        DeleteModal,
        VueGoodTable,
        Breadcrumb,
        TablePagination
    },
    props: ["page_id"],
    data() {
        return {
            id_deletable: '',

            tableData: [],
            meta: meta_data,
            prev_of_prev: false,
            next_of_next: false,
            per_page_array: [10, 20, 30, 40, 50],
            currentPage: '',

            columns: [
                {label: 'Type', field: 'type'},
                {label: 'Title', field: 'title'},
                {label: 'Content', field: 'content'},
                {label: 'Action', field: 'action', sortable: false, width: '100px'}
            ],
            rows: [],

            search_input: '',
            breadcrumb: '',
            url: '',
            sort_status: false,
            isDeleteSubmitting: false,
            is_open_delete_modal: false,
        };
    },

    methods: {
        async getList() {
            store.commit('setting/LOADING_ON')
            await VisaApi.get(this.apiUrl).then((response) => {
                store.commit('setting/LOADING_OFF')
                if (response.code == '200') {
                    this.rows = response.data.data;
                    Object.assign(this.meta, response.data.meta);
                }
            });
        },

        openDeleteModal(id) {
            this.is_open_delete_modal = true
            this.id_deletable = id;
        },

        closeDeleteModal() {
            this.is_open_delete_modal = false
            this.isDeleteSubmitting = false;
        },

        deleteData() {
            this.isDeleteSubmitting = true;
            VisaApi.delete("/frontend/page-contents/" + this.id_deletable).then((response) => {
                if (response.code == '200') {
                    this.getList();
                    this.$toast.success(response.message, {
                        position: "top-right",
                        dismissible: true,
                    });
                    this.closeDeleteModal();
                }
            }).catch((error) => {
                this.closeDeleteModal();
                this.$toast.error(error.response.data.message, {
                    position: "top-right",
                    dismissible: true,
                });
            });
        },

        updatePageValue(newValue) {
            this.currentPage = newValue;
        },
    },

    computed: {
        apiUrl() {
            return `frontend/page/page-contents/${this.page_id}?page=` + this.meta.current_page + '&per_page=' + this.meta.per_page + '&order_by=type&order_by_type=desc&search=' + this.search_input;
        },
        nextOfNext() {
            this.next_of_next = next_of_next_status(this.meta);
        },
        prevOfPrev() {
            this.prev_of_prev = prev_of_prev_status(this.meta);
        },
    },

    watch: {
        apiUrl() {
            this.getList();
        },
        nextOfNext() {
        },
        prevOfPrev() {
        },
    },

    mounted() {
        // this.url = 'frontend/page-contents?page='+this.meta.current_page+'&per_page='+this.meta.per_page+'&order_by=type&order_by_type=desc&search='+this.search_input;
        // this.getList();
        this.breadcrumb = this.$router.currentRoute.value.meta.breadcrumb;
    }
}
</script>
