<template>
    <div class="px-4 py-2 absolute w-full">

        <div class="flex flex-wrap items-center justify-between pb-2 border-b border-gray-300">
            <Breadcrumb :text="breadcrumb"/>
            <router-link :to="'/update-menu/'+this.menu_id">
                <button class="back-btn" type="button">
                    <back-icon class="mr-2 mt-1"/>
                    Back
                </button>
            </router-link>
        </div>

        <div class="visasis-card">
            <div class="grid grid-cols-1 md:grid-cols-2 gap-x-4 gap-y-2">
                <div class="w-full">
                    <label>Select Parent</label>
                    <v-select
                        v-model="menu_items_selected"
                        :options="menu_items_list"
                        class="pt-1"
                        label="title"
                        placeholder="Choose"
                    />
                </div>
                <div class="w-full">
                    <label class="flex items-center">Title <img alt="required" class="w-2 h-2 ml-1"
                                                                src="@/assets/images/star.svg"> </label>
                    <input v-model='form.title' class="py-2 rounded border border-gray-300 bg-gray-50 text-sm placeholder-gray-400 focus:border-invblue resize-none w-full px-4 mt-1" placeholder="Title"
                           type='text'>
                    <span v-if='form.errors().has("title")' class="text-red-800 text-xs">
                        {{ form.errors().get('title') }}
                    </span>
                </div>
                <div class="w-full">
                    <label>Link</label>
                    <v-select
                        v-model="form.link"
                        :options="menu_item_links"
                        :reduce="link => link.link"
                        class="pt-1"
                        label="name"
                        placeholder="Choose menu link"
                    />
                </div>
                <div class="w-full">
                    <label>Sort Order</label>
                    <input v-model='form.sort_order' class="py-2 rounded border border-gray-300 bg-gray-50 text-sm placeholder-gray-400 focus:border-invblue resize-none w-full px-4 mt-1" placeholder="sort order"
                           type='text'>
                </div>
                <div class="w-full">
                    <label>Link Status</label>
                    <select v-model='form.is_active'
                            class="py-2 rounded border border-gray-300 bg-gray-50 text-sm placeholder-gray-400 focus:border-invblue resize-none w-full px-4 mt-1">
                        <option :selected="form.is_active=='1'" value="1">Active</option>
                        <option :selected="form.is_active=='0'" value="0">Inactive</option>
                    </select>
                </div>
                <div class="w-full">
                    <label>Menu Visibility</label>
                    <select v-model='form.is_header'
                            class="py-2 rounded border border-gray-300 bg-gray-50 text-sm placeholder-gray-400 focus:border-invblue resize-none w-full px-4 mt-1">
                        <option :selected="form.is_header=='1'" value="1">Active</option>
                        <option :selected="form.is_header=='0'" value="0">Inactive</option>
                    </select>
                </div>
            </div>

            <span v-if="menu_id!=''">
                <button v-if="isSubmitting==false" class="mt-4 justify-center rounded-md bg-blue-600 px-6 py-1.5 text-white hover:bg-blue-500" type="button"
                        @click="update">
                    Submit
                </button>
                <span v-else class="mt-4 block">
                    <LoadingButton text="Submitting"/>
                </span>
            </span>
            <button v-else class="mt-4 justify-center rounded-md bg-blue-400 px-6 py-1.5 text-white hover:bg-blue-300 cursor-not-allowed"
                    type="button">
                Submit
            </button>

        </div>
    </div>
</template>

<script>
import VisaApi from "@/libs/axios"
import form from 'vuejs-form'
import Breadcrumb from '@/component/Breadcrumb'
import vSelect from "vue-select"
import "vue-select/dist/vue-select.css"
import LoadingButton from "@/component/LoadingButton"
import BackIcon from "../../../data/icons/BackIcon.vue";
import {menu_item_links} from "../../../data/type";

export default {
    name: "CreateMenu",
    components: {BackIcon, LoadingButton, Breadcrumb, "v-select": vSelect,},

    data() {
        return {
            breadcrumb: '',
            isSubmitting: false,
            menu_items_list: [],
            menu_item_links,
            menu_items_selected: [],
            menu_id: '',

            form: form.default({
                id: '',
                parent_id: '',
                title: '',
                link: '',
                sort_order: '',
                is_active: '',
                is_header: '',
            }).rules({
                title: 'required',
            }),
        };
    },

    methods: {
        update() {
            if (this.form.validate().errors().any()) {
                return;
            } else {
                this.isSubmitting = true;
                VisaApi.patch("/frontend/menus/" + this.menu_id + "/menu-items/" + this.form.id, this.form.all()).then((response) => {
                    if (response.code == '200') {
                        this.$toast.success(response.message, {
                            position: "top-right",
                            dismissible: true,
                        });
                        this.$router.push({name: 'update-menu', params: {id: this.menu_id}})
                    }
                    this.isSubmitting = false;
                }).catch((error) => {
                    this.$toast.error(error.response.data.message, {
                        position: "top-right",
                        dismissible: true,
                    });
                    this.isSubmitting = false;
                });
            }
        },

        async getMenuItemsList(menu_id) {
            await VisaApi.get("/frontend/menus/" + menu_id + "/menu-items").then((response) => {
                if (response.code == '200') {
                    this.menu_items_list = response.data;
                }
            });
        },

        getSingleData(menu_id, id) {
            VisaApi.get("/frontend/menus/" + menu_id + "/menu-items/" + id).then((response) => {
                if (response.code == '200') {
                    Object.assign(this.form, response.data);

                    if (response.data.parent_id != null) {
                        let parent_id = Number(response.data.parent_id);
                        let temp = this.menu_items_list.filter(item => item.id == parent_id);
                        this.menu_items_selected = temp[0];
                    }
                }
            });
        },
    },

    watch: {
        menu_items_selected(menu_items) {
            if (menu_items != null) {
                this.form.parent_id = menu_items.id;
            }
        },
    },

    mounted() {
        this.breadcrumb = this.$router.currentRoute.value.meta.breadcrumb;
        this.menu_id = this.$route.params.menu_id;
        this.getMenuItemsList(this.menu_id);
        this.getSingleData(this.$route.params.menu_id, this.$route.params.id)
    }
}
</script>
