<template>
    <div class="px-4 py-2 absolute w-full">

        <Breadcrumb :text="breadcrumb"/>

        <div class="visasis-card">
            <div class="flex flex-wrap items-center -mx-4">
                <TableEntryShow @input="[meta.current_page = 1, meta.per_page = $event]"/>
                <TableTopActions :is_modal="true" :need_import="true"
                                 @create="openModal(true, 'create')" @import="is_open_import_modal = true"
                                 @input="[meta.current_page = 1, search_input = $event]"/>
            </div>

            <vue-good-table
                :columns="columns"
                :rows="rows"
            >
                <template #table-row="props">
                    <div v-if="props.column.field == 'status'">
                        <p v-if="props.row.status==1" class="w-min px-2 rounded m-auto text-white bg-green-700">
                            Active</p>
                        <p v-else class="w-min px-2 rounded m-auto text-white bg-orange-700">Inactive</p>
                    </div>
                    <div v-if="props.column.field == 'action'">
                        <div class="px-3 py-2 flex items-center justify-center">
                            <router-link :to="{ name: 'assessing-authority-view', params: {id:props.row.uuid} }">
                                <view-icon/>
                            </router-link>
                            <update-icon @click="getSingleData(props.row.uuid)"/>
                            <delete-icon
                                @click="[deletable_uuid = props.row.uuid, is_open_assessing_delete_modal = true]"/>
                        </div>
                    </div>
                </template>
            </vue-good-table>

            <TablePagination
                :inputPage="meta.current_page"
                :meta='meta'
                :next_of_next='next_of_next_status(meta)'
                :prev_of_prev='prev_of_prev_status(meta)'
                @update:inputPage="meta.current_page = $event"
            />
        </div>

        <!-- modal -->
        <Modal :isSubmitting="isModalSubmitting" :modal_title='mdl_title' @close="openModal(false, 'create')"
               @submit="handleSubmitMethod">
            <template v-slot:body>
                <div class="grid grid-cols-1 md:grid-cols-2 gap-x-4 gap-y-2 mt-2 px-2">
                    <div class="w-full">
                        <label class="flex items-center">Name <img alt="required" class="w-2 h-2 ml-1"
                                                                   src="@/assets/images/star.svg"> </label>
                        <input v-model='form.name'
                               class="py-2 rounded border border-gray-300 bg-gray-50 text-sm placeholder-gray-400 focus:border-invblue resize-none w-full px-4 mt-1"
                               placeholder="Name" type='text'>
                        <span v-if='form.errors().has("name")' class="text-red-800 text-xs">
                            {{ form.errors().get('name') }}
                        </span>
                    </div>
                    <div class="w-full">
                        <label class="flex items-center">Slug <img alt="required" class="w-2 h-2 ml-1"
                                                                   src="@/assets/images/star.svg"> </label>
                        <input v-model='form.slug'
                               class="py-2 rounded border border-gray-300 bg-gray-50 text-sm placeholder-gray-400 focus:border-invblue resize-none w-full px-4 mt-1"
                               placeholder="Slug" type='text'>
                        <span v-if='form.errors().has("slug")' class="text-red-800 text-xs">
                            {{ form.errors().get('slug') }}
                        </span>
                    </div>
                    <div class="w-full">
                        <label class="flex items-center">Link <img alt="required" class="w-2 h-2 ml-1"
                                                                   src="@/assets/images/star.svg"> </label>
                        <input v-model='form.link'
                               class="py-2 rounded border border-gray-300 bg-gray-50 text-sm placeholder-gray-400 focus:border-invblue resize-none w-full px-4 mt-1"
                               placeholder="Link" type='text'>
                        <span v-if='form.errors().has("link")' class="text-red-800 text-xs">
                            {{ form.errors().get('link') }}
                        </span>
                    </div>
                    <div class="w-full">
                        <label class="flex items-center">Short Code <img alt="required"
                                                                         class="w-2 h-2 ml-1" src="@/assets/images/star.svg">
                        </label>
                        <input v-model='form.short_code'
                               class="py-2 rounded border border-gray-300 bg-gray-50 text-sm placeholder-gray-400 focus:border-invblue resize-none w-full px-4 mt-1"
                               placeholder="short code" type='text'>
                        <span v-if='form.errors().has("short_code")' class="text-red-800 text-xs">
                            {{ form.errors().get('short_code') }}
                        </span>
                    </div>
                    <div class="w-full">
                        <label>Status</label>
                        <select v-model='form.status'
                                class="py-2 rounded border border-gray-300 bg-gray-50 text-sm placeholder-gray-400 focus:border-invblue resize-none w-full px-4 mt-1">
                            <option :selected="form.status==1" value="1">Active</option>
                            <option :selected="form.status==0" value="0">Inactive</option>
                        </select>
                        <span v-if='form.errors().has("type")' class="text-red-800 text-xs">
                            {{ form.errors().get('type') }}
                        </span>
                    </div>
                </div>
            </template>
        </Modal>

        <!-- delete modal -->
        <DeleteModal
            :isDeleteSubmitting="isDeleteSubmitting"
            :is_open="is_open_assessing_delete_modal"
            modal_title='Delete Assessing Authority'
            @close="[isDeleteSubmitting = false, is_open_assessing_delete_modal = false]"
            @submit="deleteAssessing"
        />

        <!-- Import -->
        <ImportModal
            :demo_link="demo_link"
            :is_open="is_open_import_modal"
            :is_submitting="isSubmitting"
            demo_link_label="Download Demo Assessing Authority File"
            title="Import Occupation"
            @close="closeImportModal"
            @submit="importExcelFile"
        />

    </div>
</template>

<script>
import VisaApi from "@/libs/axios"
import form from 'vuejs-form'
import store from '@/store'
import Modal from '@/component/Modal'
import DeleteModal from '@/component/DeleteModal'
import Table from '@/component/Table'
import Breadcrumb from '@/component/Breadcrumb'
import TablePagination from '@/component/TablePagination'
import {VueGoodTable} from 'vue-good-table-next'
import 'vue-good-table-next/dist/vue-good-table-next.css'
import {
    sanitizeName,
    meta_data,
    next_of_next_status,
    prev_of_prev_status
} from '@/libs/helpers.js'
import ViewIcon from "../../data/icons/ViewIcon.vue";
import UpdateIcon from "../../data/icons/UpdateIcon.vue";
import DeleteIcon from "../../data/icons/DeleteIcon.vue";
import ImportModal from "../../component/ui/modals/ImportModal.vue";
import AddIcon from "../../data/icons/AddIcon.vue";
import DownloadIcon from "../../data/icons/DownloadIcon.vue";
import TableEntryShow from "../../component/table/TableEntryShow.vue";
import TableTopActions from "../../component/table/TableTopActions.vue";

export default {
    components: {
        TableTopActions,
        TableEntryShow,
        DownloadIcon,
        AddIcon,
        ImportModal,
        DeleteIcon,
        UpdateIcon,
        ViewIcon,
        Modal,
        DeleteModal,
        Table,
        Breadcrumb,
        TablePagination,
        VueGoodTable,
    },
    data() {
        return {
            mdl_title: 'Create Assessing',
            modal_mode: 'create',
            deletable_uuid: '',
            is_open_import_modal: false,
            isSubmitting: false,
            demo_link: process.env.VUE_APP_ASSET_FILE_URL + "/demo_assessing_authority.xlsx",
            meta: meta_data,
            prev_of_prev: false,
            next_of_next: false,
            per_page_array: [10, 20, 30, 40, 50],
            currentPage: '',
            next_of_next_status,
            prev_of_prev_status,

            search_input: '',
            breadcrumb: '',
            url: '',
            sort_status: false,
            isDeleteSubmitting: false,
            isModalSubmitting: false,
            is_open_assessing_delete_modal: false,

            form: form.default({
                id: '',
                uuid: '',
                name: '',
                slug: '',
                short_code: '',
                link: '',
                status: '1',
            }).rules({
                name: 'required',
                slug: 'required',
                short_code: 'required',
                link: 'required',
            }),

            columns: [
                {label: 'Name', field: 'name'},
                {label: 'Short Code', field: 'short_code'},
                {label: 'Link', field: 'link'},
                {label: 'Status', field: 'status'},
                {label: 'Action', field: 'action', sortable: false, width: '100px'}
            ],
            rows: [],
        };
    },

    methods: {
        openModal(value, mode) {
            this.form.errors().forget();
            const modalCls = document.getElementById('modal').classList;
            const modalAnimCls = document.getElementById('modal_animation').classList;
            if (value == true && mode == 'create') {
                modalCls.remove('hidden');
                modalAnimCls.add('open-modal-animation');

                this.form.id = '';
                this.form.uuid = '';
                this.form.name = '';
                this.form.slug = '';
                this.form.short_code = '';
                this.form.link = '';
                this.form.status = '1';

                this.modal_mode = 'create';
                this.mdl_title = 'Create Assessing';
            } else if (value == true && mode == 'update') {
                modalCls.remove('hidden');
                modalAnimCls.add('open-modal-animation');
            } else {
                modalCls.add('hidden');
                modalAnimCls.remove('open-modal-animation');
                this.isModalSubmitting = false;
            }
        },

        createAssessing() {
            if (this.form.validate().errors().any()) {
                return;
            } else {
                this.openModal(false, 'create');
                VisaApi.post("/assessing-authority/create", this.form.all()).then((response) => {
                    if (response.code == '201') {
                        this.getList();
                        this.$toast.success(response.message, {
                            position: "top-right",
                            dismissible: true,
                        });
                    }
                }).catch((error) => {
                    this.$toast.error(error.response.data.message, {
                        position: "top-right",
                        dismissible: true,
                    });
                });
            }
        },

        async getList() {
            store.commit('setting/LOADING_ON')
            await VisaApi.get(this.url).then((response) => {
                store.commit('setting/LOADING_OFF')
                if (response.code == '200') {
                    if (response.data.meta.to > 50) {
                        this.meta.per_page = 'All'
                    } else {
                        Object.assign(this.meta, response.data.meta);
                    }
                    this.rows = response.data.data;
                }
            });
        },

        async getSingleData(uuid) {
            await VisaApi.get("/assessing-authority/" + uuid).then((response) => {
                if (response.code == '200') {
                    Object.assign(this.form, response.data);
                    this.mdl_title = 'Update Assessing';
                    this.modal_mode = 'update';
                    this.openModal(true, 'update');
                }
            });
        },

        updateAssessing() {
            if (this.form.validate().errors().any()) {
                return;
            } else {
                this.isModalSubmitting = true;
                VisaApi.patch("/assessing-authority/update/" + this.form.uuid, this.form.all()).then((response) => {
                    if (response.code == '200') {
                        this.openModal(false, 'update');
                        let element = this.rows.filter(item => item.id == this.form.id);
                        Object.assign(element[0], response.data)
                        this.$toast.success(response.message, {
                            position: "top-right",
                            dismissible: true,
                        });
                    }
                }).catch((error) => {
                    this.$toast.error(error.response.data.message, {
                        position: "top-right",
                        dismissible: true,
                    });
                });
            }
        },

        deleteAssessing() {
            this.isDeleteSubmitting = true;
            VisaApi.delete("/assessing-authority/" + this.deletable_uuid).then((response) => {
                if (response.code == '200') {
                    this.getList();
                    this.$toast.success(response.message, {
                        position: "top-right",
                        dismissible: true,
                    });
                    this.isDeleteSubmitting = false
                    this.is_open_assessing_delete_modal = false
                }
            }).catch((error) => {
                this.$toast.error(error.response.data.message, {
                    position: "top-right",
                    dismissible: true,
                });
                this.isDeleteSubmitting = false
                this.is_open_assessing_delete_modal = false
            });
        },

        handleSubmitMethod() {
            if (this.modal_mode == 'create') {
                this.createAssessing();
            } else {
                this.updateAssessing();
            }
        },

        toggleSort() {
            if (this.sort_status == false) {
                this.rows.sort((a, b) => a.name.localeCompare(b.name));
                this.sort_status = true;
            } else {
                this.rows.sort((a, b) => b.name.localeCompare(a.name));
                this.sort_status = false;
            }
        },

        updatePageValue(newValue) {
            this.currentPage = newValue;
        },

        closeImportModal() {
            this.isSubmitting = false
            this.is_open_import_modal = false
        },
        importExcelFile(file) {
            if (file == '') {
                this.$toast.error('No file selected!', {
                    position: "top-right",
                    dismissible: true,
                });
                return;
            } else {
                VisaApi.post("/assessing-authority/import", {file: file}, {headers: {"Content-Type": "multipart/form-data"}})
                    .then((response) => {
                        if (response.code == '200') {
                            this.$toast.success(response.message, {
                                position: "top-right",
                                dismissible: true,
                            });
                            this.closeImportModal()
                            this.getList();
                        }
                        this.isSubmitting = false;
                    }).catch((error) => {
                    this.$toast.error(error.response.data.message, {
                        position: "top-right",
                        dismissible: true,
                    });
                    this.closeImportModal()
                });
            }
        },
    },

    computed: {
        apiUrl() {
            return this.url = 'assessing-authority?page=' + this.meta.current_page + '&per_page=' + this.meta.per_page + '&order_by=name&order_by_type=desc&search=' + this.search_input;
        },
        slug() {
            return this.form.slug = sanitizeName(this.form.name);
        },
    },

    watch: {
        apiUrl() {
            this.getList();
        },
        slug() {
        },
    },

    mounted() {
        this.url = 'assessing-authority?page=' + this.meta.current_page + '&per_page=' + this.meta.per_page + '&order_by=name&order_by_type=desc&search=' + this.search_input;
        this.getList();
        this.breadcrumb = this.$router.currentRoute.value.meta.breadcrumb;
    }
}
</script>
