<template>
    <form @submit.prevent="updateSettings">
        <div class="grid grid-cols-1 lg:grid-cols-2 gap-x-4 gap-y-2">
            <FileInput v-if="hasSlug('logo')" :max="1" :model="file.logo" field="logo" label="Logo"
                       @file-uploaded="getValue"/>
            <FileInput v-if="hasSlug('favicon')" :max="1" :model="file.favicon" field="favicon" label="Favicon"
                       @file-uploaded="getValue"/>
            <FileInput v-if="hasSlug('footer_logo')" :max="1" :model="file.footer_logo" field="footer_logo"
                       label="Footer logo" @file-uploaded="getValue"/>
            <FileInput v-if="hasSlug('home_banner_image')" :max="1" :model="file.home_banner_image" field="home_banner_image"
                       label="Home banner image" @file-uploaded="getValue"/>
            <FileInput v-if="hasSlug('login_page_image')" :max="1" :model="file.login_page_image" field="login_page_image"
                       label="Login page image" @file-uploaded="getValue"/>
            <FileInput v-if="hasSlug('filter_page_image')" :max="1"
                       :model="file.filter_page_image" field="filter_page_image" label="Filter page image (1200x400 px)" @file-uploaded="getValue"/>
            <FileInput v-if="hasSlug('visa_page_image')" :max="1"
                       :model="file.visa_page_image" field="visa_page_image" label="Visa page banner image (1200x350 px)" @file-uploaded="getValue"/>
        </div>

        <button class="justify-center mt-4 py-1.5 rounded-md bg-blue-600 px-6 text-white hover:bg-blue-500" type="button"
                @click="updateSettings">
            Submit
        </button>
    </form>
</template>

<script>
import {FileInput} from "../ui/forms";
import {computed, onMounted, reactive} from "vue";
import store from "../../store";

export default {
    components: {
        FileInput
    },
    setup(props, {emit}) {
        const file = reactive({});
        const settings = computed(() => store.getters["setting/settings"]);

        const getSettings = async () => {
            Object.assign(file, {
                logo: settings.value.logo,
                favicon: settings.value.favicon,
                footer_logo: settings.value.footer_logo,
                home_banner_image: settings.value.home_banner_image,
                login_page_image: settings.value.login_page_image,
                filter_page_image: settings.value.filter_page_image,
                visa_page_image: settings.value.visa_page_image,
            })
        }
        onMounted(async () => {
            await getSettings()
        })

        const hasSlug = (slug) => {
            return file.hasOwnProperty(slug)
        }

        const getValue = (payload) => {
            switch (payload.field) {
                case 'logo':
                    file.logo = payload.value;
                    break;
                case 'favicon':
                    file.favicon = payload.value;
                    break;
                case 'footer_logo':
                    file.footer_logo = payload.value;
                    break;
                case 'home_banner_image':
                    file.home_banner_image = payload.value;
                    break;
                case 'login_page_image':
                    file.login_page_image = payload.value;
                    break;
                case 'filter_page_image':
                    file.filter_page_image = payload.value;
                    break;
                case 'visa_page_image':
                    file.visa_page_image = payload.value;
                    break;
            }
        }

        const updateSettings = async () => {
            await store.dispatch('setting/updateSettings', file)
            emit('updated')
            setTimeout(() => {
                window.location.reload()
            }, 50)
        }

        return {
            file,
            hasSlug,
            getValue,
            updateSettings
        }
    }
}
</script>

<style scoped>

</style>
