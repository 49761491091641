<template>
    <div class="p-4">

        <div class="pb-1 border-b border-gray-300">
            <Breadcrumb :text="breadcrumb"/>
        </div>

        <div class="flex flex-wrap items-center -mx-4">
            <div class="w-full lg:w-1/2 p-4">
                <div class="flex items-center">
                    <span> Show </span>
                    <select v-model="meta.per_page" class="px-3 py-1 mx-1.5 rounded bg-white border border-gray-300">
                        <option v-for="data in per_page_array" :key="data">{{ data }}</option>
                    </select>
                    <span> entries </span>
                </div>
            </div>
            <div class="w-full lg:w-1/2 p-4">
                <div class="lg:flex lg:justify-end">
                    <input v-model="search_input"
                           class="w-64 lg:w-96 pl-4 pr-8 mx-1 h-10 rounded border focus:outline-none border-orange-400"
                           placeholder="Search" type="text"/>
                    <router-link to="/create-occupation-classification">
                        <button
                            class="px-6 py-2 mx-1 text-center tracking-wider rounded mt-4 lg:mt-0 block lg:inline-flex bg-orange-600 hover:bg-orange-500 text-white">
                            Create
                        </button>
                    </router-link>
                    <button class="px-6 py-2 ml-1 text-center tracking-wider rounded mt-4 lg:mt-0 block lg:inline-flex bg-blue-600 hover:bg-blue-500 text-white"
                            @click="openImport">
                        Import
                    </button>
                </div>
            </div>
        </div>

        <Table>
            <template v-slot:table-header>
                <th class="px-3 py-2 whitespace-nowrap" scope="col">
                    ANZSCO Code
                </th>
                <th class="px-3 py-2 flex items-center" scope="col">
                    Type
                    <img :class="sort_status==false? 'w-4 h-4 mx-2 mt-0.5 cursor-pointer':'w-4 h-4 mx-2 mt-0.5 cursor-pointer rotate-180 transition'"
                         alt="sort"
                         src="@/assets/images/arrow_down.svg" @click="toggleSort">
                </th>
                <th class="px-3 py-2" scope="col">
                    title
                </th>
                <th class="px-3 py-2" scope="col">
                    Status
                </th>
                <th class="px-3 py-2" scope="col">
                    Action
                </th>
            </template>
            <template v-slot:table-body>
                <tr v-for="(data,index) in tableData" :key="index"
                    class="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                    <td class="px-3 py-2">
                        {{ data.anzsco_code }}
                    </td>
                    <td class="px-3 py-2">
                        <span v-if="data.type=='alt'">Alternative</span>
                        <span v-if="data.type=='spe'">Specialization</span>
                    </td>
                    <td class="px-3 py-2">
                        {{ data.title }}
                    </td>
                    <td class="px-3 py-2">
                        <p v-if="data.status==1" class="text-green-500">Active</p>
                        <p v-else class="text-orange-500">Inactive</p>
                    </td>
                    <td class="px-2 py-2 flex items-center">
                        <router-link :to="{ name:'view-occupation-classification', params:{id:data.uuid} }">
                            <img alt="view" class="w-4 h-4 cursor-pointer" src="@/assets/images/eye.svg">
                        </router-link>
                        <router-link :to="{ name:'update-occupation-classification', params:{id:data.uuid} }">
                            <svg class="w-4 h-4 mx-2 cursor-pointer" fill="none" stroke="Blue" stroke-width="1.5"
                                 viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" stroke-linecap="round"
                                      stroke-linejoin="round"/>
                            </svg>
                        </router-link>
                        <svg class="w-4 h-4 cursor-pointer" fill="none" stroke="Red"
                             stroke-width="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" @click="openDeleteModal(data.uuid)">
                            <path d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" stroke-linecap="round"
                                  stroke-linejoin="round"/>
                        </svg>
                    </td>
                </tr>
            </template>
            <template v-slot:table-footer>
                <tr>
                    <td colspan="9">
                        <TablePagination
                            :meta='meta'
                            :next_of_next='next_of_next'
                            :prev_of_prev='prev_of_prev'
                        />
                    </td>
                </tr>
            </template>
        </Table>

        <!-- delete modal -->
        <DeleteModal
            :isDeleteSubmitting="isDeleteSubmitting"
            modal_title='Delete Occupation'
            @close="closeDeleteModal"
            @submit="deleteOccupationClassification"
        />

        <!-- Import -->
        <div id="import_modal" class="relative z-10 hidden">
            <div class="fixed inset-0 bg-gray-500 bg-opacity-75"></div>
            <div class="fixed inset-0 z-10 overflow-y-auto">
                <div class="flex min-h-full items-end justify-center text-center sm:items-center sm:p-0">
                    <div id="import_modal_animation"
                         class="relative overflow-hidden rounded-lg bg-white text-left shadow-xl sm:my-8 sm:w-full sm:max-w-lg">
                        <div class="bg-white text-gray-600 pb-4">
                            <h3 class="p-2 text-lg leading-6 border-b">Import Occupation</h3>
                            <div class="w-full px-2 mt-2">
                                <label>Choose file</label>
                                <input class="py-2 rounded block" type='file' @change="takeFileFromInput($event)">
                            </div>
                            <a :href="demo_classification" class="px-2 text-blue-500 flex" download target="_blank">
                                Download Demo Classification File
                                <download-icon class="mt-1 ml-2"/>
                            </a>
                        </div>
                        <div class="bg-gray-50 px-2 py-1 flex justify-end items-center">
                            <div>
                                <button v-if="isSubmitting==false" class="mr-4 justify-center rounded-md bg-blue-600 px-6 py-1.5 text-white hover:bg-blue-500" type="button"
                                        @click="importExcelFile">
                                    Import
                                </button>
                                <span v-else>
                                    <LoadingButton text="Importing"/>
                                </span>
                            </div>
                            <button class="inline-flex justify-center rounded-md border border-transparent bg-green-600 px-6 py-1.5 text-white hover:bg-green-700" type="button"
                                    @click="closeImport">
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import VisaApi from "@/libs/axios"
import store from '@/store'
import DeleteModal from '@/component/DeleteModal'
import Table from '@/component/Table'
import Breadcrumb from '@/component/Breadcrumb'
import TablePagination from '@/component/TablePagination'
import form from 'vuejs-form'
import LoadingButton from "@/component/LoadingButton"
import {meta_data, next_of_next_status, prev_of_prev_status} from '@/libs/helpers.js'
import {DownloadIcon} from "../../../data/icons";

export default {
    components: {DownloadIcon, DeleteModal, Table, Breadcrumb, TablePagination, LoadingButton,},
    data() {
        return {
            deletable_uuid: '',
            tableData: [],
            meta: meta_data,
            prev_of_prev: false,
            next_of_next: false,
            per_page_array: [10, 20, 30, 40, 50],
            search_input: '',
            breadcrumb: '',
            url: '',
            sort_status: false,
            isSubmitting: false,
            isDeleteSubmitting: false,
            demo_classification: process.env.VUE_APP_ASSET_FILE_URL + '/demo_classification.xlsx',

            form: form.default({
                file: '',
            }).rules({
                file: 'required',
            }),
        };
    },

    methods: {
        async getList() {
            store.commit('setting/LOADING_ON')
            await VisaApi.get(this.url).then((response) => {
                store.commit('setting/LOADING_OFF')
                if (response.code == '200') {
                    this.tableData = response.data.data;
                    Object.assign(this.meta, response.data.meta);
                }
            }).catch((error) => {
                store.commit('setting/LOADING_OFF')
            });
        },

        openDeleteModal(uuid) {
            const modalCls = document.getElementById('delete_modal').classList;
            const modalAnimCls = document.getElementById('delete_modal_animation').classList;
            this.deletable_uuid = uuid;
            modalCls.remove('hidden');
            modalAnimCls.add('delete-modal-animation');
        },

        closeDeleteModal() {
            const modalCls = document.getElementById('delete_modal').classList;
            const modalAnimCls = document.getElementById('delete_modal_animation').classList;
            modalCls.add('hidden');
            modalAnimCls.remove('delete-modal-animation');
            this.deletable_uuid = '';
            this.isDeleteSubmitting = false;
        },

        deleteOccupationClassification() {
            this.isDeleteSubmitting = true;
            VisaApi.delete("/occupation-classification/" + this.deletable_uuid).then((response) => {
                this.tableData = this.tableData.filter(item => item.uuid !== this.deletable_uuid);
                this.meta.total -= 1;
                this.meta.to -= 1;
                this.$toast.success(response.message, {
                    position: "top-right",
                    dismissible: true,
                });
                this.closeDeleteModal();
            }).catch((error) => {
                this.$toast.error(error.response.data.message, {
                    position: "top-right",
                    dismissible: true,
                });
                this.closeDeleteModal();
            });
        },

        toggleSort() {
            if (this.sort_status == false) {
                this.tableData.sort((a, b) => a.type.localeCompare(b.type));
                this.sort_status = true;
            } else {
                this.tableData.sort((a, b) => b.type.localeCompare(a.type));
                this.sort_status = false;
            }
        },

        openImport() {
            const modalCls = document.getElementById('import_modal').classList;
            const modalAnimCls = document.getElementById('import_modal_animation').classList;
            modalCls.remove('hidden');
            modalAnimCls.add('delete-modal-animation');
        },

        closeImport() {
            const modalCls = document.getElementById('import_modal').classList;
            const modalAnimCls = document.getElementById('import_modal_animation').classList;
            modalCls.add('hidden');
            modalAnimCls.remove('delete-modal-animation');
            this.form.file = '';
        },

        takeFileFromInput(event) {
            const newFile = event.target.files[0]
            this.form.file = newFile;
        },

        importExcelFile() {
            if (this.form.file == '') {
                this.$toast.error('No file selected!', {
                    position: "top-right",
                    dismissible: true,
                });
                return;
            } else {
                this.isSubmitting = true;
                VisaApi.post("/occupation-classification/import", this.form.all(), {headers: {"Content-Type": "multipart/form-data"}}).then((response) => {
                    this.$toast.success(response.message, {
                        position: "top-right",
                        dismissible: true,
                    });
                    this.closeImport();
                    this.form.file = '';
                    this.getList();
                    this.isSubmitting = false;
                }).catch((error) => {
                    this.$toast.error(error.response.data.message, {
                        position: "top-right",
                        dismissible: true,
                    });
                    this.closeImport();
                    this.form.file = '';
                    this.isSubmitting = false;
                });
            }
        },
    },

    computed: {
        apiUrl() {
            return this.url = 'occupation-classification?page=' + this.meta.current_page + '&per_page=' + this.meta.per_page + '&order_by=id&order_by_type=desc&search=' + this.search_input;
        },
        nextOfNext() {
            this.next_of_next = next_of_next_status(this.meta);
        },
        prevOfPrev() {
            this.prev_of_prev = prev_of_prev_status(this.meta);
        },
    },

    watch: {
        apiUrl() {
            this.getList();
        },
        nextOfNext() {
        },
        prevOfPrev() {
        },
    },

    mounted() {
        this.url = 'occupation-classification?page=' + this.meta.current_page + '&per_page=' + this.meta.per_page + '&order_by=id&order_by_type=desc&search=' + this.search_input;
        this.getList();
        this.breadcrumb = this.$router.currentRoute.value.meta.breadcrumb;
    }
}
</script>
