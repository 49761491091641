<template>
    <div class="px-4 py-2 absolute w-full">

        <div class="flex flex-wrap items-center justify-between pb-2 border-gray-300">
            <Breadcrumb :text="breadcrumb"/>
            <router-link to="/page-list">
                <button class="rounded-md bg-red-600 px-6 py-2 inline-flex text-white hover:bg-red-500" type="button">
                    <back-icon class="mt-1 mr-2"/>
                    Back to list
                </button>
            </router-link>
        </div>

        <div class="visasis-card">
            <p class="font-bold mt-2">Attach page with page content.</p>

            <div class="grid grid-cols-1 md:grid-cols-2 gap-x-4 gap-y-2 mt-4">
                <div class="w-full">
                    <label>Page Title:</label>
                    <p>{{ page_title }}</p>
                </div>
                <div class="w-full">
                    <label class="flex items-center">Sort Order <img alt="required" class="w-2 h-2 ml-1"
                                                                     src="@/assets/images/star.svg"> </label>
                    <input v-model='form.sort_order' class="py-2 rounded border border-gray-300 bg-gray-50 text-sm placeholder-gray-400 focus:border-invblue resize-none w-full px-4 mt-1" placeholder="Sort order"
                           type='text'>
                    <span v-if='form.errors().has("sort_order")' class="text-red-800 text-xs">
                        {{ form.errors().get('sort_order') }}
                    </span>
                </div>
                <div class="w-full">
                    <label class="flex items-center">Select Page Content <img alt="required"
                                                                              class="w-2 h-2 ml-1"
                                                                              src="@/assets/images/star.svg"> </label>
                    <v-select
                        v-model="page_content_selected"
                        :options="page_content_list"
                        class="pt-1"
                        label="title"
                        placeholder="Choose"
                    />
                    <span v-if='form.errors().has("page_content_id")' class="text-red-800 text-xs">
                        {{ form.errors().get('page_content_id') }}
                    </span>
                </div>
            </div>

            <div class="text-center mt-8">
                <button v-if="isSubmitting==false" class="mt-4 rounded-md bg-blue-600 px-6 py-1.5 text-white hover:bg-blue-500" type="button"
                        @click="attachContent">
                    Attach
                </button>
                <span v-else>
                    <LoadingButton text="Attaching"/>
                </span>
            </div>

            <div class="overflow-x-auto mt-8 mb-4">
                <p class="font-bold text-sm">This page already attached with the following page content: </p>
                <table class="w-full border-collapse border border-gray-400">
                    <thead>
                    <tr class="text-left">
                        <th class="border border-gray-300 p-2">Title</th>
                        <th class="border border-gray-300 p-2">Type</th>
                        <th class="border border-gray-300 p-2">Content</th>
                        <th class="border border-gray-300 p-2 text-center">Detach</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="data in alreadyAttachedList" :key="data.id">
                        <td class="border border-gray-300 p-2">{{ data.title }}</td>
                        <td class="border border-gray-300 p-2">{{ data.type }}</td>
                        <td class="border border-gray-300 p-2">
                            <span v-if="data.content!=null && data.content.length >= 30" class="whitespace-nowrap"> {{ data.content.slice(0, 30)}} ... </span>
                            <span v-else class="whitespace-nowrap"> {{data.content }} </span>
                        </td>
                        <td class="border border-gray-300 p-2">
                            <div>
                                <img alt="img" class="w-5 cursor-pointer mx-auto"
                                     src="@/assets/images/cross_red.svg" @click="openDeleteModal(data.id)">
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>

        </div>

        <!-- delete modal -->
        <DeleteModal modal_title='Detach page content' @close="closeDeleteModal"
                     @submit="detachPageContent"></DeleteModal>

    </div>
</template>

<script>
import VisaApi from "@/libs/axios"
import form from 'vuejs-form'
import store from '@/store'
import Breadcrumb from '@/component/Breadcrumb'
import vSelect from "vue-select"
import "vue-select/dist/vue-select.css"
import DeleteModal from '@/component/DeleteModal'
import LoadingButton from "@/component/LoadingButton"
import BackIcon from "../../../data/icons/BackIcon.vue";

export default {
    components: {BackIcon, Breadcrumb, "v-select": vSelect, DeleteModal, LoadingButton},
    data() {
        return {
            breadcrumb: '',
            page_title: '',
            alreadyAttachedList: [],
            page_content_list: [],
            page_content_selected: [],
            isSubmitting: false,

            form: form.default({
                page_id: '',
                page_content_id: '',
                sort_order: '',
            }).rules({
                page_id: 'required',
                page_content_id: 'required',
                sort_order: 'required',
            }),
        };
    },

    methods: {
        async getPageContentList() {
            store.commit('setting/LOADING_ON')
            await VisaApi.get("/frontend/page-contents").then((response) => {
                store.commit('setting/LOADING_OFF')
                if (response.code == '200') {
                    this.page_content_list = response.data;
                }
            });
        },

        attachContent() {
            if (this.form.validate().errors().any()) {
                return;
            } else {
                this.isSubmitting = true;
                VisaApi.post("/frontend/page/attach-page-content", this.form.all()).then((response) => {
                    this.$toast.success(response.message, {
                        position: "top-right",
                        dismissible: true,
                    });
                    this.page_content_selected = [];
                    this.form.page_content_id = '';
                    this.form.sort_order = '';
                    this.getSingleData(this.form.page_id);
                    this.isSubmitting = false;
                }).catch((error) => {
                    this.$toast.error(error.response.data.message, {
                        position: "top-right",
                        dismissible: true,
                    });
                    this.isSubmitting = false;
                });
            }
        },

        openDeleteModal(id) {
            const modalCls = document.getElementById('delete_modal').classList;
            const modalAnimCls = document.getElementById('delete_modal_animation').classList;
            this.form.page_content_id = id;
            modalCls.remove('hidden');
            modalAnimCls.add('delete-modal-animation');
        },

        closeDeleteModal() {
            const modalCls = document.getElementById('delete_modal').classList;
            const modalAnimCls = document.getElementById('delete_modal_animation').classList;
            modalCls.add('hidden');
            modalAnimCls.remove('delete-modal-animation');
            this.form.page_content_id = '';
        },

        detachPageContent() {
            VisaApi.post("/frontend/page/detach-page-content", {
                page_id: this.form.page_id,
                page_content_id: this.form.page_content_id
            }).then((response) => {
                this.$toast.success(response.message, {
                    position: "top-right",
                    dismissible: true,
                });
                this.closeDeleteModal();
                this.getSingleData(this.form.page_id);
            }).catch((error) => {
                this.$toast.error(error.response.data.message, {
                    position: "top-right",
                    dismissible: true,
                });
                this.closeDeleteModal();
            });
        },

        getSingleData(id) {
            VisaApi.get("/frontend/pages/" + id).then((response) => {
                if (response.code == '200') {
                    this.alreadyAttachedList = response.data.page_contents;
                    this.page_title = response.data.title;
                }
            });
        },
    },

    watch: {
        page_content_selected(value) {
            this.form.page_content_id = value.id;
        }
    },

    mounted() {
        this.form.page_id = this.$route.params.id;
        this.getSingleData(this.$route.params.id);
        this.getPageContentList();
        this.breadcrumb = this.$router.currentRoute.value.meta.breadcrumb;
    }
}
</script>
