import VisaApi from "../../libs/axios";

export default {
    namespaced: true,
    state: {
        caveat: {},
        caveats: [],
    },
    getters: {
        caveat: state => state.caveat,
        caveats: state => state.caveats,
    },
    mutations: {
        SET_CAVEAT(state, data) {
            state.caveat = data;
        },
        SET_CAVEATS(state, data) {
            state.caveats = data;
        },
    },
    actions: {
        async getAllCaveat({commit}) {
            return new Promise(async (resolve, reject) => {
                await VisaApi.get('caveat').then((res) => {
                    commit("SET_CAVEATS", res.data)
                    resolve(res.data)
                }).catch((err) => {
                    reject(err.response)
                })
            })
        }
    },
}
