<template>
    <div class="mr-2 md:mr-4">
        <img alt="image" class="w-8 cursor-pointer" src="@/assets/images/sun.svg">
    </div>
</template>

<script setup>

</script>

<style scoped>

</style>
