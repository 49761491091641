<template>
    <div class="px-4 py-2 absolute w-full">
        <div class="flex flex-wrap items-center justify-between">
            <Breadcrumb :text="breadcrumb"/>
            <router-link to="/visa-category-list">
                <button class="back-btn" type="button">
                    <back-icon class="mt-1 mr-2"/>
                    Back to list
                </button>
            </router-link>
        </div>

        <div class="visasis-card">
            <div class="grid grid-cols-1 md:grid-cols-2 gap-x-4 gap-y-2">
                <div class="w-full">
                    <label class="flex items-center">Name <img alt="required" class="w-2 h-2 ml-1"
                                                               src="@/assets/images/star.svg"> </label>
                    <input v-model='form.name'
                           class="py-2 rounded border border-gray-300 bg-gray-50 text-sm placeholder-gray-400 focus:border-invblue resize-none w-full px-4 mt-1"
                           placeholder="Name" type='text'>
                    <span v-if='form.errors().has("name")' class="text-red-800 text-xs">
                        {{ form.errors().get('name') }}
                    </span>
                </div>
                <div class="w-full">
                    <label class="flex items-center">Slug <img alt="required" class="w-2 h-2 ml-1"
                                                               src="@/assets/images/star.svg"> </label>
                    <input v-model='form.slug'
                           class="py-2 rounded border border-gray-300 bg-gray-50 text-sm placeholder-gray-400 focus:border-invblue resize-none w-full px-4 mt-1"
                           placeholder="Slug" type='text'>
                    <span v-if='form.errors().has("slug")' class="text-red-800 text-xs">
                        {{ form.errors().get('slug') }}
                    </span>
                </div>
                <div class="w-full">
                    <label>Status</label>
                    <select v-model='form.status'
                            class="py-2 rounded border border-gray-300 bg-gray-50 text-sm placeholder-gray-400 focus:border-invblue resize-none w-full px-4 mt-1">
                        <option :selected="form.status==1" value="1">Active</option>
                        <option :selected="form.status==0" value="0">Inactive</option>
                    </select>
                </div>
                <div class="w-full">
                    <label>Is Feature?</label>
                    <select v-model='form.is_feature'
                            class="py-2 rounded border border-gray-300 bg-gray-50 text-sm placeholder-gray-400 focus:border-invblue resize-none w-full px-4 mt-1">
                        <option :selected="form.is_feature==1" value="1">Yes</option>
                        <option :selected="form.is_feature==0" value="0">No</option>
                    </select>
                </div>
                <div class="w-full">
                    <label>Description</label>
                    <textarea v-model='form.description' class="py-2 rounded border border-gray-300 bg-gray-50 text-sm placeholder-gray-400 focus:border-invblue resize-none w-full px-4 mt-1" cols="50" placeholder="Note"
                              rows="4"></textarea>
                </div>
                <div class="w-full">
                    <FileInput :max="1" :model="form.photo" field="photo" label="Banner Image"
                               @file-uploaded="getPhotoValue"/>
                    <p class="text-gray-900">Image should be 1200x350 (px)</p>
                </div>
            </div>

            <button v-if="isSubmitting==false" class="mt-4 justify-center rounded-md bg-blue-600 px-6 py-1.5 text-white hover:bg-blue-500" type="button"
                    @click="createVisaCategory">
                Submit
            </button>
            <span v-else class="mt-4 block">
                <LoadingButton text="Submitting"/>
            </span>

        </div>
    </div>
</template>

<script>
import VisaApi from "@/libs/axios"
import form from 'vuejs-form'
import Breadcrumb from '@/component/Breadcrumb'
import vSelect from "vue-select"
import "vue-select/dist/vue-select.css"
import LoadingButton from "@/component/LoadingButton"
import {sanitizeName,} from '@/libs/helpers.js'
import BackIcon from "../../../data/icons/BackIcon.vue";
import {FileInput} from "../../../component/ui/forms";

export default {
    components: {BackIcon, LoadingButton, Breadcrumb, "v-select": vSelect, FileInput},
    data() {
        return {
            breadcrumb: '',
            isSubmitting: false,

            form: form.default({
                name: '',
                slug: '',
                description: '',
                is_feature: '0',
                status: '1',
                photo: '',
            }).rules({
                name: 'required',
                slug: 'required',
            }),
        };
    },

    methods: {
        createVisaCategory() {
            if (this.form.validate().errors().any()) {
                return;
            } else {
                this.isSubmitting = true;
                VisaApi.post("/visa-category/create", this.form.all(), {headers: {"Content-Type": "multipart/form-data"}}).then((response) => {
                    if (response.code == '201') {
                        this.$toast.success(response.message, {
                            position: "top-right",
                            dismissible: true,
                        });
                        this.$router.push({name: 'visa-category-list'})
                    }
                    this.isSubmitting = false;
                }).catch((error) => {
                    this.$toast.error(error.response.data.message, {
                        position: "top-right",
                        dismissible: true,
                    });
                    this.isSubmitting = false;
                });
            }
        },
        getPhotoValue(photo) {
            this.form.photo = photo.value
        },
    },
    computed: {
        slug() {
            return this.form.slug = sanitizeName(this.form.name);
        },
    },

    watch: {
        slug() {
        },
    },

    mounted() {
        this.breadcrumb = this.$router.currentRoute.value.meta.breadcrumb;
    }
}
</script>
