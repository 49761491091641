<template>
    <div class="p-4">
        <Breadcrumb :text="breadcrumb"/>

        <!-- card start -->
        <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 mt-4">

            <div class="visasis-card">
                <div class="flex items-center justify-between">
                    <div>
                        <p class="font-bold text-slate-600">Revinue</p>
                        <p class="font-bold text-lg text-gray-500">1290.00 <span class="pl-1">&#2547;</span></p>
                    </div>
                    <div class="bg-white p-2 rounded">
                        <img alt="image" class="w-6 h-6" src="@/assets/images/cog.svg">
                    </div>
                </div>
            </div>

            <div class="visasis-card">
                <div class="flex items-center justify-between">
                    <div>
                        <p class="font-bold text-slate-600">Sales</p>
                        <p class="font-bold text-lg text-gray-500">233434.00 <span class="pl-1">&#2547;</span></p>
                    </div>
                    <div class="bg-white p-2 rounded">
                        <img alt="image" class="w-6 h-6" src="@/assets/images/fees.svg">
                    </div>
                </div>
            </div>

            <div class="visasis-card">
                <div class="flex items-center justify-between">
                    <div>
                        <p class="font-bold text-slate-600">Purchase</p>
                        <p class="font-bold text-lg text-gray-500">65465.23 <span class="pl-1">&#2547;</span></p>
                    </div>
                    <div class="bg-white p-2 rounded">
                        <img alt="image" class="w-6 h-6" src="@/assets/images/category.svg">
                    </div>
                </div>
            </div>

            <div class="visasis-card">
                <div class="flex items-center justify-between">
                    <div>
                        <p class="font-bold text-slate-600">Sale Return</p>
                        <p class="font-bold text-lg text-gray-500">6534.56 <span class="pl-1">&#2547;</span></p>
                    </div>
                    <div class="bg-white p-2 rounded">
                        <img alt="image" class="w-6 h-6" src="@/assets/images/adjust.svg">
                    </div>
                </div>
            </div>

        </div>
        <!-- card end -->

        <div class="grid grid-cols-1 md:grid-cols-6 gap-4 mt-8">

            <div class="md:col-span-4">
                <div class="visasis-card">
                    <p class="text-slate-600 font-bold">Chart: Visa per-month</p>
                    <div>
                        <Line :data="line_chart_data" :options="line_chart_options" class="h-full"/>
                    </div>
                </div>
            </div>

            <div class="md:col-span-2">
                <div class="visasis-card">
                    <p class="mt-4 px-4 py-2 text-center text-lg bg-slate-100 rounded">Top Customers</p>
                    <div class="rounded pb-6">
                        <div class="mt-2 rounded">
                            <div class="flex justify-between px-3 py-4 mt-1 bg-slate-100">
                                <div class="flex">
                                    <img alt="btc" class="w-6 h-6 mr-3" src="@/assets/images/cog.svg">
                                    <div>
                                        <p class="text-lg">Customer-1</p>
                                        <p class="">+ 122</p>
                                        <p class="text-sm">Lorem ipsum dolor sit amet, onsectetur adipiscing elig.</p>
                                    </div>
                                </div>
                                <p class="text-xs">11/02/2023</p>
                            </div>
                            <div class="flex justify-between px-3 py-4 mt-1 bg-slate-100">
                                <div class="flex">
                                    <img alt="btc" class="w-6 h-6 mr-3" src="@/assets/images/cog.svg">
                                    <div>
                                        <p class="text-lg">Customer-2</p>
                                        <p class="">- $10,00</p>
                                        <p class="text-sm">Lorem ipsum dolor sit amet, onsectetur adipiscing elig.</p>
                                    </div>
                                </div>
                                <p class="text-xs">11/02/2023</p>
                            </div>
                            <div class="flex justify-between px-3 py-4 mt-1 bg-slate-100">
                                <div class="flex">
                                    <img alt="btc" class="w-6 h-6 mr-3" src="@/assets/images/cog.svg">
                                    <div>
                                        <p class="text-lg">Customer-3</p>
                                        <p class="">+ $10,00</p>
                                        <p class="text-sm">Lorem ipsum dolor sit amet, onsectetur adipiscing elig.</p>
                                    </div>
                                </div>
                                <p class="text-xs">11/02/2023</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import {CategoryScale, Chart as ChartJS, Legend, LinearScale, LineElement, PointElement, Title, Tooltip} from 'chart.js'
import {Line} from 'vue-chartjs'
import Breadcrumb from '@/component/Breadcrumb'

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
)

export default {
    name: 'Dashboard',
    components: {Line, Breadcrumb,},
    data() {
        return {
            breadcrumb: '',
            line_chart_data: {
                labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
                datasets: [
                    {
                        label: 'Data One',
                        backgroundColor: '#f87979',
                        data: [40, 39, 10, 40, 39, 80, 40]
                    }
                ]
            },

            line_chart_options: {
                responsive: true,
                maintainAspectRatio: false
            },

        };
    },
    methods: {},
    computed: {},
    mounted() {
        this.breadcrumb = this.$router.currentRoute.value.meta.breadcrumb;
    }
}
</script>
<style scoped>
canvas {
    height: 350px;
}
</style>
