<template>
    <Transition name="fade">
        <div v-if="is_open" id="delete_modal" class="relative z-10">
            <div class="fixed inset-0 bg-gray-500 bg-opacity-75"></div>
            <div class="fixed inset-0 z-10 overflow-y-auto">
                <div class="flex min-h-full items-end justify-center text-center sm:items-center sm:p-0">
                    <div
                        class="relative overflow-hidden rounded-lg bg-white text-left shadow-xl sm:my-8 sm:w-full sm:max-w-lg">
                        <div class="text-gray-600 pb-4">
                            <h3 class="py-2 px-4 bg-slate-700 text-white border-b">{{ modal_title }}</h3>
                            <p class="p-4">Do you want to <span class="text-red-600">delete</span> this?</p>
                        </div>
                        <div class="bg-gray-200 px-4 py-2 flex justify-end items-center">
                        <span>
                            <span v-if="isDeleteSubmitting">
                                <LoadingButton text="Deleting"/>
                            </span>
                            <button v-else class="inline-flex justify-center rounded-md border border-transparent bg-red-600 px-4 py-1 mr-3 text-white hover:bg-red-700" type="button"
                                    @click="$emit('submit')">
                                Yes
                            </button>
                        </span>
                            <button class="inline-flex justify-center rounded-md border border-transparent bg-green-600 px-4 py-1 text-white hover:bg-green-700" type="button"
                                    @click="$emit('close')">
                                No
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Transition>
</template>

<script>
import LoadingButton from "@/component/LoadingButton"

export default {
    name: 'DeleteModal',
    components: {LoadingButton},
    props: ['modal_title', 'isDeleteSubmitting', 'is_open'],
};
</script>
