import VisaApi from "../../libs/axios";

export default {
    namespaced: true,
    state() {
        return {
            visas: [],
            visa_streams: [],
        }
    },
    getters: {
        visas: state => state.visas,
        visa_streams: state => state.visa_streams,
    },
    mutations: {
        SET_VISAS(state, data) {
            state.visas = data;
        },
        SET_VISA_STREAMS(state, data) {
            state.visa_streams = data;
        }
    },
    actions: {
        async getAllVisa({commit}) {
            return new Promise(async (resolve, reject) => {
                await VisaApi.get('visa').then((res) => {
                    commit("SET_VISAS", res.data)
                    resolve(res.data)
                }).catch((err) => {
                    reject(err.response)
                })
            })
        },
        async getAllVisaStream({commit}) {
            return new Promise(async (resolve, reject) => {
                await VisaApi.get('visa-stream').then((res) => {
                    commit("SET_VISA_STREAMS", res.data)
                    resolve(res.data)
                }).catch((err) => {
                    reject(err.response)
                })
            })
        },
    },
}
