<template>
    <div class="px-4 py-2 absolute w-full">

        <Breadcrumb :text="breadcrumb"/>

        <div class="visasis-card">
            <div class="flex flex-wrap items-center justify-end -mx-4">
                <TableEntryShow @input="[meta.current_page = 1, meta.per_page = $event]">
                    <template #default>
                        <div class="filter-options">
                            <span> Filter </span>
                            <select v-model="type" class="px-3 py-1 mx-1.5 rounded bg-white border border-gray-300">
                                <option value="">Select</option>
                                <option v-for="data in types" :key="data.value" :value="data.value">{{ data.text }}
                                </option>
                            </select>
                        </div>
                    </template>
                </TableEntryShow>
                <TableTopActions need_import="true" to="/group-create"
                                 @import="is_open_import_modal = true"
                                 @input="[meta.current_page = 1, search_input = $event]"/>
            </div>
            <vue-good-table
                :columns="columns"
                :rows="rows"
            >
                <template #table-row="props">
                    <div v-if="props.column.field == 'name'">
                        <p>{{ props.row.code }} - {{ props.row.name }}</p>
                    </div>
                    <div v-if="props.column.field == 'details'">
                        <div class="flex flex-row">
                            <p v-if="props.row.details != null && props.row.details.description && props.row.details.description.length >= 20"
                               class="whitespace-nowrap" v-html="props.row.details.description.slice(0, 20)"></p>
                            <p v-else-if="props.row.details != null && props.row.details.description"
                               class="whitespace-nowrap" v-html="props.row.details.description"></p>
                            <update-icon class="mt-1" @click="openDetailsModal(props.row)"/>
                        </div>
                    </div>
                    <div v-if="props.column.field == 'type'">
                        <p v-show="props.row.type==1">Major</p>
                        <p v-show="props.row.type==2">Sub Major</p>
                        <p v-show="props.row.type==3">Minor</p>
                        <p v-show="props.row.type==4">Unit</p>
                    </div>
                    <div v-if="props.column.field == 'status'">
                        <p v-if="props.row.status==1" class="w-min px-2 rounded m-auto text-white bg-green-700">
                            Active</p>
                        <p v-else class="w-min px-2 rounded m-auto text-white bg-orange-700">Inactive</p>
                    </div>
                    <div v-if="props.column.field == 'action'">
                        <div class="px-3 py-2 flex items-center justify-center">
                            <router-link :to="{ name: 'group-view', params: {id:props.row.uuid} }">
                                <view-icon/>
                            </router-link>
                            <router-link :to="{ name: 'group-update', params: {id:props.row.uuid} }">
                                <update-icon/>
                            </router-link>
                            <delete-icon @click="[uuid_deletable = props.row.uuid, is_open_delete_modal = true]"/>
                        </div>
                    </div>
                </template>
            </vue-good-table>
            <TablePagination
                :inputPage="meta.current_page"
                :meta='meta'
                :next_of_next='next_of_next_status(meta)'
                :prev_of_prev='prev_of_prev_status(meta)'
                @update:inputPage="meta.current_page = $event"
            />
        </div>

        <!-- modal -->
        <FormModal :is_open="is_open_details_modal" :is_submitting="isDetailsModalSubmitting" :title='mdl_title'
                   @close="is_open_details_modal = false" @submit="submitAnzscoDetails">
            <template v-slot:body>
                <div class="grid grid-cols-1 md:grid-cols-1 gap-x-4 gap-y-2 mt-2 px-2">
                    <div class="w-full">
                        <label class="flex items-center">Description <img alt="required"
                                                                          class="w-2 h-2 ml-1" src="@/assets/images/star.svg">
                        </label>
                        <article class="prose" style="max-width: 100%">
                            <ckeditor v-model="details.description" :config="editorConfig" :editor="editor"/>
                        </article>
                    </div>
                </div>
            </template>
        </FormModal>

        <!-- Import -->
        <ImportModal
            :demo_link="demo_anzsco_link"
            :demo_link_label="'Download Demo Anzsco Group File'"
            :is_open="is_open_import_modal"
            :is_submitting="isSubmitting"
            title="Import ANZSCO Group"
            @close="closeImportModal"
            @submit="importExcelFile"
        />

        <!-- delete modal -->
        <DeleteModal
            :isDeleteSubmitting="isDeleteSubmitting"
            :is_open="is_open_delete_modal"
            modal_title='Delete Group'
            @close="[isDeleteSubmitting = false, is_open_delete_modal = false]"
            @submit="deleteGroup"
        />

    </div>
</template>

<script>
import VisaApi from "@/libs/axios"
import store from '@/store'
import DeleteModal from '@/component/DeleteModal'
import ImportModal from "../../../component/ui/modals/ImportModal.vue";
import Table from '@/component/Table'
import Breadcrumb from '@/component/Breadcrumb'
import TablePagination from '@/component/TablePagination'
import form from 'vuejs-form'
import LoadingButton from "@/component/LoadingButton"
import {VueGoodTable} from 'vue-good-table-next'
import 'vue-good-table-next/dist/vue-good-table-next.css'
import TableEntryShow from "../../../component/table/TableEntryShow.vue";
import TableTopActions from "../../../component/table/TableTopActions.vue";
import {meta_data, next_of_next_status, prev_of_prev_status,} from '@/libs/helpers.js'
import {DownloadIcon} from "../../../data/icons";
import ViewIcon from "../../../data/icons/ViewIcon.vue";
import UpdateIcon from "../../../data/icons/UpdateIcon.vue";
import DeleteIcon from "../../../data/icons/DeleteIcon.vue";
import AddIcon from "../../../data/icons/AddIcon.vue";
import FormModal from "../../../component/ui/modals/FormModal.vue";
import TextInput from "../../../component/ui/forms/TextInput.vue";
import CKEditor from "@ckeditor/ckeditor5-vue"
import {ClassicEditor} from 'ckeditor5';
import ckeditorConfig from "../../../libs/ckeditorConfig";

export default {
    components: {
        TextInput,
        FormModal,
        AddIcon,
        DeleteIcon,
        UpdateIcon,
        ckeditor: CKEditor.component,
        ViewIcon, TableEntryShow, TableTopActions,
        DownloadIcon, DeleteModal, Table, Breadcrumb, TablePagination, LoadingButton, VueGoodTable, ImportModal
    },
    data() {
        return {
            uuid_deletable: '',

            meta: meta_data,
            prev_of_prev: false,
            next_of_next: false,
            currentPage: '',
            demo_anzsco_link: process.env.VUE_APP_ASSET_FILE_URL + '/demo_anzsco_group.xlsx',
            next_of_next_status,
            prev_of_prev_status,
            search_input: '',
            breadcrumb: '',
            url: '',
            sort_status: false,
            isSubmitting: false,
            isDeleteSubmitting: false,
            is_open_import_modal: false,
            is_open_delete_modal: false,
            mdl_title: "Anzsco description",
            is_open_details_modal: false,
            isDetailsModalSubmitting: false,
            editor: ClassicEditor,
            editorConfig: ckeditorConfig,

            form: form.default({
                file: '',
            }).rules({
                file: 'required',
            }),

            details: {
                uuid: '',
                code: '',
                type: '',
                description: ''
            },

            columns: [
                {label: 'Name', field: 'name'},
                {label: 'Description', field: 'details'},
                {label: 'Type', field: 'type'},
                {label: 'Status', field: 'status'},
                {label: 'Action', field: 'action', sortable: false, width: '100px'}
            ],
            rows: [],
            types: [
                {value: 1, text: 'Major'},
                {value: 2, text: 'Sub-Major'},
                {value: 3, text: 'Minor'},
                {value: 4, text: 'Unit'},
            ],
            type: "",
        };
    },

    methods: {
        async getGroupList() {
            store.commit('setting/LOADING_ON')
            await VisaApi.get(this.apiUrl).then((response) => {
                store.commit('setting/LOADING_OFF')
                if (response.success == true) {
                    if (response.data.meta.to > 50) {
                        this.meta.per_page = 'All'
                    } else {
                        Object.assign(this.meta, response.data.meta);
                    }
                    this.rows = response.data.data;
                }
            });
        },

        deleteGroup() {
            this.isDeleteSubmitting = true;
            VisaApi.delete("/anzsco-group/" + this.uuid_deletable).then((response) => {
                if (response.success == true) {
                    this.getGroupList();
                    this.$toast.success(response.message, {
                        position: "top-right",
                        dismissible: true,
                    });
                    this.isDeleteSubmitting = false;
                    this.is_open_delete_modal = false
                }
            }).catch((error) => {
                this.isDeleteSubmitting = false
                this.is_open_delete_modal = false
                if (error.response.data.code == '400') {
                    this.$toast.error('This category has a child item!', {
                        position: "top-right",
                        dismissible: true,
                    });

                } else {
                    this.$toast.error(error.response.data.message, {
                        position: "top-right",
                        dismissible: true,
                    });
                }
            });
        },

        closeImportModal() {
            this.isSubmitting = false
            this.is_open_import_modal = false
        },

        importExcelFile(file) {
            if (file == '') {
                this.$toast.error('No file selected!', {
                    position: "top-right",
                    dismissible: true,
                });
                return;
            } else {
                VisaApi.post("/anzsco-group/import", {file: file}, {headers: {"Content-Type": "multipart/form-data"}}).then((response) => {
                    this.$toast.success(response.message, {
                        position: "top-right",
                        dismissible: true,
                    });
                    this.closeImportModal();
                    this.getGroupList();
                }).catch((error) => {
                    this.$toast.error(error.response.data.message, {
                        position: "top-right",
                        dismissible: true,
                    });
                    this.closeImportModal();
                });
            }
        },

        updatePageValue(newValue) {
            this.currentPage = newValue;
        },

        openDetailsModal(data) {
            this.details.code = data.code
            this.details.type = data.type
            this.is_open_details_modal = true

            if (data.details != null) {
                this.details.uuid = data.details.uuid
                this.details.description = data.details.description
            }
        },
        submitAnzscoDetails() {
            let details;
            if (this.details.uuid != '') {
                details = VisaApi.patch(`anzsco-details/update/${this.details.uuid}`, this.details)
            } else {
                details = VisaApi.post('anzsco-details/store', this.details)
            }
            details.then((response) => {
                if (response.code == '201') {
                    this.$toast.success(response.message, {
                        position: "top-right",
                        dismissible: true,
                    });
                }
                this.getGroupList()
                this.is_open_details_modal = false;
            }).catch((error) => {
                this.$toast.error(error.response.data.message, {
                    position: "top-right",
                    dismissible: true,
                });
                this.is_open_details_modal = false;
            });
        }
    },

    computed: {
        apiUrl() {
            return 'anzsco-group?page=' + this.meta.current_page + '&per_page=' + this.meta.per_page + '&order_by=name&order_by_type=desc&type=' + this.type + '&search=' + this.search_input;
        }
    },

    watch: {
        apiUrl() {
            this.getGroupList();
        },
    },

    mounted() {
        this.getGroupList();
        this.breadcrumb = this.$router.currentRoute.value.meta.breadcrumb;
    }
}
</script>
