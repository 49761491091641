<template>
    <div class="px-4 py-2 absolute w-full">

        <div class="flex flex-wrap items-center justify-between">
            <Breadcrumb :text="breadcrumb"/>
            <router-link to="/menu-list">
                <button class="rounded-md bg-red-600 px-6 inline-flex py-2 text-white hover:bg-red-500" type="button">
                    <back-icon class="mr-2 mt-1"/>
                    Back to list
                </button>
            </router-link>
        </div>

        <div class="visasis-card">
            <div class="grid grid-cols-1 md:grid-cols-2 gap-x-4 gap-y-2">
                <div class="w-full">
                    <label class="flex items-center">Title <img alt="required" class="w-2 h-2 ml-1"
                                                                src="@/assets/images/star.svg"> </label>
                    <input v-model='form.title' class="py-2 rounded border border-gray-300 bg-gray-50 text-sm placeholder-gray-400 focus:border-invblue resize-none w-full px-4 mt-1" placeholder="Title"
                           type='text'>
                    <span v-if='form.errors().has("title")' class="text-red-800 text-xs">
                        {{ form.errors().get('title') }}
                    </span>
                </div>
                <div class="w-full">
                    <label class="flex items-center">Location <img alt="required" class="w-2 h-2 ml-1"
                                                                   src="@/assets/images/star.svg"> </label>
                    <select v-model='form.location'
                            class="py-2 rounded border border-gray-300 bg-gray-50 text-sm placeholder-gray-400 focus:border-invblue resize-none w-full px-4 mt-1">
                        <option :selected="form.location=='main'" value="main">Main</option>
                        <option :selected="form.location=='footer'" value="footer">Footer</option>
                    </select>
                    <span v-if='form.errors().has("location")' class="text-red-800 text-xs">
                        {{ form.errors().get('location') }}
                    </span>
                </div>
                <div class="w-full">
                    <label>Status</label>
                    <select v-model='form.is_active'
                            class="py-2 rounded border border-gray-300 bg-gray-50 text-sm placeholder-gray-400 focus:border-invblue resize-none w-full px-4 mt-1">
                        <option :selected="form.is_active=='1'" value="1">Active</option>
                        <option :selected="form.is_active=='0'" value="0">Inactive</option>
                    </select>
                </div>
            </div>

            <button v-if="isSubmitting==false" class="mt-4 justify-center rounded-md bg-blue-600 px-6 py-1.5 text-white hover:bg-blue-500" type="button"
                    @click="update">
                Submit
            </button>
            <span v-else class="mt-4 block">
                <LoadingButton text="Submitting"/>
            </span>

        </div>

        <div class="mt-2 h-full rounded">
            <menu-item-list/>
        </div>
    </div>
</template>

<script>
import VisaApi from "@/libs/axios"
import form from 'vuejs-form'
import Breadcrumb from '@/component/Breadcrumb'
import LoadingButton from "@/component/LoadingButton"
import MenuItemList from "./MenuItemList.vue";
import {AddIcon} from "../../../data/icons";
import BackIcon from "../../../data/icons/BackIcon.vue";

export default {
    name: "CreateMenu",
    components: {BackIcon, AddIcon, LoadingButton, Breadcrumb, MenuItemList},

    data() {
        return {
            breadcrumb: '',
            isSubmitting: false,
            menu_id: '',

            form: form.default({
                id: '',
                title: '',
                location: '',
                is_active: '',
            }).rules({
                title: 'required',
                location: 'required',
            }),
        };
    },

    methods: {
        update() {
            if (this.form.validate().errors().any()) {
                return;
            } else {
                this.isSubmitting = true;
                VisaApi.patch("/frontend/menus/" + this.form.id, this.form.all()).then((response) => {
                    if (response.code == '200') {
                        this.$toast.success(response.message, {
                            position: "top-right",
                            dismissible: true,
                        });
                        this.$router.push({name: 'menu-list'})
                    }
                    this.isSubmitting = false;
                }).catch((error) => {
                    this.$toast.error(error.response.data.message, {
                        position: "top-right",
                        dismissible: true,
                    });
                    this.isSubmitting = false;
                });
            }
        },

        getSingleData(id) {
            VisaApi.get("/frontend/menus/" + id).then((response) => {
                if (response.code == '200') {
                    Object.assign(this.form, response.data);
                }
            });
        },
    },

    mounted() {
        this.breadcrumb = this.$router.currentRoute.value.meta.breadcrumb;
        this.menu_id = this.$route.params.id
        this.getSingleData(this.$route.params.id);
    }
}
</script>
