import axios from 'axios'
import store from "../store"

const VisaApi = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    timeout: 30000,
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Accept': 'application/json'
    }
})

const if401 = (config) => {
    if (localStorage.getItem('refresh')) {
        store.dispatch('user/refresh_token', localStorage.getItem('refresh')).then((res) => {
            config.headers['Authorization'] = 'Bearer ' + res.access_token
            // window.location.reload()
            return axios(config);
        }).catch((err) => {
            console.log(err)
        })
    } else {
        store.dispatch('user/logout').then(() => window.location.href = '/login')
    }
}

// Intercept request
VisaApi.interceptors.request.use(
    (config) => {
        if (localStorage.getItem('token') != null) {
            config.headers['Authorization'] = 'Bearer ' + localStorage.getItem('token')
        }
        // store.commit('setting/LOADING_ON')
        return config;
    },
    (error) => {
        // store.commit('setting/LOADING_ON')
        return Promise.reject(error);
    });

// Intercept each axios response
VisaApi.interceptors.response.use(
    response => {
        const res = response.data
        if (res.code === undefined) {
            res.code = 200
        }
        if (res.code === 200 || res.code === 201) {
            store.commit('setting/LOADING_OFF')
            return res
        } else {
            if (res.code === 401) {
                if401(response.config)
            } else {
                store.commit('setting/LOADING_OFF')
                return Promise.reject(new Error(res.message || 'Error'))
            }
        }
    },
    async error => {
        console.log(error)
        if (error.code === 'ERR_NETWORK') {
            console.log(error)
            // store.dispatch('user/logout').then(() => window.location.href = '/login' )
        }
        if (error.response.status === 401) {
            if401(error.config)
        }
        // store.commit('setting/LOADING_OFF')
        return Promise.reject(error);
    });

export default VisaApi
