<template>
    <div class="bg-slate-50 h-screen">
        <loading/>
        <router-view/>
    </div>
</template>

<script>
import Loading from "./component/Loading";
import {computed, onMounted} from "vue";
import store from "./store";

export default {
    components: {Loading},
    setup() {
        let settings = computed(() => store.getters["setting/settings"])

        const updateTitle = () => {
            if (settings.value != null) {
                document.title = settings.value.title
            }
        }
        const updateFavicon = () => {
            let link = document.querySelector("link[rel*='icon']")
            link.type = 'image/x-icon';
            link.rel = 'shortcut icon';
            if (settings.value != null) {
                link.href = settings.value.favicon;
            }
        }
        onMounted(() => {
            updateTitle()
            updateFavicon()
        })

    }
}
</script>
