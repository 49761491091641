<template>
    <div class="px-4 py-2 absolute w-full">
        <div class="flex flex-wrap items-center justify-between pb-2 border-b border-gray-300">
            <Breadcrumb :text="breadcrumb"/>
            <router-link to="/menu-items-list">
                <button class="back-btn" type="button">
                    <back-icon class="mr-2 mt-1"/>
                    Back to list
                </button>
            </router-link>
        </div>

        <div class="p-4 mt-2 h-full rounded shadow-md bg-slate-100">
            <p class="w-max px-4 py-1 mb-2 font-bold rounded uppercase bg-gray-300 border-gray-400">
                Menu Items
            </p>
            <div class="grid grid-cols-1 gap-2 p-4 w-full rounded bg-white">
                <div class="w-full flex items-center">
                    <label class="font-bold">Category:</label>
                    <p v-if="data.parent != null" class="pl-2">{{ data.parent.title }}</p>
                </div>
                <div class="w-full flex items-center">
                    <label class="font-bold">Title:</label>
                    <p class="pl-2">{{ data.title }}</p>
                </div>
                <div class="w-full flex items-center">
                    <label class="font-bold">Link:</label>
                    <p class="pl-2">{{ data.link }}</p>
                </div>
                <div class="w-full flex items-center">
                    <label class="font-bold">Sort Order:</label>
                    <p class="pl-2">{{ data.sort_order }}</p>
                </div>
                <div class="w-full flex items-center">
                    <label class="font-bold">Status:</label>
                    <p v-if="data.is_active==1" class="pl-2">Active</p>
                    <p v-else class="pl-2">Inactive</p>
                </div>
                <div class="w-full flex items-center">
                    <label class="font-bold">Header Status:</label>
                    <p v-if="data.is_header==1" class="pl-2">Active</p>
                    <p v-else class="pl-2">Inactive</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import VisaApi from "@/libs/axios"
import Breadcrumb from '@/component/Breadcrumb'
import BackIcon from "../../../data/icons/BackIcon.vue";

export default {
    components: {BackIcon, Breadcrumb,},
    data() {
        return {
            breadcrumb: '',
            data: {},
        };
    },

    methods: {
        getSingleData(menu_id, id) {
            VisaApi.get("/frontend/menus/" + menu_id + "/menu-items/" + id).then((response) => {
                if (response.code == '200') {
                    this.data = response.data;
                }
            });
        },
    },

    mounted() {
        this.breadcrumb = this.$router.currentRoute.value.meta.breadcrumb;
        this.getSingleData(this.$route.params.menu_id, this.$route.params.id);
    }
}
</script>
