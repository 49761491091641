<template>
    <div class="w-full">
        <label class="flex items-center text-gray-600">
            {{ label }}<img v-if="required" alt="required" class="w-2 h-2 ml-1" src="@/assets/images/star.svg">
        </label>
        <input v-model="input_field"
               :placeholder="label"
               class="py-2 rounded border text-gray-500 border-gray-300 bg-gray-50 text-sm placeholder-gray-400 focus:border-gray-400 focus-visible:border-gray-400 focus-visible:border-opacity-30 resize-none w-full px-4 mt-1" type='text'/>
        <span class="text-red-800 text-xs">{{ error }}</span>
    </div>
</template>

<script>
import {ref, watch} from "vue";

export default {
    props: {
        label: String,
        model: String,
        required: {
            type: Boolean,
            default: false
        },
        field: String,
        error: {
            type: String,
            required: false
        }
    },
    setup(props, {emit}) {
        const input_field = ref(props.model);

        watch(input_field, (val) => {
            emit('input-value', {
                'field': props.field,
                'value': val
            })
        })

        return {
            input_field,
        }
    }
}

</script>

<style scoped>

</style>
