<template>
    <div class="w-full">
        <label class="flex items-center text-gray-600">
            {{ label }}<img v-if="required" alt="required" class="w-2 h-2 ml-1" src="@/assets/images/star.svg">
        </label>
        <div class="image-uploader">
            <div
                class="dropzone"
                @dragenter="onDragEnter"
                @dragleave="onDragLeave"
                @drop="onFileDrop"
                @dragover.prevent
            >
                <input ref="uploadInput" style="display: none" type="file" @change="onFileInputChange"/>
                <AddIcon class="add" @click="uploadInput.click()"/>
                <ul v-if="!isDragging && uploadedImages.length > 0" class="uploaded-images">
                    <li v-for="(image, index) in uploadedImages" :key="index">
                        <CloseIcon class="close" @click="removeItem(image, index)"/>
                        <img :src="image" alt="Uploaded Image"/>
                    </li>
                </ul>
                <p v-if="has_error">{{message}}</p>
            </div>
        </div>
    </div>
</template>

<script>
import {computed, onMounted, ref} from "vue";
import CloseIcon from "../../../data/icons/CloseIcon.vue";
import AddIcon from "../../../data/icons/AddIcon.vue";

export default {
    components: {CloseIcon, AddIcon},
    props: {
        label: String,
        required: {
            type: Boolean,
            default: false
        },
        field: String,
        model: [String, Object],
        max: {
            type: Number,
            required: false
        },
        error: {
            type: String,
            required: false
        }
    },
    setup(props, {emit}) {
        const files = ref([]);
        const isDragging = ref(false);
        const uploadedImages = ref([props.model]);
        const has_error = ref(false)

        const message = ref('')

        const onDragEnter = () => {
            isDragging.value = true;
        }
        const onDragLeave = () => {
            isDragging.value = false;
        }
        const onFileDrop = (event) => {
            event.preventDefault();
            isDragging.value = false;

            const files = event.dataTransfer.files;
            processFiles(files);
        }

        const onFileInputChange = (event) => {
            const files = event.target.files;
            if (files.length > 0) {
                uploadedImages.value.pop()
            }
            processFiles(files);
        }
        const processFiles = (files) => {
            let total = uploadedImages.value.length + files.length;
            if (total > props.max + 1) {
                has_error.value = true
                message.value = `Maximum ${props.max} file allowed`
                return;
            }

            renderFiles(files)
        }

        const renderFiles = (files) => {
            for (let i = 0; i < files.length; i++) {
                const file = files[i];
                emit('file-uploaded', {
                    'field': props.field,
                    'value': file
                })
                if (file.type.startsWith("image/")) {
                    const reader = new FileReader();

                    reader.onload = (event) => {
                        uploadedImages.value.push(event.target.result);
                    };

                    reader.readAsDataURL(file);
                }
            }
        }

        onMounted(() => {
            if (uploadedImages.value.length == 1 && (uploadedImages.value == '' || uploadedImages.value == null)) {
                uploadedImages.value.pop()
            }
            setTimeout(() => {
                if (uploadedImages.value.length <= 0) {
                    has_error.value = true
                    message.value = "Drag and drop photo to upload"
                }
            }, 200)

        })

        const uploadInput = ref('uploadInput');

        const removeItem = (image, index) => {
            uploadedImages.value.splice(index, 1)
            if (uploadedImages.value.length <= 0) {
                has_error.value = true
                message.value = "Drag and drop photo to upload"
                emit('file-uploaded', {
                    'field': props.field,
                    'value': 'no'
                })
            } else {
                renderFiles(uploadedImages.value)
            }
        }

        return {
            message,
            has_error,
            onFileInputChange,
            onDragEnter,
            onDragLeave,
            isDragging,
            uploadedImages,
            files,
            onFileDrop,
            uploadInput,
            removeItem
        }
    }
}

</script>

<style>
.image-uploader {
    margin: 0 auto;
    width: 100%;
    position: relative;
    height: 200px;
}

.dropzone {
    text-align: center;
    padding: 30px;
    border: 2px dashed #ccc;
    border-radius: 5px;
    cursor: pointer;
    width: 100%;
    height: 200px;
}

.image-uploader .dropzone .add {
    color: #027925;
    position: absolute;
    right: 35px;
}

.uploaded-images {
    margin-top: 20px;
    list-style: none;
    display: flex;
    gap: 50px;
}

.uploaded-images img {
    width: 100%;
    height: 100px;
    margin: 5px;
}

.uploaded-images .close {
    color: #ce0000;
    position: absolute;
    margin: -20px 0;
}

.uploaded-images .close:hover {
    background-color: #ce0000;
    color: #ffffff;
}
</style>
