import {createToaster} from "@meforma/vue-toaster";

export const sanitizeName = (text, ampersand = 'and') => {
    const a = 'àáäâèéëêìíïîòóöôùúüûñçßÿỳýœæŕśńṕẃǵǹḿǘẍźḧ'
    const b = 'aaaaeeeeiiiioooouuuuncsyyyoarsnpwgnmuxzh'
    const p = new RegExp(a.split('').join('|'), 'g')

    return text.toString().toLowerCase()
        .replace(/[\s_]+/g, '-')
        .replace(p, c =>
            b.charAt(a.indexOf(c)))
        .replace(/&/g, `-${ampersand}-`)
        .replace(/[^\w-]+/g, '')
        .replace(/--+/g, '-')
        .replace(/^-+|-+$/g, '')
}

export const meta_data = {
    from: 1,
    to: 0,
    current_page: 1,
    prev_page: null,
    next_page: 0,
    per_page: 10,
    total: 0
};

export const next_of_next_status = (meta) => {
    let tmp_val = meta.total - (meta.per_page * meta.current_page);
    if (tmp_val > meta.per_page) {
        return true;
    } else {
        return false;
    }
};

export const prev_of_prev_status = (meta) => {
    if (meta.current_page >= 10) {
        return true;
    } else {
        return false;
    }
};

export const settings = () => {
    return localStorage.getItem('settings') !== null ? JSON.parse(localStorage.getItem('settings')) : null
}

export const limitString = (inputString, maxLength) => {
    return inputString.length <= maxLength ? inputString : inputString.substr(0, maxLength) + '...';
}

export const toaster = createToaster({
    position: "top-right",
    dismissible: true,
})
