<template>
    <div class="px-4 py-2 absolute w-full">

        <Breadcrumb :text="breadcrumb"/>

        <div class="visasis-card">
            <div class="w-full md:w-6/12 lg:w-4/12 mb-8">
                <label class="font-bold">Select Role</label>
                <v-select
                    v-model="form.role_id"
                    :options="roleList"
                    :reduce="role => role.id"
                    class="pt-1"
                    label="title"
                    placeholder="Select role"
                />
            </div>

            <div class="w-full mt-4">
                <p class="mb-3 font-bold">Select Permissions</p>
                <div class="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-4">
                    <div v-for="(v, k, index) in permission_list" :key="index" class="">
                        <p class="uppercase mb-2 font-bold"><u> {{ k }} </u></p>
                        <div class="mb-4">
                            <div v-for="permission in v" :key="permission.id" class="flex items-center space-x-2 mb-3">
                                <input :id="'role-'+permission.id" v-model="form.permission_ids" :checked="true"
                                       :name="permission.id" :value="permission.id" class="w-5 h-5 text-blue-400 border-gray-200 rounded-md focus:ring focus:ring-blue-200"
                                       type="checkbox">
                                <label :for="'role-'+permission.id" class="text-gray-700 capitalize"> {{
                                        permission.name
                                    }} </label><br>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="text-center">
                <button v-if="isSubmitting==false" class="px-4 py-1 rounded text-white bg-green-600 hover:bg-green-700"
                        @click="setPermission">
                    Set Permissions
                </button>
                <span v-else>
                    <LoadingButton text="Submitting"/>
                </span>
            </div>

        </div>
    </div>
</template>

<script>
import VisaApi from "@/libs/axios"
import form from 'vuejs-form'
import store from '@/store'
import Breadcrumb from '@/component/Breadcrumb'
import LoadingButton from "@/component/LoadingButton"
import vSelect from "vue-select"
import "vue-select/dist/vue-select.css"

export default {
    components: {LoadingButton, Breadcrumb, "v-select": vSelect,},
    data() {
        return {
            permission_list: [],
            breadcrumb: '',
            isSubmitting: false,
            roleList: [
                {id: 2, title: 'Admin'},
                {id: 3, title: 'Agent'},
                {id: 4, title: 'User'},
            ],

            form: form.default({
                id: '',
                uuid: '',
                role_id: 2,
                permission_ids: [],
            }).rules({
                permission_ids: 'required',
                role_id: 'required'
            }),
        };
    },

    methods: {
        setPermission() {
            store.commit('setting/LOADING_ON')
            if (this.form.validate().errors().any()) {
                store.commit('setting/LOADING_OFF')
                return;
            } else {
                this.isSubmitting = true;
                VisaApi.post("/permissions/set", this.form.all()).then((response) => {
                    store.commit('setting/LOADING_OFF')
                    this.$toast.success(response.message, {
                        position: "top-right",
                        dismissible: true,
                    });
                    this.isSubmitting = false;
                }).catch((error) => {
                    this.$toast.error(error.response.data.message, {
                        position: "top-right",
                        dismissible: true,
                    });
                    this.isSubmitting = false;
                })
            }
            ;
        },

        async getAllPermissions() {
            store.commit('setting/LOADING_ON')
            await VisaApi.get("/permissions").then((response) => {
                store.commit('setting/LOADING_OFF')
                this.permission_list = [];
                if (response.code == '200') {
                    this.permission_list = response.data;
                }
            });
        },

        getRolePermissions() {
            store.commit('setting/LOADING_ON')
            VisaApi.get(`/permissions/role/${this.form.role_id}`).then((response) => {
                store.commit('setting/LOADING_OFF')
                this.form.permission_ids = response.data.permissions.map(p => p.id)
            });
        },
    },

    watch: {
        'form.role_id': function () {
            this.getRolePermissions()
        }
    },

    mounted() {
        this.getAllPermissions();
        this.breadcrumb = this.$router.currentRoute.value.meta.breadcrumb;
        this.getRolePermissions()
    }
}
</script>
