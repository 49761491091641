import {
    Alignment,
    Bold,
    Code,
    Essentials,
    Font,
    Heading,
    Image,
    ImageCaption,
    ImageStyle,
    ImageToolbar,
    ImageUpload,
    Italic,
    Link,
    List,
    MediaEmbed,
    SourceEditing,
    Strikethrough,
    Subscript,
    Superscript,
    Table,
    TableToolbar,
    Underline,
    Undo,
    Base64UploadAdapter,
} from "ckeditor5";

function Redo() {

}

const ckeditorConfig = {
    plugins: [
        Essentials, Bold, Italic, Underline, Strikethrough, Code, Subscript, Superscript,
        Heading, Font, Alignment, List, Link, Image, ImageToolbar, ImageCaption, ImageStyle, ImageUpload,
        MediaEmbed, Table, TableToolbar, Undo, Redo, SourceEditing, Base64UploadAdapter
    ],
    toolbar: [
        'sourceEditing', '|', 'heading', '|',
        'bold', 'italic', 'insertTable', 'underline', 'strikethrough', 'code', 'subscript', 'superscript', '|',
        'fontFamily', 'fontSize', 'fontColor', 'fontBackgroundColor', '|',
        'alignment', '|',
        'bulletedList', 'numberedList', '|',
        'link', 'imageUpload', 'mediaEmbed', '|',
        'undo', 'redo'
    ],
    heading: {
        options: [
            {model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph'},
            {model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1'},
            {model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2'},
            {model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3'},
            {model: 'heading4', view: 'h4', title: 'Heading 4', class: 'ck-heading_heading4'},
            {model: 'heading5', view: 'h5', title: 'Heading 5', class: 'ck-heading_heading5'},
            {model: 'heading6', view: 'h6', title: 'Heading 6', class: 'ck-heading_heading6'}
        ]
    },
    fontFamily: {
        options: [
            'default',
            'Arial, Helvetica, sans-serif',
            'Courier New, Courier, monospace',
            'Georgia, serif',
            'Lucida Sans Unicode, Lucida Grande, sans-serif',
            'Tahoma, Geneva, sans-serif',
            'Times New Roman, Times, serif',
            'Trebuchet MS, Helvetica, sans-serif',
            'Verdana, Geneva, sans-serif'
        ],
        supportAllValues: true
    },
    fontSize: {
        options: [1, 2, 3],
        optionTitles: ['1x', '2x', '3x'],
        unit: 'em'
    },
    fontColor: {
        columns: 5,
        documentColors: 10
    },
    fontBackgroundColor: {
        columns: 5,
        documentColors: 10
    },
    image: {
        toolbar: [
            'imageTextAlternative', 'imageStyle:full', 'imageStyle:side'
        ]
    },
    table: {
        contentToolbar: [
            'tableColumn', 'tableRow', 'mergeTableCells'
        ]
    },
    language: 'en'
};

export default ckeditorConfig;
