<template>
    <div>
        <div class="visasis-card">
            <div class="mb-2 text-xl text-gray-600">
                Menu items
            </div>

            <div class="flex flex-wrap items-center -mx-4">
                <TableEntryShow @input="[meta.current_page = 1, meta.per_page = $event]"/>
                <TableTopActions :to="{ name:'create-menu-items', params:{menu_id:menu_id} }"
                                 @input="[meta.current_page = 1, search_input = $event]"/>
            </div>

            <vue-good-table
                :columns="columns"
                :rows="rows"
            >
                <template #table-row="props">
                    <div v-if="props.column.field == 'parent'">
                        <span v-if="props.row.parent!=null"> {{ props.row.parent.title }} </span>
                    </div>
                    <div v-if="props.column.field == 'is_active'">
                        <p v-if="props.row.is_active==1" class="w-min px-2 rounded m-auto text-white bg-green-700">
                            Active</p>
                        <p v-else class="w-min px-2 rounded m-auto text-white bg-orange-700">Inactive</p>
                    </div>
                    <div v-if="props.column.field == 'is_header'">
                        <p v-if="props.row.is_header==1" class="w-min px-2 rounded m-auto text-white bg-green-700">
                            Active</p>
                        <p v-else class="w-min px-2 rounded m-auto text-white bg-orange-700">Inactive</p>
                    </div>
                    <div v-if="props.column.field == 'action'">
                        <div class="px-3 py-2 flex items-center justify-center gap-2">
                            <router-link :to="{ name: 'view-menu-items', params: {menu_id:menu_id, id:props.row.id} }">
                                <view-icon/>
                            </router-link>
                            <router-link
                                :to="{ name: 'update-menu-items', params: {menu_id:menu_id, id:props.row.id} }">
                                <update-icon/>
                            </router-link>
                            <delete-icon @click="openDeleteModal(props.row.id)"/>
                        </div>
                    </div>
                </template>
            </vue-good-table>

            <TablePagination
                :inputPage="currentPage"
                :meta='meta'
                :next_of_next='next_of_next'
                :prev_of_prev='prev_of_prev'
                @update:inputPage="updatePageValue"
            />
        </div>

        <!-- delete modal -->
        <DeleteModal
            :isDeleteSubmitting="isDeleteSubmitting"
            :is_open="is_open_delete_menu_items_modal"
            modal_title='Delete page content'
            @close="closeDeleteModal"
            @submit="deleteData"
        />

    </div>
</template>

<script>
import VisaApi from "@/libs/axios"
import DeleteModal from '@/component/DeleteModal'
import {VueGoodTable} from 'vue-good-table-next'
import 'vue-good-table-next/dist/vue-good-table-next.css'
import TablePagination from '@/component/TablePagination'
import {meta_data, next_of_next_status, prev_of_prev_status,} from '@/libs/helpers.js'
import {AddIcon} from "../../../data/icons";
import DeleteIcon from "../../../data/icons/DeleteIcon.vue";
import UpdateIcon from "../../../data/icons/UpdateIcon.vue";
import ViewIcon from "../../../data/icons/ViewIcon.vue";
import TableEntryShow from "../../../component/table/TableEntryShow.vue";
import TableTopActions from "../../../component/table/TableTopActions.vue";

export default {
    name: "MenuList",
    components: {
        TableTopActions,
        TableEntryShow,
        ViewIcon,
        UpdateIcon,
        DeleteIcon,
        AddIcon,
        DeleteModal,
        VueGoodTable,
        TablePagination
    },

    data() {
        return {
            id_deletable: '',
            menu_list: [],

            meta: meta_data,
            prev_of_prev: false,
            next_of_next: false,
            per_page_array: [10, 20, 30, 40, 50],
            currentPage: '',
            is_open_delete_menu_items_modal: false,

            columns: [
                {label: 'Parent', field: 'parent'},
                {label: 'Title', field: 'title'},
                {label: 'Link', field: 'link'},
                {label: 'Link Status', field: 'is_active'},
                {label: 'Menu Visibility', field: 'is_header'},
                {label: 'Action', field: 'action', sortable: false, width: '100px'}
            ],
            rows: [],

            search_input: '',
            url: '',
            sort_status: false,
            menu_id: 0,
            isDeleteSubmitting: false,
        };
    },

    methods: {
        async getList() {
            await VisaApi.get(this.url).then((response) => {
                if (response.code == '200') {
                    this.rows = response.data.data;
                    Object.assign(this.meta, response.data.meta)
                }
            });
        },

        openDeleteModal(id) {
            this.id_deletable = id;
            this.is_open_delete_menu_items_modal = true
        },

        closeDeleteModal() {
            this.isDeleteSubmitting = false;
            this.is_open_delete_menu_items_modal = false;
        },

        deleteData() {
            this.isDeleteSubmitting = true;
            VisaApi.delete("frontend/menus/" + this.menu_id + "/menu-items/" + this.id_deletable).then((response) => {
                if (response.code == '200') {
                    this.getList();
                    this.$toast.success(response.message, {
                        position: "top-right",
                        dismissible: true,
                    });
                    this.closeDeleteModal();
                }
            }).catch((error) => {
                if (error.response.data.code == '400') {
                    this.$toast.error('This category has a child item!', {
                        position: "top-right",
                        dismissible: true,
                    });
                    this.closeDeleteModal();
                } else {
                    this.$toast.error(error.response.data.message, {
                        position: "top-right",
                        dismissible: true,
                    });
                    this.closeDeleteModal();
                }
            });
        },

        updatePageValue(newValue) {
            this.currentPage = newValue;
        },
    },

    computed: {
        apiUrl() {
            return this.url = 'frontend/menus/' + this.menu_id + '/menu-items?page=' + this.meta.current_page + '&per_page=' + this.meta.per_page + '&order_by=id&order_by_type=desc&search=' + this.search_input;
        },
        nextOfNext() {
            this.next_of_next = next_of_next_status(this.meta);
        },
        prevOfPrev() {
            this.prev_of_prev = prev_of_prev_status(this.meta);
        },
    },

    watch: {
        apiUrl() {
            this.getList();
        },
        nextOfNext() {
        },
        prevOfPrev() {
        },
    },

    mounted() {
        this.menu_id = this.$route.params.id
    }
}
</script>
