<template>
    <div class="px-4 py-2 absolute w-full">
        <div class="flex flex-wrap items-center justify-between">
            <Breadcrumb :text="breadcrumb"/>
            <router-link to="/visa-schedule-list">
                <button class="back-btn" type="button">
                    <back-icon class="mt-1 mr-2"/>
                    Back to list
                </button>
            </router-link>
        </div>

        <div class="visasis-card">
            <div class="grid grid-cols-1 md:grid-cols-2 gap-x-4 gap-y-2">
                <div class="w-full">
                    <label class="flex items-center">Select Visa <img alt="required"
                                                                      class="w-2 h-2 ml-1" src="@/assets/images/star.svg">
                    </label>
                    <v-select
                        v-model="form.visa_id"
                        :get-option-label="(option) => option.visa_subclass + ' - ' + option.name"
                        :options="visa_list"
                        :reduce="visa => visa.id"
                        class="pt-1"
                        placeholder="Choose visa..."
                    >
                        <template v-slot:option="option">
                            {{ option.visa_subclass }} - {{ option.name }}
                        </template>
                    </v-select>
                    <span v-if='form.errors().has("visa_id")' class="text-red-800 text-xs">
                        {{ form.errors().get('visa_id') }}
                    </span>
                </div>
                <div class="w-full">
                    <label>Select Schedule</label>
                    <select v-model='form.type'
                            class="py-2 rounded border border-gray-300 bg-gray-50 text-sm placeholder-gray-400 focus:border-invblue resize-none w-full px-4 mt-1">
                        <option :selected="form.type==1" value="1">Schedule One</option>
                        <option :selected="form.type==2" value="2">Schedule Two</option>
                    </select>
                </div>
                <div class="w-full">
                    <label>Status</label>
                    <select v-model='form.status'
                            class="py-2 rounded border border-gray-300 bg-gray-50 text-sm placeholder-gray-400 focus:border-invblue resize-none w-full px-4 mt-1">
                        <option :selected="form.status==1" value="1">Active</option>
                        <option :selected="form.status==0" value="0">Inactive</option>
                    </select>
                </div>
            </div>
            <div class="w-full mt-2">
                <label>Description</label>
                <article class="prose" style="max-width: 100%">
                    <ckeditor v-model="form.description" :editor="editor"></ckeditor>
                </article>
            </div>

            <button v-if="isSubmitting==false" class="mt-4 justify-center rounded-md bg-blue-600 px-6 py-1.5 text-white hover:bg-blue-500" type="button"
                    @click="updateVisaSchedule">
                Submit
            </button>
            <span v-else class="mt-4 block">
                <LoadingButton text="Submitting"/>
            </span>

        </div>
    </div>
</template>

<script>
import VisaApi from "@/libs/axios"
import form from 'vuejs-form'
import Breadcrumb from '@/component/Breadcrumb'
import vSelect from "vue-select"
import "vue-select/dist/vue-select.css"
import LoadingButton from "@/component/LoadingButton"
import BackIcon from "../../../data/icons/BackIcon.vue";
import CKEditor from "@ckeditor/ckeditor5-vue"

export default {
    components: {BackIcon, LoadingButton, Breadcrumb, "v-select": vSelect, ckeditor: CKEditor.component},
    data() {
        return {
            breadcrumb: '',
            visa_list: [],
            isSubmitting: false,
            editor: ClassicEditor,

            form: form.default({
                id: '',
                uuid: '',
                visa_id: '',
                type: '1',
                description: '',
                status: '1',
            }).rules({
                visa_id: 'required'
            }),
        };
    },

    methods: {
        async getSingleData(uuid) {
            await VisaApi.get("/visa-schedule/" + uuid).then((response) => {
                if (response.code == '200') {
                    Object.assign(this.form, response.data);
                }
            });
        },

        updateVisaSchedule() {
            if (this.form.validate().errors().any()) {
                return;
            } else {
                this.isSubmitting = true;
                VisaApi.patch("/visa-schedule/update/" + this.form.uuid, this.form.all()).then((response) => {
                    if (response.code == '200') {
                        this.$toast.success(response.message, {
                            position: "top-right",
                            dismissible: true,
                        });
                        this.$router.push({name: 'visa-schedule-list'})
                    }
                    this.isSubmitting = false;
                }).catch((error) => {
                    this.$toast.error(error.response.data.message, {
                        position: "top-right",
                        dismissible: true,
                    });
                    this.isSubmitting = false;
                });
            }
        },

        async getVisaList() {
            await VisaApi.get("/visa").then((response) => {
                if (response.code == '200') {
                    this.visa_list = response.data;
                }
            });
        },
    },

    mounted() {
        this.breadcrumb = this.$router.currentRoute.value.meta.breadcrumb;
        this.getVisaList();
        let id = this.$route.params.id;
        this.getSingleData(id);
    }
}
</script>
