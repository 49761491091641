<template>
    <div class="px-4 py-2 absolute w-full">

        <Breadcrumb :text="breadcrumb"/>

        <div class="visasis-card">
            <div class="flex flex-wrap items-center -mx-4">
                <TableEntryShow @input="[meta.current_page = 1, meta.per_page = $event]"/>
                <TableTopActions to="/visa-create" @input="[meta.current_page = 1, search_input = $event]"/>
            </div>

            <vue-good-table
                :columns="columns"
                :rows="rows"
            >
                <template #table-row="props">
                    <div v-if="props.column.field == 'name'">
                        {{ props.row.visa_subclass }} - {{ props.row.name }}
                    </div>
                    <div v-if="props.column.field == 'category'">
                        <span v-if="props.row.category!=null"> {{ props.row.category.name }} </span>
                    </div>
                    <div v-if="props.column.field == 'types'">
                        <span v-if="props.row.types.length > 0">
                            <span
                                v-for="(type, type_index) in props.row.types"
                                :key="'type-'+type_index"
                                class="rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">{{ type.name }}</span>
                        </span>
                    </div>
                    <div v-if="props.column.field == 'short_description'">
                        <span v-if="props.row.short_description!=null && props.row.short_description.length >= 30"
                              class="whitespace-nowrap"> {{ props.row.short_description.slice(0, 30) }} ... </span>
                        <span v-else class="whitespace-nowrap"> {{ props.row.short_description }} </span>
                    </div>
                    <div v-if="props.column.field == 'is_popular'">
                        <p v-if="props.row.is_popular==1" class="w-min px-2 rounded m-auto text-white bg-green-700">
                            Yes</p>
                        <p v-else class="w-min px-2 rounded m-auto text-white bg-orange-700">No</p>
                    </div>
                    <div v-if="props.column.field == 'status'">
                        <p v-if="props.row.status==1" class="w-min px-2 rounded m-auto text-white bg-green-700">
                            Active</p>
                        <p v-else class="w-min px-2 rounded m-auto text-white bg-orange-700">Inactive</p>
                    </div>
                    <div v-if="props.column.field == 'action'">
                        <div class="px-3 py-2 flex items-center justify-center">
                            <router-link :to="{ name: 'visa-view', params: {id:props.row.uuid} }">
                                <view-icon/>
                            </router-link>
                            <router-link :to="{ name: 'visa-update', params: {id:props.row.uuid} }">
                                <update-icon/>
                            </router-link>
                            <delete-icon @click="openDeleteModal(props.row.uuid)"/>
                        </div>
                    </div>
                </template>
            </vue-good-table>

            <TablePagination
                :inputPage="currentPage"
                :meta='meta'
                :next_of_next='next_of_next'
                :prev_of_prev='prev_of_prev'
                @update:inputPage="updatePageValue"
            />
        </div>

        <!-- delete modal -->
        <DeleteModal
            :isDeleteSubmitting="isDeleteSubmitting"
            :is_open="is_open_delete_modal"
            modal_title='Delete Visa'
            @close="closeDeleteModal"
            @submit="deleteVisa"
        />

    </div>
</template>

<script>
import VisaApi from "@/libs/axios"
import DeleteModal from '@/component/DeleteModal'
import store from '@/store'
import Breadcrumb from '@/component/Breadcrumb'
import TablePagination from '@/component/TablePagination'
import {VueGoodTable} from 'vue-good-table-next'
import 'vue-good-table-next/dist/vue-good-table-next.css'
import pagination from "../../../data/pagination";
import {meta_data, next_of_next_status, prev_of_prev_status} from '@/libs/helpers.js'
import AddIcon from "../../../data/icons/AddIcon.vue";
import ViewIcon from "../../../data/icons/ViewIcon.vue";
import UpdateIcon from "../../../data/icons/UpdateIcon.vue";
import DeleteIcon from "../../../data/icons/DeleteIcon.vue";
import TableEntryShow from "../../../component/table/TableEntryShow.vue";
import TableTopActions from "../../../component/table/TableTopActions.vue";

export default {
    components: {
        TableTopActions,
        TableEntryShow,
        DeleteIcon,
        UpdateIcon,
        ViewIcon,
        AddIcon,
        DeleteModal,
        Breadcrumb,
        TablePagination,
        VueGoodTable
    },
    data() {
        return {
            is_open_delete_modal: false,
            tableData: [],
            meta: meta_data,
            prev_of_prev: false,
            next_of_next: false,
            per_page_array: [10, 20, 30, 40, 50],
            currentPage: '',

            uuid_deletable: '',
            search_input: '',
            breadcrumb: '',
            url: '',
            sort_status: false,
            isDeleteSubmitting: false,
            pagination,
            columns: [
                {label: 'Name', field: 'name', width: '300px'},
                {label: 'Category', field: 'category'},
                {label: 'Type', field: 'types'},
                {label: 'Short Des.', field: 'short_description'},
                {label: 'Is Popular?(6)', field: 'is_popular', sortable: false},
                {label: 'Status', field: 'status', sortable: false},
                {label: 'Action', field: 'action', sortable: false, width: '100px'}
            ],
            rows: [],
        };
    },

    methods: {
        async getVisaList() {
            store.commit('setting/LOADING_ON')
            await VisaApi.get(this.apiUrl).then((response) => {
                store.commit('setting/LOADING_OFF')
                if (response.code == '200') {
                    this.rows = response.data.data;
                    Object.assign(this.meta, response.data.meta);
                }
            });
        },

        openDeleteModal(uuid) {
            this.uuid_deletable = uuid;
            this.is_open_delete_modal = true
        },

        closeDeleteModal() {
            this.isDeleteSubmitting = false;
            this.is_open_delete_modal = false
        },

        deleteVisa() {
            this.isDeleteSubmitting = true;
            VisaApi.delete("/visa/" + this.uuid_deletable).then((response) => {
                if (response.code == '200') {
                    this.getVisaList();
                    this.$toast.success(response.message, {
                        position: "top-right",
                        dismissible: true,
                    });
                    this.closeDeleteModal();
                }
            }).catch((error) => {
                this.$toast.error(error.response.data.message, {
                    position: "top-right",
                    dismissible: true,
                });
                this.closeDeleteModal();
            });
        },

        updatePageValue(newValue) {
            this.currentPage = newValue;
        },
    },

    computed: {
        apiUrl() {
            return 'visa?page=' + this.meta.current_page + '&per_page=' + this.meta.per_page + '&order_by=id&order_by_type=desc&search=' + this.search_input;
        },
        nextOfNext() {
            this.next_of_next = next_of_next_status(this.meta);
        },
        prevOfPrev() {
            this.prev_of_prev = prev_of_prev_status(this.meta);
        },
    },

    watch: {
        apiUrl() {
            this.getVisaList();
        },
        nextOfNext() {
        },
        prevOfPrev() {
        },
    },

    mounted() {
        this.getVisaList();
        this.breadcrumb = this.$router.currentRoute.value.meta.breadcrumb;
    }
}
</script>
