<template>
    <div class="px-4 py-2 absolute w-full">

        <div class="flex flex-wrap items-center justify-between pb-2 border-b border-gray-300">
            <Breadcrumb :text="breadcrumb"/>
            <router-link to="/occupation-list">
                <button class="rounded-md bg-orange-600 px-6 py-1.5 text-white hover:bg-orange-500" type="button">
                    Back to list
                </button>
            </router-link>
        </div>

        <div class="p-2 mt-2 h-full rounded bg-slate-100">
            <p class="font-bold mt-2">Attach occupation with assessing authority.</p>

            <div class="grid grid-cols-1 md:grid-cols-2 gap-x-4 gap-y-2 mt-4">
                <div class="w-full flex items-center">
                    <label>Occupation Name:</label>
                    <p class="pl-2">{{ occupation_name }}</p>
                </div>
                <div class="w-full flex items-center mt-4">
                    <label>Anzsco Code:</label>
                    <p class="pl-2">{{ anzsco_code }}</p>
                </div>
                <div class="w-full">
                    <label class="flex items-center">Select Assessing Authority <img alt="required"
                                                                                     class="w-2 h-2 ml-1"
                                                                                     src="@/assets/images/star.svg"> </label>
                    <div v-show="assessingAuthorityList.length>0">
                        <v-select
                            v-model="selected_data"
                            :options="assessingAuthorityList"
                            class="pt-1"
                            label="name"
                            placeholder="Choose"
                        />
                        <span v-if='form.errors().has("assessing_authority_uuid")' class="text-red-800 text-xs">
                            {{ form.errors().get('assessing_authority_uuid') }}
                        </span>
                    </div>
                </div>
            </div>

            <div class="text-center mt-8">
                <button v-if="isSubmitting==false" class="mt-4 rounded-md bg-blue-600 px-6 py-1.5 text-white hover:bg-blue-500" type="button"
                        @click="attach">
                    Attach
                </button>
                <span v-else>
                    <LoadingButton text="Attaching"/>
                </span>
            </div>

            <div class="overflow-x-auto mt-8 mb-4">
                <p class="font-bold text-sm">This occupation already attached with the following assessing
                    authority: </p>
                <table class="w-full border-collapse border border-gray-400">
                    <thead>
                    <tr class="text-left">
                        <th class="border border-gray-300 p-2">Name</th>
                        <th class="border border-gray-300 p-2">Link</th>
                        <th class="border border-gray-300 p-2 whitespace-nowrap">Short code</th>
                        <th class="border border-gray-300 p-2">Status</th>
                        <th class="border border-gray-300 p-2 text-center">Detach</th>
                    </tr>
                    </thead>
                    <tbody v-if="alreadyAttachedList.length>0">
                    <tr v-for="data in alreadyAttachedList" :key="data.id">
                        <td class="border border-gray-300 p-2">{{ data.name }}</td>
                        <td class="border border-gray-300 p-2">{{ data.link }}</td>
                        <td class="border border-gray-300 p-2">{{ data.short_code }}</td>
                        <td class="border border-gray-300 p-2">
                            <p v-if="data.status==1" class="text-green-500">Active</p>
                            <p v-else-if="data.status==0" class="text-red-500">Inactive</p>
                        </td>
                        <td class="border border-gray-300 p-2">
                            <div>
                                <img alt="img" class="w-5 cursor-pointer mx-auto"
                                     src="@/assets/images/cross_red.svg" @click="openDeleteModal(data.uuid)">
                            </div>
                        </td>
                    </tr>
                    </tbody>
                    <tbody v-else>
                    <tr>
                        <td class="border border-gray-300 p-2" colspan="5">
                            Data is empty
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>

        </div>

        <!-- delete modal -->
        <DeleteModal modal_title='Detach assessing authority' @close="closeDeleteModal"
                     @submit="detachOccupation"></DeleteModal>

    </div>
</template>

<script>
import VisaApi from "@/libs/axios"
import form from 'vuejs-form'
import store from '@/store'
import Breadcrumb from '@/component/Breadcrumb'
import vSelect from "vue-select"
import "vue-select/dist/vue-select.css"
import DeleteModal from '@/component/DeleteModal'
import LoadingButton from "@/component/LoadingButton"

export default {
    components: {Breadcrumb, "v-select": vSelect, DeleteModal, LoadingButton},
    data() {
        return {
            breadcrumb: '',
            alreadyAttachedList: [],
            assessingAuthorityList: [],
            selected_data: {},
            occupation_name: '',
            anzsco_code: '',
            isSubmitting: false,

            form: form.default({
                occupation_uuid: '',
                assessing_authority_uuid: '',
            }).rules({
                assessing_authority_uuid: 'required',
            }).messages({
                'assessing_authority_uuid.required': 'This field is required',
            }),
        };
    },

    methods: {
        async getAssessingAuthorityList() {
            store.commit('setting/LOADING_ON')
            await VisaApi.get("/assessing-authority").then((response) => {
                store.commit('setting/LOADING_OFF')
                if (response.code == '200') {
                    this.assessingAuthorityList = response.data;
                }
            });
        },

        attach() {
            if (this.form.validate().errors().any()) {
                return;
            } else {
                this.isSubmitting = true;
                VisaApi.post("/occupation/attach", this.form.all()).then((response) => {
                    if (response.code == '200') {
                        this.$toast.success(response.message, {
                            position: "top-right",
                            dismissible: true,
                        });
                        this.form.assessing_authority_uuid = '';
                        this.getSingleData(this.form.occupation_uuid);
                    }
                    this.isSubmitting = false;
                }).catch((error) => {
                    this.$toast.error(error.response.data.message, {
                        position: "top-right",
                        dismissible: true,
                    });
                    this.isSubmitting = false;
                });
            }
        },

        openDeleteModal(uuid) {
            const modalCls = document.getElementById('delete_modal').classList;
            const modalAnimCls = document.getElementById('delete_modal_animation').classList;
            this.form.assessing_authority_uuid = uuid;
            modalCls.remove('hidden');
            modalAnimCls.add('delete-modal-animation');
        },

        closeDeleteModal() {
            const modalCls = document.getElementById('delete_modal').classList;
            const modalAnimCls = document.getElementById('delete_modal_animation').classList;
            modalCls.add('hidden');
            modalAnimCls.remove('delete-modal-animation');
            this.form.assessing_authority_uuid = '';
        },

        detachOccupation() {
            if (this.form.validate().errors().any()) {
                return;
            } else {
                VisaApi.post("/occupation/detach", this.form.all()).then((response) => {
                    this.$toast.success(response.message, {
                        position: "top-right",
                        dismissible: true,
                    });
                    this.form.assessing_authority_uuid = '';
                    this.closeDeleteModal();
                    this.getSingleData(this.form.occupation_uuid);
                }).catch((error) => {
                    this.$toast.error(error.response.data.message, {
                        position: "top-right",
                        dismissible: true,
                    });
                    this.closeDeleteModal();
                });
            }
        },

        getSingleData(uuid) {
            VisaApi.get("/occupation/" + uuid).then((response) => {
                if (response.code == '200') {
                    this.alreadyAttachedList = response.data.assessing_authorities;
                    this.occupation_name = response.data.name;
                    this.anzsco_code = response.data.anzsco_code;
                }
            });
        },
    },

    watch: {
        selected_data(data) {
            this.form.assessing_authority_uuid = data.uuid;
        }
    },

    mounted() {
        this.form.occupation_uuid = this.$route.params.id;
        this.getSingleData(this.$route.params.id);
        this.getAssessingAuthorityList();
        this.breadcrumb = this.$router.currentRoute.value.meta.breadcrumb;
    }
}
</script>
