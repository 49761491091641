<template>
    <div class="w-full lg:w-1/2 px-4 py-2">
        <div class="flex justify-start lg:justify-end flex-wrap gap-1">
            <input v-model="search_input" :class="input_size" class="pl-4 h-10 rounded border focus:outline-none border-gray-300"
                   placeholder="Search" type="text"
                   @input.stop="$emit('input', search_input)"/>
            <!--create modal button-->
            <button v-if="props.is_modal" :class="create_size" class="px-6 flex justify-center items-center h-10 gap-2 py-2 text-center tracking-wider rounded bg-green-600 hover:bg-green-500 text-white"
                    @click="$emit('create')">
                <add-icon/>
                <span class="hidden sm:block">Create</span>
            </button>
            <!--create button-->
            <router-link v-else :class="create_size" :to="props.to"
                         class="px-6 flex justify-center items-center h-10 gap-2 py-2 text-center tracking-wider rounded bg-green-600 hover:bg-green-500 text-white">
                <add-icon/>
                <span class="hidden sm:block">Create</span>
            </router-link>
            <!--import button-->
            <button v-if="props.need_import" :class="import_size" class="px-6 h-10 flex justify-center items-center gap-2 py-2 text-center tracking-wider rounded bg-blue-600 hover:bg-blue-500 text-white"
                    @click="$emit('import')">
                <download-icon/>
                <span class="hidden sm:block">{{props.import_title}}</span>
            </button>
            <!--Extra action button-->
            <button v-if="props.need_button" :class="import_size" class="px-6 h-10 flex justify-center items-center gap-2 py-2 text-center tracking-wider rounded bg-blue-600 hover:bg-blue-500 text-white"
                    @click="$emit('action')">
                <hyper-link-icon/>
                <span class="hidden sm:block">{{props.action_title}}</span>
            </button>
            <!--another import button-->
            <button v-if="props.need_another_import" :class="another_import_size" class="px-6 h-10 flex justify-center items-center gap-2 py-2 text-center tracking-wider rounded bg-violet-700 hover:bg-violet-500 text-white"
                    @click="$emit('another_import')">
                <download-icon/>
                <span class="hidden sm:block">{{props.another_import_title}}</span>
            </button>
        </div>
    </div>
</template>

<script setup>
import AddIcon from "../../data/icons/AddIcon.vue";
import DownloadIcon from "../../data/icons/DownloadIcon.vue";
import HyperLinkIcon from "../../data/icons/HyperLinkIcon.vue";
import {computed, ref, watch} from "vue";

const search_input = ref('')

const props = defineProps({
    to: {
        required: false,
    },
    is_modal: {
        required: false,
        default: false
    },
    need_import: {
        required: false,
        default: false
    },
    import_title: {
        required: false,
        default: 'Import'
    },
    need_button: {
        required: false,
        default: false
    },
    action_title: {
        required: false,
        default: 'List'
    },
    need_another_import: {
        required: false,
        default: false
    },
    another_import_title: {
        required: false,
        default: 'Import'
    }
})

const input_size = computed(() => {
    if ((props.need_import || props.need_button) && props.need_another_import) return 'w-[64%] 2xl:w-[35%]';
    else if (props.need_import || props.need_button) return 'w-[49%]';
    else return 'w-[64%]';
})

const create_size = computed(() => {
    if ((props.need_import || props.need_button) && props.need_another_import) return 'w-[34%] 2xl:w-[20%]';
    else if (props.need_import || props.need_button) return 'w-[24%]';
    else return 'w-[34%]';
})

const import_size = computed(() => {
    if ((props.need_import || props.need_button) && props.need_another_import) return 'w-[34%] 2xl:w-[20%] mt-2 2xl:mt-0';
    else return 'w-[24%]';
})

const another_import_size = computed(() => {
    if ((props.need_import || props.need_button) && props.need_another_import) return 'w-[64%] 2xl:w-[20%] mt-2 2xl:mt-0';
    else return 'w-[25%]';
})

</script>

<style scoped>

</style>
