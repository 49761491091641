<template>
    <div class="p-4">

        <div class="pb-1 border-b border-gray-300">
            <Breadcrumb :text="breadcrumb"/>
        </div>

        <div class="flex flex-wrap items-center -mx-4">
            <div class="w-full lg:w-1/2 p-4">
                <div class="flex items-center">
                    <span> Show </span>
                    <select v-model="meta.per_page" class="px-3 py-1 mx-1.5 rounded bg-white border border-gray-300">
                        <option v-for="data in per_page_array" :key="data">{{ data }}</option>
                    </select>
                    <span> entries </span>
                </div>
            </div>
            <div class="w-full lg:w-1/2 p-4">
                <div class="lg:flex lg:justify-end">
                    <input v-model="search_input"
                           class="w-64 lg:w-96 pl-4 pr-8 mx-1 h-10 rounded border focus:outline-none border-orange-400"
                           placeholder="Search" type="text"/>
                    <button class="px-6 py-2 mx-1 text-center tracking-wider rounded mt-4 lg:mt-0 block lg:inline-flex bg-orange-600 hover:bg-orange-500 text-white"
                            @click="openModal(true, 'create')">
                        Create
                    </button>
                    <button class="px-6 py-2 ml-1 text-center tracking-wider rounded mt-4 lg:mt-0 block lg:inline-flex bg-blue-600 hover:bg-blue-500 text-white"
                            @click="openImport">
                        Import
                    </button>
                </div>
            </div>
        </div>

        <Table>
            <template v-slot:table-header>
                <th class="px-3 py-2 flex items-center" scope="col">
                    Occupation
                    <img :class="sort_status==false? 'w-4 h-4 mx-2 mt-0.5 cursor-pointer':'w-4 h-4 mx-2 mt-0.5 cursor-pointer rotate-180 transition'"
                         alt="sort"
                         src="@/assets/images/arrow_down.svg" @click="toggleSort">
                </th>
                <th class="px-3 py-2" scope="col">
                    Title
                </th>
                <th class="px-3 py-2 whitespace-nowrap" scope="col">
                    Description
                </th>
                <th class="px-3 py-2" scope="col">
                    Status
                </th>
                <th class="px-3 py-2" scope="col">
                    Action
                </th>
            </template>
            <template v-slot:table-body>
                <tr v-for="(data,index) in tableData" :key="index"
                    class="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                    <td class="px-3 py-2">
                        <span v-if="data.occupation!=null"> {{ data.occupation.name }} </span>
                    </td>
                    <td class="px-3 py-2">
                        {{ data.titles.join(', ') }}
                    </td>
                    <td class="px-3 py-2">
                        <span v-if="data.description!=null && data.description.length >= 30" class="whitespace-nowrap"> {{ data.description.slice(0, 30) }} ... </span>
                        <span v-else class="whitespace-nowrap"> {{ data.description }} </span>
                    </td>
                    <td class="px-3 py-2">
                        <p v-if="data.status==1" class="text-green-500">Active</p>
                        <p v-else class="text-orange-500">Inactive</p>
                    </td>
                    <td class="px-3 py-2 flex items-center">
                        <router-link :to="{ name: 'occupation-alternative-view', params: {id:data.uuid} }">
                            <img alt="view" class="w-4 h-4 cursor-pointer" src="@/assets/images/eye.svg">
                        </router-link>
                        <svg class="w-4 h-4 mx-2 cursor-pointer" fill="none" stroke="Blue"
                             stroke-width="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" @click="getSingleData(data.uuid)">
                            <path d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" stroke-linecap="round"
                                  stroke-linejoin="round"/>
                        </svg>
                        <svg class="w-4 h-4 cursor-pointer" fill="none" stroke="Red"
                             stroke-width="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" @click="openDeleteModal(data.uuid)">
                            <path d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" stroke-linecap="round"
                                  stroke-linejoin="round"/>
                        </svg>
                    </td>
                </tr>
            </template>
            <template v-slot:table-footer>
                <tr>
                    <td colspan="5">
                        <TablePagination
                            :meta='meta'
                            :next_of_next='next_of_next'
                            :prev_of_prev='prev_of_prev'
                        />
                    </td>
                </tr>
            </template>
        </Table>

        <!-- modal -->
        <Modal :modal_title='mdl_title' @close="openModal(false, 'create')" @submit="handleSubmitMethod">
            <template v-slot:body>
                <div class="grid grid-cols-1 md:grid-cols-2 gap-x-4 gap-y-2 mt-2 px-2">
                    <div class="w-full">
                        <label class="flex items-center">Select Title <img alt="required"
                                                                           class="w-2 h-2 ml-1"
                                                                           src="@/assets/images/star.svg"> </label>
                        <v-select
                            v-model="form.titles"
                            :options="title_list"
                            class="pt-1 "
                            multiple
                            placeholder="Choose"
                        />
                        <span v-if='form.errors().has("titles")' class="text-red-800 text-xs">
                            {{ form.errors().get('titles') }}
                        </span>
                    </div>
                    <div class="w-full">
                        <label>Status</label>
                        <select v-model='form.status'
                                class="py-2 rounded border border-gray-300 bg-gray-50 text-sm placeholder-gray-400 focus:border-invblue resize-none w-full px-4 mt-1">
                            <option :selected="form.status==1" value="1">Active</option>
                            <option :selected="form.status==0" value="0">Inactive</option>
                        </select>
                    </div>
                    <div class="w-full">
                        <label class="flex items-center">Select Occupation <img alt="required"
                                                                                class="w-2 h-2 ml-1"
                                                                                src="@/assets/images/star.svg"> </label>
                        <v-select
                            v-model="selected_occupation"
                            :options="occupation_list"
                            class="pt-1 "
                            label="name"
                            placeholder="Choose"
                        />
                        <span v-if='form.errors().has("occupation_uuid")' class="text-red-800 text-xs">
                            {{ form.errors().get('occupation_uuid') }}
                        </span>
                    </div>
                    <div class="w-full">
                        <label>Description</label>
                        <textarea v-model='form.description' class="p-2 rounded border border-gray-300 bg-gray-50 text-sm placeholder-gray-400 focus:border-invblue resize-none w-full mt-1" cols="50" placeholder="Note"
                                  rows="4"></textarea>
                    </div>
                </div>
            </template>
        </Modal>

        <!-- delete modal -->
        <DeleteModal modal_title='Delete Occupation Alternative' @close="closeDeleteModal"
                     @submit="deleteAlternative"></DeleteModal>


        <!-- Import -->
        <div id="import_modal" class="relative z-10 hidden">
            <div class="fixed inset-0 bg-gray-500 bg-opacity-75"></div>
            <div class="fixed inset-0 z-10 overflow-y-auto">
                <div class="flex min-h-full items-end justify-center text-center sm:items-center sm:p-0">
                    <div id="import_modal_animation"
                         class="relative overflow-hidden rounded-lg bg-white text-left shadow-xl sm:my-8 sm:w-full sm:max-w-lg">
                        <div class="bg-white text-gray-600 pb-4">
                            <h3 class="p-2 text-lg leading-6 border-b">Import Occupation Alternative</h3>
                            <div class="w-full px-2 mt-2">
                                <label>Choose file</label>
                                <input class="py-2 rounded block" type='file' @change="takeFileFromInput($event)">
                            </div>
                            <a class="px-2 text-blue-500" href="https://" target="_blank">View demo file</a>
                        </div>
                        <div class="bg-gray-50 px-2 py-1 flex justify-end items-center">
                            <button :class="importForm.file==''? 'inline-flex justify-center rounded-md border border-transparent bg-red-400 px-3 py-0.5 mr-3 text-white hover:bg-red-500 cursor-not-allowed':
                            'inline-flex justify-center rounded-md border border-transparent bg-red-600 px-3 py-0.5 mr-3 text-white hover:bg-red-700'" :disabled="importForm.file==''" type="button"
                                    @click="importExcelFile">
                                Import
                            </button>
                            <button class="inline-flex justify-center rounded-md border border-transparent bg-green-600 px-3 py-0.5 text-white hover:bg-green-700" type="button"
                                    @click="closeImport">
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import VisaApi from "@/libs/axios"
import form from 'vuejs-form'
import store from '@/store'
import Modal from '@/component/Modal'
import DeleteModal from '@/component/DeleteModal'
import Table from '@/component/Table'
import Breadcrumb from '@/component/Breadcrumb'
import vSelect from "vue-select"
import "vue-select/dist/vue-select.css"
import TablePagination from '@/component/TablePagination'
import {meta_data, next_of_next_status, prev_of_prev_status} from '@/libs/helpers.js'

export default {
    components: {Modal, DeleteModal, Table, Breadcrumb, "v-select": vSelect, TablePagination},
    data() {
        return {
            mdl_title: 'Create Occupation Alternative',
            modal_mode: 'create',
            deletable_uuid: '',
            tableData: [],
            meta: meta_data,
            prev_of_prev: false,
            next_of_next: false,
            per_page_array: [10, 20, 30, 40, 50],
            search_input: '',
            breadcrumb: '',
            url: '',
            sort_status: false,
            test: '',
            title_list: ["Software Architect", "Software Developer"],
            selected_occupation: '',
            occupation_list: [],

            form: form.default({
                id: '',
                uuid: '',
                occupation_uuid: '',
                titles: [],
                description: '',
                status: '1',
            }).rules({
                occupation_uuid: 'required',
                titles: 'required',
            }),

            importForm: form.default({file: '',}),
        };
    },

    methods: {
        openModal(value, mode) {
            this.form.errors().forget();
            const modalCls = document.getElementById('modal').classList;
            const modalAnimCls = document.getElementById('modal_animation').classList;
            if (value == true && mode == 'create') {
                modalCls.remove('hidden');
                modalAnimCls.add('open-modal-animation');

                this.form.occupation_uuid = '';
                this.form.titles = [];
                this.form.description = '';
                this.form.status = '1';

                this.modal_mode = 'create';
                this.mdl_title = 'Create Occupation Alternative';
            } else if (value == true && mode == 'update') {
                modalCls.remove('hidden');
                modalAnimCls.add('open-modal-animation');
            } else {
                modalCls.add('hidden');
                modalAnimCls.remove('open-modal-animation');
            }
        },

        createAlternative() {
            if (this.form.validate().errors().any()) {
                return;
            } else {
                this.openModal(false, 'create');
                VisaApi.post("/occupation-alternative/create", this.form.all()).then((response) => {
                    if (response.code == '201') {
                        this.tableData.unshift({
                            id: response.data.id,
                            uuid: response.data.uuid,
                            occupation: response.data.occupation,
                            titles: response.data.titles,
                            description: response.data.description,
                            status: response.data.status,
                        });
                        this.meta.total += 1;
                        this.meta.to += 1;
                        this.$toast.success(response.message, {
                            position: "top-right",
                            dismissible: true,
                        });
                    }
                }).catch((error) => {
                    this.$toast.error(error.response.data.message, {
                        position: "top-right",
                        dismissible: true,
                    });
                });
            }
        },

        async getList() {
            store.commit('setting/LOADING_ON')
            await VisaApi.get(this.url).then((response) => {
                store.commit('setting/LOADING_OFF')
                if (response.code == '200') {
                    this.tableData = response.data.data;
                    Object.assign(this.meta, response.data.meta);
                }
            });
        },

        async getSingleData(uuid) {
            await VisaApi.get("/occupation-alternative/" + uuid).then((response) => {
                if (response.code == '200') {
                    Object.assign(this.form, response.data);

                    let occupation = this.occupation_list.filter(item => item.id == response.data.occupation_id);
                    this.selected_occupation = occupation[0];

                    this.mdl_title = 'Update Occupation Alternative';
                    this.modal_mode = 'update';
                    this.openModal(true, 'update');
                }
            });
        },

        updateAlternative() {
            if (this.form.validate().errors().any()) {
                return;
            } else {
                VisaApi.patch("/occupation-alternative/update/" + this.form.uuid, this.form.all()).then((response) => {
                    if (response.code == '200') {
                        this.openModal(false, 'update');
                        let element = this.tableData.filter(item => item.id == this.form.id)
                        Object.assign(element[0], response.data)
                        this.$toast.success(response.message, {
                            position: "top-right",
                            dismissible: true,
                        });
                    }
                }).catch((error) => {
                    this.$toast.error(error.response.data.message, {
                        position: "top-right",
                        dismissible: true,
                    });
                });
            }
        },

        openDeleteModal(uuid) {
            const modalCls = document.getElementById('delete_modal').classList;
            const modalAnimCls = document.getElementById('delete_modal_animation').classList;
            this.deletable_uuid = uuid;
            modalCls.remove('hidden');
            modalAnimCls.add('delete-modal-animation');
        },

        closeDeleteModal() {
            const modalCls = document.getElementById('delete_modal').classList;
            const modalAnimCls = document.getElementById('delete_modal_animation').classList;
            modalCls.add('hidden');
            modalAnimCls.remove('delete-modal-animation');
        },

        deleteAlternative() {
            VisaApi.delete("/occupation-alternative/" + this.deletable_uuid).then((response) => {
                if (response.code == '200') {
                    this.tableData = this.tableData.filter(item => item.uuid !== this.deletable_uuid);
                    this.meta.total -= 1;
                    this.meta.to -= 1;
                    this.$toast.success(response.message, {
                        position: "top-right",
                        dismissible: true,
                    });
                    this.closeDeleteModal();
                }
            }).catch((error) => {
                this.$toast.error(error.response.data.message, {
                    position: "top-right",
                    dismissible: true,
                });
            });
        },

        handleSubmitMethod() {
            if (this.modal_mode == 'create') {
                this.createAlternative();
            } else {
                this.updateAlternative();
            }
        },

        async getOccupationList() {
            await VisaApi.get("/occupation").then((response) => {
                if (response.code == '200') {
                    this.occupation_list = response.data;
                }
            });
        },

        toggleSort() {
            if (this.sort_status == false) {
                this.tableData.sort((a, b) => a.occupation.name.localeCompare(b.occupation.name));
                this.sort_status = true;
            } else {
                this.tableData.sort((a, b) => b.occupation.name.localeCompare(a.occupation.name));
                this.sort_status = false;
            }
        },

        openImport() {
            const modalCls = document.getElementById('import_modal').classList;
            const modalAnimCls = document.getElementById('import_modal_animation').classList;
            modalCls.remove('hidden');
            modalAnimCls.add('delete-modal-animation');
        },

        closeImport() {
            const modalCls = document.getElementById('import_modal').classList;
            const modalAnimCls = document.getElementById('import_modal_animation').classList;
            modalCls.add('hidden');
            modalAnimCls.remove('delete-modal-animation');
            this.importForm.file = '';
        },

        takeFileFromInput(event) {
            const newFile = event.target.files[0]
            this.importForm.file = newFile;
        },

        importExcelFile() {
            if (this.importForm.file == '') {
                return;
            } else {
                VisaApi.post("/occupation-alternative/import", this.importForm.all(), {headers: {"Content-Type": "multipart/form-data"}}).then((response) => {
                    if (response.code == '200') {
                        this.$toast.success(response.message, {
                            position: "top-right",
                            dismissible: true,
                        });
                        this.closeImport();
                        this.importForm.file = '';
                        this.getList();
                    }
                }).catch((error) => {
                    this.$toast.error(error.response.data.message, {
                        position: "top-right",
                        dismissible: true,
                    });
                });
            }
        },
    },

    computed: {
        apiUrl() {
            return this.url = 'occupation-alternative?page=' + this.meta.current_page + '&per_page=' + this.meta.per_page + '&order_by=status&order_by_type=desc&search=' + this.search_input;
        },
        nextOfNext() {
            this.next_of_next = next_of_next_status(this.meta);
        },
        prevOfPrev() {
            this.prev_of_prev = prev_of_prev_status(this.meta);
        },
    },

    watch: {
        apiUrl() {
            this.getList();
        },
        selected_occupation(value) {
            if (value != null) {
                this.form.occupation_uuid = value.uuid;
            }
        },
        nextOfNext() {
        },
        prevOfPrev() {
        },
    },

    mounted() {
        this.url = 'occupation-alternative?page=' + this.meta.current_page + '&per_page=' + this.meta.per_page + '&order_by=status&order_by_type=desc&search=' + this.search_input;
        this.getList();
        this.getOccupationList();
        this.breadcrumb = this.$router.currentRoute.value.meta.breadcrumb;
    }
}
</script>
