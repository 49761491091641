<template>
    <div class="px-4 py-2 absolute w-full">
        <div class="flex flex-wrap items-center justify-between pb-2">
            <Breadcrumb :text="breadcrumb"/>
            <router-link to="/page-list">
                <button class="back-btn" type="button">
                    <back-icon class="mr-2 mt-1"/>
                    Back to list
                </button>
            </router-link>
        </div>

        <div class="visasis-card">
            <p class="w-max px-4 py-1 mb-2 font-bold rounded uppercase bg-gray-300 border-gray-400">
                Page Content
            </p>
            <div class="grid grid-cols-1 gap-2 p-4 w-full rounded bg-white">
                <div class="w-full flex items-center">
                    <label class="font-bold">Type:</label>
                    <p class="pl-2">{{ data.type }}</p>
                </div>
                <div class="w-full flex items-center">
                    <label class="font-bold">Title:</label>
                    <p class="pl-2">{{ data.title }}</p>
                </div>
                <div class="w-full">
                    <label class="font-bold">Content:</label>
                    <p class="pl-2">{{ data.content }}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import VisaApi from "@/libs/axios"
import Breadcrumb from '@/component/Breadcrumb'
import BackIcon from "../../../data/icons/BackIcon.vue";

export default {
    components: {BackIcon, Breadcrumb,},
    data() {
        return {
            breadcrumb: '',
            data: {},
        };
    },

    methods: {
        getSingleData(id) {
            VisaApi.get("/frontend/page-contents/" + id).then((response) => {
                if (response.code == '200') {
                    this.data = response.data;
                }
            });
        },
    },

    mounted() {
        this.breadcrumb = this.$router.currentRoute.value.meta.breadcrumb;
        this.getSingleData(this.$route.params.id);
    }
}
</script>
