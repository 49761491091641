<template>
    <div class="px-4 py-2 absolute w-full">

        <div class="flex flex-wrap items-center justify-between pb-2">
            <Breadcrumb :text="breadcrumb"/>
            <router-link to="/group-list">
                <button class="back-btn" type="button">
                    <back-icon class="mt-1 mr-2"/>
                    Back to list
                </button>
            </router-link>
        </div>

        <div class="visasis-card">
            <div class="grid grid-cols-1 md:grid-cols-2 gap-x-4 gap-y-2">
                <div class="w-full">
                    <label class="flex items-center">Name <img alt="required" class="w-2 h-2 ml-1"
                                                               src="@/assets/images/star.svg"> </label>
                    <input v-model='form.name'
                           class="py-2 rounded border border-gray-300 bg-gray-50 text-sm placeholder-gray-400 focus:border-invblue resize-none w-full px-4 mt-1"
                           placeholder="Name" type='text'>
                    <span v-if='form.errors().has("name")' class="text-red-800 text-xs">
                        {{ form.errors().get('name') }}
                    </span>
                </div>
                <div class="w-full">
                    <label class="flex items-center">Slug <img alt="required" class="w-2 h-2 ml-1"
                                                               src="@/assets/images/star.svg"> </label>
                    <input v-model='form.slug'
                           class="py-2 rounded border border-gray-300 bg-gray-50 text-sm placeholder-gray-400 focus:border-invblue resize-none w-full px-4 mt-1"
                           placeholder="Slug" type='text'>
                    <span v-if='form.errors().has("slug")' class="text-red-800 text-xs">
                        {{ form.errors().get('slug') }}
                    </span>
                </div>
                <div class="w-full">
                    <label class="flex items-center">Code <img alt="required" class="w-2 h-2 ml-1"
                                                               src="@/assets/images/star.svg"> </label>
                    <input v-model='form.code'
                           class="py-2 rounded border border-gray-300 bg-gray-50 text-sm placeholder-gray-400 focus:border-invblue resize-none w-full px-4 mt-1"
                           placeholder="Code" type='text'>
                    <span v-if='form.errors().has("code")' class="text-red-800 text-xs">
                        {{ form.errors().get('code') }}
                    </span>
                </div>
                <div class="w-full">
                    <label class="flex items-center">Select Type <img alt="required"
                                                                      class="w-2 h-2 ml-1" src="@/assets/images/star.svg">
                    </label>
                    <v-select
                        v-model="selectedType"
                        :options="typeList"
                        class="pt-1"
                        label="title"
                        placeholder="Choose"
                    />
                    <span v-if='form.errors().has("type")' class="text-red-800 text-xs">
                        {{ form.errors().get('type') }}
                    </span>
                </div>
                <div class="w-full">
                    <label>Status</label>
                    <select v-model='form.status'
                            class="py-2 rounded border border-gray-300 bg-gray-50 text-sm placeholder-gray-400 focus:border-invblue resize-none w-full px-4 mt-1">
                        <option :selected="form.status==1" value="1">Active</option>
                        <option :selected="form.status==0" value="0">Inactive</option>
                    </select>
                    <span v-if='form.errors().has("type")' class="text-red-800 text-xs">
                        {{ form.errors().get('type') }}
                    </span>
                </div>
            </div>

            <button v-if="isSubmitting==false" class="mt-4 justify-center rounded-md bg-blue-600 px-6 py-1.5 text-white hover:bg-blue-500" type="button"
                    @click="updateGroup">
                Submit
            </button>
            <span v-else class="mt-4 block">
                <LoadingButton text="Submitting"/>
            </span>

        </div>
    </div>
</template>

<script>
import VisaApi from "@/libs/axios"
import form from 'vuejs-form'
import Breadcrumb from '@/component/Breadcrumb'
import vSelect from "vue-select"
import "vue-select/dist/vue-select.css"
import LoadingButton from "@/component/LoadingButton"
import {sanitizeName,} from '@/libs/helpers.js'
import BackIcon from "../../../data/icons/BackIcon.vue";

export default {
    components: {BackIcon, LoadingButton, Breadcrumb, "v-select": vSelect,},
    data() {
        return {
            breadcrumb: '',
            group_list: [],
            selected_group: [],
            isSubmitting: false,
            selectedType: [{id: 1, title: 'Major'}],
            typeList: [
                {id: 1, title: 'Major'},
                {id: 2, title: 'Sub Major'},
                {id: 3, title: 'Minor'},
                {id: 4, title: 'Unit'},
            ],

            form: form.default({
                id: '',
                uuid: '',
                name: '',
                slug: '',
                code: '',
                type: '1',
                status: '1',
            }).rules({
                name: 'required',
                slug: 'required',
                code: 'required',
                type: 'required',
            }),
        };
    },

    methods: {
        async getGroupList() {
            await VisaApi.get("/anzsco-group").then((response) => {
                if (response.success == true) {
                    this.group_list = response.data;
                }
            });
        },

        updateGroup() {
            if (this.form.validate().errors().any()) {
                return;
            } else {
                this.isSubmitting = true;
                VisaApi.patch("/anzsco-group/update/" + this.form.uuid, this.form.all()).then((response) => {
                    if (response.code == '200') {
                        this.$toast.success(response.message, {
                            position: "top-right",
                            dismissible: true,
                        });
                        this.$router.push({name: 'group-list'})
                    }
                    this.isSubmitting = false;
                }).catch((error) => {
                    this.$toast.error(error.response.data.message, {
                        position: "top-right",
                        dismissible: true,
                    });
                    this.isSubmitting = false;
                });
            }
        },

        getSingleData(uuid) {
            VisaApi.get("/anzsco-group/" + uuid).then((response) => {
                if (response.code == '200') {
                    Object.assign(this.form, response.data);
                    if (response.data.parent_id != null) {
                        let tmp = this.group_list.filter(item => item.id == response.data.parent_id);
                        this.selected_group = tmp[0];
                    }
                    let tmpType = this.typeList.filter(item => item.id == response.data.type);
                    this.selectedType = tmpType[0];
                }
            });
        },
    },

    computed: {
        slug() {
            return this.form.slug = sanitizeName(this.form.name);
        },
    },

    watch: {
        slug() {
        },

        selectedType(value) {
            if (value != null) {
                this.form.type = value.id;
            }
        }
    },

    mounted() {
        this.breadcrumb = this.$router.currentRoute.value.meta.breadcrumb;
        this.getSingleData(this.$route.params.id);
        this.getGroupList();
    }
}
</script>
