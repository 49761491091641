<template>
    <div class="px-4 py-2 absolute w-full">
        <div class="flex flex-wrap items-center justify-between pb-2">
            <Breadcrumb :text="breadcrumb"/>
            <div>
                <button class="px-6 py-2 mx-1 mb-4 lg:mb-0 text-center tracking-wider rounded block lg:inline-flex bg-green-600 hover:bg-green-700 text-white"
                        @click="openModal(true, 'create')">
                    <add-icon class="mr-2 mt-1"/>
                    Add menu item
                </button>
                <router-link to="/menu-list">
                    <button class="back-btn" type="button">
                        <BackIcon class="mr-2 mt-1"/>
                        Back to list
                    </button>
                </router-link>
            </div>
        </div>

        <div class="visasis-card">

            <div class="w-full grid grid-cols-1 gap-2 p-4 rounded bg-white">
                <p class="w-max py-1 mb-2 font-bold rounded border-gray-400 text-xl">Menu Details</p>
                <div class="col-span-3">
                    <div class="w-full flex items-center p-2">
                        <label class="font-bold w-1/12">Title</label>
                        <label class="font-bold w-1/12">:</label>
                        <p class="pl-2">{{ menuDetails.title }}</p>
                    </div>
                    <div class="w-full flex items-center p-2">
                        <label class="font-bold w-1/12">Location</label>
                        <label class="font-bold w-1/12">:</label>
                        <p class="pl-2">{{ menuDetails.location }}</p>
                    </div>
                    <div class="w-full flex items-center p-2">
                        <label class="font-bold w-1/12">Status</label>
                        <label class="font-bold w-1/12">:</label>
                        <p v-if="menuDetails.status==1" class="pl-2">Active</p>
                        <p v-else class="pl-2">Inactive</p>
                    </div>
                </div>
            </div>


            <div class="w-full grid grid-cols-1 gap-2 p-4 mt-5 rounded bg-white">
                <p class="w-max pb-0.5 mb-2 mt-6 lg:mt-0 border-gray-600 font-bold">Menu Items </p>
                <vue-good-table
                    :columns="columns"
                    :rows="menuItems"
                >
                    <template #table-row="props">
                        <div v-if="props.column.field == 'status'">
                            <p v-if="props.row.is_active==1" class="text-green-500">Active</p>
                            <p v-else class="text-orange-500">Inactive</p>
                        </div>
                        <div v-if="props.column.field == 'action'">
                            <div class="px-3 py-2 flex items-center justify-center gap-2">
                                <update-icon @click="openEditModal(props.row)"/>
                                <delete-icon
                                    @click="[id_deletable = props.row.id, is_open_menu_item_delete_modal = true]"/>
                            </div>
                        </div>
                    </template>
                </vue-good-table>
            </div>
        </div>

        <!-- modal -->
        <Modal :isSubmitting="isModalSubmitting" :modal_title='mdl_title' @close="openModal(false, 'create')"
               @submit="handleSubmitMethod">
            <template v-slot:body>
                <div class="grid grid-cols-1 md:grid-cols-2 gap-x-4 gap-y-2 mt-2 px-4">
                    <div class="w-full">
                        <label>Select Parent</label>
                        <v-select
                            v-model="menu_items_selected"
                            :options="menuItems"
                            class="pt-1"
                            label="title"
                            placeholder="Choose"
                        />
                    </div>
                    <div class="w-full">
                        <label class="flex items-center">Title <img alt="required" class="w-2 h-2 ml-1"
                                                                    src="@/assets/images/star.svg"> </label>
                        <input v-model='form.title' class="py-2 rounded border border-gray-300 bg-gray-50 text-sm placeholder-gray-400 focus:border-invblue resize-none w-full px-4 mt-1" placeholder="Title"
                               type='text'>
                        <span v-if='form.errors().has("title")' class="text-red-800 text-xs">
                            {{ form.errors().get('title') }}
                        </span>
                    </div>
                    <div class="w-full">
                        <label>Link</label>
                        <input v-model='form.link' class="py-2 rounded border border-gray-300 bg-gray-50 text-sm placeholder-gray-400 focus:border-invblue resize-none w-full px-4 mt-1" placeholder="Link"
                               type='text'>
                    </div>
                    <div class="w-full">
                        <label>Sort Order</label>
                        <input v-model='form.sort_order' class="py-2 rounded border border-gray-300 bg-gray-50 text-sm placeholder-gray-400 focus:border-invblue resize-none w-full px-4 mt-1" placeholder="sort order"
                               type='text'>
                    </div>
                    <div class="w-full">
                        <label>Status</label>
                        <select v-model='form.is_active'
                                class="py-2 rounded border border-gray-300 bg-gray-50 text-sm placeholder-gray-400 focus:border-invblue resize-none w-full px-4 mt-1">
                            <option :selected="form.is_active=='1'" value="1">Active</option>
                            <option :selected="form.is_active=='0'" value="0">Inactive</option>
                        </select>
                    </div>
                    <div class="w-full">
                        <label>Header Status</label>
                        <select v-model='form.is_header'
                                class="py-2 rounded border border-gray-300 bg-gray-50 text-sm placeholder-gray-400 focus:border-invblue resize-none w-full px-4 mt-1">
                            <option :selected="form.is_header=='1'" value="1">Active</option>
                            <option :selected="form.is_header=='0'" value="0">Inactive</option>
                        </select>
                    </div>
                </div>
            </template>
        </Modal>

        <!-- delete modal -->
        <DeleteModal
            :isDeleteSubmitting="isDeleteSubmitting"
            :is_open="is_open_menu_item_delete_modal"
            modal_title='Delete Menu Item'
            @close="[this.is_open_menu_item_delete_modal = false, this.isDeleteSubmitting = false]"
            @submit="deleteData"
        />

    </div>
</template>

<script>
import VisaApi from "@/libs/axios"
import Breadcrumb from '@/component/Breadcrumb'
import Table from '@/component/Table'
import Modal from '@/component/Modal'
import form from 'vuejs-form'
import vSelect from "vue-select"
import "vue-select/dist/vue-select.css"
import DeleteModal from '@/component/DeleteModal'
import {AddIcon, BackIcon, DeleteIcon, ViewIcon} from "../../../data/icons";
import UpdateIcon from "../../../data/icons/UpdateIcon.vue";
import {VueGoodTable} from 'vue-good-table-next'
import 'vue-good-table-next/dist/vue-good-table-next.css'
import TablePagination from '@/component/TablePagination'
import ToggleSwitch from "../../../component/ui/ToggleSwitch.vue";

export default {
    components: {
        ToggleSwitch,
        UpdateIcon,
        VueGoodTable,
        TablePagination,
        Breadcrumb,
        Table,
        Modal,
        "v-select": vSelect,
        DeleteModal,
        AddIcon,
        DeleteIcon,
        ViewIcon,
        BackIcon
    },
    data() {
        return {
            breadcrumb: '',
            menuDetails: {},
            menuItems: [],
            menuId: '',
            sort_status: false,
            mdl_title: 'Create Menu Item',
            modal_mode: 'create',
            isModalSubmitting: false,
            menu_items_selected: [],
            id_deletable: '',
            isDeleteSubmitting: false,
            is_open_menu_item_delete_modal: false,

            form: form.default({
                id: '',
                parent_id: '',
                title: '',
                link: '',
                sort_order: '',
                is_active: '1',
                is_header: '1',
            }).rules({
                title: 'required',
            }),
            columns: [
                {label: 'Parent', field: 'parent.title'},
                {label: 'Title', field: 'title'},
                {label: 'Link', field: 'link'},
                {label: 'Status', field: 'status'},
                {label: 'Action', field: 'action'},
            ],
            rows: []
        };
    },

    methods: {
        openModal(value, mode) {
            this.form.errors().forget();
            const modalCls = document.getElementById('modal').classList;
            const modalAnimCls = document.getElementById('modal_animation').classList;
            if (value == true && mode == 'create') {
                modalCls.remove('hidden');
                modalAnimCls.add('open-modal-animation');

                this.form.parent_id = '';
                this.form.title = '';
                this.form.link = '';
                this.form.sort_order = '';
                this.form.is_active = '1';
                this.form.is_header = '1';

                this.modal_mode = 'create';
                this.mdl_title = 'Create Menu Item';
            } else if (value == true && mode == 'update') {
                modalCls.remove('hidden');
                modalAnimCls.add('open-modal-animation');
            } else {
                modalCls.add('hidden');
                modalAnimCls.remove('open-modal-animation');
                this.isModalSubmitting = false;
            }
        },

        handleSubmitMethod() {
            if (this.modal_mode == 'create') {
                this.createMenuItem();
            } else {
                this.updateMenuItem();
            }
        },

        createMenuItem() {
            if (this.form.validate().errors().any()) {
                return;
            } else {
                this.isModalSubmitting = true;
                VisaApi.post("/frontend/menus/" + this.menuId + "/menu-items", this.form.all()).then((response) => {
                    if (response.code == '201') {
                        this.$toast.success(response.message, {
                            position: "top-right",
                            dismissible: true,
                        });
                        this.getMenuItems();
                    }
                    this.openModal(false, 'create');
                }).catch((error) => {
                    this.$toast.error(error.response.data.message, {
                        position: "top-right",
                        dismissible: true,
                    });
                    this.isModalSubmitting = true;
                });
            }
        },

        openEditModal(data) {
            Object.assign(this.form, data);
            if (data.parent != null) {
                let tmp = this.menuItems.filter(item => item.id == data.parent.id);
                this.menu_items_selected = tmp[0];
            }
            this.mdl_title = 'Update Menu Item';
            this.modal_mode = 'update';
            this.openModal(true, 'update');
        },

        updateMenuItem() {
            this.isModalSubmitting = true;
            VisaApi.patch("/frontend/menus/" + this.menuId + "/menu-items/" + this.form.id, this.form.all()).then((response) => {
                if (response.code == '200') {
                    this.$toast.success(response.message, {
                        position: "top-right",
                        dismissible: true,
                    });
                    this.getMenuItems();
                    this.openModal(false, 'update');
                }
                this.isModalSubmitting = false;
            }).catch((error) => {
                this.$toast.error(error.response.data.message, {
                    position: "top-right",
                    dismissible: true,
                });
                this.isModalSubmitting = false;
            });
        },

        deleteData() {
            this.isDeleteSubmitting = true;
            VisaApi.delete("frontend/menus/" + this.menuId + "/menu-items/" + this.id_deletable).then((response) => {
                if (response.code == '200') {
                    this.menuItems = this.menuItems.filter(item => item.id !== this.id_deletable);
                    this.$toast.success(response.message, {
                        position: "top-right",
                        dismissible: true,
                    });
                    this.is_open_menu_item_delete_modal = false
                }
            }).catch((error) => {
                if (error.response.data.code == '400') {
                    this.$toast.error('This menu item has a child item!', {
                        position: "top-right",
                        dismissible: true,
                    });
                    this.isDeleteSubmitting = false;
                    this.is_open_menu_item_delete_modal = false
                } else {
                    this.$toast.error(error.response.data.message, {
                        position: "top-right",
                        dismissible: true,
                    });
                    this.isDeleteSubmitting = false;
                    this.is_open_menu_item_delete_modal = false
                }
            });
        },

        getMenu() {
            VisaApi.get("/frontend/menus/" + this.menuId).then((response) => {
                if (response.code == '200') {
                    this.menuDetails = response.data;
                }
            });
        },

        getMenuItems() {
            VisaApi.get('frontend/menus/' + this.menuId + '/menu-items?').then((response) => {
                if (response.code == '200') {
                    this.menuItems = response.data;
                }
            });
        },

        toggleSort() {
            if (this.sort_status == false) {
                this.menuItems.sort((a, b) => a.title.localeCompare(b.title));
                this.sort_status = true;
            } else {
                this.menuItems.sort((a, b) => b.title.localeCompare(a.title));
                this.sort_status = false;
            }
        },
    },

    watch: {
        menu_items_selected(menu_items) {
            if (menu_items != null) {
                this.form.parent_id = menu_items.id;
            }
        },
    },

    mounted() {
        this.breadcrumb = this.$router.currentRoute.value.meta.breadcrumb;
        this.menuId = this.$route.params.id;
        this.getMenu();
        this.getMenuItems();
    }
}
</script>
