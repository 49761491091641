<template>
    <!-- Import -->
    <Transition name="fade">
        <div v-if="is_open" id="modal" class="relative z-[999]">
            <div class="fixed inset-0 bg-gray-500 bg-opacity-75"></div>
            <div class="fixed inset-0 z-10 overflow-y-auto">
                <div class="flex min-h-full items-center justify-center text-center sm:items-center sm:p-0">
                    <div
                        class="relative overflow-hidden rounded-lg bg-white text-left shadow-xl sm:my-8 sm:w-full lg:w-2/3 xl:w-1/3">
                        <div class="bg-white text-gray-600 pb-2">
                            <h3 class="py-2.5 px-4 text-lg leading-6 border-b bg-slate-700 text-white">{{ title }}</h3>
                            <slot name="body"> This is the default body!</slot>
                        </div>
                        <div class="bg-gray-200 py-2 px-4 flex justify-end items-center">
                            <button class="rounded-md border border-transparent bg-red-600 px-4 py-1 mr-3 text-white hover:bg-red-700" type="button"
                                    @click="closeModal">
                                Cancel
                            </button>
                            <span>
                            <button v-if="is_submitting==false" class="rounded-md border border-transparent bg-green-600 px-4 py-1 text-white hover:bg-green-700" type="button"
                                    @click="submitModal">
                                Submit
                            </button>
                            <span v-else>
                                <LoadingButton text="Submitting"/>
                            </span>
                        </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Transition>
</template>

<script setup>
import LoadingButton from "@/component/LoadingButton"

const props = defineProps({
    title: {
        required: true
    },
    is_open: {
        required: true,
        default: false
    },
    is_submitting: {
        required: false
    },
})


const emit = defineEmits(["close", "submit"])

const closeModal = () => {
    emit("close")
}
const submitModal = () => {
    emit("submit")
}
</script>

<style scoped>

</style>
