<template>
    <div class="px-4 py-2 absolute w-full">

        <Breadcrumb :text="breadcrumb"/>

        <div class="visasis-card">

            <div class="flex flex-wrap items-center -mx-4">
                <TableEntryShow @input="[meta.current_page = 1, meta.per_page = $event]"/>
                <TableTopActions :is_modal="true" @create="openModal(true, 'create')"
                                 @input="[meta.current_page = 1, search_input = $event]"/>
            </div>

            <vue-good-table
                :columns="columns"
                :rows="rows"
            >
                <template #table-row="props">
                    <div v-if="props.column.field == 'stream_name'">
                        {{ visaStreamDetails(props.row.visa_stream_id) }}
                    </div>

                    <div v-if="props.column.field == 'pathway_from'">
                        <span v-html="pathwayStream(props.row.pathway_from)"></span>
                    </div>

                    <div v-if="props.column.field == 'pathway_to'">
                        <span v-html="pathwayStream(props.row.pathway_to)"></span>
                    </div>

                    <div v-if="props.column.field == 'action'">
                        <div class="px-3 py-2 flex items-center justify-center">
                            <update-icon
                                @click="[modal_mode = 'update', setData(props.row), openModal(true, 'update')]"/>
                            <delete-icon @click="openDeleteModal(props.row.uuid)"/>
                        </div>
                    </div>
                </template>
            </vue-good-table>

            <TablePagination
                :inputPage="meta.current_page"
                :meta='meta'
                :next_of_next='next_of_next'
                :prev_of_prev='prev_of_prev'
                @update:inputPage="updatePageValue"
            />
        </div>

        <!-- modal -->
        <Modal :isSubmitting="isModalSubmitting" :modal_title='modal_title' @close="openModal(false, 'create')"
               @submit="handleSubmitMethod">
            <template v-slot:body>
                <div class="grid grid-cols-1 mt-2 px-2">
                    <div class="w-full">
                        <label class="flex items-center">Select stream <img alt="required"
                                                                            class="w-2 h-2 ml-1"
                                                                            src="@/assets/images/star.svg"> </label>
                        <v-select
                            v-model="pathway.visa_stream_id"
                            :get-option-label="(option) => option.visa_subclass + ' - ' + option.name"
                            :options="visa_streams"
                            :reduce="stream => stream.id"
                            class="pt-1"
                            placeholder="Choose stream..."
                            required
                        />
                    </div>
                </div>

                <div class="grid grid-cols-1 md:grid-cols-2 gap-x-4 gap-y-2 mt-2 px-2">
                    <div class="w-full">
                        <label class="flex items-center">Pathway from</label>
                        <v-select
                            v-model="pathway.pathway_from"
                            :get-option-label="(option) => option.visa_subclass + ' - ' + option.name"
                            :options="visa_streams"
                            :reduce="stream => stream.id"
                            class="pt-1"
                            multiple
                            placeholder="Choose pathway from..."
                        />
                    </div>

                    <div class="w-full">
                        <label class="flex items-center">Pathway to</label>
                        <v-select
                            v-model="pathway.pathway_to"
                            :get-option-label="(option) => option.visa_subclass + ' - ' + option.name"
                            :options="visa_streams"
                            :reduce="stream => stream.id"
                            class="pt-1"
                            multiple
                            placeholder="Choose pathway to..."
                        />
                    </div>

                    <div class="w-full">
                        <label class="flex items-center">Note From</label>
                        <textarea v-model='pathway.note_from' class="py-2 rounded border border-gray-300 bg-gray-50 text-sm placeholder-gray-400 focus:border-invblue resize-none w-full px-4 mt-1" cols="50" placeholder="Note"
                                  rows="4"></textarea>
                    </div>

                    <div class="w-full">
                        <label class="flex items-center">Note to</label>
                        <textarea v-model='pathway.note_to' class="py-2 rounded border border-gray-300 bg-gray-50 text-sm placeholder-gray-400 focus:border-invblue resize-none w-full px-4 mt-1" cols="50" placeholder="Note"
                                  rows="4"></textarea>
                    </div>
                </div>
            </template>
        </Modal>

        <!-- delete modal -->
        <DeleteModal
            :isDeleteSubmitting="isDeleteSubmitting"
            :is_open="is_open_delete_modal"
            modal_title='Delete Visa Stream'
            @close="closeDeleteModal"
            @submit="deleteItem"
        />

    </div>
</template>

<script setup>
import UpdateIcon from "../../../data/icons/UpdateIcon.vue";
import DeleteIcon from "../../../data/icons/DeleteIcon.vue";
import TableEntryShow from "../../../component/table/TableEntryShow.vue";
import TableTopActions from "../../../component/table/TableTopActions.vue";
import VisaApi from "@/libs/axios"
import Modal from '@/component/Modal'
import DeleteModal from '@/component/DeleteModal'
import vSelect from "vue-select"
import "vue-select/dist/vue-select.css"
import store from '@/store'
import Breadcrumb from '@/component/Breadcrumb'
import TablePagination from '@/component/TablePagination'
import {VueGoodTable} from 'vue-good-table-next'
import 'vue-good-table-next/dist/vue-good-table-next.css'
import {meta_data} from '@/libs/helpers.js'
import useDelete from "../../../composables/delete";
import useModal from "../../../composables/modal";
import {useRouter} from "vue-router";
import {computed, defineComponent, onMounted, reactive, ref, watch} from "vue";
import {toaster} from "../../../libs/helpers";

defineComponent({
    'v-select': vSelect
})

const router = useRouter()
const breadcrumb = ref('')
const search_input = ref('')
const prev_of_prev = ref(false)
const next_of_next = ref(false)
const meta = reactive(meta_data)
const pathway = reactive({
    uuid: '',
    visa_stream_id: '',
    pathway_from: [],
    pathway_to: [],
    note_from: '',
    note_to: '',
})
const columns = reactive([
    {label: 'Visa stream', field: 'stream_name'},
    {label: 'Pathway From', field: 'pathway_from', width: '350px'},
    {label: 'Note From', field: 'note_from'},
    {label: 'Pathway To', field: 'pathway_to', width: '350px'},
    {label: 'Note To', field: 'note_to'},
    {label: 'Action', field: 'action', sortable: false, width: '100px'}
])
const rows = ref([])
const visa_streams = ref([])

const getVisaPathwayList = () => {
    VisaApi.get(apiUrl.value).then((res) => {
        rows.value = res.data.data
        Object.assign(meta, res.data.meta);
    })
}

const getVisaStreamList = () => {
    store.dispatch('visa/getAllVisaStream').then((res) => {
        visa_streams.value = res
    });
}
const createVisaPathway = () => {
    VisaApi.post('/visa-pathway/create', pathway).then((res) => {
        toaster.success(res.message)
        getVisaPathwayList()
    }).catch((err) => {
        toaster.error(err.response.data.message)
        console.log(err)
    })
}

const visaStreamDetails = (stream_id) => {
    let visa_stream = visa_streams.value.find((st) => st.id == stream_id)
    if (visa_stream) {
        return visa_stream.visa_subclass + ' - ' + visa_stream.name;
    }
    return '';
}

const pathwayStream = (stream_ids) => {
    let $visa_streams = visa_streams.value.filter((st) => stream_ids.includes(st.id.toString()))
    if ($visa_streams) {
        let st_html = '';
        $visa_streams.forEach((stream) => {
            st_html += '<span class="bg-gray-50 px-3 text-sm rounded-md">' + stream.visa_subclass + ' - ' + stream.name + '</span><br/>'
        })
        return st_html;
    }
    return '';
}

const setData = (row) => {
    Object.assign(pathway, {
        uuid: row.uuid,
        visa_stream_id: row.visa_stream_id,
        pathway_from: row.pathway_from.map(str => Number(str)),
        pathway_to: row.pathway_to.map(str => Number(str)),
        note_from: row.note_from,
        note_to: row.note_to,
    })
}

const updateVisaPathway = () => {
    VisaApi.patch(`/visa-pathway/update/${pathway.uuid}`, pathway).then((res) => {
        toaster.success(res.message)
        getVisaPathwayList()
    }).catch((err) => {
        console.log(err)
        toaster.error(err.response.data.message)
    })
}

const itemDeleted = async () => {
    await getVisaPathwayList()
}

const updatePageValue = (current_page) => {
    meta.current_page = current_page
}

const {
    isDeleteSubmitting,
    is_open_delete_modal,
    uuid_deletable,
    openDeleteModal,
    closeDeleteModal,
    deleteItem
} = useDelete('/visa-pathway', itemDeleted)
const {
    modal_title,
    modal_mode,
    isModalSubmitting,
    openModal,
    handleSubmitMethod
} = useModal(pathway, createVisaPathway, updateVisaPathway)

const apiUrl = computed(() => 'visa-pathway?page=' + meta.current_page + '&per_page=' + meta.per_page + '&order_by=id&order_by_type=desc&search=' + search_input.value)
watch(apiUrl, async () => {
    await getVisaPathwayList()
})

onMounted(async () => {
    breadcrumb.value = router.currentRoute.value.meta.breadcrumb;
    await getVisaStreamList()
    await getVisaPathwayList()
})

</script>

<style scoped>

</style>
