<template>
    <div class="p-4">
        <div class="flex flex-wrap items-center justify-between pb-4 border-b border-gray-300">
            <Breadcrumb :text="breadcrumb"/>
            <router-link to="/occupation-alternative">
                <button class="rounded-md bg-orange-600 px-6 py-1.5 text-white hover:bg-orange-500" type="button">
                    Back to list
                </button>
            </router-link>
        </div>

        <div class="p-2 mt-1 h-full rounded bg-slate-100">
            <div class="grid grid-cols-1 lg:grid-cols-2 gap-4">
                <div class="w-full flex items-center">
                    <label class="font-bold">Occupation Name:</label>
                    <p v-if="data.occupation!=null" class="pl-2">{{ data.occupation.name }}</p>
                </div>
                <div class="w-full">
                    <label class="font-bold">Titles:</label>
                    <p v-for="(title, index) in data.titles" :key="index">{{ title }}</p>
                </div>
                <div class="w-full flex items-center">
                    <label class="font-bold">Status:</label>
                    <p v-if="data.status==1" class="pl-2">Active</p>
                    <p v-else class="pl-2">Inactive</p>
                </div>
                <div class="w-full flex items-center">
                    <label class="font-bold">Description:</label>
                    <p class="pl-2">{{ data.description }}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import VisaApi from "@/libs/axios"
import Breadcrumb from '@/component/Breadcrumb'

export default {
    components: {Breadcrumb,},
    data() {
        return {
            breadcrumb: '',
            data: {},
        };
    },

    methods: {
        getSingleData(uuid) {
            VisaApi.get("/occupation-alternative/" + uuid).then((response) => {
                if (response.code == '200') {
                    this.data = response.data;
                }
            });
        },
    },

    mounted() {
        this.breadcrumb = this.$router.currentRoute.value.meta.breadcrumb;
        let id = this.$route.params.id;
        this.getSingleData(id);
    }
}
</script>
