<template>
    <div v-if="is_loading" class="loading">
        <div class="loading-inner">
            <div></div>
            <div></div>
            <div></div>
        </div>
    </div>
</template>

<script>
import store from '../store'
import {computed} from "vue";

export default {
    setup() {
        const is_loading = computed(() => store.getters["setting/loading"])
        return {
            is_loading
        }
    }
}
</script>

<style>
.loading {
    background-color: aliceblue;
    height: 100%;
    width: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: .5;
    z-index: 1;
}

.loading-inner {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}

.loading-inner div {
    display: inline-block;
    position: absolute;
    left: 8px;
    width: 16px;
    background: #fff;
    animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}

.loading-inner div:nth-child(1) {
    left: 8px;
    animation-delay: -0.24s;
    background: #ff0000;
}

.loading-inner div:nth-child(2) {
    left: 32px;
    animation-delay: -0.12s;
    background: #ff0000;
}

.loading-inner div:nth-child(3) {
    left: 56px;
    animation-delay: 0;
    background: #ff0000;
}

@keyframes lds-facebook {
    0% {
        top: 8px;
        height: 64px;
    }
    50%, 100% {
        top: 24px;
        height: 32px;
    }
}

</style>
