import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/tailwind.css'
import Toaster from "@meforma/vue-toaster";
import 'ckeditor5/ckeditor5.css';
import './assets/custom.css'

const app = createApp(App).use(store).use(router).use(Toaster)

// Global error handler
if (process.env.NODE_ENV != 'production') {
    app.config.errorHandler = (err) => {
        console.error("Global error:", err);
    };
}

app.mount('#app')
