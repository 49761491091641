import {ref} from "vue";
import VisaApi from "@/libs/axios"
import {toaster} from '@/libs/helpers.js'

export default function useDelete(path, itemDeleted) {
    const isDeleteSubmitting = ref(false);
    const is_open_delete_modal = ref(false);
    const uuid_deletable = ref();

    const openDeleteModal = (uuid) => {
        is_open_delete_modal.value = true;
        uuid_deletable.value = uuid;
    }

    const closeDeleteModal = () => {
        isDeleteSubmitting.value = false;
        is_open_delete_modal.value = false;
    }

    const deleteItem = () => {
        isDeleteSubmitting.value = true;
        VisaApi.delete(path + '/' + uuid_deletable.value).then((response) => {
            if (response.code == '200') {
                toaster.success(response.message)
            }
            closeDeleteModal();
            itemDeleted();
        }).catch((error) => {
            toaster.error(error.response.data.message)
            closeDeleteModal();
        });
    }

    return {
        isDeleteSubmitting,
        is_open_delete_modal,
        uuid_deletable,
        openDeleteModal,
        closeDeleteModal,
        deleteItem
    }
}
