<template>
    <div class="px-4 py-2 absolute w-full">

        <Breadcrumb :text="breadcrumb"/>

        <div class="visasis-card">
            <div class="flex flex-wrap items-center justify-end -mx-4">
                <TableEntryShow @input="[meta.current_page = 1, meta.per_page = $event]"/>
                <TableTopActions to="/anzsco-details-create" @input="[meta.current_page = 1, search_input = $event]"/>
            </div>
            <vue-good-table
                :columns="columns"
                :rows="rows"
            >
                <template #table-row="props">
                    <div v-if="props.column.field == 'type'">
                        <p v-show="props.row.type==1">Major</p>
                        <p v-show="props.row.type==2">Sub Major</p>
                        <p v-show="props.row.type==3">Minor</p>
                        <p v-show="props.row.type==4">Unit</p>
                    </div>
                    <div v-if="props.column.field == 'description'">
                        <span v-html="props.row.description"></span>
                    </div>
                    <div v-if="props.column.field == 'action'">
                        <div class="px-3 py-2 flex items-center">
                            <router-link :to="{ name: 'anzsco-details-view', params: {id:props.row.uuid} }">
                                <view-icon/>
                            </router-link>
                            <router-link :to="{ name: 'anzsco-details-update', params: {id:props.row.uuid} }">
                                <update-icon/>
                            </router-link>
                            <delete-icon @click="[uuid_deletable = props.row.uuid, is_open_delete_modal = true]"/>
                        </div>
                    </div>
                </template>
            </vue-good-table>
            <TablePagination
                :inputPage="currentPage"
                :meta='meta'
                :next_of_next='next_of_next'
                :prev_of_prev='prev_of_prev'
                @update:inputPage="updatePageValue"
            />
        </div>

        <!-- delete modal -->
        <DeleteModal
            :isDeleteSubmitting="isDeleteSubmitting"
            :is_open="is_open_delete_modal"
            modal_title='Delete Group'
            @close="[isDeleteSubmitting = false, is_open_delete_modal = false]"
            @submit="deleteGroup"
        />

    </div>
</template>

<script>
import VisaApi from "@/libs/axios"
import store from '@/store'
import DeleteModal from '@/component/DeleteModal'
import ImportModal from "../../../component/ui/modals/ImportModal.vue";
import Table from '@/component/Table'
import Breadcrumb from '@/component/Breadcrumb'
import TablePagination from '@/component/TablePagination'
import form from 'vuejs-form'
import LoadingButton from "@/component/LoadingButton"
import {VueGoodTable} from 'vue-good-table-next'
import 'vue-good-table-next/dist/vue-good-table-next.css'
import {meta_data, next_of_next_status, prev_of_prev_status,} from '@/libs/helpers.js'
import {DownloadIcon} from "../../../data/icons";
import ViewIcon from "../../../data/icons/ViewIcon.vue";
import UpdateIcon from "../../../data/icons/UpdateIcon.vue";
import DeleteIcon from "../../../data/icons/DeleteIcon.vue";
import AddIcon from "../../../data/icons/AddIcon.vue";
import TableEntryShow from "../../../component/table/TableEntryShow.vue";
import TableTopActions from "../../../component/table/TableTopActions.vue";

export default {
    components: {
        TableTopActions, TableEntryShow,
        AddIcon,
        DeleteIcon,
        UpdateIcon,
        ViewIcon,
        DownloadIcon, DeleteModal, Table, Breadcrumb, TablePagination, LoadingButton, VueGoodTable, ImportModal
    },
    data() {
        return {
            uuid_deletable: '',

            meta: meta_data,
            prev_of_prev: false,
            next_of_next: false,
            per_page_array: [10, 20, 30, 40, 50],
            currentPage: '',

            search_input: '',
            breadcrumb: '',
            url: '',
            sort_status: false,
            isSubmitting: false,
            isDeleteSubmitting: false,
            is_open_import_modal: false,
            is_open_delete_modal: false,

            form: form.default({
                file: '',
            }).rules({
                file: 'required',
            }),

            columns: [
                {label: 'Code', field: 'code'},
                {label: 'Type', field: 'type'},
                {label: 'Description', field: 'description', width: '50%'},
                {label: 'Action', field: 'action', sortable: false, width: '100px'}
            ],
            rows: [],

        };
    },

    methods: {

        async getAnzscoDetailsList() {
            store.commit('setting/LOADING_ON')
            await VisaApi.get(this.apiUrl).then((response) => {
                store.commit('setting/LOADING_OFF')
                if (response.success == true) {
                    if (response.data.meta.to > 50) {
                        this.meta.per_page = 'All'
                    } else {
                        Object.assign(this.meta, response.data.meta);
                    }
                    this.rows = response.data.data;
                }
            });
        },

        deleteGroup() {
            this.isDeleteSubmitting = true;
            VisaApi.delete("/anzsco-details/" + this.uuid_deletable).then((response) => {
                if (response.success == true) {
                    this.getGroupList();
                    this.$toast.success(response.message, {
                        position: "top-right",
                        dismissible: true,
                    });
                    this.isDeleteSubmitting = false;
                    this.is_open_delete_modal = false
                }
            }).catch((error) => {
                this.isDeleteSubmitting = false
                this.is_open_delete_modal = false
                if (error.response.data.code == '400') {
                    this.$toast.error('This category has a child item!', {
                        position: "top-right",
                        dismissible: true,
                    });

                } else {
                    this.$toast.error(error.response.data.message, {
                        position: "top-right",
                        dismissible: true,
                    });
                }
            });
        },

        closeImportModal() {
            this.isSubmitting = false
            this.is_open_import_modal = false
        },

        importExcelFile(file) {
            if (file == '') {
                this.$toast.error('No file selected!', {
                    position: "top-right",
                    dismissible: true,
                });
                return;
            } else {
                VisaApi.post("/anzsco-details/import", {file: file}, {headers: {"Content-Type": "multipart/form-data"}}).then((response) => {
                    this.$toast.success(response.message, {
                        position: "top-right",
                        dismissible: true,
                    });
                    this.closeImportModal();
                    this.getGroupList();
                }).catch((error) => {
                    this.$toast.error(error.response.data.message, {
                        position: "top-right",
                        dismissible: true,
                    });
                    this.closeImportModal();
                });
            }
        },

        updatePageValue(newValue) {
            this.currentPage = newValue;
        },
    },

    computed: {
        apiUrl() {
            return 'anzsco-details?page=' + this.meta.current_page + '&per_page=' + this.meta.per_page + '&order_by=code&order_by_type=desc&search=' + this.search_input;
        },
        nextOfNext() {
            this.next_of_next = next_of_next_status(this.meta);
        },
        prevOfPrev() {
            this.prev_of_prev = prev_of_prev_status(this.meta);
        },
    },

    watch: {
        apiUrl() {
            this.getAnzscoDetailsList();
        },
        nextOfNext() {
        },
        prevOfPrev() {
        },
    },

    mounted() {
        this.breadcrumb = this.$router.currentRoute.value.meta.breadcrumb;
        this.getAnzscoDetailsList();
    }
}
</script>
